/**
 * Created on: 2022-09-06T08:19:59.3865158+00:00
 */
export class Patient_Attribute {
  public constructor() {
    //
  }

  protected _characteristicId!: number;
  protected _date!: Date;

  /**
   * @returns _characteristicId
   */
  public get characteristicId() {
    return this._characteristicId;
  }

  /**
   * Sets _characteristicId
   */
  public set characteristicId(value: number) {
    this._characteristicId = value;
  }

  /**
   * @returns _date
   */
  public get date() {
    return this._date;
  }

  /**
   * Sets _date
   */
  public set date(value: Date) {
    this._date = value;
  }

  // Start Userdefined content - don't change anything above
  // End Userdefined content - don't change anything below
}
