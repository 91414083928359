import { MarkingPoint } from '@/types/api/Patient/Anatomy/MarkingPoint';

/**
 * Created on: 2022-09-06T08:19:59.4261466+00:00
 */
export class BodyPart {
  public constructor() {
    //
  }

  protected _bodyPartId!: number;
  protected _name!: string;
  protected _markingPoints!: MarkingPoint[];

  /**
   * @returns _bodyPartId
   */
  public get bodyPartId() {
    return this._bodyPartId;
  }

  /**
   * Sets _bodyPartId
   */
  public set bodyPartId(value: number) {
    this._bodyPartId = value;
  }

  /**
   * @returns _name
   */
  public get name() {
    return this._name;
  }

  /**
   * Sets _name
   */
  public set name(value: string) {
    this._name = value;
  }

  /**
   * @returns _markingPoints
   */
  public get markingPoints() {
    return this._markingPoints;
  }

  /**
   * Sets _markingPoints
   */
  public set markingPoints(value: MarkingPoint[]) {
    this._markingPoints = value;
  }

  // Start Userdefined content - don't change anything above

  public deepCopy(toCopy: BodyPart): BodyPart {
    if (!toCopy) return toCopy;

    const bodyPart = {
      name: toCopy.name,
      markingPoints: [] as MarkingPoint[]
    } as BodyPart;

    for(const copyMarkingPoint of toCopy.markingPoints ?? []) {
      this.markingPoints.push(new MarkingPoint().deepCopy(copyMarkingPoint));
    }

    return bodyPart
  }

  // End Userdefined content - don't change anything below
}
