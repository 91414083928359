<template>
  <div class="not-found">
    <h1>
      {{ $t('views.default.not-found.header') }}
    </h1>
    <p>
      {{ $t('views.default.not-found.info') }}
    </p>
    <br />
    <router-link to="/">
      <el-button type="primary" round>
        {{ $t('views.default.not-found.submit') }}
      </el-button>
    </router-link>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {},
})
export default class NotFound extends Vue {}
</script>

<style lang="scss" scoped>
.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
  text-align: center;
  padding: 1rem;
  background-size: cover;
}
</style>
