<template>
  <custom-header class="p-4" :isEditor="false" :workflowModel="null" :authentication="authentication" :supplier="supplierUrl"/>
  <div class="p-4  flex-grow-1 overflow-auto" v-loading="productsLoading" element-loading-background="transparent">
    <!-- Product category overview -->
    <div>
      <!-- Title -->
      <h1>
        {{ $t('views.customize.workflow.productOverview.titlePart1') }}<br />
        {{ $t('views.customize.workflow.productOverview.titlePart2') }}
      </h1>

      <button v-if="!isHomepage" type="button" class="btn btn-secondary mb-3" @click="$router.back()">
        {{ '< ' + $t('components.embossing-footer.backButton') }}
      </button>

      <!-- Product categories -->
      <div class="d-flex flex-wrap">
        <div class="mr-4 mb-3"
             v-for="product in getProductTypes()"
             :key="product.type.productTypeId">
          <product-card
              :title="product.type.name"
              :primary-button-text="$t('views.customize.workflow.productOverview.productCardButton')"
              @primary-button-clicked="supplierUrl ? 
                $router.push(`customize-workflow/?supplier=${supplierUrl}&productTypeId=${product.type.productTypeId}`) : 
                $router.push(`customize-workflow/?productTypeId=${product.type.productTypeId}`)">
            <preview-renderer-img :meshes="[{
                        thumbnail: product.name,
                        thumbnailUrl: product.thumbnail.content
                      }]"
                      imgSize="10.5625" />
          </product-card>
        </div>
      </div>

      <!-- Config upload button -->
      <!--<button class="btn btn-primary mt-3 mb-3">{{ $t('views.customize.workflow.productOverview.configUploadButton') }}</button>-->

      <!-- FAQ -->
      <!--<h2>{{ $t('views.customize.workflow.productOverview.faqTitle') }}</h2>
      <ul class="form-text p-0">
        <li>1. Lorem ipsum dolor sit amet, consetetur sadipscing</li>
        <li>2. elitr, sed diam nonumy eirmod tempor invidunt</li>
        <li>3. ut labore et dolore magna aliquyam</li>
        <li>4. erat, sed diam voluptua.</li>
        <li>5. At vero eos et accusam et justo duo dolores et</li>
        <li>6. ea rebum. Stet clita</li>
      </ul>-->
    </div>
  </div>
  <custom-footer :isEditor="false" class="p-4" />
</template>

<script lang="ts">
import Auth from '@/services/api/auth';
import { User } from '@/types/api/User/User';
import { Options, Vue } from 'vue-class-component';
import CustomHeader from "@/components/CustomHeader.vue";
import CustomFooter from "@/components/CustomFooter.vue";
import ProductCard from '@/components/ProductCard.vue';
import * as modelService from '@/services/api/modelService';
import { Model2 } from '@/types/api/Model/Model';
import { Product_Type } from '@/types/api/Model/ProductType';
import { Product } from '@/types/api/Model/Product';
import PreviewRendererImg from '@/components/three/PreviewRendererImg.vue';

@Options({
  components: {
    ProductCard,
    CustomFooter,
    CustomHeader,
    PreviewRendererImg
  },
})

export default class CustomizeOverview extends Vue {
  authentication = Auth.getInstance();
  user: User | null = null;
  modelList: Model2[] = [];
  productsLoading = false;
  supplierUrl: string | null = null;
  isHomepage = false;

  mounted(): void {
    this.productsLoading = true;
    this.supplierUrl = this.$route.params.supplier == undefined ? null : this.$route.params.supplier.toString();
    this.isHomepage = this.supplierUrl != null;
    this.authentication.handleAuthentication(this.supplierUrl).then(() => {
      //get user information
      this.user = this.authentication.user;
      const domain = this.authentication.primaryDomain;
      if (this.user) {
        modelService.getModelsByUserId(this.user.userId, null)
          .then((models) => {
            if (models) {
              const mList = models.filter((item) => !item.basedOn);
              this.modelList = mList;
              const productTypes = this.getProductTypes();
              if (productTypes.length == 1) {
                const product = productTypes[0];
                this.$router.push(`customize-workflow/?productTypeId=${product.type.productTypeId}`);
              }
            } else 
              this.modelList = [];
          })
          .finally(() => {
            this.productsLoading = false;
          });
      } else if (domain) {
        modelService.getModelsByUrlSuffix(this.supplierUrl!, null)
          .then((models) => {
            if (models) {
              const mList = models.filter((item) => !item.basedOn);
              this.modelList = mList; 
              const productTypes = this.getProductTypes();
              if (productTypes.length == 1) {
                const product = productTypes[0];
                this.$router.push(`customize-workflow/?supplier=${this.supplierUrl}&productTypeId=${product.type.productTypeId}`);
              }
            } else {
              this.modelList = [];
            }
          })
          .finally(() => {
            this.productsLoading = false;
          });
      } else {
        this.$router.replace(`/page/not-found`);
      }
    });
  }

  getProductTypes(): Product[] {
    const productTypeList: Product[] = [];
    for(const model of this.modelList) {
      if (productTypeList.findIndex(pt => pt.type.productTypeId == model.product.type.productTypeId) === -1) {
        productTypeList.push(model.product);
      }
    }
    return productTypeList;
  }
}
</script>