export function createPdfOfferTemplate(
    imageLogo: string,
    title: string,
    imageProduct: string,
    productName: string,
    productPrice: string,
    productDescription: string,
): HTMLElement {
    // Creating the main div element
    const container = document.createElement('div');
    container.style.boxSizing = 'border-box';
    container.style.backgroundColor = '#fff';
    container.style.minHeight = '270mm';

    // Adding logo
    const logo = document.createElement('img');
    logo.src = imageLogo;
    logo.style.height = '40px';
    logo.style.display = 'block';
    logo.style.marginLeft = 'auto';
    logo.style.marginRight = 'auto';
    logo.style.marginBottom = '40px';
    container.appendChild(logo);

    // Adding title
    const mainHeading = document.createElement('h1');
    mainHeading.textContent = title;
    mainHeading.style.marginBottom = '80px';
    mainHeading.style.textAlign = 'center';
    container.appendChild(mainHeading);

    // Craete table
    const table = document.createElement('table');
    table.style.borderCollapse = 'collapse';
    table.style.textAlign = 'left';
    table.style.width = '100%';
    // table.style.border = '1px solid';
    // table.style.borderColor = '#dddddd';

    // Adding the first row
    const row1 = document.createElement('tr');

    // Product image column
    const productImageCell = document.createElement('td');
    productImageCell.rowSpan = 2;
    productImageCell.style.width = '100px';
    productImageCell.style.paddingTop = '10px';

    const productImage = document.createElement('img');
    productImage.src = imageProduct;
    productImage.width = 100;
    productImageCell.appendChild(productImage);
    row1.appendChild(productImageCell);

    // Product title column
    const productTitle = document.createElement('th');
    productTitle.textContent = productName; // Product Name
    productTitle.style.padding = '10px'
    productTitle.style.paddingTop = '0px !important'
    row1.appendChild(productTitle);

    // Product quantity column
    const quantityCell = document.createElement('td');
    quantityCell.textContent = '1 Stk';
    quantityCell.style.padding = '10px'
    productTitle.style.paddingTop = '0px !important'
    quantityCell.style.fontWeight = 'normal';
    row1.appendChild(quantityCell);

    // Product price column
    const priceCell = document.createElement('th');
    priceCell.textContent = `€ ${productPrice}`;
    priceCell.style.padding = '10px'
    productTitle.style.paddingTop = '0px !important'
    row1.appendChild(priceCell);

    table.appendChild(row1);

    // Adding a second row
    const row2 = document.createElement('tr');

    const descriptionCell = document.createElement('td');
    descriptionCell.colSpan = 3;
    descriptionCell.style.padding = '10px'
    descriptionCell.style.fontWeight = 'normal'
    descriptionCell.innerHTML = productDescription;
    row2.appendChild(descriptionCell);

    table.appendChild(row2);

    // Adding a table to a container
    container.appendChild(table);

    const bottomLine = document.createElement('div');
    bottomLine.style.width = '100%';
    bottomLine.style.height = '2px';
    bottomLine.style.backgroundColor = '#dddddd';
    bottomLine.style.marginTop = '20px';
    bottomLine.style.marginBottom = '40px';
    container.appendChild(bottomLine);

    // Adding the total price
    const totalPriceHeading = document.createElement('h3');
    totalPriceHeading.textContent = `Preis (zzgl. Mwst + zzgl. Versand): € ${productPrice}`;
    totalPriceHeading.style.textAlign = 'center'
    container.appendChild(totalPriceHeading);

    // Add payment information
    const paymentInfo = document.createElement('div');
    paymentInfo.textContent = 'Zahlungsbedingungen: Innerhalb von 7 Tage ohne Abzug';
    paymentInfo.style.textAlign = 'center';
    paymentInfo.style.fontWeight = 'normal';
    container.appendChild(paymentInfo);

    // Adding a footer 
    const footerAddress = document.createElement('div');
    footerAddress.textContent = 'Granitweg 1 | 4202 | Kirchschlag bei Linz | Austria';
    footerAddress.style.fontWeight = 'normal'
    
    const footerBankInfo = document.createElement('div');
    footerBankInfo.textContent = ' Gramastetten-Herzogsdorf | IBAN: AT45 3413 5000 0716 3397 | BIC: RZOOAT2L13'
    footerBankInfo.style.fontWeight = 'normal'

    const footerContent = document.createElement('div');
    footerContent.style.paddingTop = '4mm';
    footerContent.style.paddingBottom = '4mm';
    footerContent.style.borderTop = '1px solid #dddddd';

    const footer = document.createElement('div');
    footer.style.position = 'absolute';
    footer.style.width = '100%';
    footer.style.left = '0';
    footer.style.bottom = '0';

    footerContent.appendChild(footerAddress)
    footerContent.appendChild(footerBankInfo)
    footer.appendChild(footerContent)
    container.appendChild(footer);

    return container
}

// Create Part List PDF Template 
export function createPdfPartListTemplate(
    imageLogo: string,
    title: string, 
    partList: any[]
): HTMLElement {
    // Creating the main div element
    const container = document.createElement('div');
    container.style.boxSizing = 'border-box';
    container.style.backgroundColor = '#fff';

    // Adding a logo
    const logo = document.createElement('img');
    logo.src = imageLogo;
    logo.style.height = '40px';
    logo.style.display = 'block';
    logo.style.marginLeft = 'auto';
    logo.style.marginRight = 'auto';
    logo.style.marginBottom = '40px';
    container.appendChild(logo);

    // Add title
    const mainHeading = document.createElement('h1');
    mainHeading.textContent = title;
    mainHeading.style.marginBottom = '50px';
    mainHeading.style.textAlign = 'center';
    container.appendChild(mainHeading);

    for (let index = 0; index < partList.length; index++) {
        const table = document.createElement('table');
        table.style.borderCollapse = 'collapse';
        table.style.textAlign = 'left';
        table.style.width = '100%';
    
        // Adding the first row
        const row1 = document.createElement('tr');

        // Product image column
        const productImageCell = document.createElement('td');
        productImageCell.rowSpan = partList[index].parts.length * 4;
        productImageCell.width = '100px';
        productImageCell.style.paddingRight = '16px';
        

        const productImage = document.createElement('img');
        productImage.src = partList[index].parts[0].thumbnailUrl;
        productImage.style.width = '100px';
        productImageCell.appendChild(productImage);
        row1.appendChild(productImageCell);
        
        const productTitleSize = document.createElement('th');
        productTitleSize.textContent = partList[index].label;
        productTitleSize.style.paddingBottom = '4px'
        productTitleSize.style.textTransform = 'capitalize'
        row1.appendChild(productTitleSize);

        const productTitleQty = document.createElement('th');
        productTitleQty.textContent = 'Qty';
        productTitleQty.style.paddingBottom = '4px'
        productImageCell.width = '50px';
        row1.appendChild(productTitleQty);

        const productTitlePrice = document.createElement('th');
        productTitlePrice.textContent = 'Price';
        productTitlePrice.style.paddingBottom = '4px'
        productImageCell.width = '100px';
        row1.appendChild(productTitlePrice);
        table.appendChild(row1);

        for (let key = 0; key < partList[index].parts.length; key++) {
            const row2 = document.createElement('tr');

            const prudctSizeWidth = document.createElement('td');
            prudctSizeWidth.textContent = partList[index].parts[key].width;
            prudctSizeWidth.style.padding = '0'
            prudctSizeWidth.style.fontWeight = 'normal'
            row2.appendChild(prudctSizeWidth);
            
            const prudctQty = document.createElement('td');
            prudctQty.textContent = partList[index].parts[key].cnt + 'x';
            prudctQty.style.fontWeight = 'normal'
            prudctQty.style.padding = '0'
            prudctQty.width = '50px'
            row2.appendChild(prudctQty);
            
            const prudctPrice = document.createElement('th');
            prudctPrice.textContent = partList[index].parts[key].totalPrice.toFixed(2) + ' €';
            prudctPrice.style.fontWeight = 'normal'
            prudctPrice.style.padding = '0'
            prudctPrice.width = '100px'
            row2.appendChild(prudctPrice);
            table.appendChild(row2);

            const row3 = document.createElement('tr');

            const prudctSizeHeight = document.createElement('td');
            prudctSizeHeight.textContent = partList[index].parts[key].height;
            prudctSizeHeight.style.padding = '0'
            prudctSizeHeight.style.fontWeight = 'normal'
            row3.appendChild(prudctSizeHeight);
            table.appendChild(row3);

            const row4 = document.createElement('tr');
            const prudctSizeDepth = document.createElement('td');
            prudctSizeDepth.textContent = partList[index].parts[key].depth;
            
            if(partList[index].parts.length > 1 && key < partList[index].parts.length) {
                prudctSizeDepth.style.paddingBottom = '10px' 
            }
            else {
                prudctSizeDepth.style.paddingBottom = '0'
            }

            prudctSizeDepth.style.fontWeight = 'normal'
            row4.appendChild(prudctSizeDepth);
            table.appendChild(row4);
        }

        container.appendChild(table);

        const bottomLine = document.createElement('div');
        bottomLine.style.width = '100%';
        bottomLine.style.height = '1px';
        bottomLine.style.backgroundColor = '#dddddd';
        bottomLine.style.marginTop = '20px';
        bottomLine.style.marginBottom = '20px';

        container.appendChild(bottomLine);
    }

    return container
}
