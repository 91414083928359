<script lang="ts">
import { Product } from '@/types/api/Model/Product';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ProductParameterCover from '@/components/productParameters/ProductParameterCover.vue';
import ProductParameterCuboid from '@/components/productParameters/ProductParameterCuboid.vue';
import * as productService from '@/services/api/productService';
import { Body_Attribute } from '@/types/api/Patient/BodyAttribute';
import { LinearLightNameType } from '@/types/enum/order';
import ProductParameterBalcony from '@/components/productParameters/ProductParameterBalcony.vue';
import ProductParameterFence from '@/components/productParameters/ProductParameterFence.vue';
import ProductParameterOrthesis from '@/components/productParameters/ProductParameterOrthesis.vue';
import ProductParameterTemplate from '@/components/productParameters/ProductParameterTemplate.vue';
import ProductParameterLinearLight from '@/components/productParameters/ProductParameterLinearLight.vue';
import ProductParameterBathtub from '@/components/productParameters/ProductParameterBathtub.vue';
import MeasurementInfoCard from '@/components/MeasurementInfoCard.vue';
import { Modal } from 'bootstrap';
import { removeWordFromString } from '@/utils/formBuilder';
import { ElForm } from 'element-plus';
import { Calendar } from '@element-plus/icons-vue'
import { TableField, VideoDetails } from '@/utils/file';
import PreviewRendererDetails from '../three/PreviewRendererDetails.vue';

@Options({
  components: {
    MeasurementInfoCard,
    ProductParameterCover,
    ProductParameterCuboid,
    ProductParameterBalcony,
    ProductParameterFence,
    ProductParameterOrthesis,
    ProductParameterTemplate,
    ProductParameterLinearLight,
    ProductParameterBathtub,
    PreviewRendererDetails,
  },
  emits: [
    'buttonClicked',
    'zipChanged',
    'infoButtonClicked',
    'updateProperties',
    'productUpdated',
  ],
})
export default class ProductParamter extends Vue {
  @Prop() product!: Product;
  @Prop() isAuthenticated?: boolean;
  @Prop() properties!: any[];
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) hideInfo!: boolean;
  @Prop() videoMetadata!: TableField;

  selectedBodyAttribute: Body_Attribute | null = null;

  isEditable = false;
  isUpdating = false;
  productState: {
    name: string;
    description: string;
  } = {
    name: '',
    description: '',
  };

  private measurementInfoModalDialog: any = null;
  private productParameterTemplateDialog: any = null;
  selectedContent: string | null = null;

  LinearLightNameType = LinearLightNameType;

  mounted(): void {
    // Set default values of properties
    switch (this.product.type.name.toLowerCase()) {
      case 'covers':
        this.properties[0].heightWithoutDeduction = 330;
        this.properties[0].depth = 265;
        this.properties[0].calfCircumferenceWithoutDeduction = 200;
        break;
      case 'zäune':
        this.properties[0].heightWithoutDeduction = 1100;
        break;
      case 'orthesen':
        this.properties[0].heightWithoutDeduction = 420;
        this.properties[0].depth = 180;
        break;
    }

    const modalDialogOptions = {};
    this.measurementInfoModalDialog = new Modal(
      '#measurement-info',
      modalDialogOptions
    );
    this.productParameterTemplateDialog = new Modal(
      '#product-parameter-info',
      modalDialogOptions
    );
    this.productState.name = removeWordFromString(this.product?.name, 'phase1');
    this.productState.description = this.product.description;
  }

  // Truncate 'phase1' word in title string
  get productTitle(): string {
    return removeWordFromString(this.product?.name, 'phase1', 20);
  }

  selected(bodyAttribute: Body_Attribute): void {
    this.selectedBodyAttribute = bodyAttribute as Body_Attribute;
  }

  showMeasurementInfoModalDialog(content): void {
    this.selectedContent = content;
    this.measurementInfoModalDialog.show();
  }

  showProductParameterTemplateInfoDialog(content): void {
    this.selectedContent = content;
    this.productParameterTemplateDialog.show();
  }

  toggleEditable() {
    if(this.isUpdating) return
    this.isEditable = !this.isEditable;
  }

  updateProduct() {
    this.isUpdating = true
    const dataForm = this.$refs.dataForm as typeof ElForm;
    dataForm?.validate(async (valid) => {
      if (!valid) return;
      const newName = this.productState.name + ' phase1'
      productService
        .updateProduct({
          productId: this.product.productId,
          name: newName,
          description: this.productState.description,
        })
        .then(() => {
          this.isUpdating = false
          this.toggleEditable()
          this.$emit('productUpdated', {
            productId: this.product.productId,
            name: newName,
          })
        })
        .catch((err) => {
          this.isUpdating = false
          console.error('[ERROR UPDATE PRODUCT]: ', err);
        });
    });
  }

  handleShowVideo(payload: TableField): void {
    const rendererDetails = this.$refs.previewRendererDetails as PreviewRendererDetails
    
    if(!payload || !rendererDetails) return;
    console.log('payload', payload)

    const { filename, fileType, fileUrl } = payload
    rendererDetails.handleShowModal({
      fileName: filename,
      fileType,
      fileUrl,
    })
  }
}
</script>

<template>
  <div id="product-parameter" class="pl-5 pr-5 pt-3 pb-3 rounded-3 text-white">
    <h1>{{ $t('components.product-parameter.title') }}</h1>
      <el-form
          ref="dataForm"
          :model="productState"
          label-position="top"
          status-icon
      >
      <div class="row ml-0 mr-0" v-if="!hideInfo">
        <div class="col col-lg-7">
          <template  v-if="!isEditable">
            <h2 class="row">{{ productState.name }}</h2>
          </template>
          <template
          v-else 
          >
            <el-form-item
              prop="name"
              required
              class="form-text text-white"
              :rules="{
                required: true,
                message: $t('error.vuelidate.required'),
                trigger: 'blur',
              }"
            >
              <el-input
                @blur="updateProduct"
                placeholder="Title"
                v-model="productState.name"
                type="text"
                :disabled="isUpdating"
              />
          </el-form-item>
          </template>
          
          <p
            v-if="!isEditable"
            class="row form-text text-white"
            id="product-details-info-text"
          >
            <span class="d-block mb-3">
              {{ productState.description }}
            </span>

            <button 
              type="button" 
              class="btn btn-warning d-flex"
              v-if="(videoMetadata?.productId === product.productId.toString())"
              @click="handleShowVideo(videoMetadata)"
            >
              Watch Video
            </button>
          </p>

          <el-form-item
              v-else 
              prop="description"
              required
              class="form-text text-white"
              :rules="{
                required: true,
                message: $t('error.vuelidate.required'),
                trigger: 'blur',
              }"
            >
              <el-input
                @blur="updateProduct"
                placeholder="Description"
                v-model="productState.description"
                :rows="4"
                type="textarea"
                :disabled="isUpdating"
              />
          </el-form-item>
        </div>
        <div class="col col-lg-1">
          <el-icon @click="toggleEditable" v-if="isAuthenticated && !isEditable">
              <font-awesome-icon icon="edit" />
            </el-icon>
            <!--<el-icon @click="toggleEditable" v-else>
              <font-awesome-icon icon="edit" />
            </el-icon>-->
        </div>
        <div class="col col-lg-4 d-flex justify-content-center">
          <slot></slot>
        </div>
      </div>
    </el-form>

    <div class="row">
      <product-parameter-cover
        v-if="product.type.name.toLowerCase() == 'covers'"
        :properties="properties"
        :product="product"
        @zip-changed="(zip) => $emit('zipChanged', zip)"
        @info-button-clicked="showMeasurementInfoModalDialog"
        v-on:scanFileChanged="selected"
        :readonly="readonly"
      />
      <!-- TODOS: ADJUST IF PROPS -->
      <product-parameter-template
        v-if="product.name.replace(/\s/g, '').toLowerCase().includes('phase1')"
        :key="product.productId"
        :properties="properties"
        :product="product"
        :readonly="readonly"
        @info-button-clicked="showProductParameterTemplateInfoDialog"
        @update-properties="
          (newProperties) => $emit('updateProperties', newProperties)
        "
      />
      <!-- TODOS: ADJUST IF PROPS -->
      <product-parameter-orthesis
        v-if="
          product.type.name.toLowerCase() == 'orthesen' &&
          !product.name.replace(/\s/g, '').toLowerCase().includes('phase1')
        "
        :properties="properties"
        :product="product"
        @zip-changed="(zip) => $emit('zipChanged', zip)"
        v-on:scanFileChanged="selected"
        :readonly="readonly"
      />
      <!--<product-parameter-cuboid
        v-if="product.type.name.toLowerCase() == 'möbel' ||
              product.type.name.toLowerCase() == 'sonstige' ||
              product.type.name.toLowerCase() == 'hütten'"
        :properties="properties"
      />
      -->
      <product-parameter-balcony
        v-if="product.type.name.toLowerCase() == 'balkone'"
        :properties="properties"
        :readonly="readonly"
      />
      <product-parameter-fence
        v-if="product.type.name.toLowerCase() == 'zäune'"
        :properties="properties"
        :readonly="readonly"
      />
      <product-parameter-linear-light
        v-if="product.type.name.toLowerCase() == 'lineare beleuchtung'"
        :properties="properties"
        :isHangingLight="
          product.name.toLowerCase() ===
          LinearLightNameType.hanging.toLowerCase()
        "
        :isSurfaceMountedLight="
          product.name.toLowerCase() ===
          LinearLightNameType.surfaceMounted.toLowerCase()
        "
        :readonly="readonly"
      />
      <product-parameter-bathtub
        v-if="product.type.name.toLowerCase() == 'duschen und bäder'"
        :properties="properties"
        :readonly="readonly"
      />

      <div
        class="modal fade"
        id="measurement-info"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content" id="info-content">
            <div
              class="modal-header border-bottom-0 bg-transparent"
              id="info-header"
            >
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-0 bg-grey" >
              <measurement-info-card>
                <div class="row">
                  <div class="col-12" v-if="selectedContent == 'content1'">
                    <h2>{{ $t('components.order-form.scope-top') }}</h2>
                    <div class="row">
                      <div class="col-md-6" id="txtcol">
                        <p class="form-text text-black">
                          {{
                            $t(
                              'components.product-parameter.measurementsInfo.scope.top.info'
                            )
                          }}
                        </p>
                        <ul style="list-style-type: disc; padding-left: 20px">
                          <li class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.scope.bullet1'
                              )
                            }}
                          </li>
                          <li class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.scope.bullet2'
                              )
                            }}
                          </li>
                          <li class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.scope.bullet3'
                              )
                            }}
                          </li>
                          <li class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.scope.bullet4'
                              )
                            }}
                          </li>
                          <li class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.scope.bullet5'
                              )
                            }}
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-6" id="imgcol">
                        <img
                          id="infoimg"
                          class="img-fluid"
                          src="../../assets/images/covers/scope_calve.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12" v-if="selectedContent == 'content2'">
                    <h2>{{ $t('components.order-form.height') }}</h2>
                    <div class="row">
                      <div class="col-md-6" id="txtcol">
                        <div
                          v-if="
                            product.name.toLowerCase().includes('unterschenkel')
                          "
                        >
                          <p class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.height-leg.upper.info'
                              )
                            }}
                          </p>
                          <ul style="list-style-type: disc; padding-left: 20px">
                            <li class="form-text text-black">
                              {{
                                $t(
                                  'components.product-parameter.measurementsInfo.height-leg.bullet1'
                                )
                              }}
                            </li>
                            <li class="form-text text-black">
                              {{
                                $t(
                                  'components.product-parameter.measurementsInfo.height-leg.bullet2'
                                )
                              }}
                            </li>
                            <li class="form-text text-black">
                              {{
                                $t(
                                  'components.product-parameter.measurementsInfo.height-leg.bullet3'
                                )
                              }}
                            </li>
                            <li class="form-text text-black">
                              {{
                                $t(
                                  'components.product-parameter.measurementsInfo.height-leg.bullet4'
                                )
                              }}
                            </li>
                            <li class="form-text text-black">
                              {{
                                $t(
                                  'components.product-parameter.measurementsInfo.height-leg.bullet5'
                                )
                              }}
                            </li>
                          </ul>
                        </div>
                        <div v-else>
                          <p class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.height-leg.lower.info'
                              )
                            }}
                          </p>
                          <ul style="list-style-type: disc; padding-left: 20px">
                            <li class="form-text text-black">
                              {{
                                $t(
                                  'components.product-parameter.measurementsInfo.height-leg.bullet1'
                                )
                              }}
                            </li>
                            <li class="form-text text-black">
                              {{
                                $t(
                                  'components.product-parameter.measurementsInfo.height-leg.bullet2'
                                )
                              }}
                            </li>
                            <li class="form-text text-black">
                              {{
                                $t(
                                  'components.product-parameter.measurementsInfo.height-leg.bullet3'
                                )
                              }}
                            </li>
                            <li class="form-text text-black">
                              {{
                                $t(
                                  'components.product-parameter.measurementsInfo.height-leg.bullet4'
                                )
                              }}
                            </li>
                            <li class="form-text text-black">
                              {{
                                $t(
                                  'components.product-parameter.measurementsInfo.height-leg.bullet5'
                                )
                              }}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-md-6" id="imgcol">
                        <img
                          v-if="
                            product.name.toLowerCase().includes('unterschenkel')
                          "
                          id="infoimg"
                          class="img-fluid"
                          src="../../assets/images/covers/height_lower_leg.png"
                        />
                        <img
                          v-else
                          id="infoimg"
                          class="img-fluid"
                          src="../../assets/images/covers/height_upper_leg.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12" v-if="selectedContent == 'content3'">
                    <h2>{{ $t('components.order-form.scope-bottom') }}</h2>
                    <div class="row">
                      <div class="col-md-6" id="txtcol">
                        <p class="form-text text-black">
                          {{
                            $t(
                              'components.product-parameter.measurementsInfo.scope.bottom.info'
                            )
                          }}
                        </p>
                        <ul style="list-style-type: disc; padding-left: 20px">
                          <li class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.scope.bullet1'
                              )
                            }}
                          </li>
                          <li class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.scope.bullet2'
                              )
                            }}
                          </li>
                          <li class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.scope.bullet3'
                              )
                            }}
                          </li>
                          <li class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.scope.bullet4'
                              )
                            }}
                          </li>
                          <li class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.scope.bullet5'
                              )
                            }}
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-6" id="imgcol">
                        <img
                          id="infoimg"
                          class="img-fluid"
                          src="../../assets/images/covers/scope_ankle.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </measurement-info-card>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="product-parameter-info"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content" id="info-content">
            <div
              class="modal-header border-bottom-0 bg-transparent"
              id="info-header"
            >
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-0" id="info-body">
              <measurement-info-card>
                <div class="row">
                  <div class="col-12 text-center" v-if="Boolean(selectedContent)">
                    <img
                          id="infoimg"
                          class="img-fluid"
                          :src="selectedContent || ''"
                        />
                  </div>
                </div>
              </measurement-info-card>
            </div>
          </div>
        </div>
      </div>

      <!-- Button -->
      <div class="row d-flex justify-content-center mb-5 mt-2">
        <button
          type="button"
          class="btn btn-primary"
          @click="$emit('buttonClicked', selectedBodyAttribute)"
        >
          {{ $t('components.product-parameter.startConfigurator') }}
        </button>
      </div>
    </div>
  </div>

  <!-- Video preview modal ... -->
  <PreviewRendererDetails 
      ref="previewRendererDetails"
      :canDownload="true"
      :canRemove="true"
    />
</template>

<style scoped lang="scss">
#product-parameter {
  background: transparent
    radial-gradient(
      closest-side at 50% 50%,
      rgba(75, 75, 75, 0.85) 0%,
      rgba(0, 0, 0, 0.85) 100%
    )
    0% 0% no-repeat padding-box;
}

#value1-range-text,
#value2-range-text,
#value1,
#value2 {
  margin-top: -0.65rem;
}

#value1,
#value2 {
  padding: 0.5rem;
}

#product-parameter-info-text {
  font-size: 1rem;
}

#measurement-info {
  box-sizing: border-box;
  height: 100%;
  width: 60%;
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

#info-content {
  background-color: transparent;
}

#info-header {
  background-color: transparent;
  z-index: 1;
}

#info-body {
  background-color: rgba(0, 0, 0, 0.85);
  margin-top: -3.6rem;
  z-index: 0;
  display: flex;
  flex-direction: column;
  height: 80%;
  overflow: scroll;
}

.bg-grey{
  background-color: rgba(0, 0, 0, 0.85);
}

#infoimg {
  max-height: 330px;
  max-width: auto;
}

#txtcol {
  overflow: auto;
  flex: 1;
}

#imgcol {
  overflow: auto;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-form {
  width: 100%;
  i {
    width: auto;
    cursor: pointer;
    font-size: 16px;
  }
}

@media only screen and (max-width: 1000px) {
  #txtcol,
  #imgcol {
    width: 100%;
  }

  #infoimg {
    margin-left: 0;
  }
}
</style>
