import { Body_Attribute } from '@/types/api/Patient/BodyAttribute';
import { queryWithVariables } from './api';
import { Component } from '@/types/api/Model/Component';

export const getBodyAttributesByUserId = async (userId: number): Promise<Body_Attribute[]> => {
  const getBodyAttributesResult = await queryWithVariables(
    `
        query ($userId: Long!) {
          bodyAttributesByUserId (userId: $userId) {
            characteristicId,
            date,
            scanFile {
              fileId,
              name,
              type
            },
            skeleton {
              fileId,
              name,
              type
            },
            scaleFactor,
            side,
            roi {
              roiId,
              length,
              width,
              depth
            },
            bodyPart {
              bodyPartId,
              name
            }
          }
        }
      `,
    {
      userId: userId,
    }
  );

  const copyBodyAttributes: Body_Attribute[] = [];
  const bodyAttributes = getBodyAttributesResult.data.bodyAttributesByUserId as Body_Attribute[];
  for (const bodyAttribute of bodyAttributes) {
    copyBodyAttributes.push(new Body_Attribute().deepCopy(bodyAttribute));
  }

  return copyBodyAttributes;
}

export const getScanById = async (fileId: number): Promise<Component> => {
  const getScanResult = await queryWithVariables(
    `
        query ($fileId: Long!) {
          scanById (fileId: $fileId) {
            componentId,
            uuid,
            name,
            attributes {attributeId, additionalPrice, components {componentTypeId, name}, date, visualOnly, attributeType},
            resolutions {
              level, 
              meshBase64 {
                fileId, 
                content, 
                name, 
                type
              }, 
              resolutionId
            },
            markingPoints {
              polylineId,
              positions{
                mppId,
                position { xValue, yValue, zValue },
                markingPoint { markingPointId, name }
              }
            },
            position {xValue,  yValue, zValue},
            rotation { xValue, yValue, zValue },
            scale { xValue, yValue, zValue },
            type { componentTypeId, name },
            curves {
              curveId,
              name,
              category,
              closed,
              points { xValue, yValue, zValue },
              position { xValue, yValue, zValue },
              rotation { xValue, yValue, zValue },
              scale { xValue, yValue, zValue },
              type
            },
            parent { componentId, uuid },
            thumbnail { name, type, content },
            basePrice,
            unit
          }
        }
      `,
    {
      fileId: fileId,
    }
  );

  return getScanResult.data.scanById;
};
