import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { getCenter } from '@/utils/three/boundingBox';

/* eslint-disable @typescript-eslint/no-explicit-any*/

export const getCameraObjectDistance = (
  camera: THREE.Camera,
  object: THREE.Object3D
): number => {
  return camera.position.distanceTo(
    object.getWorldPosition(new THREE.Vector3())
  );
};

export const getCameraDistance = (
  camera: THREE.PerspectiveCamera,
  controls: OrbitControls,
  selection: THREE.Object3D[],
  offset = 1.2
): number => {
  return getCenter(camera, selection, offset, controls.target.clone()).distance;
};

export const fitControlCameraToSelection = (
  camera: THREE.PerspectiveCamera,
  controls: OrbitControls,
  selection: THREE.Object3D[],
  offset = 1.2
): void => {
  const activeSelection = selection.filter(
    (item) => item.visible && ((item as any).isMesh || (item as any).isGroup)
  );
  if (activeSelection.length === 0) return;
  const { center, direction, distance } = getCenter(
    camera,
    activeSelection,
    offset,
    controls.target.clone()
  );

  if (isNaN(distance) || distance === 0) return;

  controls.maxDistance = distance * 10;
  controls.target.copy(center);

  camera.near = distance / 100;
  camera.far = distance * 100;
  camera.updateProjectionMatrix();

  camera.position.copy(controls.target).sub(direction);

  controls.update();

  if (activeSelection.length > 0) {
    const scene = activeSelection[0].parent as THREE.Scene;
    if (scene) {
      adaptLight(scene, distance);
    }
  }
};

export const fitCameraToSelection = (
  camera: THREE.PerspectiveCamera,
  selection: THREE.Object3D[],
  offset = 1.2
): void => {
  const activeSelection = selection.filter(
    (item) => item.visible && ((item as any).isMesh || (item as any).isGroup)
  );
  if (activeSelection.length === 0) return;
  const { center, direction, distance } = getCenter(
    camera,
    activeSelection,
    offset
  );

  camera.near = distance / 100;
  camera.far = distance * 100;
  camera.updateProjectionMatrix();

  camera.position.copy(center).sub(direction);
  camera.lookAt(center);

  if (activeSelection.length > 0) {
    const scene = activeSelection[0].parent as THREE.Scene;
    if (scene) {
      adaptLight(scene, distance);
    }
  }
};

const adaptLight = (scene: THREE.Scene, distance: number): void => {
  for (const child of scene.children) {
    if (child instanceof THREE.PointLight) {
      if (
        child.position.x === 0 &&
        child.position.y === 0 &&
        child.position.z === 0
      )
        child.position.set(1, 1, 1);
      child.position.normalize().multiplyScalar(distance);
    }
  }
};
