<template>
    <div 
      class="image" 
      v-for="mesh in meshes"
      v-loading="isLoading"
      :key="mesh.thumbnail!"
    >
      <img
        :src="mesh.videoThumbnailUrl || defaultImage"
        :alt="mesh.video"
      />
      <span v-if="canRemove" class="el-upload-list__item-actions">
        <span
          v-if="canRemove"
          class="el-upload-list__item-delete"
        >
          <el-icon>
            <font-awesome-icon icon="trash" />
          </el-icon>
        </span>
      </span>
      <span v-if="canPlay" class="el-upload-list__item-actions">
        <span
          v-if="canPlay"
          class="btn-play"
          @click="handleShowVideo(mesh)"
        >
          <el-icon>
            <font-awesome-icon icon="play" />
          </el-icon>
        </span>
      </span>
    </div>

    <PreviewRendererDetails 
      ref="previewRendererDetails"
      @removeItem="handleRemoveItem"
      :canDownload="true"
      :canRemove="true"
    />
</template>

<script lang="ts">
import { MeshTreeDataItem } from '@/types/ui/MeshTreeData';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { defaultImage, VideoDetails } from '@/utils/file';
import { FileTypeImage, FileTypeVideo } from '@/types/enum/upload';
import PreviewRendererDetails from '@/components/three/PreviewRendererDetails.vue';

@Options({
  components: {
    PreviewRendererDetails
  },
  emits: [
    'removeItem'
  ],
})

export default class PreviewRendererVideo extends Vue {
  @Prop() meshes!: MeshTreeDataItem[];
  @Prop({ default: false }) canRemove!: boolean;
  @Prop({ default: false }) canPlay!: boolean;
  @Prop({ default: false }) isLoading!: boolean;

  private defaultImage = defaultImage;

  // Watch to detect state change isLoading
  @Watch('meshes')
  onMeshesChanged(newValue: MeshTreeDataItem) {
    console.log('meshes detected change:', newValue);
    // Additional actions if needed when isLoading changes
  }

  handleRemoveItem(): void {
    this.$emit('removeItem');
  }

  handleShowVideo(payload: VideoDetails): void {
    const rendererDetails = this.$refs.previewRendererDetails as PreviewRendererDetails
    
    if(!payload || !rendererDetails) return;

    const { video, videoFiletype, videoUrl } = payload
    rendererDetails.handleShowModal({
      fileName: video,
      fileType: videoFiletype,
      fileUrl: videoUrl,
    })

    // @Testing image file ...
    // rendererDetails.handleShowModal({
    //   fileName: payload.videoThumbnail,
    //   fileType: payload.videoThumbnailFiletype,
    //   fileUrl: payload.videoThumbnailUrl,
    // })
  }
}
</script>

<style lang="scss" scoped>
.upload-item {
  overflow: hidden;
  background-color: var(--color-sidebar);
  border: 1px solid #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  vertical-align: middle;
  //display: inline-block;
  cursor: pointer;
}

.products {
  .upload-item {
    border-radius: 6px 6px 0 0;
  }
}

.selected {
  border: var(--color-primary) 2px solid;
}

.image {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
}

.image img {
  display: block;
  height: 100%;
  object-fit: cover;
}

.noImage {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;

  > div {
    text-align: center;
    color: var(--color-primary);
    opacity: 0.5;
    font-size: 2rem;
    vertical-align: middle;
    align-items: center;
    margin: auto;
  }
}

.btn-play {
  width: 44px;
  height: 44px;
  background-color: transparent;
  border: 2px  solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  padding-left: 4px;
}

.copy-clipboard {
  position: relative;
  padding: 0 4px;
  height: 30px;
  width: 100%;
  border-radius: 6px;
  background-color: #ebf2f9;
  white-space: nowrap; /* Membuat teks tetap dalam satu baris */
  overflow-x: auto; /* Mengaktifkan horizontal scroll jika teks terlalu panjang */
  // text-overflow: ellipsis; /* Opsional: Menampilkan ... jika teks terpotong */
  
  /* Menyembunyikan scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.text-to-copy::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.copy-clipboard > button {
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  width: 30px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border: none;
  outline: none;
  color: white;
}
</style>