import * as THREE from 'three';
import {
  FaceVertices,
  FaceIndices,
} from '@/plugin/ffd/adapter/GeometryAdapter';
import {
  calculateSimilarVertexForAdapter,
  GeometryAdapterInterface,
} from '@/plugin/ffd/adapter/GeometryAdapterFactory';

/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class UnIndexedBufferGeometryAdapter
  implements GeometryAdapterInterface
{
  protected bufferGeometry: THREE.BufferGeometry;
  positions: number[];
  normal: number[];
  similarVertex: number[][];

  constructor(
    bufferGeometry: THREE.BufferGeometry,
    calculateSimilarVertex = false
  ) {
    this.bufferGeometry = bufferGeometry;
    this.positions = bufferGeometry.attributes.position.array as number[];
    if (bufferGeometry.attributes.normal)
      this.normal = bufferGeometry.attributes.normal.array as number[];
    else this.normal = [];
    this.similarVertex = [...Array(this.numVertices)].map(() => []);
    if (calculateSimilarVertex) calculateSimilarVertexForAdapter(this);
  }

  get vertices(): THREE.Vector3[] {
    const result: THREE.Vector3[] = [];
    for (let index = 0; index < this.numVertices; index++) {
      result.push(this.getVertex(index));
    }
    return result;
  }

  get numVertices(): number {
    return this.positions.length / 3;
  }

  get numFaces(): number {
    return this.numVertices;
  }

  setVertex(index: number, x: number, y: number, z: number): void {
    const offsetPosition = 3 * index;

    this.positions[offsetPosition] = x;
    this.positions[offsetPosition + 1] = y;
    this.positions[offsetPosition + 2] = z;
  }

  setVertexX(index: number, x: number): void {
    this.positions[3 * index] = x;
  }

  setVertexY(index: number, y: number): void {
    this.positions[3 * index + 1] = y;
  }

  setVertexZ(index: number, z: number): void {
    this.positions[3 * index + 2] = z;
  }

  public getVertex(index: number): THREE.Vector3 {
    const offsetPosition = 3 * index;

    return new THREE.Vector3(
      this.positions[offsetPosition],
      this.positions[offsetPosition + 1],
      this.positions[offsetPosition + 2]
    );
  }

  getVertexX(index: number): number {
    return this.positions[3 * index];
  }

  getVertexY(index: number): number {
    return this.positions[3 * index + 1];
  }

  getVertexZ(index: number): number {
    return this.positions[3 * index + 2];
  }

  public getNormal(index: number): THREE.Vector3 {
    const offsetPosition = 3 * index;

    if (this.normal.length > offsetPosition + 2) {
      return new THREE.Vector3(
        this.normal[offsetPosition],
        this.normal[offsetPosition + 1],
        this.normal[offsetPosition + 2]
      );
    }
    return new THREE.Vector3(0, 0, 0);
  }

  getFace(index: number): FaceIndices {
    const offsetPosition = 3 * index;

    return {
      a: offsetPosition,
      b: offsetPosition + 1,
      c: offsetPosition + 2,
    };
  }

  getFaceVertices(index: number): FaceVertices {
    const offsetPosition = 3 * index;

    return {
      a: this.getVertex(offsetPosition),
      b: this.getVertex(offsetPosition + 1),
      c: this.getVertex(offsetPosition + 2),
    };
  }

  updateVertices(): void {
    this.bufferGeometry.attributes.position.needsUpdate = true;
    if (this.bufferGeometry.attributes.normal)
      this.bufferGeometry.attributes.normal.needsUpdate = true;
    this.bufferGeometry.computeVertexNormals();
  }

  updateFaces(): void {
    this.updateVertices();
  }

  getBoundingBox(): THREE.Box3 | null {
    this.bufferGeometry.computeBoundingBox();

    return this.bufferGeometry.boundingBox;
  }
}
