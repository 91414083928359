<script setup lang="ts">
interface Props {
  title: string;
  selected?: boolean;
}

const props = defineProps<Props>();
</script>

<template>
  <div :class="'card ' + (props.selected ? 'border-primary' : 'border-secondary')">
    <div class="card-body">
      <h5 class="card-title text-center mb-3">{{ props.title }}</h5>
      <div class="d-flex justify-content-center m-2">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.border-secondary {
  border-color: #CBCBCB !important;
}
</style>
