<template>
  <!-- Main selection -->
  <div class="col mt-3">
    <label for="lightLength" class="form-label">{{ $t('components.product-parameter.linearlight.lightLength') }}</label>
    <el-slider
      id="lightLength"
      v-model="properties[0].lightLength"
      :min="1"
      :max="10"
      :step="1"
      @change="$emit('sizeChanged')"
      :disabled="readonly"
      style="width: 30vw;"
    />
  </div>
  <div v-if="isBuiltInLight">
    <div class="row mb-2" v-if="!readonly">
      <div class="col">
        <label for="constructionMaterial" class="form-label mb-2">{{ $t('components.product-parameter.linearlight.constructionMaterial') }}</label><br />
        <el-radio-group v-model="properties[0].lightType" size="large" id="constructionMaterial">
          <el-radio-button
            v-for="lightType in Object.values(LightType)"
            :key="lightType"
            :label="lightType"
            @change="$emit('sizeChanged')"
            :disabled="readonly"
          >
            {{ $t(`enum.lightType.${lightType}`) }}
          </el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <!-- Thermal Insulation Section -->
    <div v-if="properties[0].lightType == LightType.thermalInsulation">
      <div class="row mb-2" >
        <div class="col">
          <label for="concreteType" class="form-label mb-2">{{ $t('components.product-parameter.linearlight.insulationType') }}</label><br />
          <el-radio-group v-model="properties[0].insulationType" size="large" id="insulationType">
            <el-radio-button
              v-for="insulationType in Object.values(InsulationType)"
              :key="insulationType"
              :label="insulationType"
              @change="$emit('sizeChanged')"
              :disabled="readonly"
            >
              {{ $t(`enum.insulationType.${insulationType}`) }}
            </el-radio-button>
          </el-radio-group>
        </div>
      </div>
    </div>
    <!-- Concrete Paving Section -->
    <div v-if="properties[0].lightType == LightType.concretePaving">
      <div class="row mb-2" >
        <div class="col">
          <label for="concreteType" class="form-label mb-2">{{ $t('components.product-parameter.linearlight.concreteType') }}</label><br />
          <el-radio-group v-model="properties[0].concreteType" size="large" id="concreteType">
            <el-radio-button
              v-for="concreteType in Object.values(ConcreteType)"
              :key="concreteType"
              :label="concreteType"
              @change="onConcreteTypeChanged"
              :disabled="readonly"
            >
              {{ $t(`enum.concreteType.${concreteType}`) }}
            </el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div class="row mb-2" v-if="properties[0].concreteType">
        <div class="col">
          <label for="concreteFinishingType" class="form-label mb-2">{{ $t('components.product-parameter.linearlight.finishingType') }}</label><br />
          <el-radio-group v-model="properties[0].concreteFinishingType" size="large" id="concreteFinishingType">
            <el-radio-button
              v-for="concreteFinishingType in concreteFinishingTypeList"
              :key="concreteFinishingType"
              :label="concreteFinishingType"
              @change="$emit('sizeChanged')"
              :disabled="readonly"
            >
              {{ $t(`enum.concreteFinishingType.${concreteFinishingType}`) }}
            </el-radio-button>
          </el-radio-group>
        </div>
      </div>
    </div>
    <!-- Drywall Section -->
    <div v-if="properties[0].lightType == LightType.drywall">
      <div class="row mb-2">
        <div class="col">
          <label for="fireProtection" class="form-label">{{ $t('components.product-parameter.linearlight.fireProtection') }}</label>
          <br />
          <el-switch
            id="fireProtection"
            v-model="properties[0].fireProtection"
            @change="$emit('sizeChanged')"
            :disabled="readonly"
          />
        </div>
      </div>
    </div>
    <!-- Global Sections -->
    <!-- Transformer Type -->
    <div class="row mb-2">
      <div class="col">
        <label for="transformerType" class="form-label mb-2">{{ $t('components.product-parameter.linearlight.transformerType') }}</label><br />
        <el-radio-group v-model="properties[0].transformerType" size="large" id="transformerType">
          <el-radio-button
            v-for="transformerType in Object.values(TransformerType)"
          :key="transformerType"
          :label="transformerType"
          @change="$emit('sizeChanged')"
          :disabled="readonly || disableTransformer"
          >
            {{ $t(`enum.transformerType.${transformerType}`) }}
          </el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div v-if="canShowTransformerOptions">
      <div class="row mb-2">
        <div class="col">
          <label for="transformerConfigurationType" class="form-label mb-2">{{ $t('components.product-parameter.linearlight.transformerConfigurationType') }}</label><br />
          <el-radio-group v-model="properties[0].transformerConfigurationType" size="large" id="transformerConfigurationType">
            <el-radio-button
              v-for="transformerConfigurationType in transformerConfigurationTypeList"
            :key="transformerConfigurationType"
            :label="transformerConfigurationType"
            @change="$emit('sizeChanged')"
            :disabled="readonly"
            >
              {{ $t(`enum.transformerConfigurationType.${transformerConfigurationType}`) }}
            </el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <label for="transformerIntensityType" class="form-label mb-2">{{ $t('components.product-parameter.linearlight.transformerIntensityType') }}</label><br />
          <el-radio-group v-model="properties[0].transformerIntensityType" size="large" id="transformerIntensityType">
            <el-radio-button
              v-for="transformerIntensityType in transformerIntensityTypeList"
            :key="transformerIntensityType"
            :label="transformerIntensityType"
            @change="$emit('sizeChanged')"
            :disabled="readonly"
            >
              {{ $t(`enum.transformerIntensityType.${transformerIntensityType}`) }}
            </el-radio-button>
          </el-radio-group>
        </div>
      </div>
    </div>
  </div>
  <!-- Color -->
  <div class="row mb-2">
    <div class="col">
      <label for="colorType" class="form-label mb-2">{{ $t('views.customize.workflow.color') }}</label><br />
      <el-radio-group v-model="properties[0].color" size="large" id="colorType">
        <el-radio-button
          v-for="color in colorOptions"
          :key="color"
          :label="color"
          @change="$emit('sizeChanged')"
          :disabled="readonly"
        >
          {{ $t(`views.colors.${color}`)  }}
        </el-radio-button>
      </el-radio-group>
    </div>
  </div>
  <!-- Application Profile Section -->
  <div v-if="canShowApplicationProfileSection || !isBuiltInLight">
    <div class="row mb-2">
      <div class="col">
        <label for="mountingProfileType" class="form-label mb-2">{{ $t('components.product-parameter.linearlight.mountingProfileType') }}</label><br />
        <el-radio-group v-model="properties[0].mountingProfileType" size="large" id="mountingProfileType">
          <el-radio-button
            v-for="mountingProfileType in mountingProfileTypeList"
            :key="mountingProfileType"
            :label="mountingProfileType"
            @change="$emit('sizeChanged')"
            :disabled="readonly"
          >
            {{ $t(`enum.mountingProfileType.${mountingProfileType}`)  }}
          </el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <label for="lightCoverType" class="form-label mb-2">{{ $t('components.product-parameter.linearlight.lightCoverType') }}</label><br />
        <el-radio-group v-model="properties[0].lightCoverType" size="large" id="lightCoverType">
          <el-radio-button
            v-for="lightCoverType in lightCoverTypeList"
            :key="lightCoverType"
            :label="lightCoverType"
            @change="$emit('sizeChanged')"
            :disabled="readonly"
          >
            {{ $t(`enum.lightCoverType.${lightCoverType}`)  }}
          </el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="row mb-2" v-if="isHangingLight">
      <div class="col">
        <label for="vFour" class="form-label">{{ $t('components.product-parameter.linearlight.vFour') }}?</label>
        <br />
        <el-switch
          id="vFour"
          v-model="properties[0].vFour"
          @change="$emit('sizeChanged')"
          :disabled="readonly"
        />
      </div>
    </div>
    <div class="row mb-2" v-else>
      <div class="col">
        <label for="eloxed" class="form-label">{{ $t('components.product-parameter.linearlight.eloxed') }}?</label>
        <br />
        <el-switch
          id="eloxed"
          v-model="properties[0].eloxed"
          @change="$emit('sizeChanged')"
          :disabled="readonly"
        />
      </div>
    </div>
  </div>
  <!-- Application Light Section -->
  <div v-if="canShowApplicationLightSection">
    <div class="row mb-2">
      <div class="col">
        <label for="iPRatingType" class="form-label mb-2">{{ $t('components.product-parameter.linearlight.iPRatingType') }}</label><br />
        <el-radio-group v-model="properties[0].iPRatingType" size="large" id="iPRatingType">
          <el-radio-button
            v-for="iPRatingType in Object.values(IPRatingType)"
            :key="iPRatingType"
            :label="iPRatingType"
            @change="$emit('sizeChanged')"
            :disabled="readonly"
          >
            {{ $t(`enum.iPRatingType.${iPRatingType}`)  }}
          </el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <label for="lightColorType" class="form-label mb-2">{{ $t('components.product-parameter.linearlight.lightColorType') }}</label><br />
        <el-radio-group v-model="properties[0].lightColorType" size="large" id="lightColorType">
          <el-radio-button
            v-for="lightColorType in Object.values(LightColorType)"
            :key="lightColorType"
            :label="lightColorType"
            @change="onLightColorTypeChanged"
            :disabled="readonly"
          >
            {{ $t(`enum.lightColorType.${lightColorType}`)  }}
          </el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <label for="lightIntensityType" class="form-label mb-2">{{ $t('components.product-parameter.linearlight.lightIntensityType') }}</label><br />
        <el-radio-group v-model="properties[0].lightIntensityType" size="large" id="lightIntensityType">
          <el-radio-button
            v-for="lightIntensityType in colorIntensityTypeList"
            :key="lightIntensityType"
            :label="lightIntensityType"
            @change="$emit('sizeChanged')"
            :disabled="readonly || disableLightIntensity"
          >
            {{ $t(`enum.lightIntensityType.${lightIntensityType}`)  }}
          </el-radio-button>
        </el-radio-group>
      </div>
    </div>
  </div>
</template>
  
<script lang="ts">
  import { LightType, ConcreteType, TransformerType, InsulationType, ConcreteFinishingType, TransformerConfigurationType, TransformerIntensityType, MountingProfileType, LightCoverType, IPRatingType, LightColorType, LightIntensityType  } from "@/types/enum/order";
  import { Options, Vue } from "vue-class-component";
  import { Prop } from "vue-property-decorator";
  
  @Options({
    components: {},
    emits: ['sizeChanged']
  })
  
  export default class ProductParamterLinearLight extends Vue {
    @Prop() properties!: any[];
    @Prop({ default: false }) readonly!: boolean;
    @Prop({ default: false}) isHangingLight!: boolean;
    @Prop({ default: false}) isSurfaceMountedLight!: boolean;
  
    LightType = LightType;
    ConcreteType = ConcreteType;
    InsulationType = InsulationType;
    ConcreteFinishingType = ConcreteFinishingType;
    TransformerType = TransformerType;
    TransformerConfigurationType = TransformerConfigurationType;
    TransformerIntensityType = TransformerIntensityType;
    MountingProfileType = MountingProfileType;
    LightCoverType = LightCoverType;
    IPRatingType = IPRatingType;
    LightColorType = LightColorType;
    LightIntensityType = LightIntensityType;

    disableTransformer = false;
    disableLightIntensity = false;

    colorOptions = ["black", "white"]

    /**
     * Checks if the light is built-in.
     */
    get isBuiltInLight() {
      return !this.isHangingLight && !this.isSurfaceMountedLight;
    }

    /**
     * Returns reactive altered concreteFinishingType list, depending on previously chosen concreteType.
     */
    get concreteFinishingTypeList() {
      // Get currently selected concreteType, if exists.
      const concreteType = this.properties[0].concreteType;
      // We also wanna make sure that concreteFinishingType is set to null when the list changes.
      this.properties[0].concreteFinishingType = null;
      if (concreteType && concreteType == ConcreteType.prefabricatedCeiling) {
        return Object.values(ConcreteFinishingType).filter(item => item !== ConcreteFinishingType.exposed);
      }
      // Either none chosen or not prefabricatedCeiling.
      return Object.values(ConcreteFinishingType);
    }

    /**
     * Checks if application profile section can be shown.
     * @returns True if lightType, transformer & color have been chosen.
     */
    get canShowApplicationProfileSection() {
      const lightType = this.properties[0].lightType;
      const transformerType = this.properties[0].transformerType;
      const color = this.properties[0].color;

      return lightType && transformerType && color;
    }

    /**
     * Checks if application profile section can be shown.
     * @returns True if lightType, transformer & color have been chosen.
     */
     get canShowApplicationLightSection() {
      const mountingProfileType = this.properties[0].mountingProfileType;
      const lightCoverType = this.properties[0].lightCoverType;

      return mountingProfileType && lightCoverType;
    }

    get canShowTransformerOptions() {
      return this.properties[0].transformerType;
    }

    /**
     * Returns reactive altered transformerConfigurationType list, depending on previously chosen transformerType.
     */
     get transformerConfigurationTypeList() {
      // Get currently selected transformerType, if exists.
      const transformerType = this.properties[0].transformerType;
      // We also wanna make sure that transformerConfigurationType is set to null when the list changes.
      this.properties[0].transformerConfigurationType = null;
      if (transformerType && transformerType == TransformerType.buildIn) {
        return Object.values(TransformerConfigurationType).filter(item => item !== TransformerConfigurationType.triacDimming && item !== TransformerConfigurationType.daliSwitch);
      }
      // Type is external.
      return Object.values(TransformerConfigurationType).filter(item => item !== TransformerConfigurationType.dali);
    }

    /**
     * Returns reactive altered transformerIntensityType list, depending on previously chosen transformerType.
     */
    get transformerIntensityTypeList() {
      // Get currently selected transformerType, if exists.
      const transformerType = this.properties[0].transformerType;
      // Get currently selected transformerConfigurationType, if exists.
      const transformerConfigurationType = this.properties[0].transformerConfigurationType;
      // We also wanna make sure that transformerIntensityType is set to null when the list changes.
      this.properties[0].transformerIntensityType = null;
      if (transformerType && transformerType == TransformerType.external && transformerConfigurationType && transformerConfigurationType == TransformerConfigurationType.onOff) {
        return Object.values(TransformerIntensityType).filter(item => item !== TransformerIntensityType.thirty);
      }
      // Type is external.
      return Object.values(TransformerIntensityType).filter(item => item !== TransformerIntensityType.fifteen);
    }

    /**
     * Returns reactive altered transformerConfigurationType list, depending on previously chosen transformerType.
     */
     get colorIntensityTypeList() {
      // Get currently selected lightColorType, if exists.
      const lightColorType = this.properties[0].lightColorType;
      // We also wanna make sure that lightIntensityType is set to null when the list changes.
      this.properties[0].lightIntensityType = null;
      if (lightColorType && lightColorType == LightColorType.rgb) {
        return Object.values(LightIntensityType).filter(item => item === LightIntensityType.nineteen);
      }
      // Type is non rgb.
      return Object.values(LightIntensityType).filter(item => item !== LightIntensityType.nineteen);
    }

    /**
     * Returns reactive altered MountingProfileType list, depending on chosen light category.
     */
     get mountingProfileTypeList() {
      // We want to reset "invisible" variables when updating the profile list.
      // this.resetValuesOnVariantChange();
      if (this.isBuiltInLight) {
        return Object.values(MountingProfileType);
      }
      return Object.values(MountingProfileType).filter(item => item !== MountingProfileType.llthirtyfiveF);
    }

    /**
     * Returns reactive altered LightCoverType list, depending on chosen light category.
     */
     get lightCoverTypeList() {
      // We want to reset "invisible" variables when updating the profile list.
      // this.resetValuesOnVariantChange();
      if (this.isBuiltInLight) {
        return Object.values(LightCoverType);
      }
      return Object.values(LightCoverType).filter(item => item !== LightCoverType.versionThree);
    }

    /**
     * Resets all variables that may be invisible when changing the variant.
     * For example, v4 is only relevant for hanging light, but might still be
     * selected if modal is closed and another is opened.
     */
    // resetValuesOnVariantChange() {
    //   this.properties[0].vFour = false;
    //   this.properties[0].eloxed = false;
    //   // BuildIn offers different options than the others.
    //   this.properties[0].lightCoverType = '';
    //   this.properties[0].mountingProfileType = '';
    // }

    /**
     * Fired when ConcreteType changes.
     */
    onConcreteTypeChanged() {
      const concreteType = this.properties[0].concreteType;
      // Disabe Transformer selection as prefabricated Ceiling only allows external transformer.
      if (concreteType == ConcreteType.prefabricatedCeiling) {
        this.properties[0].transformerType = TransformerType.external;
        this.disableTransformer = true;
        return;
      }
      this.properties[0].transformerType = null;
      this.disableTransformer = false;
      this.$emit('sizeChanged');
    }

    /**
     * Fired when LightColor changes.
     */
     onLightColorTypeChanged() {
      const lightColorType = this.properties[0].lightColorType;
      // Disabe LightIntensity selection as rgb only allows nineteen watts.
      if (lightColorType == LightColorType.rgb) {
        this.properties[0].lightIntensityType = LightIntensityType.nineteen;
        this.disableLightIntensity = true;
        return;
      }
      // Reset values as rgb is not wanted.
      this.properties[0].lightIntensityType = null;
      this.disableLightIntensity = false;
      this.$emit('sizeChanged');
    }
    
  }
  </script>
  
  <style scoped lang="scss">
  .text-secondary {
    color: #0000008D !important;
  }
  .form-label {
    width: 20rem;
  }
  </style>