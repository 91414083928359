/**
* Created on: 2022-09-06T08:19:59.4131563+00:00
 */
export enum Side {
  Left = 0,
  Right = 1,
  None = 2
  // Start Userdefined content - don't change anything above
  // End Userdefined content - don't change anything below
}
