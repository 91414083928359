import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { RetryLink } from '@apollo/client/link/retry';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  gql,
  ApolloQueryResult,
  FetchResult,
  NormalizedCacheObject,
  ApolloLink,
  concat,
} from '@apollo/client/core';

/* eslint-disable @typescript-eslint/no-explicit-any*/

const retryLink = new RetryLink({
  attempts: { max: 10 },
  delay: { initial: 300, max: 2000, jitter: true }
});

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_BACKEND_URL,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = localStorage.getItem('token');
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });
  return forward(operation);
});

const link = ApolloLink.from([retryLink, authMiddleware, httpLink]);

//New für caching only 1 instance  of ApolloClient needed and used everywhere
const apolloClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});

/*const getUncachedApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    link: concat(authMiddleware, httpLink),
    cache: new InMemoryCache(),
  });
};*/

export const query = async (query: string): Promise<ApolloQueryResult<any>> => {
  //const apolloClient = getUncachedApolloClient();
  return await apolloClient.query({
    query: gql(query),
  });
};

export const queryWithVariables = async (
  query: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  variables: any,
  abortController: AbortController = new AbortController(),
  shouldRefetch?: boolean
): Promise<FetchResult<any>> => {
  //const apolloClient = getUncachedApolloClient();
  return await apolloClient.query({
    query: gql(query),
    variables: variables,
    context: {
      fetchOptions: {
        signal: abortController.signal,
      },
    },
    fetchPolicy: shouldRefetch ? 'network-only' : 'cache-first', // New Use cache-first policy by default
  });
};

export const mutate = async (mutation: string): Promise<FetchResult<any>> => {
  //const apolloClient = getUncachedApolloClient(); 
  return await apolloClient.mutate({
    mutation: gql(mutation),
  });
};

export const mutateWithVariables = async (
  mutation: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  variables: any,
  abortController: AbortController = new AbortController()
): Promise<FetchResult<any>> => {
  //const apolloClient = getUncachedApolloClient();
  return await apolloClient.mutate({
    mutation: gql(mutation),
    variables: variables,
    context: {
      fetchOptions: {
        signal: abortController.signal,
      },
    },

  });
};

export const endpoint = (
  baseURL = `${process.env.VUE_APP_API_PATH}`,
  options?: Partial<AxiosRequestConfig>
): AxiosInstance => {
  const config = {
    baseURL: baseURL,
    headers: {
      'accept-language': 'de-AT,de-DE;q=0.9,de;q=0.8,en-US;q=0.7,en;q=0.6',
    },
    ...options,
  };
  if (!config.baseURL.endsWith('/')) {
    config.baseURL = `${config.baseURL}/`;
  }
  const axiosInstance = Axios.create(config);

  // response interceptors are used to catch errors globally to show a generic error message
  axiosInstance.interceptors.response.use(
    (response) => {
      /* add any response interceptors if desired */
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export async function apiExecuteGet<T = any>(url: string): Promise<T> {
  const baseUrl = window.location.origin;
  const { data: result } = await endpoint(baseUrl).get<T>(url);
  return result;
}

export async function apiExecutePost<T = any>(
  url: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  body: any,
  baseUrl = window.location.origin
): Promise<T> {
  //const baseUrl = window.location.origin;
  const { data: result } = await endpoint(baseUrl).post<T>(url, body);
  return result;
}
