import { mutateWithVariables } from '@/services/api/api';
import { UploadCategory } from '@/types/enum/upload';
import * as THREE from 'three';
import * as MathAngle from '@/utils/angle';
import { Axis } from '@/types/api/Utility/Axis';
import { Texture } from '@/types/api/Model/Attributes/Texture';
import { FileTexture } from '@/types/api/Utility/FileTexture';
import { unit_type } from '@/types/api/Model/UnitType';

/* eslint-disable @typescript-eslint/no-explicit-any*/

export const addComponents = async (
  modelId: number,
  uuid: string,
  name: string,
  mesh: string,
  filename: string,
  filetype: string,
  position: THREE.Vector3 = new THREE.Vector3(0, 0, 0),
  rotation: THREE.Euler = new THREE.Euler(0, 0, 0),
  scale: THREE.Vector3 = new THREE.Vector3(1, 1, 1),
  componentType: UploadCategory,
  resolutionLevel = 1,
  thumbnailContent: string | null = null,
  thumbnailType = '.jpg',
  boneList: THREE.Bone[] = [],
  parentId: number | null = null,
  componentId: number | null = null,
  resolutionId: number | null = null
): Promise<boolean> => {
  const mutationQueryString = `
        mutation (
          $modelId: Long!,
          $uuid: String!,
          ${componentId ? `$componentId: Long!,` : ''}
          $name: String,
          $positionX: Float!,
          $positionY: Float!,
          $positionZ: Float!,
          $rotationX: Float!,
          $rotationY: Float!,
          $rotationZ: Float!,
          $scaleX: Float!,
          $scaleY: Float!,
          $scaleZ: Float!,
          $componentType: String!,
          ${resolutionId ? `$resolutionId: Long,` : ''}
          $resolutionLevel: Int!,
          $meshBase64: String!,
          $filename: String!,
          $filetype: String!,
          ${parentId ? `parentId: Long,` : ''}
          ${thumbnailContent ? 'thumbnailContent: String!,' : ''}
          ${thumbnailContent ? 'thumbnailType: String!,' : ''}
          $boneList: [MarkingPointPositionInput!]!,
          $curveList: [CurveInput!]!
          ) {
          addComponents (
            input: {
              modelId: $modelId,
              components: [
                {
                  ${componentId ? `componentId: $componentId,` : ''}
                  uuid: $uuid,
                  name: $name,
                  attributes: [],
                  position: {
                    xValue: $positionX,
                    yValue: $positionY,
                    zValue: $positionZ
                  },
                  rotation: {
                    xValue: $rotationX,
                    yValue: $rotationY,
                    zValue: $rotationZ
                  },
                  scale: {
                    xValue: $scaleX,
                    yValue: $scaleY,
                    zValue: $scaleZ
                  },
                  type: {
                    name: $componentType
                  },
                  resolutions: [{
                    ${resolutionId ? `resolutionId: $resolutionId,` : ''}
                    level: $resolutionLevel,
                    meshBase64: {
                      content: $meshBase64,
                      name: $filename,
                      type: $filetype
                    }
                  }],
                  markingPoints: {
                    positions: $boneList
                  },
                  ${
                    thumbnailContent
                      ? `thumbnail: {content: $thumbnailContent, name: "thumbnail", type: $thumbnailType},`
                      : ''
                  }
                  ${parentId ? `parent: {componentId: $parentId},` : ''},
                  curves: $curveList
                }
              ]
            }
          ) { boolean }
        }
      `;

  const data = {
    modelId: modelId,
    componentId: componentId ? componentId : 0,
    uuid: uuid,
    name: name,
    positionX: position.x,
    positionY: position.y,
    positionZ: position.z,
    rotationX: MathAngle.toDegrees(rotation.x),
    rotationY: MathAngle.toDegrees(rotation.y),
    rotationZ: MathAngle.toDegrees(rotation.z),
    scaleX: MathAngle.toDegrees(scale.x),
    scaleY: MathAngle.toDegrees(scale.y),
    scaleZ: MathAngle.toDegrees(scale.z),
    componentType: componentType.toString(),
    resolutionId: resolutionId ? resolutionId : 0,
    resolutionLevel: resolutionLevel,
    meshBase64: mesh,
    filename: filename,
    filetype: `.${filetype}`,
    parentId: parentId,
    thumbnailContent: thumbnailContent,
    thumbnailType: thumbnailType,
    boneList: boneList.map((item) => {
      return {
        markingPoint: {
          name: item.name,
        },
        position: {
          xValue: item.position.x,
          yValue: item.position.y,
          zValue: item.position.z,
        },
      };
    }),
    curveList: [],
  };

  const result = await mutateWithVariables(mutationQueryString, data);
  return result.data.addComponents.boolean;
};

export const updateComponent = async (
  componentId: number,
  uuid: string,
  name: string,
  mesh: string,
  filename: string,
  filetype: string,
  position: THREE.Vector3 = new THREE.Vector3(0, 0, 0),
  rotation: THREE.Euler = new THREE.Euler(0, 0, 0),
  scale: THREE.Vector3 = new THREE.Vector3(1, 1, 1),
  componentType: UploadCategory,
  resolutionLevel = 1,
  thumbnailContent: string | null = null,
  thumbnailType = '.jpg',
  boneList: THREE.Bone[] = [],
  parentId: number | null = null,
  resolutionId: number | null = null              
): Promise<boolean> => {
  const mutationQueryString = `
        mutation (
          $componentId: Long!,
          $uuid: String!,
          $name: String,
          $positionX: Float!,
          $positionY: Float!,
          $positionZ: Float!,
          $rotationX: Float!,
          $rotationY: Float!,
          $rotationZ: Float!,
          $scaleX: Float!,
          $scaleY: Float!,
          $scaleZ: Float!,
          $componentType: String!,
          ${resolutionId ? `$resolutionId: Long,` : ''}
          $resolutionLevel: Int!,
          $meshBase64: String!,
          $filename: String!,
          $filetype: String!,
          ${parentId ? `parentId: Long,` : ''}
          ${thumbnailContent ? 'thumbnailContent: String!,' : ''}
          ${thumbnailContent ? 'thumbnailType: String!,' : ''}
          $boneList: [MarkingPointPositionInput!]!,
          $curveList: [CurveInput!]!,
          $basePrice: Float!,
          $unit: UnitType!,
          $textures: [FileTextureInput!]!, 
          $thumbnailTextures: [FileTextureInput!]!
          ) {
          updateComponent (
            input: {
              component: {
                componentId: $componentId,
                uuid: $uuid,
                name: $name,
                attributes: [],
                position: {
                  xValue: $positionX,
                  yValue: $positionY,
                  zValue: $positionZ
                },
                rotation: {
                  xValue: $rotationX,
                  yValue: $rotationY,
                  zValue: $rotationZ
                },
                scale: {
                  xValue: $scaleX,
                  yValue: $scaleY,
                  zValue: $scaleZ
                },
                type: {
                  name: $componentType
                },
                resolutions: [{
                  ${resolutionId ? `resolutionId: $resolutionId,` : ''}
                  level: $resolutionLevel,
                  meshBase64: {
                    content: $meshBase64,
                    name: $filename,
                    type: $filetype,
                    textures:$textures,
                    thumbnailTextures:$thumbnailTextures
                  }
                }],
                markingPoints: {
                  positions: $boneList
                },
                ${
                  thumbnailContent
                    ? `thumbnail: {content: $thumbnailContent, name: "thumbnail", type: $thumbnailType},`
                    : ''
                }
                ${parentId ? `parent: {componentId: $parentId},` : ''}
                curves: $curveList,
                basePrice: $basePrice,
                unit: $unit
              }
            }
          ) { boolean }
        }
      `;

  const data = {
    componentId: componentId,
    uuid: uuid,
    name: name,
    positionX: position.x,
    positionY: position.y,
    positionZ: position.z,
    rotationX: MathAngle.toDegrees(rotation.x),
    rotationY: MathAngle.toDegrees(rotation.y),
    rotationZ: MathAngle.toDegrees(rotation.z),
    scaleX: MathAngle.toDegrees(scale.x),
    scaleY: MathAngle.toDegrees(scale.y),
    scaleZ: MathAngle.toDegrees(scale.z),
    componentType: componentType.toString(),
    resolutionId: resolutionId ? resolutionId : 0,
    resolutionLevel: resolutionLevel,
    meshBase64: mesh,
    filename: filename,
    filetype: `.${filetype}`,
    parentId: parentId,
    thumbnailContent: thumbnailContent,
    thumbnailType: thumbnailType,
    boneList: boneList.map((item) => {
      return {
        markingPoint: {
          name: item.name,
        },
        position: {
          xValue: item.position.x,
          yValue: item.position.y,
          zValue: item.position.z,
        },
      };
    }),
    curveList: [],
    basePrice: 0,
    unit: unit_type.M,
    textures: [],
    thumbnailTextures: []
  };

  const result = await mutateWithVariables(mutationQueryString, data);
  return result.data.updateComponent.boolean;
};

export const deleteComponent = async (
  componentId: number
): Promise<boolean> => {
  const result = await mutateWithVariables(
    `
        mutation ($componentId: Long!) {
          deleteComponent(
            input: { componentId: $componentId }
          ) { boolean }
        }
      `,
    {
      componentId: componentId,
    }
  );
  return result.data.deleteComponent.boolean;
};

export const transformComponent = (
  modelId: number,
  componentId: number,
  uuid: string,
  newValue: THREE.Vector3,
  oldValue: THREE.Vector3,
  meshValue: THREE.Vector3,
  changePivot = true
): number => {
  let undoSteps = 1;
  const componentChange = {
    x: meshValue.x,
    y: meshValue.y,
    z: meshValue.z,
  };
  if (changePivot) {
    undoSteps++;
    const pivotChange = {
      x: oldValue.x - newValue.x,
      y: oldValue.y - newValue.y,
      z: oldValue.z - newValue.z,
    };
    componentChange.x = meshValue.x - pivotChange.x;
    componentChange.y = meshValue.y - pivotChange.y;
    componentChange.z = meshValue.z - pivotChange.z;
    mutateWithVariables(
      `
        mutation ($modelId: Long!, $componentId: Long, $x: Float!, $y: Float!, $z: Float!) {
          translate(
            input: {
              modelId: $modelId,
              componentId: $componentId,
              translation: { xValue: $x, yValue: $y, zValue: $z }
            }
          ) { long }
        }
      `,
      {
        modelId: modelId,
        componentId: componentId,
        x: pivotChange.x,
        y: pivotChange.y,
        z: pivotChange.z,
      }
    );
  }

  const mutationQueryString = `
      mutation (
        $componentId: Long!,
        $uuid: String!,
        $positionX: Float!,
        $positionY: Float!,
        $positionZ: Float!
        ) {
        updateComponent (
          input: {
            component: {
              componentId: $componentId,
              uuid: $uuid,
              position: {
                xValue: $positionX,
                yValue: $positionY,
                zValue: $positionZ
              },
              attributes: [],
              resolutions: [],
              curves: [],
              basePrice: 0,
              unit: ${unit_type.M}
            }
          }
        ) { boolean }
      }
    `;

  const data = {
    componentId: componentId,
    uuid: uuid,
    positionX: meshValue.x,
    positionY: meshValue.y,
    positionZ: meshValue.z,
  };
  mutateWithVariables(mutationQueryString, data);
  return undoSteps;
};

export const rotateComponent = (
  modelId: number,
  componentId: number,
  uuid: string,
  newValue: THREE.Euler,
  oldValue: THREE.Euler,
  meshValue: THREE.Euler,
  changePivot = true
): number => {
  let undoSteps = 1;
  if (changePivot) {
    const pivotChange = {
      x: oldValue.x - newValue.x,
      y: oldValue.y - newValue.y,
      z: oldValue.z - newValue.z,
    };
    const rotate = (axis: Axis, value: number): void => {
      undoSteps++;
      mutateWithVariables(
        `
        mutation ($modelId: Long!, $componentId: Long, $axis: Axis!, $value: Float!) {
          rotate(
            input: { 
              modelId: $modelId,
              componentId: $componentId,
              axis: $axis,
              value: $value
            }
          ) { long }
        }
      `,
        {
          modelId: modelId,
          componentId: componentId,
          axis: axis,
          value: MathAngle.toDegrees(value),
        }
      );
    };

    if (pivotChange.x !== 0) rotate(Axis.X, pivotChange.x);
    if (pivotChange.y !== 0) rotate(Axis.Y, pivotChange.y);
    if (pivotChange.z !== 0) rotate(Axis.Z, pivotChange.z);
  }

  const mutationQueryString = `
      mutation (
        $componentId: Long!,
        $uuid: String!,
        $rotationX: Float!,
        $rotationY: Float!,
        $rotationZ: Float!
        ) {
        updateComponent (
          input: {
            component: {
              componentId: $componentId,
              uuid: $uuid,
              rotation: {
                xValue: $rotationX,
                yValue: $rotationY,
                zValue: $rotationZ
              },
              attributes: [],
              resolutions: [],
              curves: [],
              basePrice: 0,
              unit: ${unit_type.M}
            }
          }
        ) { boolean }
      }
    `;

  const data = {
    componentId: componentId,
    uuid: uuid,
    rotationX: MathAngle.toDegrees(meshValue.x),
    rotationY: MathAngle.toDegrees(meshValue.y),
    rotationZ: MathAngle.toDegrees(meshValue.z),
  };
  mutateWithVariables(mutationQueryString, data);
  return undoSteps;
};

export const scaleComponent = (
  modelId: number,
  componentId: number,
  uuid: string,
  newValue: THREE.Vector3,
  oldValue: THREE.Vector3,
  meshValue: THREE.Vector3,
  changePivot = true
): number => {
  let undoSteps = 1;
  if (changePivot) {
    const pivotChange = {
      x: oldValue.x - newValue.x,
      y: oldValue.y - newValue.y,
      z: oldValue.z - newValue.z,
    };
    const scale = (axis: Axis, value: number): void => {
      undoSteps++;
      mutateWithVariables(
        `
        mutation ($modelId: Long!, $componentId: Long, $axis: Axis!, $value: Float!) {
          scale(
            input: { 
              modelId: $modelId,
              componentId: $componentId,
              axis: $axis,
              value: $value
            }
          ) { long }
        }
      `,
        {
          modelId: modelId,
          componentId: componentId,
          axis: axis,
          value: value,
        }
      );
    };

    if (pivotChange.x !== 1) scale(Axis.X, pivotChange.x);
    if (pivotChange.y !== 1) scale(Axis.Y, pivotChange.y);
    if (pivotChange.z !== 1) scale(Axis.Z, pivotChange.z);
  }

  const mutationQueryString = `
      mutation (
        $componentId: Long!,
        $uuid: String!,
        $scaleX: Float!,
        $scaleY: Float!,
        $scaleZ: Float!
        ) {
        updateComponent (
          input: {
            component: {
              componentId: $componentId,
              uuid: $uuid,
              scale: {
                xValue: $scaleX,
                yValue: $scaleY,
                zValue: $scaleZ
              },
              attributes: [],
              resolutions: [],
              curves: [],
              basePrice: 0,
              unit: ${unit_type.M}
            }
          }
        ) { boolean }
      }
    `;

  const data = {
    componentId: componentId,
    uuid: uuid,
    scaleX: meshValue.x,
    scaleY: meshValue.y,
    scaleZ: meshValue.z,
  };
  mutateWithVariables(mutationQueryString, data);
  return undoSteps;
};

export const changeParent = (
  modelId: number,
  componentId: number,
  uuid: string,
  parentId: number | null
): number => {
  const mutationQueryString = `
      mutation (
        $componentId: Long!,
        $uuid: String!,
        $parentId: Long
        ) {
        updateComponent (
          input: {
            component: {
              componentId: $componentId,
              uuid: $uuid,
              ${parentId ? `parent: {componentId: $parentId},` : 'parent: null'}
              attributes: [],
              resolutions: [],
              curves: [],
              basePrice: 0
            }
          }
        ) { boolean }
      }
    `;

  const data = {
    componentId: componentId,
    uuid: uuid,
    parentId: parentId,
  };
  mutateWithVariables(mutationQueryString, data);
  return 1;
};

export const changeThumbnail = async (
  componentId: number,
  uuid: string,
  thumbnail: string | null,
  thumbnailType: string | null,
  thumbnailUrl: string | null
): Promise<boolean> => {
  if (!thumbnail) {
    return false;
  }

  const mutationQueryString = `
      mutation ($input: UpdateComponentInput!) {
        updateComponent (input: $input) {
          boolean
        }
      }
    `;

  const data = {
    input: {
      component: {
        componentId: componentId,
        uuid: uuid,
        attributes: [],
        curves: [],
        resolutions: [],
        thumbnail: {
          name: thumbnail,
          type: thumbnailType,
          content: thumbnailUrl,
          textures: [],
        },
        basePrice: 0
      },
    },
  };
  const result = await mutateWithVariables(mutationQueryString, data);
  return result.data.updateComponent.boolean;
};
