<template>
  <template v-if="isLoading">
    <p>Loading...</p>
  </template>
  <template v-else>
    <div class="row" v-if="!readonly">
      <template v-for="field in fields">
        <div
          :key="field.id"
          :class="field.fullColumn ? 'col-sm-12 mb-3 ps-4' : 'col-sm-6 mb-3'"
          v-if="field.type === 'select-option' && field.show"
        >
          <label :for="field.id + 'select-option'" class="form-label">{{
            field.label
          }} <a v-if="Boolean(field.image)" @click="$emit('infoButtonClicked', field.image)"><el-icon :size="15" style="position: relative; left: 2px; top: 3px;"><InfoFilled /></el-icon></a></label>
          <select
            v-model="field.value"
            class="form-select"
            :id="field.id + 'select-option'"
            @change="(e) => handleChange((e?.target as HTMLSelectElement)?.value, field)"
          >
            <option
              v-for="option in field.options"
              :key="option.id"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
        <div
          :key="field.id"
          :class="field.fullColumn ? 'col-sm-12 mb-3 ps-4' : 'col-sm-6 mb-3'"
          v-if="field.type === 'input-text' && field.show"
        >
          <label :for="field.id + 'input-text'" class="form-label">{{
            field.label
          }} <a v-if="Boolean(field.image)" @click="$emit('infoButtonClicked', field.image)"><el-icon :size="15" style="position: relative; left: 2px; top: 3px;"><InfoFilled /></el-icon></a></label>
          <input
            type="text"
            class="form-control"
            :id="field.id + 'input-text'"
            v-model="field.value"
            @change="
            (e:Event) => {
              handleChange((e.target as HTMLInputElement)?.value as any, field);
            }
          "
          />
        </div>
        <div
          :key="field.id"
          :class="field.fullColumn ? 'col-sm-12 mb-3 ps-4' : 'col-sm-6 mb-3'"
          v-if="field.type === 'switch' && field.show"
        >
          <label :for="field.id + 'switch'" class="form-label">{{
            field.label
          }} <a v-if="Boolean(field.image)" @click="$emit('infoButtonClicked', field.image)"><el-icon :size="15" style="position: relative; left: 2px; top: 3px;"><InfoFilled /></el-icon></a></label>
          <br />
          <el-switch
            :id="field.id + 'switch'"
            v-model="field.value"
            @change="(value) => handleChange(value, field)"
          />
        </div>
        <div
          :key="field.id"
          :class="field.fullColumn ? 'col-sm-12 mb-3 ps-4' : 'col-sm-6 mb-3'"
          v-if="field.type === 'slider' && field.show"
        >
          <label :for="field.id + 'slider'" class="form-label">{{
            field.label
          }} <a v-if="Boolean(field.image)" @click="$emit('infoButtonClicked', field.image)"><el-icon :size="15" style="position: relative; left: 2px; top: 3px;"><InfoFilled /></el-icon></a></label>
          <el-slider
            v-model="field.value"
            show-input
            :precision="0"
            :min="field.min"
            :max="field.max"
            :step="1"
            :show-tooltip="false"
            :id="field.id + 'slider'"
            @change="(value) => handleChange(value, field)"
          />
        </div>
        <div
          :key="field.id"
          :class="field.fullColumn ? 'col-sm-12 mb-3 ps-4' : 'col-sm-6 mb-3'"
          v-if="field.type === 'radio-button' && field.show"
        >
          <label :for="field.id + 'radio-button'" class="form-label">{{
            field.label
          }} <a v-if="Boolean(field.image)" @click="$emit('infoButtonClicked', field.image)"><el-icon :size="15" style="position: relative; left: 2px; top: 3px;"><InfoFilled /></el-icon></a></label
          ><br />
          <el-radio-group
            v-model="field.value"
            size="large"
            :id="field.id + 'radio-button'"
          >
            <el-radio-button
              v-for="opt in field.options"
              :key="opt.id"
              :label="opt.value"
              @change="(e) => handleChange(opt.value, field)"
            >
              <template v-if="opt.image">
                {{ opt.label }} <br />
                <img class="parameter-img" :src="opt.image" />
              </template>
              <template v-else>
                {{ opt.label }}
              </template>
            </el-radio-button>
          </el-radio-group>
        </div>
      </template>
    </div>
    <div class="row" v-else>
      <h3 class="mt-3">{{ $t('components.order-summary.parameterTitle') }}</h3>
      <div class="row border-0">
        <div class="col-12 mb-2" v-for="field in fields" :key="field.id">
          <div class="row">
            <label
              class="col form-label text-secondary mb-1"
              for="body-side-readonly"
              >{{ field.label }}</label
            >
            <label class="col form-label mb-1">{{ field.value }}</label>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import { BodySide } from '@/types/enum/order';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as CustomerService from '@/services/api/customerService';
import Auth from '@/services/api/auth';
import { Body_Attribute } from '@/types/api/Patient/BodyAttribute';
import { Product } from '@/types/api/Model/Product';
import { cloneDeep } from '@apollo/client/utilities';
import * as FormBuilderService from '@/services/api/formBuilderService';

@Options({
  components: {},
  emits: ['updateProperties', 'infoButtonClicked'],
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class ProductParamterTemplate extends Vue {
  @Prop() properties!: any[];
  @Prop() product!: Product;
  @Prop({ default: false }) readonly!: boolean;

  authenticator = Auth.getInstance();
  bodyAttributes: Body_Attribute[] = [];

  BodySide = BodySide;
  selectedBodyAttribute: Body_Attribute | null = null;
  fields: any[] = [];
  isLoading = false;

  mounted(): void {
    if (this.authenticator.user) {
      CustomerService.getBodyAttributesByUserId(
        this.authenticator.user.userId
      ).then((bodyAttributes) => {
        this.bodyAttributes = bodyAttributes;
        this.getFormBuilderConfigs();
      });
    }
  }

  getFormBuilderConfigs() {
    this.isLoading = true;
    FormBuilderService.getProductFormBuilder(this.product.productId)
      .then((res) => {
        const parsedFields = JSON.parse(res || '[]');
        const newFields = parsedFields.map((cField) => {
          const show = this.checkCondition(cField, parsedFields);
          Object.entries(this.properties[0]).forEach(([key, value]) => {
            if (key === cField.key) cField.value = value || cField.value;
          });
          return {
            ...cField,
            show,
          };
        });
        this.fields = newFields;
        this.isLoading = false;
      })
      .catch((err) => {
        console.error('err', err);
        this.isLoading = false;
      });
  }

  checkCondition(cField: any, fields?: any) {
    const fs = fields ? fields : this.fields;
    let shouldShow = true;
    if (cField.conditions) {
      cField.conditions.forEach((cs) => {
        const relatedField = fs.find((f) => f.id === cs.idRelation);
        if (relatedField)
          shouldShow = relatedField.value.toString() === cs.value.toString();
      });
    }
    return shouldShow;
  }

  handleChange(value: unknown, field: { id: number }) {
    try {
      const clonedFields = cloneDeep(this.fields);
      const newFields = clonedFields.map((cField) => {
        const newField = cloneDeep(cField);
        if (cField.id === field.id) newField.value = value;
        const show = this.checkCondition(cField);
        return {
          ...newField,
          show,
        };
      });
      this.fields = newFields;
      const productProperties = {};
      newFields.forEach((f) => {
        productProperties[f.key] = f.value;
      });
      this.$emit('updateProperties', productProperties);
    } catch (error) {
      console.error(error);
    }
  }
}
</script>

<style scoped lang="scss">
.text-secondary {
  color: #0000008d !important;
}
.form-label {
  width: 20rem;
}

.parameter-img {
  padding-top: 12px;
  width: 100px;
  height: 100px;
}
</style>
