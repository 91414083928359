import * as THREE from 'three';
import {
  calculateSimilarVertexForAdapter,
  GeometryAdapterInterface,
} from '@/plugin/ffd/adapter/GeometryAdapterFactory';

export interface FaceIndices {
  a: number;
  b: number;
  c: number;
}

export interface FaceVertices {
  a: THREE.Vector3;
  b: THREE.Vector3;
  c: THREE.Vector3;
}

/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class GeometryAdapter implements GeometryAdapterInterface {
  geometry: THREE.BufferGeometry;
  similarVertex: number[][];

  constructor(geometry: THREE.BufferGeometry, calculateSimilarVertex = false) {
    this.geometry = geometry;
    this.similarVertex = [...Array(this.numVertices)].map(() => []);
    if (calculateSimilarVertex) calculateSimilarVertexForAdapter(this);
  }

  get vertices(): THREE.Vector3[] {
    return (this.geometry as any).vertices;
  }

  private get faces(): THREE.Face[] {
    return (this.geometry as any).faces;
  }

  private get normals(): THREE.Vector3[] {
    return (this.geometry as any).normals;
  }

  get numVertices(): number {
    return this.vertices.length;
  }

  get numFaces(): number {
    return this.faces.length;
  }

  setVertex(index: number, x: number, y: number, z: number): void {
    this.vertices[index].set(x, y, z);
  }

  setVertexX(index: number, x: number): void {
    this.vertices[index].setX(x);
  }

  setVertexY(index: number, y: number): void {
    this.vertices[index].setY(y);
  }

  setVertexZ(index: number, z: number): void {
    this.vertices[index].setZ(z);
  }

  getVertex(index: number): THREE.Vector3 {
    return this.vertices[index];
  }

  getVertexX(index: number): number {
    return this.vertices[index].x;
  }

  getVertexY(index: number): number {
    return this.vertices[index].y;
  }

  getVertexZ(index: number): number {
    return this.vertices[index].z;
  }

  public getNormal(index: number): THREE.Vector3 {
    return this.normals[index];
  }

  getFace(index: number): FaceIndices {
    return this.faces[index];
  }

  getFaceVertices(index: number): FaceVertices {
    const face = this.getFace(index);

    return {
      a: this.getVertex(face.a),
      b: this.getVertex(face.b),
      c: this.getVertex(face.c),
    };
  }

  updateVertices(): void {
    (this.geometry as any).verticesNeedUpdate = true;
  }

  updateFaces(): void {
    (this.geometry as any).elementsNeedUpdate = true;
  }

  getBoundingBox(): THREE.Box3 | null {
    this.geometry.computeBoundingBox();

    return this.geometry.boundingBox;
  }
}
