import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-067ceffd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "level-item action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransformTools = _resolveComponent("TransformTools")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TransformTools, {
      ref: "transformTools",
      modelValue: _ctx.modelValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue) = $event)),
      troisRenderer: _ctx.troisRenderer,
      editorMode: _ctx.editorMode,
      selectionList: _ctx.selectionList,
      viewCtrl: _ctx.viewCtrl,
      canTransform: _ctx.canCurve && _ctx.isCurvePlaneSelected,
      canDisableTransform: true,
      canManualTransform: true,
      canTogglePivotMode: false,
      selectObject: _ctx.selectObject,
      historyList: _ctx.historyList
    }, null, 8, ["modelValue", "troisRenderer", "editorMode", "selectionList", "viewCtrl", "canTransform", "selectObject", "historyList"]),
    (_ctx.canCurve && _ctx.activeCurve)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["level-item action", { active: _ctx.isModifyTypeAdd }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setCurveTool(_ctx.CurveTool.add)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: ['fac', 'pen_plus'] })
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.canCurve && _ctx.activeCurve)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["level-item action", { active: _ctx.isModifyTypeRemove }]),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setCurveTool(_ctx.CurveTool.remove)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: ['fac', 'pen_minus'] })
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.canCurve && _ctx.activeCurve)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["level-item action", {
      active: _ctx.isModifyTypeMove,
    }]),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setCurveTool(_ctx.CurveTool.move)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "pen-nib" })
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.canCurve)
      ? (_openBlock(), _createBlock(_component_el_popover, {
          key: 3,
          ref: "popoverAddCurve",
          placement: "bottom-start",
          title: _ctx.$t('components.mesh-editor.curve.title'),
          width: 200,
          trigger: "click",
          onShow: _ctx.showAddCurveSettings
        }, {
          reference: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_font_awesome_icon, { icon: "circle-plus" })
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_form, {
              ref: "dataForm",
              model: _ctx.curveData,
              onSubmit: _ctx.submitCurve
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  prop: "category",
                  label: _ctx.$t('components.mesh-editor.curve.category')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.curveData.category,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.curveData.category) = $event))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allowedCurveCategories, (category) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: category,
                            value: category,
                            label: _ctx.$t(`enum.curve-category.${category}`)
                          }, null, 8, ["value", "label"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, {
                  prop: "name",
                  rules: {
          required: true,
        },
                  label: _ctx.$t('components.mesh-editor.curve.name')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.curveData.name,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.curveData.name) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, {
                  prop: "closed",
                  label: _ctx.$t('components.mesh-editor.curve.closed')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_checkbox, {
                      modelValue: _ctx.curveData.closed,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.curveData.closed) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, {
                  prop: "type",
                  label: _ctx.$t('components.mesh-editor.curve.type')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.curveData.type,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.curveData.type) = $event))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_ctx.CurveType), (cType) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: cType,
                            value: cType,
                            label: _ctx.$t(`enum.curve-type.${cType}`)
                          }, null, 8, ["value", "label"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      onClick: _ctx.addPlaneCurve,
                      type: "primary",
                      disabled: _ctx.curveData.name.length === 0
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('components.mesh-editor.plane')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick", "disabled"]),
                    _createVNode(_component_el_button, {
                      onClick: _ctx.addMeshCurve,
                      type: "primary",
                      disabled: !_ctx.canCreateMeshCurve || _ctx.curveData.name.length === 0
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('components.mesh-editor.mesh')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model", "onSubmit"])
          ]),
          _: 1
        }, 8, ["title", "onShow"]))
      : _createCommentVNode("", true)
  ], 64))
}