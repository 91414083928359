<template>
  <el-container class="screenHeight">
    <el-container>
      <el-main v-loading="meshEditorLoading">
        <MeshEditor
          v-model="selectedProductInput"
          :canTogglePivotMode="false"
          :canSelect="false"
          :canToggleBones="false"
          :canDeform="false"
          :canCurve="false"
          :activateAlineToFloor="false"
          :activateBones="false"
          :activeEditorMode="false"
          :activateDisplayBoundingBox="false"
          :activateDisplayGrid="false"
          :resetOnPivotChanged="true"
          :fitCameraToScene="true"
          :canUndo="false"
          :canPartSelect="false"
          v-on:treeDataLoaded="ConfigProduct"
        />
      </el-main>
    </el-container>
  </el-container>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import MeshEditor from '@/components/three/MeshEditor.vue';
import { MeshTreeData, MeshTreeDataItem } from '@/types/ui/MeshTreeData';
import * as ProductDbService from '@/services/api/productDbService';
import { UploadCategory, FileType3D, getFileType } from '@/types/enum/upload';
import * as THREEMaterial from '@/utils/three/material';
import * as THREE from 'three';
import { apiExecuteGet } from '@/services/api/api';
import { CoverModel, BodySide, ProthesisType } from '@/types/enum/order';
import * as LayoutUtility from '@/utils/layout';
import { Color } from 'three';
import { Prop } from 'vue-property-decorator';
import { DefaultUndoRedo } from '@/types/ui/HistoryList';

//#region interfaces
interface ImageData {
  uuid: string;
  name: string;
  url: string;
  thumbnailUrl: string;
}

interface Embossing {
  name: string;
  colors: string[];
}

interface MeshConfig {
  filename: string;
  colors: { [name: string]: string[] } | undefined;
  embossing: Embossing[] | undefined;
  wtransfer: string[] | undefined;
  thumbnail: string;
}

interface ProductConfig {
  type: string;
  name: string;
  thumbnail: string;
  meshes: MeshConfig[];
}
//#endregion interfaces

@Options({
  components: {
    MeshEditor,
  },
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class ThreeDView extends Vue implements DefaultUndoRedo {
  @Prop({ default: '' }) productId!: string;
  @Prop({ default: '' }) color!: string[] | string;
  @Prop({ default: '' }) embossingId!: string [] | string;
  @Prop({ default: '' }) embossingColor!: string[] | string;
  @Prop({ default: '' }) wtransferId!: string[] | string;

  //#region properties
  productConfigurationDB: ProductConfig[] = [];
  productDB: ProductDbService.ProductData[] = [];
  selectedProductId = '';
  selectedProductInput: MeshTreeData = new MeshTreeData(null, this);
  embossingDB: ImageData[] = [];
  wtransferDB: ImageData[] = [];
  meshEditorLoading = false;
  dataLoaded = false;
  width = window.innerWidth;

  CoverModel = CoverModel;
  ProthesisType = ProthesisType;
  BodySide = BodySide;
  FileType = FileType3D;
  getFileType = getFileType;
  transparentColor = '#333333';
  //#endregion properties

  //#region load
  mounted(): void {
    LayoutUtility.refresh();
    const colorConfigUrl = `assets/config/productDB.json`;
    apiExecuteGet(colorConfigUrl).then((result) => {
      this.productConfigurationDB = result.products as ProductConfig[];
      for (const item of result.products) {
        ProductDbService.addData(
          UploadCategory[item.type],
          item.name,
          item.thumbnail,
          item.meshes.map((mesh) => mesh.filename),
          `assets/meshes/`,
          item.meshes.map((mesh) => mesh.thumbnail),
          `assets/thumbnails/`
        );
      }
      this.productDB = ProductDbService.getList();
      this.dataLoaded = true;


      

      this.handleSelectProduct(this.productDB[this.productId]);
    });
  }

  ConfigProduct(): void {
    this.meshEditorLoading = true;

    let i = 0;
    this.selectedProductInput.treeData.forEach((treeData) => {
      treeData.isSelected = true;

      this.loadEmbossingList();
      this.loadWtransferList();

      if (this.color) {
        this.handleSelectProductColor(Array.isArray(this.color) ? this.color[i] : this.color);
      }

      if (this.embossingId) {
        this.handleSelectEmbossing(Array.isArray(this.embossingId) ? this.embossingDB[this.embossingId[i]] : this.embossingDB[this.embossingId]);
        if (this.embossingColor) {
          this.handleSelectEmbossingColor(
              Array.isArray(this.embossingColor) ? this.embossingColor[i] : this.embossingColor
            );
        }
      } else if (this.wtransferId) {
        this.handleSelectWtransfer(Array.isArray(this.wtransferId) ? this.wtransferDB[this.wtransferId[i]] : this.wtransferDB[this.wtransferId]);
      }

      i++;
      treeData.isSelected = false;
    });

    this.meshEditorLoading = false;
  }

  loadEmbossingList(): void {
    this.embossingDB.length = 0;
    const config = this.selectedConfig;
    const selection = this.selectedProductInput.getSelection();
    const meshEmbossing: string[] = [];
    if (selection.length > 0 && config) {
      const mesh = config.meshes.find(
        (item) => item.filename === selection[0].filename
      );
      if (mesh && mesh.embossing) {
        meshEmbossing.push(...mesh.embossing.map((emb) => emb.name));
      }
    }
    if (meshEmbossing) {
      for (const item of meshEmbossing) {
        this.embossingDB.push({
          uuid: item,
          name: item,
          url: `assets/embossing/${item}`,
          thumbnailUrl: `assets/embossing/thumbnails/${item}`,
        });
      }
    } else {
      const embossingConfigUrl = `assets/config/embossingDB.json`;
      apiExecuteGet(embossingConfigUrl).then((result) => {
        for (const item of result.embossing) {
          this.embossingDB.push({
            uuid: item.name,
            name: item.name,
            url: `assets/embossing/${item.filename}`,
            thumbnailUrl: `assets/embossing/thumbnails/${item.filename}`,
          });
        }
      });
    }
  }

  loadWtransferList(): void {
    this.wtransferDB.length = 0;
    const config = this.selectedConfig;
    const selection = this.selectedProductInput.getSelection();
    const meshWtransfer: string[] = [];
    if (selection.length > 0 && config) {
      const mesh = config.meshes.find(
        (item) => item.filename === selection[0].filename
      );
      if (mesh && mesh.wtransfer) {
        meshWtransfer.push(...mesh.wtransfer);
      }
    }
    if (meshWtransfer) {
      for (const item of meshWtransfer) {
        this.wtransferDB.push({
          uuid: item,
          name: item,
          url: `assets/wtransfer/${item}`,
          thumbnailUrl: `assets/wtransfer/thumbnails/${item}`,
        });
      }
    } else {
      const wtransferConfigUrl = `assets/config/wtransferDB.json`;
      apiExecuteGet(wtransferConfigUrl).then((result) => {
        for (const item of result.wtransfer) {
          this.wtransferDB.push({
            uuid: item.name,
            name: item.name,
            url: `assets/wtransfer/${item.filename}`,
            thumbnailUrl: `assets/wtransfer/thumbnails/${item}`,
          });
        }
      });
    }
  }
  //#endregion load

  //#region selection
  handleSelectProduct(file: ProductDbService.ProductData | null): void {
    if (file) {
      this.selectedProductId = file.id;
      this.selectedProductInput.clear();
      let firstItem: MeshTreeDataItem | null = null;
      for (const mesh of file.mesh) {
        if (mesh.filetype !== FileType3D.NONE) {
          if (mesh.url) {
            const item = this.selectedProductInput.addImport(
              file.id.toString(),
              mesh.filename,
              mesh.url,
              mesh.thumbnail,
              mesh.thumbnailUrl,
              null,
              false
            );
            if (!firstItem) firstItem = item;
          }
        }
      }
    } else {
      this.selectedProductId = '';
      this.selectedProductInput.clear();
    }
  }

  get selectedConfig(): ProductConfig | null {
    const product = this.productDB.find(
      (item) => item.id === this.selectedProductId
    );
    if (product) {
      const filename = product.mesh[0].filename;
      const name = product.name;
      const config = this.productConfigurationDB.find(
        (item) => item.meshes[0].filename === filename && item.name === name
      );
      if (config) return config;
    }
    return null;
  }

  getActivePart(): MeshTreeDataItem | null {
    const selection = this.selectedProductInput.getSelection();
    const data =
      selection.length > 0 ? selection : this.selectedProductInput.treeData;
    if (data.length > 0) return data[0];
    return null;
  }
  //#endregion selection

  //#region color
  handleSelectProductColor(color: string): void {
    const treeData = this.getActivePart();
    this.meshEditorLoading = true;
    if (treeData) {
      (treeData.mesh as THREE.Mesh).material = new THREE.MeshPhongMaterial();
      THREEMaterial.setColor(treeData.mesh, color);
      treeData.color = color;
    }
    this.meshEditorLoading = false;
  }
  //#endregion color

  //#region embossing
  set selectedEmbossing(uuid: string | null) {
    const treeData = this.getActivePart();
    if (treeData) {
      treeData.embossingId = uuid;
    }
  }

  get selectedEmbossing(): string | null {
    const treeData = this.getActivePart();
    if (treeData) {
      if (treeData.embossingId) return treeData.embossingId;
    }
    return null;
  }

  handleSelectEmbossing(file: ImageData | null): void {
    const treeData = this.getActivePart();
    if (file) {
      this.selectedEmbossing = file.uuid;
      if (treeData) {
        treeData.embossingId = file.uuid;
        treeData.embossing = this.selectedEmbossingName;
        treeData.embossingThumbnailUrl = this.selectedEmbossingThumbnailUrl;
        treeData.embossingUrl = this.selectedEmbossingUrl;
        new THREE.TextureLoader().load(file.url, (bmap) => {
          const color = treeData.color ? treeData.color : 'white';
          treeData.material = new THREE.MeshPhongMaterial({
            color: color,
            shininess: 20,
            bumpMap: bmap,
            bumpScale: 0.45,
          });
          if (!this.embossingColor) {
            (treeData.mesh as THREE.Mesh).material = treeData.material;
          }
        });
      }
    } else {
      this.selectedEmbossing = null;
      if (treeData) {
        treeData.embossingId = null;
        treeData.embossing = null;
        treeData.embossingThumbnailUrl = null;
      }
    }
  }

  get selectedEmbossingThumbnailUrl(): string {
    const selection = this.embossingDB.find(
      (item) => item.uuid === this.selectedEmbossing
    );
    if (selection) {
      return selection.thumbnailUrl;
    }
    return '';
  }

  get selectedEmbossingUrl(): string {
    const selection = this.embossingDB.find(
      (item) => item.uuid === this.selectedEmbossing
    );
    if (selection) {
      return selection.url;
    }
    return '';
  }

  get selectedEmbossingName(): string {
    const selection = this.embossingDB.find(
      (item) => item.uuid === this.selectedEmbossing
    );
    if (selection) {
      return selection.name;
    }
    return '';
  }
  //#endregion embossing

  //#region wtransfer
  set selectedWtransfer(uuid: string | null) {
    const treeData = this.getActivePart();
    if (treeData) {
      if (treeData.wtransferId) treeData.wtransferId = uuid;
    }
  }

  get selectedWtransfer(): string | null {
    const treeData = this.getActivePart();
    if (treeData) {
      if (treeData.wtransferId) return treeData.wtransferId;
    }
    return null;
  }

  async handleSelectWtransfer(file: ImageData | null): Promise<void> {
    const treeData = this.getActivePart();
    if (file) {
      this.selectedWtransfer = file.uuid;
      if (treeData) {
        treeData.wtransferId = file.uuid;
        treeData.wtransfer = this.selectedWtransferName;
        treeData.wtransferUrl = this.selectedWtransferUrl;
        new THREE.TextureLoader().load(file.url, (bmap) => {
          const color = 'white';
          treeData.material = new THREE.MeshPhongMaterial({
            color: color,
            shininess: 20,
            map: bmap,
          });
          (treeData.mesh as THREE.Mesh).material = treeData.material;
          treeData.color = null;
        });
      }
    } else {
      this.selectedWtransfer = null;
      if (treeData) {
        treeData.wtransferId = null;
        treeData.wtransfer = null;
        treeData.wtransferUrl = null;
      }
    }
  }

  get selectedWtransferUrl(): string {
    const selection = this.wtransferDB.find(
      (item) => item.uuid === this.selectedWtransfer
    );
    if (selection) {
      return selection.thumbnailUrl;
    }
    return '';
  }

  get selectedWtransferName(): string {
    const selection = this.wtransferDB.find(
      (item) => item.uuid === this.selectedWtransfer
    );
    if (selection) {
      return selection.name;
    }
    return '';
  }
  //#endregion wtransfer

  set selectedEmbossingColor(color: string | null) {
    const treeData = this.getActivePart();
    if (treeData) {
      treeData.embossingColor = color;
    }
  }

  get selectedEmbossingColor(): string | null {
    const treeData = this.getActivePart();
    if (treeData) {
      if (treeData.embossingColor) return treeData.embossingColor;
    }
    return this.transparentColor;
  }

  handleSelectEmbossingColor(colorString: string): void {
    const treeData = this.getActivePart();
    this.selectedEmbossingColor = colorString;

    if (treeData) {
      let color = new Color(colorString);
      if (colorString == this.transparentColor && treeData.color) {
        color = new Color(treeData.color);
      }

      const url = treeData.embossingUrl;
      if (url == null) return;

      new THREE.ImageLoader().load(url, (image) => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;

        const context = canvas.getContext('2d');
        if (!context) return;

        context.drawImage(image, 0, 0);
        const imgData = context.getImageData(0, 0, canvas.width, canvas.height);

        const data = imgData.data;
        const limitValue = 100;

        let selColor: Color | null = null;
        if (treeData.color) {
          selColor = new Color(treeData.color);
        }

        for (let i = 0; i < data.length; i += 4) {
          if (
            data[i] >= limitValue &&
            data[i + 1] >= limitValue &&
            data[i + 2] >= limitValue
          ) {
            data[i] = selColor ? selColor.r * 255 : 255;
            data[i + 1] = selColor ? selColor.g * 255 : 255;
            data[i + 2] = selColor ? selColor.b * 255 : 255;
          } else {
            data[i] = color.r * 255;
            data[i + 1] = color.g * 255;
            data[i + 2] = color.b * 255;
          }
        }

        context.putImageData(imgData, 0, 0);
        const map = new THREE.CanvasTexture(context.canvas);
        (treeData.material as THREE.MeshPhongMaterial).color = new Color(
          'white'
        );
        (treeData.material as THREE.MeshPhongMaterial).map = map;
        (treeData.mesh as THREE.Mesh).material = treeData.material;
      });
    }
  }

  defaultUndo(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  defaultRedo(): Promise<void> {
    throw new Error('Method not implemented.');
  }
}
</script>
