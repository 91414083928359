import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ebfc2b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = {
  key: 0,
  class: "el-upload-list__item-actions"
}
const _hoisted_3 = {
  key: 0,
  class: "el-upload-list__item-delete"
}
const _hoisted_4 = {
  key: 1,
  class: "el-upload-list__item-actions"
}
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_PreviewRendererDetails = _resolveComponent("PreviewRendererDetails")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.meshes, (mesh) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "image",
        key: mesh.thumbnail!
      }, [
        _createElementVNode("img", {
          src: mesh.videoThumbnailUrl || _ctx.defaultImage,
          alt: mesh.video
        }, null, 8, _hoisted_1),
        (_ctx.canRemove)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              (_ctx.canRemove)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "trash" })
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.canPlay)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
              (_ctx.canPlay)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "btn-play",
                    onClick: ($event: any) => (_ctx.handleShowVideo(mesh))
                  }, [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "play" })
                      ]),
                      _: 1
                    })
                  ], 8, _hoisted_5))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])), [
        [_directive_loading, _ctx.isLoading]
      ])
    }), 128)),
    _createVNode(_component_PreviewRendererDetails, {
      ref: "previewRendererDetails",
      onRemoveItem: _ctx.handleRemoveItem,
      canDownload: true,
      canRemove: true
    }, null, 8, ["onRemoveItem"])
  ], 64))
}