import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a4737d5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mr-3 mt-12" }
const _hoisted_2 = { class: "bg-grey-transparent d-flex flex-row align-items-center views-container" }
const _hoisted_3 = { class: "productViewTitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('views.sidebar-tools.view-option')), 1),
      _createVNode(_component_el_select, {
        modelValue: _ctx.view,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.view) = $event)),
        placeholder: "Select view",
        onChange: _ctx.changeView,
        class: "view-menu-select"
      }, {
        prefix: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, {
            icon: ['fac', _ctx.view]
          }, null, 8, ["icon"])
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.viewOptions, (item) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: item,
              value: item,
              label: _ctx.$t(`enum.views.${item}`)
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: ['fac', item]
                }, null, 8, ["icon"]),
                _createTextVNode(" " + _toDisplayString(_ctx.$t(`enum.views.${item}`)), 1)
              ]),
              _: 2
            }, 1032, ["value", "label"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "onChange"])
    ])
  ]))
}