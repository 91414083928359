/**
 * Created on: 2022-09-06T08:19:59.3802861+00:00
 */
export class ROI {
  public constructor() {
    //
  }

  protected _roiId!: number;
  protected _length!: number;
  protected _width!: number;
  protected _depth!: number;

  /**
   * @returns _roiId
   */
  public get roiId() {
    return this._roiId;
  }

  /**
   * Sets _roiId
   */
  public set roiId(value: number) {
    this._roiId = value;
  }

  /**
   * @returns _length
   */
  public get length() {
    return this._length;
  }

  /**
   * Sets _length
   */
  public set length(value: number) {
    this._length = value;
  }

  /**
   * @returns _width
   */
  public get width() {
    return this._width;
  }

  /**
   * Sets _width
   */
  public set width(value: number) {
    this._width = value;
  }

  /**
   * @returns _depth
   */
  public get depth() {
    return this._depth;
  }

  /**
   * Sets _depth
   */
  public set depth(value: number) {
    this._depth = value;
  }

  // Start Userdefined content - don't change anything above

  public deepCopy(toCopy: ROI): ROI
  {
    if (!this.deepCopy) return toCopy;

    const roi = {
      length: toCopy.length,
      width: toCopy.width,
      depth: toCopy.depth
    } as ROI;

    return roi;
  }
  // End Userdefined content - don't change anything below
}
