import { createApp, ref } from 'vue';
import VueCookies from 'vue3-cookies';
import i18n from '@/i18n';
import App from '@/App.vue';
import router from './router';
import mitt, { Emitter, EventType } from 'mitt';
import { TroisJSVuePlugin } from 'troisjs';

import ElementPlus from 'element-plus';
import '@/assets/styles/global.scss';
import '@/assets/styles/element-plus.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
// internal icons
import '@/assets/fontawesome/css/all.min.css'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { fac } from '@/assets/icons/fac';
import { IconPack } from '@fortawesome/fontawesome-common-types';

import '@/assets/styles/bootstrap.scss';
import { KEY_CURRENT_NAME, KEY_CURRENT_PRICE, KEY_CURRENT_STEP, KEY_IS_EDITOR, KEY_STEP_TEXT } from './utils/keys';

import { InfoFilled } from '@element-plus/icons-vue';
import Auth from './services/api/auth';
import curriedDarken from 'polished/lib/color/darken';
import curriedLighten from 'polished/lib/color/lighten';

const customerColorMap: { [key: string]: { color: string; text: string } } = {
  wjtortho: { color: '#007AC2', text: '#fff' },
  luttermann: { color: '#204a9e', text: '#fff' },
  bayer: { color: '#0dade6', text: '#fff' },
  festwood: { color: '#9abd38', text: '#fff' },
  gzpilz: { color: '#74114d', text: '#fff' }
};

async function setPrimaryColor() {
  const authentication = Auth.getInstance();

  await loadDomains(authentication);

  let primaryColor;
  let primaryText;
  let primaryFooter;
  
  if (authentication.domains) {
    const matchingDomain = authentication.domains.find(d => customerColorMap[d.urlSuffix.toLowerCase()]);
  
    if (matchingDomain) {
      const { color, text } = customerColorMap[matchingDomain.urlSuffix.toLowerCase()];
      primaryColor = color;
      primaryText = text;
      primaryFooter = color;
    } else { 
      primaryColor = '#FFD400';
      primaryText = '#000';
      primaryFooter = 'linear-gradient(90deg,#BAC435,#4594AF)';
    }
  } else { 
    primaryColor = '#FFD400';
    primaryText = '#000';
    primaryFooter = 'linear-gradient(90deg,#BAC435,#4594AF)';
  }

  document.documentElement.style.setProperty('--primary', primaryColor);
  document.documentElement.style.setProperty('--color-primary', primaryColor);
  document.documentElement.style.setProperty('--primary-text', primaryText);
  document.documentElement.style.setProperty('--color-footer', primaryFooter);
  document.documentElement.style.setProperty('--color-primary-dark', curriedDarken(0.05, primaryColor));
  document.documentElement.style.setProperty('--color-primary-light', curriedLighten(0.05, primaryColor));
}

document.addEventListener('DOMContentLoaded', function() {
  setPrimaryColor();
});

async function loadDomains(authentication) {
  while (!authentication.domains || authentication.domains.length === 0) {
    await new Promise(resolve => setTimeout(resolve, 100));
  }
}

library.add(fas);
library.add(far);
library.add(fac);

/* eslint-disable @typescript-eslint/no-explicit-any*/

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    eventBus: Emitter<Record<EventType, unknown>>;
  }
}

const eventBus = mitt();
const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(router);
app.use(i18n as any);
app.use(VueCookies as any, {
  expireTimes: '30d',
  secure: true,
  sameSite: 'Strict', // "Lax"
});
app.config.globalProperties.eventBus = eventBus;
app.use(ElementPlus);
app.use(TroisJSVuePlugin);
app.component('InfoFilled', InfoFilled);

// Global provides
app.provide(KEY_IS_EDITOR, ref(false));
app.provide(KEY_STEP_TEXT, ref("firstStepDescription"));
app.provide(KEY_CURRENT_NAME, ref("S-Flex Cover"));
app.provide(KEY_CURRENT_PRICE, ref("235"));
app.provide(KEY_CURRENT_STEP, ref(1));

app.mount('#app');

export default app;