import { BlobServiceClient, BlockBlobParallelUploadOptions } from "@azure/storage-blob";
import { AzureSASCredential, TableClient, TableEntity } from '@azure/data-tables';
import { v4 as uuidv4 } from 'uuid';
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile } from '@ffmpeg/util';
import axios from "axios";
import { FileTypeImage, FileTypeVideo } from "@/types/enum/upload";

// Replace with your shared access token.
// Get access to your azure blob & tables storage account.
const blobUrlGeneral = process.env.VUE_APP_AZURE_BLOB_URL;
const blobUrlFormBuilder = process.env.VUE_APP_AZURE_BLOB_URL_FORMBUILDER;
const sasTokenGeneral = process.env.VUE_APP_AZURE_SAS_TOKEN;
const sasTokenFormBuilder = process.env.VUE_APP_AZURE_SAS_TOKEN_FORMBUILDER;
const tablesUrl = process.env.VUE_APP_AZURE_TABLES_URL;
const tablesSasToken = process.env.VUE_APP_AZURE_TABLES_SAS_TOKEN;

// Define & export interface ... 
export interface ProductVideoFiles {
  uid?: number,
  video: string,
  videoFiletype: FileTypeVideo,
  videoUrl: string,
  videoThumbnail: string,
  videoThumbnailFiletype: FileTypeImage,
  videoThumbnailUrl: string,
}

export interface TableField {
  productId: string,
  filename: string,
  fileType: FileTypeImage | FileTypeVideo,
  fileUrl: string,
}

export interface ExtendedTableEntity extends TableEntity {
  fileType: FileTypeImage | FileTypeVideo,
  fileUrl: string,
}

export interface VideoDetails {
  video: string,
  videoFiletype: FileTypeVideo,
  videoUrl: string,
  videoThumbnail: string,
  videoThumbnailFiletype: FileTypeImage,
  videoThumbnailUrl: string,
}


/* eslint-disable @typescript-eslint/no-explicit-any*/
export const fileContentToBase64 = (
  file: File,
  callback: (encodeString: string) => void
): void => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    callback(reader.result as string);
  };
};

export function extractExtensionFromBase64(base64String: string): string {
  const match = base64String.match(/^data:(image\/[a-zA-Z]+);base64,/);
  if (match) {
    const mimeType = match[1];
    const extension = mimeType.split('/')[1]; // Get the extension part from MIME type
    return extension;
  }
  return 'png'; // Default to 'png' if MIME type cannot be determined
}

export function generateRandomFilename(extension = 'png') {
  // Create a random part using Date.now() and Math.random()
  const randomPart = Date.now() + '-' + Math.round(Math.random() * 1E9);

  // Combine the random part with the desired file extension
  return `file-${randomPart}.${extension}`;
}

// Convert blob URL to file 
export async function blobUrlToFile(blobUrl: string, fileName: string): Promise<File> {
  // Fetch the blob data from the blob URL
  const response = await fetch(blobUrl);
  const blob = await response.blob();

  // Create a new file from the blob with the specified file name and type
  const file = new File([blob], fileName, { type: blob.type });

  return file;
}

// Image default 
export const defaultImage = '/assets/no-image.jpg'

// Generate filename like slug with unique uuid...  
export function generateFilename(fileName: string): string {
  // Pisahkan nama file dan ekstensi
  const fileExtension = fileName.split('.').pop();
  const nameWithoutExtension = fileName.replace(/\.[^/.]+$/, '');

  // Generate slug dari nama file tanpa ekstensi
  let filename = nameWithoutExtension
    .toLowerCase() // Ubah ke huruf kecil
    .trim() // Hapus spasi di awal dan akhir
    .replace(/[^\w\s-]/g, '') // Hapus karakter non-alphanumeric kecuali spasi dan tanda hubung
    .replace(/\s+/g, '-') // Ganti spasi dengan tanda hubung
    .replace(/-+/g, '-'); // Ganti tanda hubung berturut-turut menjadi satu tanda hubung

  return filename = `${filename}-${uuidv4()}.${fileExtension}`
}

// Generate thumbnail based on the original file extension
export async function generateVideoThumbnail(file: File, prefix = 'thumbnail'): Promise<File> {
  const ffmpeg = new FFmpeg()

  if (!ffmpeg.loaded) {
    await ffmpeg.load();
  }

  // Get the original file extension
  const fileExtension = file.name.split('.').pop(); // Extract file extension
  const fileName = file.name.replace(/\.[^/.]+$/, '');
  const inputFileName = `input.${fileExtension}`; // Use the original extension for the input file
  const outputFileName = `${prefix}-${fileName}.png`; // Use the original extension for the input file

  // Write the original file into FFmpeg's filesystem
  await ffmpeg.writeFile(inputFileName, await fetchFile(file));

  // Extract frame at 1 second
  await ffmpeg.exec(['-i', inputFileName, '-ss', '00:00:01', '-frames:v', '1', outputFileName]);

  // Read the extracted thumbnail
  const result = await ffmpeg.readFile(outputFileName);

  // Convert data to base64 image URL
  const blobUrl = URL.createObjectURL(new Blob([result], { type: 'image/png' }));

  // Convert to file image 
  const imageFile = await blobUrlToFile(blobUrl, outputFileName)

  return imageFile
}

// Function upload file to Azure Blob Storage 
export async function uploadToAzure({
  file,
  direcatoryPath,
  containerName = 'uploadfile',
  type = "general"
}: {
  file: File,
  direcatoryPath: string,
  containerName?: string,
  type?: string
}
): Promise<string> {
  const sasToken = type === 'general' ? sasTokenGeneral : sasTokenFormBuilder
  const blobUrl = type === 'general' ? blobUrlGeneral : blobUrlFormBuilder
  if (!blobUrl || !sasToken) {
    throw new Error('Blob URL or SAS Token is missing');
  }

  // Modify filename metadata 
  const fileName = `${direcatoryPath}/${generateFilename(file.name)}`
  const newFile = new File([file], fileName, { type: file.type });

  console.log('fileName', fileName)


  const blobServiceClient = new BlobServiceClient(`${blobUrl}${sasToken}`);
  const containerClient = blobServiceClient.getContainerClient(containerName);
  const blockBlobClient = containerClient.getBlockBlobClient(newFile.name);


  const uploadOptions: BlockBlobParallelUploadOptions = {
    blobHTTPHeaders: {
      blobContentType: file.type,
    },
  };

  await blockBlobClient.uploadData(file, uploadOptions);
  console.log('uploadToAzure result url: ', blockBlobClient.url)

  return blockBlobClient.url
}


/**
   * Function to delete files from Azure Blob Storage using URL and SAS Token
   * @param fileName 👉 Name of the file to be deleted
   */
export async function deleteFileFromAzure(fileURL: string): Promise<boolean> {
  try {
    // Delete a file by performing an HTTP DELETE request
    await axios.delete(fileURL);
    return true;
  } catch (error) {
    console.error("Failed to delete files from Azure Blob Storage:", error);
    return false;
  }
}

// Function to call download
export async function downloadFileFromAzure(fileName: string, fileUrl: string): Promise<void> {
  try {
    // Fetch file data
    const response = await fetch(fileUrl);
    const blob = await response.blob();

    // Create a URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Create an <a> element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;  // Name of the desired file
    document.body.appendChild(link);
    link.click();

    // Remove the <a> element after the download is complete
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);  // Clear URL blob from memory
  } catch (error) {
    console.error('Error downloading file:', error);
  }
}

async function authAzureTableStorage(): Promise<TableClient> {
  // authenticate Table Client with SAS token
  if (!tablesUrl || !tablesSasToken) {
    throw new Error('Tables URL or SAS Token is missing');
  }

  const tableName = 'uploadFile'
  const tableClient = new TableClient(tablesUrl, tableName, new AzureSASCredential(tablesSasToken));

  return tableClient;
}

// Function to upload file metadata to Azure Table
export async function saveFileMetadataToAzureTables(payloads: TableField[]): Promise<void> {
  if (!payloads) return;

  // authenticate Table Client with SAS token
  const tableClient = await authAzureTableStorage()

  if (!tableClient) return;

  try {
    // Define the entity to insert (ensure PartitionKey and RowKey are correctly named)
    await Promise.all(payloads.map(async (payload) => {
      const entity: ExtendedTableEntity = {
        partitionKey: payload.productId, // Properti case-sensitive
        rowKey: payload.filename, // Properti case-sensitive
        fileType: payload.fileType,
        fileUrl: payload.fileUrl,
        timestamp: new Date().toISOString() // Optional: adding timestamp
      }

      // Store the entity into the Azure Table
      await tableClient.createEntity(entity);
      console.log('File metadata uploaded successfully!');
    }))

  } catch (error) {
    console.error('Error saving file metadata:', error);
  }
}

// Function to get data from azure tables storage 
export async function getFileMetadataToAzureByProudctId(prouductId: string): Promise<TableField[] | void> {
  if (!prouductId) return;

  // authenticate Table Client with SAS token
  const tableClient = await authAzureTableStorage()

  if (!tableClient) return;

  try {
    // Query for the entity with the given partitionKey [productId]
    const entities = tableClient.listEntities<ExtendedTableEntity>({
      queryOptions: {
        filter: `PartitionKey eq '${prouductId}'`
      }
    });

    const resultData: TableField[] = []

    for await (const entity of entities) {
      resultData.push({
        productId: entity.partitionKey,
        filename: entity.rowKey,
        fileType: entity.fileType,
        fileUrl: entity.fileUrl,
      })
    }

    console.log('resultData', resultData);

    return resultData;
  } catch (error) {
    console.error('Error querying file metadata:', error);
  }
}


// Function to Delete data from azure tables storage 
export async function deleteFileMetadataToAzureByProudctId(prouductId: string): Promise<void> {
  // authenticate Table Client with SAS token
  const tableClient = await authAzureTableStorage()

  if (!tableClient) return;

  try {
    // Retrieve all entities based on partitionKey
    const entities = tableClient.listEntities<ExtendedTableEntity>({
      queryOptions: {
        filter: `PartitionKey eq '${prouductId}'`
      }
    });

    // Loop through the found entities and remove each entity
    for await (const entity of entities) {
      await tableClient.deleteEntity(entity.partitionKey, entity.rowKey);
      console.log(`Deleted entity with PartitionKey: ${entity.partitionKey} and RowKey: ${entity.rowKey}`);
    }

    console.log('All entities with the given PartitionKey have been deleted.');
  } catch (error) {
    console.error('Error deleting entities:', error);
  }
}
