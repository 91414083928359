<script lang="ts">
import {MeshTreeData, MeshTreeDataGroupItem, MeshTreeDataItem} from "@/types/ui/MeshTreeData";
import ButtonCard from "@/components/ButtonCard.vue";
import PreviewRendererImg from "@/components/three/PreviewRendererImg.vue";
import { WorkflowModel } from "@/types/ui/WorkflowModel";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ProductQuality from "./three/ProductQuality.vue";
import { waitFor } from "@/utils/three/webGlContext";
@Options({
  components: {
    PreviewRendererImg,
    ButtonCard
  }
})
export default class PartSelection extends Vue {
  @Prop() meshTreeData!: MeshTreeData;
  @Prop() productTypeString!: string;

  meshCountItemList: MeshTreeDataGroupItem[] = [];

  mounted(): void {
    this.meshTreeData.getPartItemList().then((list) => {
      this.meshCountItemList = list.filter(item => item.meshTreeDataItem.name !== 'Reißverschluss'); //shows all parts except Reißverschluss
    });
  }
}
</script>

<template>
<div id="design-selection" class="pl-5 pr-5 pt-3 pb-3 rounded-3 text-white">
  <h1>{{ $t('components.part-selection.title') }}</h1>
  <p class="form-text text-white">{{ $t('components.part-selection.info') }}</p>
  <div class="d-flex flex-wrap gap-3 mt-4">
    <button-card v-for="mesh in meshCountItemList" :key="mesh.meshTreeDataItem.id"
                 :title="$t('components.part-selection.' + productTypeString + '.' + mesh.meshTreeDataItem.name) ? mesh.cnt + 'x ' + $t('components.part-selection.' + productTypeString + '.' + mesh.meshTreeDataItem.name) : mesh.cnt + 'x ' + mesh.meshTreeDataItem.name" :selected="mesh.meshTreeDataItem.isSelected"
                 @click="$emit('part-selected', mesh.meshTreeDataItem)">
      <PreviewRendererImg :meshes="[{
                            thumbnail: mesh.meshTreeDataItem.thumbnail,
                            thumbnailUrl: mesh.meshTreeDataItem.thumbnailUrl,
                          }]"
                          imgSize="9"/>
    </button-card>
  </div>
</div>
</template>

<style scoped lang="scss">
#design-selection {
  background: transparent radial-gradient(closest-side at 50% 50%, rgba(75,75,75,0.6) 0%, rgba(0,0,0,0.6) 100%) 0% 0% no-repeat padding-box;
}
</style>
