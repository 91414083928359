import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { DragControls } from 'three/examples/jsm/controls/DragControls';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils';
import GeometryAdapterFactory, {
  GeometryAdapterInterface,
} from '@/plugin/ffd/adapter/GeometryAdapterFactory';
import { MeshSelector, SelectionList } from '@/types/ui/SelectionList';
import { SpecialObjectName } from '@/types/enum/three';
import { HistoryInfo, HistoryOperationType } from '@/types/ui/HistoryList';
import { DeformationToolType } from '@/components/Tools/DeformationTools.vue';

const adapterFactory = new GeometryAdapterFactory();

/* eslint-disable @typescript-eslint/no-explicit-any*/

interface SelectedVertices {
  index: number;
  position: THREE.Vector3;
}

export default class PaintModification {
  scene!: THREE.Scene;
  camera!: THREE.Camera;
  renderer!: THREE.Renderer;
  controls!: OrbitControls;
  selectionList!: SelectionList;
  size = 1;
  powerSize = 1;
  depth = 1;
  dragControls!: DragControls;
  geometryAdapter: GeometryAdapterInterface | null = null;
  history: HistoryInfo;
  meshSelector: MeshSelector;
  meshId: string;

  dragModifier!: THREE.Mesh;

  constructor(
    size: number,
    depth: number,
    history: HistoryInfo,
    meshSelector: MeshSelector
  ) {
    const geometry = new THREE.SphereGeometry(1, 32, 16);
    const material = new THREE.MeshBasicMaterial({
      color: 0xffff00,
      opacity: 0.2,
      transparent: true,
    });
    this.dragModifier = new THREE.Mesh(geometry, material);
    this.dragModifier.visible = false;
    this.dragModifier.name = SpecialObjectName.SoftModifier;
    this.changeSize(size);
    this.changeDepth(depth);
    this.history = history;
    this.meshSelector = meshSelector;
    this.meshId = '';
  }

  changeSize(size: number): void {
    this.size = size;
    this.powerSize = Math.pow(this.size, 2);
    this.dragModifier.scale.setScalar(size);
  }

  changeDepth(depth: number): void {
    this.depth = depth;
  }

  initView(
    scene: THREE.Scene,
    camera: THREE.Camera,
    renderer: THREE.Renderer,
    controls: OrbitControls | undefined,
    selectionList: SelectionList
  ): void {
    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;
    if (controls) this.controls = controls;
    this.selectionList = selectionList;
    this.dragControls = new DragControls(
      [this.dragModifier],
      this.camera,
      this.renderer.domElement
    );
    scene.add(this.dragModifier);

    this.renderer.domElement.addEventListener(
      'mousedown',
      (e) => this.onPointerDown(e),
      false
    );
    this.renderer.domElement.addEventListener(
      'mousemove',
      (e) => this.onPointerMove(e),
      false
    );
    this.renderer.domElement.addEventListener(
      'mouseup',
      () => this.onPointerUp(),
      false
    );
  }

  cleanupView(): void {
    this.renderer.domElement.removeEventListener(
      'mousedown',
      (e) => this.onPointerDown(e),
      false
    );
    this.renderer.domElement.removeEventListener(
      'mousemove',
      (e) => this.onPointerMove(e),
      false
    );
    this.renderer.domElement.removeEventListener(
      'mouseup',
      () => this.onPointerUp(),
      false
    );
  }

  isVisible(): boolean {
    return this.dragModifier !== null && this.dragModifier.visible;
  }

  paintingStarted = false;
  paintNormal: THREE.Vector3 = new THREE.Vector3(0, 0, 0);
  onPointerDown(event: MouseEvent): void {
    if (!this.isVisible()) return;
    this.moveSelectionRegion(event);

    if (this.selectionList) {
      const selection = this.selectionList.getSelectedObject();
      const clickIntersection = this.selectionList.getIntersection(
        event,
        false,
        selection
      );
      if (clickIntersection) {
        this.paintingStarted = true;
        this.controls.enabled = false;
        this.paintNormal = clickIntersection.direction
          .clone()
          .multiplyScalar(-this.depth);
        this.deformPaintModification(event);
      }
    }
  }

  moveSelectionRegion(event: MouseEvent): void {
    if (this.selectionList) {
      const selection = this.selectionList.getSelectedObject();
      const intersectionPoint = this.selectionList.checkIntersection(
        event,
        false,
        selection
      );
      if (intersectionPoint) {
        this.dragModifier.position.copy(intersectionPoint);
      }
    }
  }

  onPointerMove(event: MouseEvent): void {
    if (!this.isVisible()) return;
    this.moveSelectionRegion(event);
    if (this.paintingStarted) this.deformPaintModification(event);
  }

  onPointerUp(): void {
    this.paintingStarted = false;
    if (!this.isVisible()) return;
    this.controls.enabled = true;
    const historyMeshId = this.meshId;
    const modifiedIndex = Array.from(this.vertexAddDepth.keys()).filter(
      (index) => this.vertexAddDepth[index] > 0
    );
    const oldPositions = modifiedIndex.map((index) => {
      return {
        index: index,
        position: this.vertexDefaultPosition[index].clone(),
      };
    });
    const newPositions = modifiedIndex.map((index) => {
      const vertex = this.vertexDefaultPosition[index].clone();
      return {
        index: index,
        position: vertex.add(
          this.paintNormal.clone().multiplyScalar(this.vertexAddDepth[index])
        ),
      };
    });
    this.history.historyList.add(
      this.meshId,
      HistoryOperationType.paintModification,
      async () => {
        if (this.meshId !== historyMeshId) {
          this.meshSelector
            .selectMesh(historyMeshId, DeformationToolType.paintModification)
            .then(() => {
              this.undoRedoPaintModification(oldPositions);
            });
        } else {
          this.undoRedoPaintModification(oldPositions);
        }
      },
      async () => {
        if (this.meshId !== historyMeshId) {
          this.meshSelector
            .selectMesh(historyMeshId, DeformationToolType.paintModification)
            .then(() => {
              this.undoRedoPaintModification(newPositions);
            });
        } else {
          this.undoRedoPaintModification(newPositions);
        }
      }
    );
    this.initDefaultVertexPosition();
    if (this.history.lastUpdateTime < this.dragTime)
      this.history.lastUpdateTime = Date.now();
  }

  clearMesh(): void {
    this.dragModifier.visible = false;
    this.meshId = '';
  }

  vertexNeighbour: number[][] = [];
  vertexDefaultPosition: THREE.Vector3[] = [];
  vertexAddDepth: number[] = [];
  initMesh(model: THREE.Object3D, merge = true): void {
    this.meshId = (model as any).apiData?.uuid;
    if (merge) {
      const orig_geom = (model as THREE.Mesh).geometry;
      const mergedGeometry = BufferGeometryUtils.mergeVertices(orig_geom);
      mergedGeometry.computeVertexNormals();
      (model as THREE.Mesh).geometry = mergedGeometry;
    }
    this.geometryAdapter = adapterFactory.getAdapter(
      (model as THREE.Mesh).geometry,
      true
    );
    this.initDefaultVertexNeighbours();
    this.initDefaultVertexPosition();

    this.dragModifier.visible = true;
    model.add(this.dragModifier);
  }

  initDefaultVertexNeighbours(): void {
    if (this.geometryAdapter) {
      this.vertexNeighbour = [...Array(this.geometryAdapter.numVertices)].map(
        () => []
      );
      const addVertex = (vertex: number, neighbour: number): void => {
        if (!this.vertexNeighbour[vertex].includes(neighbour))
          this.vertexNeighbour[vertex].push(neighbour);
        if (!this.vertexNeighbour[neighbour].includes(vertex))
          this.vertexNeighbour[neighbour].push(vertex);
      };
      for (let i = 0; i < this.geometryAdapter.numFaces; i++) {
        const face = this.geometryAdapter.getFace(i);
        addVertex(face.a, face.b);
        addVertex(face.a, face.c);
        addVertex(face.b, face.c);
      }
      for (let i = 0; i < this.geometryAdapter.numVertices; i++) {
        for (const simIndex of this.geometryAdapter.similarVertex[i]) {
          addVertex(i, simIndex);
          for (const simNeighbour of this.vertexNeighbour[simIndex]) {
            addVertex(i, simNeighbour);
          }
        }
      }
    }
  }

  initDefaultVertexPosition(): void {
    if (this.geometryAdapter) {
      this.vertexAddDepth = [...Array(this.geometryAdapter.numVertices)].map(
        () => 0
      );
      this.vertexDefaultPosition = this.geometryAdapter.vertices.map((v) =>
        v.clone()
      );
    }
  }

  dragTime = -1;
  deformPaintModification(event: MouseEvent): void {
    if (this.selectionList && this.geometryAdapter && this.paintingStarted) {
      const selection = this.selectionList.getSelectedObject();
      const paintPoint = this.selectionList.getIntersection(
        event,
        false,
        selection
      );
      if (paintPoint) {
        this.dragTime = Date.now();
        const normal = this.paintNormal;
        const checkedVertex: number[] = [];
        const checkVertex = (vertexIndex: number): void => {
          checkedVertex.push(vertexIndex);
          if (this.geometryAdapter) {
            const vertex = this.vertexDefaultPosition[vertexIndex].clone();
            const weight =
              this.powerSize - paintPoint.point.distanceToSquared(vertex);
            if (weight > 0) {
              const vertexWeight = Math.sqrt(weight) / this.size;
              if (vertexWeight > this.vertexAddDepth[vertexIndex]) {
                const newPosition = vertex.add(
                  normal.clone().multiplyScalar(vertexWeight)
                );
                this.geometryAdapter.setVertex(
                  vertexIndex,
                  newPosition.x,
                  newPosition.y,
                  newPosition.z
                );
                this.vertexAddDepth[vertexIndex] = vertexWeight;
              }
              const neighbours = this.vertexNeighbour[vertexIndex];
              for (const neighbour of neighbours) {
                if (!checkedVertex.includes(neighbour)) checkVertex(neighbour);
              }
            }
          }
        };
        checkVertex(paintPoint.vertex);

        this.geometryAdapter.updateVertices();
      }
    }
  }

  undoRedoPaintModification(selectedVertices: SelectedVertices[]): void {
    if (this.geometryAdapter) {
      for (const vertex of selectedVertices) {
        const newPosition = vertex.position.clone();
        this.geometryAdapter.setVertex(
          vertex.index,
          newPosition.x,
          newPosition.y,
          newPosition.z
        );
      }
      this.geometryAdapter.updateVertices();
    }
  }
}
