<template>
  <div
    v-for="mesh in meshes"
    :style="{
      width: width ? width : imgSize + 'rem',
      height: height ? height : imgSize + 'rem',
    }"
    :class="{ selected: canSelect && (mesh.isSelected ?? isSelected) }"
    :key="mesh.thumbnail!"
    @click="selectItem(mesh)"
  >
    <div class="image">
      <img
        :key="mesh.thumbnail!"
        :src="
          mesh.thumbnailUrl == ''
            ? require(`@/assets/images/noImage.jpg`)
            : mesh.thumbnailUrl
        "
        alt=""
      />
      <div v-if="canTakeSnapshot" class="noImage">
        <div>
          <font-awesome-icon
            icon="camera-viewfinder"
            @click="takeSnapshot(mesh)"
          />
        </div>
      </div>
      <span v-if="canRemove" class="el-upload-list__item-actions">
        <span
          v-if="canRemove"
          class="el-upload-list__item-delete"
          @click="$emit('removeItem')"
        >
          <el-icon>
            <font-awesome-icon icon="trash" />
          </el-icon>
        </span>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ObjModel, StlModel } from '@/utils/three/importExport';
import { MeshImportData, MeshTreeDataItem } from '@/types/ui/MeshTreeData';
import { FileType3D } from '@/types/enum/upload';
import { v4 as uuidv4 } from 'uuid';
import { deleteWebGlContext } from '@/utils/three/webGlContext';

@Options({
  components: {
    ObjModel,
    StlModel,
  },
  emits: ['selectItem', 'removeItem', 'takeSnapshot'],
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class PreviewRendererImg extends Vue {
  @Prop() meshes!: MeshTreeDataItem[];
  @Prop({ default: false }) isSelected!: boolean;
  @Prop({ default: '10' }) imgSize!: string;
  @Prop({ default: '' }) width!: string;
  @Prop({ default: '' }) height!: string;
  @Prop({ default: 1.2 }) offset!: number;
  @Prop({ default: true }) canSelect!: boolean;
  @Prop({ default: false }) canRemove!: boolean;
  @Prop({ default: false }) canTakeSnapshot!: boolean;
  uuid = uuidv4();

  FileType = FileType3D;

  unmounted(): void {
    deleteWebGlContext(this.uuid);
  }

  selectItem(mesh: MeshTreeDataItem): void {
    if (this.canSelect) this.$emit('selectItem', mesh);
  }

  get isEmpty(): boolean {
    return (
      this.meshes.filter((item) => item.thumbnail && item.thumbnailUrl)
        .length === 0
    );
  }

  getMeshesOfImageType(): MeshImportData[] {
    return this.meshes.filter((item) => item.thumbnail && item.thumbnailUrl);
  }

  takeSnapshot(mesh: MeshTreeDataItem): void {
    if (mesh.isSelected) {
      this.$emit('takeSnapshot');
    } else {
      this.selectItem(mesh);
    }
  }

  /*isEmpty(): boolean {
    return this.url === '';
  }*/
}
</script>

<style lang="scss" scoped>
.upload-item {
  overflow: hidden;
  background-color: var(--color-sidebar);
  border: 1px solid #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  vertical-align: middle;
  //display: inline-block;
  cursor: pointer;
}

.products {
  .upload-item {
    border-radius: 6px 6px 0 0;
  }
}

.selected {
  border: var(--color-primary) 2px solid;
}

.image {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
}

.image img {
  display: block;
  height: 100%;
  object-fit: cover;
}

.noImage {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;

  > div {
    text-align: center;
    color: var(--color-primary);
    opacity: 0.5;
    font-size: 2rem;
    vertical-align: middle;
    align-items: center;
    margin: auto;
  }
}
</style>
