import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f5d8a2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data"]
const _hoisted_2 = {
  key: 0,
  class: "level toolbar"
}
const _hoisted_3 = {
  key: 0,
  class: "level-left"
}
const _hoisted_4 = { class: "level-item" }
const _hoisted_5 = {
  key: 1,
  class: "level-right"
}
const _hoisted_6 = { class: "level-item action" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 6,
  class: "level-item"
}
const _hoisted_9 = {
  key: 7,
  class: "level-item"
}
const _hoisted_10 = {
  key: 1,
  class: "hierarchy"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_RenderTools = _resolveComponent("RenderTools")!
  const _component_ProjectionTools = _resolveComponent("ProjectionTools")!
  const _component_CurveTools = _resolveComponent("CurveTools")!
  const _component_DeformationTools = _resolveComponent("DeformationTools")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_BoneTools = _resolveComponent("BoneTools")!
  const _component_TransformTools = _resolveComponent("TransformTools")!
  const _component_OrientationGizmo = _resolveComponent("OrientationGizmo")!
  const _component_Camera = _resolveComponent("Camera")!
  const _component_AmbientLight = _resolveComponent("AmbientLight")!
  const _component_PointLight = _resolveComponent("PointLight")!
  const _component_Scene = _resolveComponent("Scene")!
  const _component_RenderPass = _resolveComponent("RenderPass")!
  const _component_EffectComposer = _resolveComponent("EffectComposer")!
  const _component_Renderer = _resolveComponent("Renderer")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_ToggleSidebar = _resolveComponent("ToggleSidebar")!
  const _component_EmbossingSidebar = _resolveComponent("EmbossingSidebar")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_PreviewRendererImg = _resolveComponent("PreviewRendererImg")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", {
    class: "fillHorizontal displayContents",
    data: _ctx.modelValue
  }, [
    (_ctx.hasToolbar)
      ? (_openBlock(), _createElementBlock("nav", _hoisted_2, [
          (_ctx.isMounted)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.view,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.view) = $event)),
                    placeholder: "Select view",
                    onChange: _ctx.changeView
                  }, {
                    prefix: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['fac', _ctx.view]
                      }, null, 8, ["icon"])
                    ]),
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_ctx.Views), (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item,
                          value: item,
                          label: _ctx.$t(`enum.views.${item}`),
                          disabled: item === _ctx.Views.custom
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_font_awesome_icon, {
                              icon: ['fac', item]
                            }, null, 8, ["icon"]),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t(`enum.views.${item}`)), 1)
                          ]),
                          _: 2
                        }, 1032, ["value", "label", "disabled"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onChange"])
                ]),
                _createElementVNode("div", {
                  class: "level-item action",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.zoom(0.1)))
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: "search-plus" })
                ]),
                _createElementVNode("div", {
                  class: "level-item action",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.zoom(-0.1)))
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: "search-minus" })
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["level-item action", { active: _ctx.displayGrid }]),
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleGrid && _ctx.toggleGrid(...args)))
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: "border-none" })
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["level-item action", { active: _ctx.displayBoundingBox }]),
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleBoundingBox && _ctx.toggleBoundingBox(...args)))
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: ['fac', 'custom'] })
                ], 2),
                (_ctx.canToggleBones && _ctx.boneTools)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["level-item action", { active: _ctx.boneTools.displayBones }]),
                      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.toggleBones && _ctx.toggleBones(...args)))
                    }, [
                      _createVNode(_component_font_awesome_icon, { icon: "bone" })
                    ], 2))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.isMounted)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_ctx.canUndo)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["level-item action", { disabled: !_ctx.modelValue.historyList.hasUndo }]),
                      onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.undo && _ctx.undo(...args)))
                    }, [
                      _createVNode(_component_font_awesome_icon, { icon: "rotate-left" })
                    ], 2))
                  : _createCommentVNode("", true),
                (_ctx.canUndo)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: _normalizeClass(["level-item action", { disabled: !_ctx.modelValue.historyList.hasRedo }]),
                      onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.redo && _ctx.redo(...args)))
                    }, [
                      _createVNode(_component_font_awesome_icon, { icon: "rotate-right" })
                    ], 2))
                  : _createCommentVNode("", true),
                _createVNode(_component_RenderTools, {
                  ref: "renderTools",
                  modelValue: _ctx.modelValue,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.modelValue) = $event)),
                  troisRenderer: _ctx.troisRenderer,
                  editorMode: _ctx.editorMode,
                  selectionList: _ctx.selectionList,
                  viewCtrl: _ctx.viewCtrl,
                  selectObject: _ctx.selectObject,
                  canRender: _ctx.canRender,
                  historyList: _ctx.modelValue.historyList
                }, null, 8, ["modelValue", "troisRenderer", "editorMode", "selectionList", "viewCtrl", "selectObject", "canRender", "historyList"]),
                (_ctx.editorMode.isDefaultMode)
                  ? (_openBlock(), _createBlock(_component_ProjectionTools, {
                      key: 2,
                      ref: "projectionTools",
                      modelValue: _ctx.modelValue,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.modelValue) = $event)),
                      troisRenderer: _ctx.troisRenderer,
                      editorMode: _ctx.editorMode,
                      selectionList: _ctx.selectionList,
                      viewCtrl: _ctx.viewCtrl,
                      selectObject: _ctx.selectObject,
                      canProject: _ctx.canEmbossing,
                      embossing: _ctx.selectedEmbossing,
                      historyList: _ctx.modelValue.historyList
                    }, null, 8, ["modelValue", "troisRenderer", "editorMode", "selectionList", "viewCtrl", "selectObject", "canProject", "embossing", "historyList"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_CurveTools, {
                  ref: "curveTools",
                  modelValue: _ctx.modelValue,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.modelValue) = $event)),
                  troisRenderer: _ctx.troisRenderer,
                  editorMode: _ctx.editorMode,
                  selectionList: _ctx.selectionList,
                  viewCtrl: _ctx.viewCtrl,
                  canCurve: _ctx.canCurve,
                  selectObject: _ctx.selectObject,
                  allowedCurveCategories: _ctx.allowedCurveCategories,
                  historyList: _ctx.modelValue.historyList
                }, null, 8, ["modelValue", "troisRenderer", "editorMode", "selectionList", "viewCtrl", "canCurve", "selectObject", "allowedCurveCategories", "historyList"]),
                _createVNode(_component_DeformationTools, {
                  ref: "deformationTools",
                  modelValue: _ctx.modelValue,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.modelValue) = $event)),
                  canDeform: _ctx.canDeform,
                  troisRenderer: _ctx.troisRenderer,
                  selectionList: _ctx.selectionList,
                  historyList: _ctx.modelValue.historyList,
                  selectMeshById: _ctx.selectMesh
                }, null, 8, ["modelValue", "canDeform", "troisRenderer", "selectionList", "historyList", "selectMeshById"]),
                (_ctx.editorMode.isDefaultMode && _ctx.canColorize)
                  ? (_openBlock(), _createBlock(_component_el_popover, {
                      key: 3,
                      placement: "bottom-start",
                      title: _ctx.$t('components.mesh-editor.colors'),
                      width: 200,
                      trigger: "click"
                    }, {
                      reference: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_font_awesome_icon, { icon: "palette" })
                        ])
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_el_space, { wrap: "" }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors, (color) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: color,
                                class: "action",
                                onClick: ($event: any) => (_ctx.colorizeSelected(color))
                              }, [
                                _createVNode(_component_font_awesome_icon, {
                                  icon: "square",
                                  style: _normalizeStyle({ color: color })
                                }, null, 8, ["style"])
                              ], 8, _hoisted_7))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["title"]))
                  : _createCommentVNode("", true),
                (_ctx.editorMode.isDefaultMode && _ctx.canGroup)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 4,
                      class: "level-item action",
                      onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.groupObject && _ctx.groupObject(...args)))
                    }, [
                      _createVNode(_component_font_awesome_icon, { icon: "object-group" })
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.editorMode.isDefaultMode && _ctx.canAline)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 5,
                      class: "level-item action",
                      onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.alineSelectedToFloor && _ctx.alineSelectedToFloor(...args)))
                    }, [
                      _createVNode(_component_font_awesome_icon, { icon: "question" })
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.editorMode.isDefaultMode && _ctx.canCopy)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_el_dropdown, { class: "level-item" }, {
                        dropdown: _withCtx(() => [
                          _createVNode(_component_el_dropdown_menu, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_dropdown_item, {
                                class: "action",
                                onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.copySelected(_ctx.AxisType.x)))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" x ")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_dropdown_item, {
                                class: "action",
                                onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.copySelected(_ctx.AxisType.y)))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" y ")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_dropdown_item, {
                                class: "action",
                                onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.copySelected(_ctx.AxisType.z)))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" z ")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createVNode(_component_font_awesome_icon, { icon: "clone" })
                          ])
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.editorMode.isDefaultMode && _ctx.canMirror)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_el_dropdown, { class: "level-item" }, {
                        dropdown: _withCtx(() => [
                          _createVNode(_component_el_dropdown_menu, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_dropdown_item, {
                                class: "action",
                                onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.mirrorSelected(_ctx.AxisType.x)))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" x ")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_dropdown_item, {
                                class: "action",
                                onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.mirrorSelected(_ctx.AxisType.y)))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" y ")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_dropdown_item, {
                                class: "action",
                                onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.mirrorSelected(_ctx.AxisType.z)))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" z ")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createVNode(_component_font_awesome_icon, { icon: ['fac', 'mirror'] })
                          ])
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_component_BoneTools, {
                  ref: "boneTools",
                  modelValue: _ctx.modelValue,
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.modelValue) = $event)),
                  troisRenderer: _ctx.troisRenderer,
                  editorMode: _ctx.editorMode,
                  selectionList: _ctx.selectionList,
                  viewCtrl: _ctx.viewCtrl,
                  transformTools: _ctx.transformTools,
                  activateBones: _ctx.activateBones,
                  historyList: _ctx.modelValue.historyList
                }, null, 8, ["modelValue", "troisRenderer", "editorMode", "selectionList", "viewCtrl", "transformTools", "activateBones", "historyList"]),
                _createVNode(_component_TransformTools, {
                  ref: "transformTools",
                  modelValue: _ctx.modelValue,
                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.modelValue) = $event)),
                  troisRenderer: _ctx.troisRenderer,
                  editorMode: _ctx.editorMode,
                  selectionList: _ctx.selectionList,
                  viewCtrl: _ctx.viewCtrl,
                  canTransform: _ctx.canTransform,
                  canManualTransform: _ctx.canManualTransform,
                  canTogglePivotMode: _ctx.canTogglePivotMode,
                  resetOnPivotChanged: _ctx.resetOnPivotChanged,
                  updateAppearance: _ctx.updateAppearance,
                  historyList: _ctx.modelValue.historyList,
                  selectObject: _ctx.selectObject,
                  defaultOrientationAxis: _ctx.defaultOrientationAxis,
                  activeModifyType: _ctx.activeModifyType
                }, null, 8, ["modelValue", "troisRenderer", "editorMode", "selectionList", "viewCtrl", "canTransform", "canManualTransform", "canTogglePivotMode", "resetOnPivotChanged", "updateAppearance", "historyList", "selectObject", "defaultOrientationAxis", "activeModifyType"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_el_container, {
      class: _normalizeClass(["webGlView", { fullscreen: _ctx.fullscreen }])
    }, {
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_el_main, {
          "element-loading-background": "transparent",
          class: "fillHorizontal",
          id: "main"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(_component_OrientationGizmo, { ref: "orientationGizmo" }, null, 512), [
              [_vShow, _ctx.showOrientationGizmo]
            ]),
            (_ctx.isActive)
              ? (_openBlock(), _createBlock(_component_Renderer, {
                  key: 0,
                  ref: "troisRenderer",
                  antialias: "",
                  "orbit-ctrl": {
            enableDamping: false,
            dampingFactor: 0.05,
            enablePan: true,
          },
                  resize: true,
                  alpha: true,
                  xr: true,
                  preserveDrawingBuffer: true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Camera, { position: _ctx.renderCameraPos }, null, 8, ["position"]),
                    _createVNode(_component_Scene, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_AmbientLight, { color: "#808080" }),
                        _createVNode(_component_PointLight, {
                          position: _ctx.pointLightPos,
                          intensity: 0.55
                        }, null, 8, ["position"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_EffectComposer, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_RenderPass)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 512))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })), [
          [_directive_loading, _ctx.loadingCircle]
        ]),
        (_ctx.canEmbossing)
          ? (_openBlock(), _createBlock(_component_ToggleSidebar, {
              key: 0,
              modelValue: _ctx.showSidebar,
              "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.showSidebar) = $event)),
              useLeftSidebar: false
            }, null, 8, ["modelValue"]))
          : _createCommentVNode("", true),
        (_ctx.canEmbossing && _ctx.showSidebar)
          ? (_openBlock(), _createBlock(_component_EmbossingSidebar, {
              key: 1,
              modelValue: _ctx.selectedEmbossing,
              "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.selectedEmbossing) = $event)),
              selectObject: _ctx.selectObject,
              selectionList: _ctx.selectionList,
              historyList: _ctx.modelValue.historyList
            }, null, 8, ["modelValue", "selectObject", "selectionList", "historyList"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["class"]),
    (_ctx.canPartSelect)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_el_scrollbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_space, null, {
                default: _withCtx(() => [
                  _createVNode(_component_PreviewRendererImg, {
                    meshes: _ctx.modelValue.treeData,
                    imgSize: "5",
                    offset: 1.5,
                    canRemove: true,
                    canSelect: true,
                    isSelected: true,
                    canTakeSnapshot: _ctx.canTakeSnapshot,
                    onSelectItem: _ctx.selectPart,
                    onTakeSnapshot: _ctx.takeSnapshot
                  }, null, 8, ["meshes", "canTakeSnapshot", "onSelectItem", "onTakeSnapshot"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}