import { 
  apiExecutePost,
  mutateWithVariables,
  query,
  queryWithVariables
} from '@/services/api/api';
import { ApiResponse } from '@/types/api/Utility/ApiResponse';

/* eslint-disable @typescript-eslint/no-explicit-any*/

export interface OrderRequestData {
  product: string;
  colorList: (string | null)[];
  embossingList: (string | null)[];
  embossingColorList: (string | null)[];
  wtransferList: (string | null)[];
  varnish: string;
  technicianFirstname: string;
  technicianLastname: string;
  technician: string;
  company: string;
  customerNr: string;
  orderNr: string;
  billingNr: string;
  commissionNo: string;
  vrNumber: string;
  vgNumber: string;
  vsgNumber: string;
  mail: string;
  orderMail: string;
  coverModel: string;
  prothesisType: string;
  bodySide: string;
  kneeJoint: string;
  foot: string;
  zip: string;
  sewInByCustomer: boolean;
  cosmeticEndingAtFoot: boolean;
  heightWithoutDeduction: number;
  heightDeduction: number;
  heightWithDeduction: number;
  depth: number;
  calfCircumferenceWithoutDeduction: number;
  calfCircumferenceDeduction: number;
  calfCircumferenceWithDeduction: number;
  ankleCircumference: number;
  imageFiles: any[];
  scanFiles: any[];
  designFiles: string[];
  remarks: string;
  price: number;
  balconyType: string;
  mountingType: string;
  paneelType: string;
  orientationType: string;
  isPrivacyAccepted: boolean;
  privacy: string;
}

export const createPdfPartList = async (modelId: number, domainId: number): Promise<Uint8Array> => {
  const result = await mutateWithVariables(
    `
        mutation ($modelId: Long!, $domainId: Long!) {
          createPdfPartList(
            modelId: $modelId
            domainId: $domainId
          )
        }
      `,
    {
      modelId: modelId,
      domainId: domainId
    }
  );
  return result.data.createPdfPartList;
};

export const createPdfOffer = async (orderData: OrderRequestData, modelId: number, domainId: number): Promise<Uint8Array> => {
  const result = await mutateWithVariables(
    `
        mutation ($orderData: OrderDataInput!, $modelId: Long!, $domainId: Long!) {
          createPdfOffer(
            orderData: $orderData
            modelId: $modelId
            domainId: $domainId
          )
        }
      `,
    {
      orderData: orderData,
      modelId: modelId,
      domainId: domainId
    }
  );
  return result.data.createPdfOffer;
};

export const sendOrderAsync = async (orderData: OrderRequestData, modelId: number, domainId: number, userId: number | null): Promise<ApiResponse> => {
  const result = await mutateWithVariables(
    `
        mutation ($orderData: OrderDataInput!, $modelId: Long!, $domainId: Long!, $userId: Long) {
          sendOrder(
            orderData: $orderData
            modelId: $modelId
            domainId: $domainId
            userId: $userId
          )
        }
      `,
    {
      orderData: orderData,
      modelId: modelId,
      domainId: domainId,
      userId: userId
    }
  );
  return result.data;
};

export const getSasUrl = async (): Promise<string> => {
  const result = await query(
    `
      query {
        sasUrl
      }
    `
  );
  return result.data;
} 
