<script setup lang="ts">
</script>

<template>
  <div id="info" class="pl-5 pr-5 pt-5 pb-5 rounded-3 text-black">
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
#info {
  background: radial-gradient(closest-side at 50% 50%, rgba(255, 255, 255, 0.85) 0%, rgba(255, 255, 255, 0.85) 100%) 0% 0% no-repeat padding-box;
}
</style>
