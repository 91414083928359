import {FileTexture} from "@/types/api/Utility/FileTexture";

export class File {
  public constructor() {
    //constructor
  }
 
  protected _fileId: number | null =null;
  protected _name!: string;
  protected _type!: string;
  protected _content!: string;
  protected _textures: FileTexture[]|null=null;
  protected _thumbnailTextures: FileTexture[]|null=null;
 
  /**
   * @returns _fileId
   */
  public get fileId() {
    return this._fileId;
  }
 
  /**
   * Sets _fileId
   */
  public set fileId(value: number | null) {
    this._fileId = value;
  }
 
  /**
   * @returns _name
   */
  public get name() {
    return this._name;
  }
 
  /**
   * Sets _name
   */
  public set name(value: string) {
    this._name = value;
  }
 
  /**
   * @returns _type
   */
  public get type() {
    return this._type;
  }
 
  /**
   * Sets _type
   */
  public set type(value: string) {
    this._type = value;
  }
 
  /**
   * base64 encoded file content
   * @returns _content
   */
  public get content() {
    return this._content;
  }
 
  /**
   * base64 encoded file content
   * Sets _content
   */
  public set content(value: string) {
    this._content = value;
  }
 
  /**
   * @returns _textures
   */
  public get textures() {
    return this._textures;
  }
 
  /**
   * Sets _textures
   */
  public set textures(value: FileTexture[]|null) {
    this._textures = value;
  }
 
  /**
   * Specialized add method for bidirectional ManyToOne property textures _textures
   */
  public addTextures(value: FileTexture): void {
    if (value == null) {
      return;
    }
    if (this._textures?.includes(value)) {
      return;
    }
    this._textures?.push(value);
  }
 
  /**
   * Specialized add method for bidirectional ManyToOne property textures _textures
   */
  public addAllTextures(values: FileTexture[]): void {
    values.forEach((item) => {
      this.addTextures(item);
    });
  }
 
  /**
   * Specialized remove method for bidirectional ManyToOne property textures _textures
   */
  public removeTextures(value: FileTexture): void {
    if (value == null) {
      return;
    }
    if (!this._textures?.includes(value)) {
      return;
    }
    this._textures = this._textures.filter(x => x != value);
    value.file = new File();
  }
 
  /**
   * Specialized remove method for bidirectional ManyToOne property textures _textures
   */
  public removeAllTextures(values: FileTexture[]): void {
    values.forEach((item) => {
      this.removeTextures(item);
    });
  }
 
   /**
   * @returns _thumbnailTextures
   */
   public get thumbnailTextures():FileTexture[]|null {
    return this._thumbnailTextures;
  }
 
  /**
   * Sets _thumbnailTextures
   */
  public set thumbnailTextures(value: FileTexture[]|null) {
    this._thumbnailTextures = value;
  }
  /**
   * Specialized add method for bidirectional ManyToOne property thumbnail_textures _thumbnailTextures
   */
  public addThumbnailTextures(value: FileTexture): void {
    if (value == null) {
      return;
    }
    if (this._thumbnailTextures?.includes(value)) {
      return;
    }
    this._thumbnailTextures?.push(value);
  }
 
  /**
   * Specialized add method for bidirectional ManyToOne property thumbnail_textures _thumbnailTextures
   */
  public addAllThumbnailTextures(values: FileTexture[]): void {
    values.forEach((item) => {
      this.addThumbnailTextures(item);
    });
  }
 
  /**
   * Specialized remove method for bidirectional ManyToOne property thumbnail_textures _thumbnailTextures
   */
  public removeThumbnailTextures(value: FileTexture): void {
    if (value == null) {
      return;
    }
    if (!this._thumbnailTextures?.includes(value)) {
      return;
    }
    this._thumbnailTextures = this._thumbnailTextures.filter(x => x != value);
    value.thumbnail = new File();
  }
 
 
  // Start Userdefined content - don't change anything above
 
  public deepCopy(toCopy: File): File {
    if (!toCopy) return toCopy;
 
    const file = {
      fileId: toCopy.fileId,
      name: toCopy.name,
      type: toCopy.type,
      content: toCopy.content,
      textures: [] as FileTexture[]
    } as File;
 
    return file;
  }
 
  // End Userdefined content - don't change anything below
}