import * as THREE from 'three';
import { MeshTreeDataItem } from '@/types/ui/MeshTreeData';
import { getMeshImport, parseMeshImport } from '@/utils/three/importExport';
import { transform } from '@/utils/three/transform';
import { MeshShape } from '@/types/enum/editor';
import { SpecialObjectName } from '@/types/enum/three';
import * as THREEEnum from '@/types/enum/three';
import * as THREEService from '@/utils/three/initDefault';

/* eslint-disable @typescript-eslint/no-explicit-any*/
export const initCurveMaterial = (color = '#ffffff'): THREE.Material => {
  return new THREE.LineBasicMaterial({ color: color });
};

export const initMeshMaterial = (color = '#ffffff'): THREE.Material => {
  return new THREE.MeshLambertMaterial({ color: color });
};

export const initObject3D = async (
  item: MeshTreeDataItem,
  parent: MeshTreeDataItem | null = null,
  loadChildren = true
): Promise<THREE.Object3D | undefined> => {
  const init = (object: THREE.Object3D): void => {
    object.uuid = item.uuid;
    object.name = item.name;
    if (!item.mesh) item.mesh = object;
    (object as any).apiData = item;
    if (parent && parent.mesh) {
      parent.mesh.add(object);
    }
  };

  if (item.isMesh) {
    const object = item.mesh
      ? item.mesh
      : new THREE.Mesh(item.geometry, item.material);
    init(object);
    return transform(
      object,
      item.defaultPosition,
      item.defaultRotation,
      item.defaultScale
    );
  } else if (item.isLine) {
    const object = item.mesh
      ? item.mesh
      : new THREE.Line(item.geometry, item.material);
    init(object);
    return transform(
      object,
      item.defaultPosition,
      item.defaultRotation,
      item.defaultScale
    );
  } else if (item.isGroup) {
    const object = item.mesh ? item.mesh : new THREE.Group();
    init(object);
    if (loadChildren && item.mesh.children.length === 0) {
      for (const child of item.children) {
        await initObject3D(child).then((childObject) => {
          if (childObject) object.add(childObject);
        });
      }
    }
    return transform(
      object,
      item.defaultPosition,
      item.defaultRotation,
      item.defaultScale
    );
  } else if (item.isImport && item.filename && item.url) {
    let result = item.mesh;
    await getMeshImport(item.filename, item.url, item.color).then((object) => {
      if (object) {
        const mesh = object as THREE.Mesh;
        item.geometry = mesh.geometry;
        item.material = mesh.material;
        init(mesh);
        result = mesh;
      }
    });
    return transform(
      result,
      item.defaultPosition,
      item.defaultRotation,
      item.defaultScale
    );
  } else if (item.isImport && item.filetype && item.base64) {
    let result = item.mesh;
    await parseMeshImport(item.filetype, item.base64, item.color).then(
      (object) => {
        if (object) {
          const mesh = object as THREE.Mesh;
          item.geometry = mesh.geometry;
          item.material = mesh.material;
          init(mesh);
          result = mesh;
        }
      }
    );
    return transform(
      result,
      item.defaultPosition,
      item.defaultRotation,
      item.defaultScale
    );
  }
};

export const initSpecialObject = (
  type: SpecialObjectName,
  size = 0.1
): THREE.Object3D => {
  switch (type) {
    case SpecialObjectName.Pivot:
      return initSpecialObjectPoint(
        SpecialObjectName.Pivot,
        MeshShape.box,
        true,
        size
      );
    case SpecialObjectName.StartPoint:
      return initSpecialObjectPoint(
        SpecialObjectName.StartPoint,
        MeshShape.sphere,
        false,
        size
      );
    case SpecialObjectName.EndPoint:
      return initSpecialObjectPoint(
        SpecialObjectName.EndPoint,
        MeshShape.sphere,
        false,
        size
      );
    case SpecialObjectName.ArrowHelper:
      return initSpecialObjectArrowHelper();
    case SpecialObjectName.CenterPoint:
      return initSpecialObjectPoint(
        SpecialObjectName.CenterPoint,
        MeshShape.box,
        true,
        size
      );
    case SpecialObjectName.Bone:
      return initSpecialObjectBone();
    case SpecialObjectName.Skeleton:
      return initSpecialObjectSkeleton();
  }
  return new THREE.Group();
};

export const initSpecialObjectPoint = (
  name: string,
  shape: MeshShape,
  addAxesHelper = false,
  size = 0.1
): THREE.Group => {
  let geometry: THREE.BufferGeometry | null = null;
  switch (shape) {
    case MeshShape.box:
      geometry = new THREE.BoxGeometry(size, size, size);
      break;
    case MeshShape.sphere:
      geometry = new THREE.SphereGeometry(size / 2);
      break;
  }
  const pointer = new THREE.Group();
  pointer.name = name;
  pointer.visible = false;
  if (geometry) {
    const material = new THREE.MeshBasicMaterial({ color: 0x000000 });
    pointer.add(new THREE.Mesh(geometry, material));
  }
  if (addAxesHelper) pointer.add(new THREE.AxesHelper(10));
  return pointer;
};

export const initSpecialObjectArrowHelper = (): THREE.ArrowHelper => {
  const arrowHelper = new THREE.ArrowHelper();
  arrowHelper.visible = false;
  arrowHelper.name = SpecialObjectName.ArrowHelper;
  const arrowMaterial = arrowHelper.line.material as THREE.LineBasicMaterial;
  arrowMaterial.linewidth = 3;
  arrowHelper.setColor(0xff0000);
  return arrowHelper;
};

const initSpecialObjectSkeleton = (): THREE.Group => {
  const boneContainer = new THREE.Group();
  boneContainer.name = THREEEnum.SpecialObjectName.Skeleton;
  return boneContainer;
};

const initSpecialObjectBone = (): THREE.Object3D => {
  return THREEService.initSphere(THREEEnum.SpecialObjectName.Bone, 'gray');
};
