import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';
import Home from '@/views/default/Home.vue';
import TemplateWorkflow from '@/views/template/TemplateWorkflow.vue';
import TemplateUpload from '@/views/template/TemplateUpload.vue';
import TemplateOverview from '@/views/template/TemplateOverview.vue';
import NotFound from '@/views/default/NotFound.vue';
import CustomizeWorkflow from '@/views/customize/CustomizeWorkflow.vue';
import CustomizeOverview from '@/views/customize/CustomizeOverview.vue';
import app from '@/main';
import OrderWorkflow from '@/views/order/OrderWorkflow.vue';
import OrderUpload from '@/views/order/OrderUpload.vue';
import OrderOverview from '@/views/order/OrderOverview.vue';
import WorkInProgressOverview from '@/views/order/WorkInProgressOverview.vue';
import ThreeDView from '@/views/3d/ThreeDView.vue';

/* eslint-disable @typescript-eslint/no-explicit-any*/

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'default',
    component: Home,
  },
  {
    path: '/:supplier',
    name: "supplier",
    component: CustomizeOverview,
  },
  {
    path: '/template-workflow',
    name: 'template-workflow',
    component: TemplateWorkflow,
    props: (route) => ({
      templateId: route.query.id ? parseInt(route.query.id.toString()) : null,
    }),
  },
  {
    path: '/template-upload',
    name: 'template-upload',
    component: TemplateUpload,
    props: (route) => ({
      templateId: route.query.id ? parseInt(route.query.id.toString()) : null,
    }),
  },
  {
    path: '/template-overview',
    name: 'template-overview',
    component: TemplateOverview,
  },
  {
    path: '/customize-overview',
    name: 'customize-overview',
    component: CustomizeOverview,
  },
  {
    path: '/customize-workflow',
    name: 'customize-workflow',
    component: CustomizeWorkflow,
    props: (route) => ({
      productTypeId: route.query.productTypeId ? parseInt(route.query.productTypeId.toString()) : null,
      supplierUrl: route.query.supplier ?? null,
      productId: route.query.productId,
      embossingId: route.query.embossingId,
      wtransferId: route.query.wtransferId,
      colorId: route.query.colorId,
      embossingColorId: route.query.embossingColorId,
    }),
  },
  {
    path: '/order-workflow',
    name: 'order-workflow',
    component: OrderWorkflow,
    props: (route) => ({
      templateId: route.query.templateId
        ? parseInt(route.query.templateId.toString())
        : null,
      orderId: route.query.orderId
        ? parseInt(route.query.orderId.toString())
        : null,
    }),
  },
  {
    path: '/order-upload',
    name: 'order-upload',
    component: OrderUpload,
    props: (route) => ({
      templateId: route.query.templateId
        ? parseInt(route.query.templateId.toString())
        : null,
      orderId: route.query.orderId
        ? parseInt(route.query.orderId.toString())
        : null,
    }),
  },
  {
    path: '/3d-view',
    name: '3d-view',
    component: ThreeDView,
    props: (route) => ({
      productId: route.query.productId,
      color: route.query.color,
      embossingId: route.query.embossingId,
      embossingColor: route.query.embossingColor,
      wtransferId: route.query.wtransferId
    }),
  },
  {
    path: '/order-overview',
    name: 'order-overview',
    component: OrderOverview,
  },
  {
    path: '/work-in-progress-overview',
    name: 'work-in-progress-overview',
    component: WorkInProgressOverview,
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
});

(router as any).askForChanges = false;

router.beforeEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): void => {
    if (
      to.name === 'template-workflow' &&
      !to.query.id &&
      from.name === 'template-upload' &&
      from.query.id
    ) {
      to.query = from.query;
      to.fullPath = `${to.path}?id=${from.query.id}`;
      (to as any).href = `${to.path}?id=${from.query.id}`;
      next(); //`${to.path}?id=${from.query.id}`);
    } else if ((router as any).askForChanges) {
      app.config.globalProperties
        .$confirm(
          app.config.globalProperties.$t('confirm.changes.message'),
          app.config.globalProperties.$t('confirm.changes.title'),
          {
            confirmButtonText:
              app.config.globalProperties.$t('confirm.changes.ok'),
            cancelButtonText: app.config.globalProperties.$t(
              'confirm.changes.cancel'
            ),
            type: 'warning',
            roundButton: true,
          }
        )
        .then(() => {
          (router as any).askForChanges = false;
          next();
        })
        .catch(() => {
          next(false);
        });
    } else {
      next();
    }
  }
);

export default router;
