import { User } from '@/types/api/User/User';
import { queryWithVariables } from './api';
import { Reseller } from '@/types/api/User/Reseller';
import { Supplier } from '@/types/api/User/Supplier';
import { Domain } from '@/types/api/Business/Domain';

export const getUserByEmail = async (email: string): Promise<User> => {
  const getUserResult = await queryWithVariables(
    `
        query ($email: String!) {
          userByEmail (email: $email) {
            userId,
            authentikId,
            username,
            titlePrefix,
            titlePostfix,
            lastname,
            firstname,
            email,
            addresses {
              addressId,
              street,
              houseNr,
              zipCode,
              city {
                cityId,
                name,
                country {
                  countryId,
                  name
                }
              }
            }
          }
        }
      `,
    {
      email: email,
    }
  );

  return getUserResult.data.userByEmail;
};

export const getUserById = async (userId: number): Promise<User> => {
  const getUserResult = await queryWithVariables(
    `
        query ($userId: Long!) {
          userById (userId: $userId) {
            userId,
            authentikId,
            username,
            titlePrefix,
            titlePostfix,
            lastname,
            firstname,
            email,
            addresses {
              addressId,
              street,
              houseNr,
              zipCode,
              city {
                cityId,
                name,
                country {
                  countryId,
                  name
                }
              }
            }
          }
        }
      `,
    {
      userId: userId,
    }
  );

  return getUserResult.data.userById;
};

export const getResellerByEmail = async (
  email: string
): Promise<Reseller> => {
  const getUserResult = await queryWithVariables(
    `
        query ($email: String!) {
          resellerByEmail (email: $email) {
            userId,
            authentikId,
            username,
            titlePrefix,
            titlePostfix,
            lastname,
            firstname,
            email,
            supplier {
              userId,
              email,
              domains {
                domainId,
                logo,
                price,
                urlSuffix
              }
            }
            addresses {
              addressId,
              street,
              houseNr,
              zipCode,
              city {
                cityId,
                name,
                country {
                  countryId,
                  name
                }
              }
            }
          }
        }
      `,
    {
      email: email,
    }
  );

  return getUserResult.data.resellerByEmail;
};

export const getSupplierByEmail = async (
  email: string
): Promise<Supplier> => {
  const getUserResult = await queryWithVariables(
    `
        query ($email: String!) {
          supplierByEmail (email: $email) {
            userId,
            authentikId,
            username,
            titlePrefix,
            titlePostfix,
            lastname,
            firstname,
            email,
            domains {
              domainId,
              logo,
              price,
              urlSuffix
            }
            addresses {
              addressId,
              street,
              houseNr,
              zipCode,
              city {
                cityId,
                name,
                country {
                  countryId,
                  name
                }
              }
            }
          }
        }
      `,
    {
      email: email,
    }
  );

  return getUserResult.data.supplierByEmail;
};

export const getDomainByUrlSuffix = async (urlSuffix: string): Promise<Domain> => {
  const getDomainByUrlResult = await queryWithVariables(
    `
      query ($urlSuffix: String!) {
        domainByUrlSuffix (urlSuffix: $urlSuffix) {
          domainId,
          price,
          logo,
          urlSuffix,
          colorSchema {
            key,
            value {
              name,
              red,
              green,
              blue
            }
          },
          packages {
            packageId,
            price,
            description
          }
        }
      }
    `,
    {
      urlSuffix: urlSuffix,
    }
  );

  return getDomainByUrlResult.data.domainByUrlSuffix;
}
