import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ee8b127"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col text-start pe-auto" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex align-items-start justify-content-start logo"
}
const _hoisted_3 = { class: "btn" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "col text-center justify-content-center user-select-none"
}
const _hoisted_6 = { class: "d-flex flex-column" }
const _hoisted_7 = { class: "bg-grey-transparent rounded text-white pt-1 pb-1 fw-normal" }
const _hoisted_8 = { class: "mt-2 text-color" }
const _hoisted_9 = { class: "text-center text-size fw-semibold mb-0" }
const _hoisted_10 = { class: "col d-flex justify-content-end pe-auto" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sidebar_tools = _resolveComponent("sidebar-tools")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["row absolute pe-none", _ctx.isEditor ? 'editor-view' : ''])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.authentication.primaryDomain)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("button", _hoisted_3, [
              _createElementVNode("img", {
                id: "logo",
                src: _ctx.authentication.primaryDomain.logo,
                alt: "Home",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.supplier ? _ctx.$router.push('/' + _ctx.supplier) : _ctx.$router.push('/')))
              }, null, 8, _hoisted_4)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isEditor)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("components.custom-header.stepText")) + " " + _toDisplayString(_ctx.workflowModel.active) + "/" + _toDisplayString(_ctx.workflowModel.stepCount) + ": " + _toDisplayString(_ctx.$t('enum.' + _ctx.workflowModel.localeIdentifier + '.' + _ctx.workflowModel.activeProgressName())), 1),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("h5", _hoisted_9, _toDisplayString(_ctx.productTitle), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_10, [
      (!_ctx.isEditor && !_ctx.authentication.isAuthenticated())
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-primary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.authentication.handleLogin()))
            }, _toDisplayString(_ctx.$t('components.custom-header.login')), 1)
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isEditor && _ctx.authentication.isAuthenticated())
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.authentication.handleLogout()))
            }, _toDisplayString(_ctx.$t('components.custom-header.logout')), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.isEditor)
        ? (_openBlock(), _createBlock(_component_sidebar_tools, {
            key: 2,
            onProductQualityChanged: _ctx.productQualityChanged,
            selectedProductQualityIndex: _ctx.selectedProductQualityIndex,
            workflowModel: _ctx.workflowModel,
            meshEditor: _ctx.meshEditor,
            authentication: _ctx.authentication
          }, null, 8, ["onProductQualityChanged", "selectedProductQualityIndex", "workflowModel", "meshEditor", "authentication"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}