import * as THREE from 'three';
import { initCurveMaterial } from '@/utils/three/init';
import { isSpecialObject, SpecialObjectName } from '@/types/enum/three';

/* eslint-disable @typescript-eslint/no-explicit-any*/

const cameraLookDir = (camera: THREE.Camera): THREE.Vector3 => {
  const vector = new THREE.Vector3(0, 0, -1)
    .applyQuaternion(camera.quaternion)
    .add(camera.position);

  if (vector.clone().normalize() !== vector.clone().normalize()) {
    return vector;
  }
  return new THREE.Vector3();
};

const isCustomObject3DType = (model: THREE.Object3D): boolean => {
  return (model as any).isMesh || (model as any).isGroup;
};

export const isCustomObject3D = (model: THREE.Object3D): boolean => {
  return isCustomObject3DType(model) && !isSpecialObject(model.name);
};

export const insideBoundingBox = (
  selection: THREE.Object3D[],
  searchObjectList: THREE.Object3D[]
): boolean => {
  const boundingBox = getBoundingBox(selection);
  for (const searchObject of searchObjectList) {
    if (!boundingBox.containsPoint(searchObject.position)) return false;
  }
  return true;
};

export const allOutsideBoundingBox = (
  selection: THREE.Object3D[],
  searchList: THREE.Vector3[]
): boolean => {
  const boundingBox = getBoundingBox(selection);
  for (const searchPosition of searchList) {
    if (boundingBox.containsPoint(searchPosition)) return false;
  }
  return true;
};

export const removeBoundingBox = (selection: THREE.Object3D[]): void => {
  selection = selection.filter((obj) => isCustomObject3DType(obj));
  if (selection.length > 0) {
    const parent = selection[0];

    const oldIndex = parent.children.findIndex(
      (child) => child.name === SpecialObjectName.BoundingBox
    );
    if (oldIndex >= 0) {
      parent.remove(parent.children[oldIndex]);
    }
  }
};

export const getBoundingBox = (
  selection: THREE.Object3D[],
  calculateLocal = true,
  ignoreSpecialObject = true
): THREE.Box3 => {
  const expandBoundingBox = (
    boundingBox: THREE.Box3,
    selection: THREE.Object3D[],
    parent: THREE.Object3D | boolean | null = null,
    ignoreSpecialObject = true
  ): void => {
    for (const object of selection) {
      if (object && (!ignoreSpecialObject || !isSpecialObject(object.name))) {
        object.updateWorldMatrix(false, false);
        const geometry = (object as any).geometry;
        if (geometry) {
          geometry.computeBoundingBox();
          if (geometry.boundingBox) {
            const geometryBox = geometry.boundingBox.clone();
            if (parent) geometryBox.applyMatrix4(object.matrix);
            boundingBox.union(geometryBox);
          }
        }
        if (object.children.length > 0)
          expandBoundingBox(boundingBox, object.children, object);
      }
    }
  };

  const boundingBox = new THREE.Box3();
  boundingBox.makeEmpty();
  expandBoundingBox(
    boundingBox,
    selection,
    calculateLocal ? null : true,
    ignoreSpecialObject
  );
  if (boundingBox.isEmpty()) {
    boundingBox.min.set(0, 0, 0);
    boundingBox.max.set(0, 0, 0);
  }
  return boundingBox;
};

export const addBoundingBox = (
  selection: THREE.Object3D[]
): THREE.Vector3 | null => {
  removeBoundingBox(selection);
  selection = selection.filter((obj) => isCustomObject3D(obj));
  if (selection.length > 0) {
    const parent = selection[0];

    const boundingBox = getBoundingBox(selection);
    const low = boundingBox.min;
    const high = boundingBox.max;
    const center = boundingBox.getCenter(new THREE.Vector3());

    const corners: THREE.Vector3[] = [];

    corners.push(new THREE.Vector3(low.x, low.y, low.z));
    corners.push(new THREE.Vector3(high.x, low.y, low.z));
    corners.push(new THREE.Vector3(high.x, low.y, high.z));
    corners.push(new THREE.Vector3(low.x, low.y, high.z));
    corners.push(new THREE.Vector3(low.x, high.y, low.z));
    corners.push(new THREE.Vector3(high.x, high.y, low.z));
    corners.push(new THREE.Vector3(high.x, high.y, high.z));
    corners.push(new THREE.Vector3(low.x, high.y, high.z));

    const lines: { start: number; end: number }[] = [];
    lines.push({ start: 0, end: 1 });
    lines.push({ start: 1, end: 2 });
    lines.push({ start: 2, end: 3 });
    lines.push({ start: 3, end: 0 });
    lines.push({ start: 0, end: 4 });
    lines.push({ start: 1, end: 5 });
    lines.push({ start: 2, end: 6 });
    lines.push({ start: 3, end: 7 });
    lines.push({ start: 4, end: 5 });
    lines.push({ start: 5, end: 6 });
    lines.push({ start: 6, end: 7 });
    lines.push({ start: 7, end: 4 });

    const group = new THREE.Group();
    group.name = SpecialObjectName.BoundingBox;
    for (let i = 0; i < lines.length; i++) {
      const lineCurve = new THREE.LineCurve3(
        corners[lines[i].start],
        corners[lines[i].end]
      );
      const points = lineCurve.getPoints(2);
      const lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
      const lineMaterial = initCurveMaterial('#008888');
      const edge = new THREE.Line(lineGeometry, lineMaterial);
      group.add(edge);
    }
    parent.add(group);
    return center;
  }
  return null;
};

export const getCenter = (
  camera: THREE.PerspectiveCamera,
  selection: THREE.Object3D[],
  offset = 1.2,
  cameraTarget: THREE.Vector3 | null = null
): {
  center: THREE.Vector3;
  direction: THREE.Vector3;
  distance: number;
} => {
  selection = selection.filter((obj) => isCustomObject3D(obj) && obj.visible);
  const lookAtVector = cameraTarget ? cameraTarget : cameraLookDir(camera);
  const size = new THREE.Vector3();
  const center = new THREE.Vector3();
  const boundingBox = getBoundingBox(selection, false);

  boundingBox.getSize(size);
  boundingBox.getCenter(center);

  const maxSize = Math.max(size.x, size.y, size.z);
  const fitHeightDistance =
    maxSize / (2 * Math.atan((Math.PI * camera.fov) / 360));
  const fitWidthDistance = fitHeightDistance / camera.aspect;
  const distance = offset * Math.max(fitHeightDistance, fitWidthDistance);

  const direction = lookAtVector
    .clone()
    .sub(camera.position)
    .normalize()
    .multiplyScalar(distance);

  return {
    center: center,
    direction: direction,
    distance: distance,
  };
};
