<script setup lang="ts">
interface Props {
  title?: string;
  isSecondaryButton?: boolean;
}

const props = defineProps<Props>();
</script>

<template>
  <div class="text-center">
    <p v-if="title" class="text-uppercase fw-normal mb-1">{{ props.title }}</p>
    <button type="button" :class="'btn ' + (props.isSecondaryButton ? 'btn-secondary' : 'btn-primary')" @click="$emit('buttonClicked')">
      <slot></slot>
    </button>
  </div>
</template>

<style scoped lang="scss"></style>
