<template>
  <div v-if="editorMode.isOrientationMode && canTransform" class="level-item">
    {{ $t('components.mesh-editor.damping') }}&nbsp;
    <el-select
      v-model="activeDamping"
      :fit-input-width="true"
      style="width: 5rem"
    >
      <el-option
        v-for="damping in dampingRotation"
        :key="damping"
        :value="damping"
        :label="`${damping}°`"
      />
    </el-select>
  </div>
  <div
    v-if="editorMode.isOrientationMode && canTransform"
    class="level-item action"
    v-on:click="setOrientationDirection(OrientationAxis.x)"
    :class="{
      active: orientationDirection === OrientationAxis.x,
    }"
  >
    <font-awesome-icon icon="arrow-right-long" />
  </div>
  <div
    v-if="editorMode.isOrientationMode && canTransform"
    class="level-item action"
    v-on:click="setOrientationDirection(OrientationAxis.y)"
    :class="{
      active: orientationDirection === OrientationAxis.y,
    }"
  >
    <font-awesome-icon icon="arrow-up-long" />
  </div>
  <div
    v-if="editorMode.isOrientationMode && canTransform"
    class="level-item action"
    v-on:click="setOrientationDirection(OrientationAxis.z)"
    :class="{
      active: orientationDirection === OrientationAxis.z,
    }"
  >
    <font-awesome-icon icon="arrow-down-right" />
  </div>
  <div
    v-if="editorMode.isOrientationMode && canTransform"
    class="level-item action"
    v-on:click="setOrientationDirection(OrientationAxis.all)"
    :class="{
      active: orientationDirection === OrientationAxis.all,
    }"
  >
    <font-awesome-icon :icon="['fac', 'axes']" />
  </div>
  <div
    v-if="
      (editorMode.isDefaultMode || editorMode.isPivotMode) &&
      canTransform &&
      canTogglePivotMode
    "
    class="level-item action"
    v-on:click="togglePivotMode()"
    :class="{ active: editorMode.isPivotMode }"
  >
    <font-awesome-icon icon="location-crosshairs" />
  </div>
  <el-popover
    ref="popoverManualTransformation"
    v-if="
      (editorMode.isDefaultMode || editorMode.isPivotMode) &&
      canTransform &&
      canManualTransform
    "
    placement="bottom-start"
    :title="$t('components.mesh-editor.coordinates')"
    :width="200"
    trigger="click"
  >
    <template #reference>
      <div class="level-item action">
        <font-awesome-icon icon="keyboard" />
      </div>
    </template>
    <el-form>
      <el-form-item label="x">
        <el-input-number v-model="manualTransformationX" placeholder="x" />
      </el-form-item>
      <el-form-item label="y">
        <el-input-number v-model="manualTransformationY" placeholder="y" />
      </el-form-item>
      <el-form-item label="z">
        <el-input-number v-model="manualTransformationZ" placeholder="z" />
      </el-form-item>
      <el-form-item>
        <el-button @click="resetManualTransformation" type="primary">
          {{ $t('components.mesh-editor.reset') }}
        </el-button>
      </el-form-item>
    </el-form>
  </el-popover>
  <div
    v-if="(editorMode.isDefaultMode || editorMode.isPivotMode) && canTransform"
    class="level-item action"
    v-on:click="setModifyType(ModifyType.translate)"
    :class="{ active: isModifyTypeTransform }"
  >
    <font-awesome-icon icon="arrows-alt" />
  </div>
  <div
    v-if="(editorMode.isDefaultMode || editorMode.isPivotMode) && canTransform"
    class="level-item action"
    v-on:click="setModifyType(ModifyType.rotate)"
    :class="{ active: isModifyTypeRotate }"
  >
    <font-awesome-icon icon="sync" />
  </div>
  <div
    v-if="editorMode.isDefaultMode && canTransform"
    class="level-item action"
    v-on:click="setModifyType(ModifyType.scale)"
    :class="{
      active: isModifyTypeScale,
      disabled: editorMode.isPivotMode,
    }"
  >
    <font-awesome-icon icon="expand-arrows-alt" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { MeshTreeData } from '@/types/ui/MeshTreeData';
import { RendererPublicInterface } from 'troisjs';
import { SelectionList } from '@/types/ui/SelectionList';
import { ViewCtrl } from '@/types/ui/ViewCtrl';
import { EditorMode } from '@/types/ui/EditorMode';
import * as THREEEnum from '@/types/enum/three';
import { ModifyType } from '@/types/enum/three';
import { Prop, Watch } from 'vue-property-decorator';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import * as THREE from 'three';
import { EditorModeState, MeshShape } from '@/types/enum/editor';
import * as THREEInit from '@/utils/three/init';
import * as THREEBone from '@/utils/three/bone';
import * as THREETransform from '@/utils/three/transform';
import { HistoryList, HistoryOperationType } from '@/types/ui/HistoryList';
import * as modelService from '@/services/api/modelService';
import * as componentService from '@/services/api/componentService';
import * as THREEBoundingBox from '@/utils/three/boundingBox';

export interface TransformExtension {
  applyChangesBeforeUpdate(): void;
  applyChangesAfterUpdate(
    saveHistory: boolean,
    newValue: TransformValueType,
    oldValue: TransformValueType
  ): void;
  getTransformTarget(): THREE.Group | THREE.Object3D | undefined;
}

export interface TransformValueType {
  x: number;
  y: number;
  z: number;
}

@Options({
  components: {},
  emits: ['change'],
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class TransformTools extends Vue {
  //#region properties
  @Prop() modelValue!: MeshTreeData;
  @Prop() troisRenderer!: RendererPublicInterface;
  @Prop() editorMode!: EditorMode;
  @Prop() selectionList!: SelectionList;
  @Prop() viewCtrl!: ViewCtrl;
  @Prop({ default: true }) canTransform!: boolean;
  @Prop({ default: false }) canDisableTransform!: boolean;
  @Prop({ default: true }) canManualTransform!: boolean;
  @Prop({ default: true }) canTogglePivotMode!: boolean;
  @Prop({ default: false }) resetOnPivotChanged!: boolean;
  @Prop({ default: THREEEnum.ModifyType.translate })
  activeModifyType!: THREEEnum.ModifyType;
  @Prop({ default: THREEEnum.OrientationAxis.none })
  defaultOrientationAxis!: THREEEnum.OrientationAxis;
  @Prop() readonly updateAppearance!: () => void;
  @Prop() readonly selectObject!: (
    target: THREE.Object3D | null,
    setSelectionFlag: boolean
  ) => Promise<void>;
  @Prop() historyList!: HistoryList;
  @Prop({ default: false }) ignoreCanTransformChanged!: boolean;

  // transform
  transformControl!: TransformControls;
  isModifyTypeTransform = true;
  isModifyTypeRotate = false;
  isModifyTypeScale = false;
  isModifyTypeNone = false;
  transformValues: TransformValueType = { x: 0, y: 0, z: 0 };

  // pivot
  pivot!: THREE.Group;

  // orientation
  orientationDirection: THREEEnum.OrientationAxis =
    THREEEnum.OrientationAxis.none;
  centerPoint!: THREE.Object3D;
  dampingRotation: number[] = [
    90, 45, 10, 5, 4, 3, 2, 1, 0.5, 0.4, 0.3, 0.2, 0.1, 0,
  ];
  activeDamping = 10;
  clickPointStart!: THREE.Object3D;
  clickPointEnd!: THREE.Object3D;
  arrowHelper!: THREE.ArrowHelper;

  extensionList: TransformExtension[] = [];

  isMounted = false;

  // enums for use in template section
  ModifyType = THREEEnum.ModifyType;
  OrientationAxis = THREEEnum.OrientationAxis;
  //#endregion properties

  //#region init
  mounted(): void {
    const orbitCtrl = this.troisRenderer.three.cameraCtrl;
    if (orbitCtrl && this.troisRenderer.camera) {
      // setup transform controller
      this.transformControl = new TransformControls(
        this.troisRenderer.camera,
        this.troisRenderer.three.renderer.domElement
      );
      this.transformControl.setSpace('local');
      this.transformControl.addEventListener(
        'dragging-changed',
        function (event) {
          orbitCtrl.enabled = !event.value;
        }
      );

      this.transformControl.addEventListener('mouseUp', () => {
        this.transformChanged();
      });

      this.transformControl.addEventListener('mouseDown', () => {
        this.transformStart();
      });

      // setup camera control
      orbitCtrl.addEventListener('change', () => {
        const sceneScaleFactor = this.getSceneSize() / 50;
        this.viewCtrl.adaptPointerSize(this.pivot, sceneScaleFactor);
        this.viewCtrl.adaptPointerSize(this.centerPoint, sceneScaleFactor);
        this.viewCtrl.adaptPointerSize(this.clickPointStart, sceneScaleFactor);
        this.viewCtrl.adaptPointerSize(this.clickPointEnd, sceneScaleFactor);
      });
    }

    // pivot
    this.pivot = this.createPointerObject(
      THREEEnum.SpecialObjectName.Pivot,
      MeshShape.box,
      true
    );

    // intersection
    this.centerPoint = this.createPointerObject(
      THREEEnum.SpecialObjectName.CenterPoint,
      MeshShape.box,
      true
    );

    this.clickPointStart = this.createPointerObject(
      THREEEnum.SpecialObjectName.StartPoint,
      MeshShape.sphere
    );
    this.clickPointEnd = this.createPointerObject(
      THREEEnum.SpecialObjectName.EndPoint,
      MeshShape.sphere
    );

    this.arrowHelper = THREEInit.initSpecialObjectArrowHelper();
    if (this.troisRenderer.scene)
      this.troisRenderer.scene.add(this.arrowHelper);

    this.isMounted = true;
  }

  unmounted(): void {
    const orbitCtrl = this.troisRenderer.three.cameraCtrl;
    if (orbitCtrl) {
      this.transformControl.removeEventListener(
        'dragging-changed',
        function (event) {
          orbitCtrl.enabled = !event.value;
        }
      );

      this.transformControl.removeEventListener('mouseUp', () => {
        this.transformChanged();
      });

      this.transformControl.removeEventListener('mouseDown', () => {
        this.transformStart();
      });

      // setup camera control
      orbitCtrl.removeEventListener('change', () => {
        const sceneScaleFactor = this.getSceneSize() / 50;
        this.viewCtrl.adaptPointerSize(this.pivot, sceneScaleFactor);
        this.viewCtrl.adaptPointerSize(this.centerPoint, sceneScaleFactor);
        this.viewCtrl.adaptPointerSize(this.clickPointStart, sceneScaleFactor);
        this.viewCtrl.adaptPointerSize(this.clickPointEnd, sceneScaleFactor);
      });
    }
  }

  @Watch('defaultOrientationAxis', { immediate: true })
  onDefaultOrientationAxis(): void {
    this.orientationDirection = this.defaultOrientationAxis;
  }

  @Watch('activeModifyType', { immediate: false })
  private onActiveModifyTypeChanged(): void {
    this.setModifyType(this.activeModifyType);
  }

  @Watch('canTransform', { immediate: false })
  private onCanTransformChanged(): void {
    if (!this.ignoreCanTransformChanged) {
      const selection = this.selectionList.getSelectedObject();
      if (this.selectObject) {
        if (this.canTransform) {
          this.selectObject(selection, true);
          this.setTransformControlsForTarget(selection);
        } else {
          this.deactivateTransformControls();
          this.pivot.visible = false;
        }
      }
      this.modifyPivot();
    } else if (!this.canTransform) {
      this.deactivateTransformControls();
      this.pivot.visible = false;
    }
  }

  async setTransformControlsForTarget(
    target: THREE.Object3D | null,
    useCanTransform = true
  ): Promise<void> {
    if (target && !(target as any).isCamera) {
      if (!useCanTransform || this.canTransform) {
        if (this.editorMode.isDefaultMode)
          this.activateTransformControls(target, !this.isModifyTypeNone);
        else if (this.editorMode.usePivotMode(this.orientationDirection))
          this.activateTransformControls(this.pivot, !this.isModifyTypeNone);
      }

      if (this.editorMode.usePivotMode(this.orientationDirection)) {
        target.add(this.pivot);
      }
    }

    if (!target || this.isModifyTypeNone) {
      this.deactivateTransformControls();
    }
    this.syncManualTransformation();
    this.pivot.visible =
      this.editorMode.usePivotMode(this.orientationDirection) &&
      !!this.selectionList.getSelectedObject() &&
      this.canTransform;
    const sceneScaleFactor = this.getSceneSize() / 50;
    this.viewCtrl.adaptPointerSize(this.pivot, sceneScaleFactor);
  }

  deactivateTransformControls(): void {
    if (this.transformControl) {
      if (this.troisRenderer.scene)
        this.troisRenderer.scene.remove(this.transformControl);
      this.transformControl.object = undefined;
    }
  }

  transformTarget: THREE.Object3D | undefined = undefined;
  activateTransformControls(
    target: THREE.Object3D | undefined = undefined,
    display = true
  ): void {
    if (this.transformControl) {
      if (target) {
        this.transformControl.attach(target);
        this.transformTarget = target;
      } else if (this.transformTarget)
        this.transformControl.attach(this.transformTarget);
      if (this.troisRenderer.scene && display)
        this.troisRenderer.scene.add(this.transformControl);
    }
  }

  addExtension(extension: TransformExtension): void {
    this.extensionList.push(extension);
  }

  getSceneBox(): THREE.Box3 {
    return THREEBoundingBox.getBoundingBox(this.modelValue.getMeshList());
  }

  getBoxSize(bbox: THREE.Box3): THREE.Vector3 {
    const measure = new THREE.Vector3(0, 0, 0);
    bbox.getSize(measure);
    return measure;
  }

  getSceneSize(): number {
    //const bbox = this.getSceneBox();
    //const measure = this.getBoxSize(bbox);
    return 2; //measure.x;
  }
  //#endregion init

  //#region special objects
  createPointerObject(
    name: string,
    shape: MeshShape,
    addAxesHelper = false
  ): THREE.Group {
    const pointer = THREEInit.initSpecialObjectPoint(
      name,
      shape,
      addAxesHelper
    );
    const sceneScaleFactor = this.getSceneSize() / 50;
    this.viewCtrl.adaptPointerSize(pointer, sceneScaleFactor);
    return pointer;
  }

  adaptPointerObjectToSceneSize(pointer: THREE.Group): void {
    const size = this.getSceneSize() / 50;
    pointer.scale.set(size, size, size);
  }
  //#endregion special objects

  //#region transform mode
  @Watch('canDisableTransform', { immediate: true })
  onCanDisableTransformChanged(): void {
    if (this.canDisableTransform) {
      this.isModifyTypeTransform = false;
      this.isModifyTypeRotate = false;
      this.isModifyTypeScale = false;
      this.isModifyTypeNone = true;
      this.deactivateTransformControls();
    } else if (this.isModifyTypeNone) {
      this.setModifyType(THREEEnum.ModifyType.translate);
    }
  }

  getModifyType(): THREEEnum.ModifyType {
    if (this.isModifyTypeTransform) return THREEEnum.ModifyType.translate;
    if (this.isModifyTypeRotate) return THREEEnum.ModifyType.rotate;
    if (this.isModifyTypeScale) return THREEEnum.ModifyType.scale;
    return THREEEnum.ModifyType.translate;
  }

  setModifyType(type: THREEEnum.ModifyType): void {
    if (
      !this.isModifyTypeNone &&
      this.isModifyType(type) &&
      this.canDisableTransform
    ) {
      this.isModifyTypeTransform = false;
      this.isModifyTypeRotate = false;
      this.isModifyTypeScale = false;
      this.isModifyTypeNone = true;
      this.deactivateTransformControls();
      return;
    }
    this.isModifyTypeNone = false;
    switch (type) {
      case THREEEnum.ModifyType.translate:
        this.transformControl.mode = 'translate';
        break;
      case THREEEnum.ModifyType.rotate:
        this.transformControl.mode = 'rotate';
        break;
      case THREEEnum.ModifyType.scale:
        this.transformControl.mode = 'scale';
        break;
    }
    this.isModifyTypeTransform = this.isModifyType(
      THREEEnum.ModifyType.translate
    );
    this.isModifyTypeRotate = this.isModifyType(THREEEnum.ModifyType.rotate);
    this.isModifyTypeScale = this.isModifyType(THREEEnum.ModifyType.scale);
    const selection = this.selectionList.getSelectedObject();
    if (selection && this.canTransform) {
      this.activateTransformControls();
    }
    this.syncManualTransformation();
  }

  isModifyType(type: THREEEnum.ModifyType): boolean {
    if (this.transformControl) {
      return this.transformControl.mode === type.toString();
    }
    return false;
  }
  //#endregion transform mode

  //#region input
  oldTransformState: {
    position: THREE.Vector3;
    rotation: THREE.Euler;
    scale: THREE.Vector3;
  } = {
    position: new THREE.Vector3(0, 0, 0),
    rotation: new THREE.Euler(0, 0, 0),
    scale: new THREE.Vector3(1, 1, 1),
  };
  transformStart(): void {
    const target = this.getTransformTarget();
    if (target) {
      this.oldTransformState.position.copy(target.position.clone());
      this.oldTransformState.rotation.copy(target.rotation.clone());
      this.oldTransformState.scale.copy(target.scale.clone());
    }
  }

  getTransformTarget(): THREE.Group | THREE.Object3D | undefined {
    const pivotBoneActive = THREEBone.pivotBoneActive(this.modelValue);
    if (this.editorMode.isOrientationMode) {
      const useCenterPoint = !this.pivot.visible && this.centerPoint.visible;
      if (useCenterPoint) return this.centerPoint;
      return this.pivot;
    }
    if (this.editorMode.isBoneMode) {
      for (const extension of this.extensionList) {
        const target = extension.getTransformTarget();
        if (target) return target;
      }
    }
    if (
      this.editorMode.usePivotMode(this.orientationDirection) ||
      (this.editorMode.isBoneMode && pivotBoneActive)
    )
      return this.pivot;
    return this.transformTarget; //this.transformControl.object
  }

  async transformChanged(saveHistory = true): Promise<void> {
    const mesh = this.selectionList.getSelectedObject();
    if (!mesh) return;

    for (const extension of this.extensionList) {
      extension.applyChangesBeforeUpdate();
    }

    if (this.editorMode.isOrientationMode) {
      let x = THREE.MathUtils.radToDeg(this.pivot.rotation.x);
      let y = THREE.MathUtils.radToDeg(this.pivot.rotation.y);
      let z = THREE.MathUtils.radToDeg(this.pivot.rotation.z);
      x = Math.round(x / this.activeDamping) * this.activeDamping;
      y = Math.round(y / this.activeDamping) * this.activeDamping;
      z = Math.round(z / this.activeDamping) * this.activeDamping;
      this.pivot.rotation.x = THREE.MathUtils.degToRad(x);
      this.pivot.rotation.y = THREE.MathUtils.degToRad(y);
      this.pivot.rotation.z = THREE.MathUtils.degToRad(z);
    }

    const pivotBoneActive = THREEBone.pivotBoneActive(this.modelValue);
    const target = this.getTransformTarget();
    const newValue: TransformValueType = target
      ? {
          x: this.isModifyTypeTransform
            ? target.position.x
            : this.isModifyTypeRotate
            ? target.rotation.x
            : target.scale.x,
          y: this.isModifyTypeTransform
            ? target.position.y
            : this.isModifyTypeRotate
            ? target.rotation.y
            : target.scale.y,
          z: this.isModifyTypeTransform
            ? target.position.z
            : this.isModifyTypeRotate
            ? target.rotation.z
            : target.scale.z,
        }
      : {
          x: this.transformValues.x,
          y: this.transformValues.y,
          z: this.transformValues.z,
        };
    const oldValue: TransformValueType = {
      x: this.isModifyTypeTransform
        ? this.oldTransformState.position.x
        : this.isModifyTypeRotate
        ? this.oldTransformState.rotation.x
        : this.oldTransformState.scale.x,
      y: this.isModifyTypeTransform
        ? this.oldTransformState.position.y
        : this.isModifyTypeRotate
        ? this.oldTransformState.rotation.y
        : this.oldTransformState.scale.y,
      z: this.isModifyTypeTransform
        ? this.oldTransformState.position.z
        : this.isModifyTypeRotate
        ? this.oldTransformState.rotation.z
        : this.oldTransformState.scale.z,
    };

    if (
      this.editorMode.usePivotMode(this.orientationDirection) ||
      this.editorMode.isOrientationMode ||
      (this.editorMode.isBoneMode && pivotBoneActive)
    ) {
      const useCenterPoint = !this.pivot.visible && this.centerPoint.visible;
      if (useCenterPoint) {
        this.pivot.rotation.copy(this.centerPoint.rotation);
      }

      THREETransform.updatePivot(mesh, this.pivot);
      if (this.resetOnPivotChanged) {
        mesh.rotation.set(0, 0, 0);
      }

      if (this.updateAppearance) this.updateAppearance();

      if (useCenterPoint) {
        this.centerPoint.rotation.set(0, 0, 0);
        this.viewCtrl.fitControlCameraToScene();
      }
      const sceneScaleFactor = this.getSceneSize() / 50;
      this.viewCtrl.adaptPointerSize(this.pivot, sceneScaleFactor);
    }

    for (const extension of this.extensionList) {
      extension.applyChangesAfterUpdate(saveHistory, newValue, oldValue);
    }

    const redoValue = {
      x: newValue.x - oldValue.x,
      y: newValue.y - oldValue.y,
      z: newValue.z - oldValue.z,
    };

    let undoSteps = 0;
    if (
      (this.editorMode.isPivotMode || this.editorMode.isDefaultMode) &&
      this.modelValue.modelId
    ) {
      const treeData = this.modelValue.getSelection();
      if (treeData.length > 0 && treeData[0].componentId) {
        if (this.isModifyTypeTransform) {
          undoSteps = componentService.transformComponent(
            this.modelValue.modelId,
            treeData[0].componentId,
            treeData[0].uuid,
            new THREE.Vector3(newValue.x, newValue.y, newValue.z),
            new THREE.Vector3(oldValue.x, oldValue.y, oldValue.z),
            mesh.position,
            this.editorMode.isPivotMode
          );
        } else if (this.isModifyTypeRotate) {
          undoSteps = componentService.rotateComponent(
            this.modelValue.modelId,
            treeData[0].componentId,
            treeData[0].uuid,
            new THREE.Euler(newValue.x, newValue.y, newValue.z),
            new THREE.Euler(oldValue.x, oldValue.y, oldValue.z),
            mesh.rotation,
            this.editorMode.isPivotMode
          );
        } else if (this.isModifyTypeScale) {
          undoSteps = componentService.scaleComponent(
            this.modelValue.modelId,
            treeData[0].componentId,
            treeData[0].uuid,
            new THREE.Vector3(newValue.x, newValue.y, newValue.z),
            new THREE.Vector3(oldValue.x, oldValue.y, oldValue.z),
            mesh.scale,
            this.editorMode.isPivotMode
          );
        }
      }
    }

    if (
      saveHistory &&
      this.historyList &&
      target &&
      !this.editorMode.isBoneMode
    ) {
      const meshId = (mesh as any).apiData.uuid;
      const historyTarget = target;
      const selection = this.selectionList.getSelectedObject();
      const modifyType = this.getModifyType();
      const editorMode = this.editorMode.editorMode;
      const undoValue = {
        x: oldValue.x - newValue.x,
        y: oldValue.y - newValue.y,
        z: oldValue.z - newValue.z,
      };
      if (
        (this.editorMode.usePivotMode(this.orientationDirection) ||
          this.editorMode.isOrientationMode) &&
        this.isModifyType(ModifyType.rotate)
      ) {
        const invertValue = new THREE.Euler().setFromQuaternion(
          new THREE.Quaternion()
            .setFromEuler(
              new THREE.Euler(redoValue.x, redoValue.y, redoValue.z)
            )
            .invert()
        );
        undoValue.x = invertValue.x;
        undoValue.y = invertValue.y;
        undoValue.z = invertValue.z;
      }
      this.historyList.add(
        meshId,
        HistoryOperationType.transform,
        async () =>
          await this.undoRedoTransformation(
            historyTarget,
            modifyType,
            editorMode,
            undoValue,
            selection,
            undoSteps
          ),
        async () =>
          await this.undoRedoTransformation(
            historyTarget,
            modifyType,
            editorMode,
            redoValue,
            selection,
            undoSteps
          )
      );
    }

    await setTimeout(() => {
      this.syncManualTransformation();
      const meshId = (mesh as any).apiData.uuid;
      this.modelValue.updateDB(meshId);
      this.$emit('change');
    }, 100);
  }

  //#region pivot
  togglePivotMode(): void {
    this.editorMode.togglePivotMode();
    this.modifyPivot();
  }

  modifyPivot(): void {
    if (this.isMounted) {
      this.pivot.visible =
        this.editorMode.usePivotMode(this.orientationDirection) &&
        !!this.selectionList.getSelectedObject() &&
        this.canTransform;
      const sceneScaleFactor = this.getSceneSize() / 50;
      this.viewCtrl.adaptPointerSize(this.pivot, sceneScaleFactor);
      const selection = this.selectionList.getSelectedObject();
      if (selection) {
        if (this.selectObject) this.selectObject(selection, false);
        else {
          this.selectionList.selectObject(selection, false);
          this.setTransformControlsForTarget(selection);
        }
      }
      if (this.isModifyTypeScale)
        this.setModifyType(THREEEnum.ModifyType.translate);
      this.syncManualTransformation();
    }
  }
  //#endregion pivot

  //#region orientation
  setOrientationDirection(
    orientationDirection: THREEEnum.OrientationAxis,
    toggle = true
  ): void {
    if (this.isMounted) {
      if (this.editorMode.isOrientationMode) {
        if (this.orientationDirection !== orientationDirection || !toggle) {
          this.orientationDirection = orientationDirection;
        } else this.orientationDirection = THREEEnum.OrientationAxis.none;

        switch (this.orientationDirection) {
          case THREEEnum.OrientationAxis.x:
            this.arrowHelper.setColor(0xff0000);
            break;
          case THREEEnum.OrientationAxis.y:
            this.arrowHelper.setColor(0x00ff00);
            break;
          case THREEEnum.OrientationAxis.z:
            this.arrowHelper.setColor(0x0000ff);
            break;
        }

        this.centerPoint.visible =
          this.orientationDirection === THREEEnum.OrientationAxis.all;
        if (this.orientationDirection === THREEEnum.OrientationAxis.all) {
          this.setModifyType(THREEEnum.ModifyType.rotate);
          const sceneScaleFactor = this.getSceneSize() / 50;
          this.viewCtrl.adaptPointerSize(this.centerPoint, sceneScaleFactor);

          this.activateTransformControls(this.centerPoint);
        } else {
          this.deactivateTransformControls();
        }
      } else if (this.centerPoint) {
        this.centerPoint.visible = false;
        this.deactivateTransformControls();
      }
    }
  }

  updateStartPoint(event: MouseEvent): void {
    if (
      this.orientationDirection !== THREEEnum.OrientationAxis.none &&
      this.orientationDirection !== THREEEnum.OrientationAxis.all
    ) {
      const point = this.selectionList.checkIntersection(event);
      if (point && this.selectionList.intersectionMesh) {
        this.viewCtrl.setOrbitCtrl(false);
        this.clickPointStart.visible = true;
        this.clickPointStart.position.copy(point);
        this.selectionList.intersectionMesh.add(this.arrowHelper);
        this.selectionList.intersectionMesh.add(this.clickPointStart);
        this.selectionList.intersectionMesh.add(this.clickPointEnd);
        const sceneScaleFactor = this.getSceneSize() / 50;
        this.viewCtrl.adaptPointerSize(this.clickPointStart, sceneScaleFactor);
      }
    }
  }

  updateEndPoint(event: MouseEvent): void {
    if (
      this.orientationDirection !== THREEEnum.OrientationAxis.none &&
      this.orientationDirection !== THREEEnum.OrientationAxis.all &&
      event.buttons > 0 &&
      !this.viewCtrl.isOrbitCtrl() &&
      this.selectionList.isSameIntersectionMesh(event) &&
      this.selectionList.intersectionMesh
    ) {
      const point = this.selectionList.checkIntersection(event);
      if (point) {
        this.clickPointEnd.visible = true;
        this.clickPointEnd.position.copy(point);
        const sceneScaleFactor = this.getSceneSize() / 50;
        this.viewCtrl.adaptPointerSize(this.clickPointEnd, sceneScaleFactor);
        this.arrowHelper.position.copy(this.clickPointStart.position.clone());

        const direction = this.getArrowHelperDirection();
        const length = direction.length();
        this.arrowHelper.setDirection(direction.normalize());
        this.arrowHelper.setLength(length);
        this.arrowHelper.visible = true;
      }
    }
  }

  private getArrowHelperDirection(): THREE.Vector3 {
    const from = this.clickPointStart.position.clone();
    const to = this.clickPointEnd.position.clone();
    return to.clone().sub(from);
  }

  finishIntersection(): void {
    if (
      this.orientationDirection !== THREEEnum.OrientationAxis.none &&
      this.orientationDirection !== THREEEnum.OrientationAxis.all &&
      !this.viewCtrl.isOrbitCtrl() &&
      this.selectionList.intersectionMesh
    ) {
      let axis = new THREE.Vector3(0, 1, 0);
      switch (this.orientationDirection) {
        case THREEEnum.OrientationAxis.x:
          axis = new THREE.Vector3(1, 0, 0);
          break;
        case THREEEnum.OrientationAxis.z:
          axis = new THREE.Vector3(0, 0, 1);
          break;
      }
      const direction = this.getArrowHelperDirection();
      const angle = axis.angleTo(direction.clone());
      const rotationAxis = axis.cross(direction).normalize();
      this.pivot.rotateOnAxis(rotationAxis, angle);
      this.transformChanged();
      if (this.updateAppearance) this.updateAppearance();
      this.viewCtrl.fitControlCameraToScene();
    }
    this.clearPoints();
  }

  private clearPoints(): void {
    this.viewCtrl.setOrbitCtrl(true);
    this.arrowHelper.visible = false;
    this.clickPointStart.visible = false;
    this.clickPointEnd.visible = false;
  }
  //#endregion orientation
  //#endregion input

  //#region manual transformation
  syncManualTransformation(): void {
    const selection = this.transformTarget;
    if (selection) {
      if (this.isModifyTypeTransform) {
        this.transformValues.x = selection.position.x;
        this.transformValues.y = selection.position.y;
        this.transformValues.z = selection.position.z;
      }
      if (this.isModifyTypeRotate) {
        this.transformValues.x = THREE.MathUtils.radToDeg(selection.rotation.x);
        this.transformValues.y = THREE.MathUtils.radToDeg(selection.rotation.y);
        this.transformValues.z = THREE.MathUtils.radToDeg(selection.rotation.z);
      }
      if (this.isModifyTypeScale) {
        this.transformValues.x = selection.scale.x;
        this.transformValues.y = selection.scale.y;
        this.transformValues.z = selection.scale.z;
      }
    } else {
      this.transformValues.x = 0;
      this.transformValues.y = 0;
      this.transformValues.z = 0;
    }
  }

  async undoRedoTransformation(
    mesh: THREE.Object3D,
    type: THREEEnum.ModifyType,
    editorMode: EditorModeState,
    values: TransformValueType,
    selection: THREE.Object3D | null,
    undoSteps = 1
  ): Promise<void> {
    await this.selectObject(selection, true);
    this.transformTarget = mesh;
    if (this.editorMode.editorMode !== editorMode)
      this.editorMode.editorMode = editorMode;
    if (!this.isModifyType(type)) this.setModifyType(type);
    this.transformValues.x = values.x;
    this.transformValues.y = values.y;
    this.transformValues.z = values.z;
    if (mesh) {
      if (this.isModifyTypeTransform) {
        mesh.position.x += values.x;
        mesh.position.y += values.y;
        mesh.position.z += values.z;
      }
      if (this.isModifyTypeRotate) {
        mesh.rotation.x += values.x; //THREE.MathUtils.degToRad(values.x);
        mesh.rotation.y += values.y; //THREE.MathUtils.degToRad(values.y);
        mesh.rotation.z += values.z; //THREE.MathUtils.degToRad(values.z);
      }
    }
    if (this.isModifyTypeScale) {
      mesh.scale.x += values.x;
      mesh.scale.y += values.y;
      mesh.scale.z += values.z;
    }
    if (this.editorMode.isOrientationMode) {
      this.centerPoint.rotation.copy(mesh.rotation.clone());
    }

    this.transformChanged(false);
    if (this.modelValue.modelId && undoSteps !== 0)
      await modelService.undo(this.modelValue.modelId, undoSteps);
  }

  setManualTransformation(axis: THREEEnum.AxisType, value: number): void {
    this.transformStart();
    if (axis === THREEEnum.AxisType.x) this.transformValues.x = value;
    if (axis === THREEEnum.AxisType.y) this.transformValues.y = value;
    if (axis === THREEEnum.AxisType.z) this.transformValues.z = value;

    const selection = this.transformTarget;
    if (selection) {
      if (this.isModifyTypeTransform) {
        if (axis === THREEEnum.AxisType.x) selection.position.x = value;
        if (axis === THREEEnum.AxisType.y) selection.position.y = value;
        if (axis === THREEEnum.AxisType.z) selection.position.z = value;
      }
      if (this.isModifyTypeRotate) {
        switch (axis) {
          case THREEEnum.AxisType.x:
            selection.rotation.x = THREE.MathUtils.degToRad(value);
            break;
          case THREEEnum.AxisType.y:
            selection.rotation.y = THREE.MathUtils.degToRad(value);
            break;
          case THREEEnum.AxisType.z:
            selection.rotation.z = THREE.MathUtils.degToRad(value);
            break;
        }
      }
      if (this.isModifyTypeScale) {
        if (axis === THREEEnum.AxisType.x) selection.scale.x = value;
        if (axis === THREEEnum.AxisType.y) selection.scale.y = value;
        if (axis === THREEEnum.AxisType.z) selection.scale.z = value;
      }
      this.transformChanged();
    }
  }

  resetManualTransformation(): void {
    this.setManualTransformation(THREEEnum.AxisType.x, 0);
    this.setManualTransformation(THREEEnum.AxisType.y, 0);
    this.setManualTransformation(THREEEnum.AxisType.z, 0);
    const popoverManualTransformation = this.$refs
      .popoverManualTransformation as any;
    if (popoverManualTransformation) popoverManualTransformation.hide();
  }

  get manualTransformationX(): number {
    return this.transformValues.x;
  }

  set manualTransformationX(value: number) {
    this.setManualTransformation(THREEEnum.AxisType.x, value);
  }

  get manualTransformationY(): number {
    return this.transformValues.y;
  }

  set manualTransformationY(value: number) {
    this.setManualTransformation(THREEEnum.AxisType.y, value);
  }

  get manualTransformationZ(): number {
    return this.transformValues.z;
  }

  set manualTransformationZ(value: number) {
    this.setManualTransformation(THREEEnum.AxisType.z, value);
  }
  //#endregion manual transformation
}
</script>

<style lang="scss" scoped>
.active {
  color: var(--el-color-primary);
}
</style>
