import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MeshEditor = _resolveComponent("MeshEditor")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_container, { class: "screenHeight" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          _withDirectives((_openBlock(), _createBlock(_component_el_main, null, {
            default: _withCtx(() => [
              _createVNode(_component_MeshEditor, {
                modelValue: _ctx.selectedProductInput,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedProductInput) = $event)),
                canTogglePivotMode: false,
                canSelect: false,
                canToggleBones: false,
                canDeform: false,
                canCurve: false,
                activateAlineToFloor: false,
                activateBones: false,
                activeEditorMode: false,
                activateDisplayBoundingBox: false,
                activateDisplayGrid: false,
                resetOnPivotChanged: true,
                fitCameraToScene: true,
                canUndo: false,
                canPartSelect: false,
                onTreeDataLoaded: _ctx.ConfigProduct
              }, null, 8, ["modelValue", "onTreeDataLoaded"])
            ]),
            _: 1
          })), [
            [_directive_loading, _ctx.meshEditorLoading]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}