import UnIndexedBufferGeometryAdapter from './UnIndexedBufferGeometryAdapter';
import {
  FaceVertices,
  FaceIndices,
} from '@/plugin/ffd/adapter/GeometryAdapter';
import * as THREE from 'three';
import { GeometryAdapterInterface } from '@/plugin/ffd/adapter/GeometryAdapterFactory';

export default class IndexedBufferGeometryAdapter
  extends UnIndexedBufferGeometryAdapter
  implements GeometryAdapterInterface
{
  indices: ArrayLike<number>;

  constructor(
    bufferGeometry: THREE.BufferGeometry,
    calculateSimilarVertex = false
  ) {
    super(bufferGeometry, calculateSimilarVertex);
    if (bufferGeometry.index) this.indices = bufferGeometry.index.array;
    else this.indices = [];
  }

  get numFaces(): number {
    return this.indices.length / 3;
  }

  getFace(index: number): FaceIndices {
    const offsetPosition = 3 * index;

    return {
      a: this.indices[offsetPosition],
      b: this.indices[offsetPosition + 1],
      c: this.indices[offsetPosition + 2],
    };
  }

  getFaceVertices(index: number): FaceVertices {
    const offsetPosition = 3 * index;

    return {
      a: this.getVertex(this.indices[offsetPosition]),
      b: this.getVertex(this.indices[offsetPosition + 1]),
      c: this.getVertex(this.indices[offsetPosition + 2]),
    };
  }

  updateFaces(): void {
    if (this.bufferGeometry.index) this.bufferGeometry.index.needsUpdate = true;
  }
}
