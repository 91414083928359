import * as THREE from 'three';
import { getCameraObjectDistance } from '@/utils/three/camera';
import { MeshTreeDataItem } from '@/types/ui/MeshTreeData';
import * as THREEEnum from '@/types/enum/three';
import { isSpecialObject } from '@/types/enum/three';
import * as THREEBoundingBox from '@/utils/three/boundingBox';

/* eslint-disable @typescript-eslint/no-explicit-any*/
export const transform = (
  mesh: THREE.Object3D,
  position?: THREE.Vector3,
  rotation?: THREE.Euler,
  scale?: THREE.Vector3
): THREE.Object3D => {
  if (position) mesh.position.set(position.x, position.y, position.z);
  if (rotation) mesh.rotation.set(rotation.x, rotation.y, rotation.z);
  if (scale) mesh.scale.set(scale.x, scale.y, scale.z);
  return mesh;
};

export const clone = (mesh: THREE.Object3D): THREE.Object3D => {
  if ((mesh as any).isMesh) {
    const target = mesh as THREE.Mesh;
    const copy = new THREE.Mesh(target.geometry, target.material);
    copy.name = target.name;
    return transform(copy, target.position, target.rotation, target.scale);
  } else if ((mesh as any).isLine) {
    const target = mesh as THREE.Line;
    const copy = new THREE.Line(target.geometry, target.material);
    copy.name = target.name;
    return transform(copy, target.position, target.rotation, target.scale);
  } else if ((mesh as any).isGroup) {
    const target = mesh as THREE.Group;
    const copy = new THREE.Group();
    copy.name = target.name;
    target.children.forEach((child) => {
      copy.add(clone(child));
    });
    return transform(copy, target.position, target.rotation, target.scale);
  }
  return mesh;
};

export const localToParent = (
  local: THREE.Object3D,
  vector: THREE.Vector3
): THREE.Vector3 => {
  const world = local.localToWorld(vector.clone());
  if (local.parent) return local.parent.worldToLocal(world);
  return world;
};

export const parentToLocal = (
  local: THREE.Object3D,
  vector: THREE.Vector3
): THREE.Vector3 => {
  const world = local.parent
    ? local.parent.localToWorld(vector.clone())
    : vector.clone();
  return local.worldToLocal(world);
};

export const adaptPointerSize = (
  pointer: THREE.Object3D,
  camera: THREE.Camera,
  sceneScaleFactor = 1
): void => {
  const cameraDistance = getCameraObjectDistance(camera, pointer);
  if (cameraDistance) {
    const pointerScale = cameraDistance / 10.0;
    for (const child of pointer.children) {
      child.scale.set(
        pointerScale * sceneScaleFactor,
        pointerScale * sceneScaleFactor,
        pointerScale * sceneScaleFactor
      );
    }
  }
};

export const adaptSize = (
  item: THREE.Object3D,
  camera: THREE.Camera,
  initSize: number
): void => {
  const cameraDistance = getCameraObjectDistance(camera, item);
  if (cameraDistance) {
    const scale = (cameraDistance / 10.0) * initSize;
    item.scale.set(scale, scale, scale);
  }
};

export const updatePivot = (
  mesh: THREE.Object3D | null,
  pivot: THREE.Group
): void => {
  if (!mesh) return;

  pivot.updateMatrixWorld();
  const pivotPos = pivot.position.clone();
  const pivotPosWorld = mesh.localToWorld(pivotPos.clone());
  const meshPos = mesh.position.clone();
  const diff = pivotPosWorld.clone().sub(meshPos.clone());
  mesh.position.add(diff);
  mesh.rotateX(pivot.rotation.x);
  mesh.rotateY(pivot.rotation.y);
  mesh.rotateZ(pivot.rotation.z);

  applyMatrix(mesh, pivot.matrix);

  pivot.position.set(0, 0, 0);
  pivot.rotation.set(0, 0, 0);
};

const isSpecialObject3D = (model: THREE.Object3D): boolean => {
  return isSpecialObject(model.name);
};

const applyMatrix = (mesh: THREE.Object3D, matrix: THREE.Matrix4): void => {
  // eslint-disable-next-line no-prototype-builtins
  if (mesh.hasOwnProperty('geometry')) {
    const geometry = (mesh as any).geometry;
    geometry.applyMatrix4(matrix.clone().invert());
  } else if ((mesh as any).isGroup) {
    for (const index in mesh.children) {
      const child = mesh.children[index];
      if (!isSpecialObject3D(child)) {
        child.applyMatrix4(matrix.clone().invert());
      }
    }
  }
};

export const shiftObject = (
  object: THREE.Object3D | MeshTreeDataItem,
  axis: THREEEnum.AxisType,
  bounds: THREE.Box3
): void => {
  switch (axis) {
    case THREEEnum.AxisType.x:
      object.position.set(
        object.position.x + (bounds.max.x - bounds.min.x),
        object.position.y,
        object.position.z
      );
      break;
    case THREEEnum.AxisType.y:
      object.position.set(
        object.position.x,
        object.position.y + (bounds.max.y - bounds.min.y),
        object.position.z
      );
      break;
    case THREEEnum.AxisType.z:
      object.position.set(
        object.position.x,
        object.position.y,
        object.position.z + (bounds.max.z - bounds.min.z)
      );
      break;
  }
};

export const copyObject = (
  object: THREE.Object3D
): THREE.Object3D | undefined => {
  if ((object as any).isMesh || (object as any).isLine) {
    const geometry = (object as any).geometry.clone();
    const material = (object as any).material.clone();
    let copy!: THREE.Object3D;
    if ((object as any).isMesh) {
      copy = new THREE.Mesh(geometry, material);
    }
    if ((object as any).isLine) {
      copy = new THREE.Line(geometry, material);
    }
    if (copy) {
      copy.position.set(
        object.position.x,
        object.position.y,
        object.position.z
      );
      copy.rotation.set(
        object.rotation.x,
        object.rotation.y,
        object.rotation.z
      );
      copy.scale.set(object.scale.x, object.scale.y, object.scale.z);
      return copy;
    }
  } else if ((object as any).isGroup) {
    const group = new THREE.Group();
    group.position.set(object.position.x, object.position.y, object.position.z);
    group.rotation.set(object.rotation.x, object.rotation.y, object.rotation.z);
    group.scale.set(object.scale.x, object.scale.y, object.scale.z);
    object.children.forEach((child) => {
      const copy = copyObject(child);
      if (copy) group.add(copy);
    });
    return group;
  }
};

export const mirror = (
  objectList: THREE.Object3D[],
  axis: THREEEnum.AxisType
): void => {
  for (const object of objectList) {
    const scale = object.scale.clone();
    switch (axis) {
      case THREEEnum.AxisType.x:
        object.scale.set(-1, 1, 1);
        break;
      case THREEEnum.AxisType.y:
        object.scale.set(1, -1, 1);
        break;
      case THREEEnum.AxisType.z:
        object.scale.set(1, 1, -1);
        break;
    }
    const position = object.position.clone();
    const rotation = object.rotation.clone();
    object.position.set(0, 0, 0);
    object.rotation.set(0, 0, 0);
    object.updateMatrixWorld();
    object.position.copy(position);
    object.rotation.copy(rotation);
    applyMatrix(object, object.matrix);
    object.scale.copy(scale);
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty('material')) {
      const material = (object as any).material as THREE.MeshBasicMaterial;
      material.side = THREE.DoubleSide;
    }
  }
};

export const alineToFloor = (selected: THREE.Object3D[]): void => {
  const boundingBox = THREEBoundingBox.getBoundingBox(selected);
  for (const object of selected) {
    const oldRotation = object.rotation.clone();
    object.rotation.set(0, 0, 0);
    object.position.y = -boundingBox.min.y;
    object.rotation.copy(oldRotation);
  }
};
