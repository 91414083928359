export enum MeshShape {
  box = 'box',
  sphere = 'sphere',
}

export enum EditorModeState {
  default = 'default',
  pivot = 'pivot',
  orientation = 'orientation',
  bone = 'bone',
  readonly = 'readonly',
}

export enum BoneTool {
  transform = 'transform',
  intersect = 'intersect',
}

export enum CurveTool {
  move = 'move',
  add = 'add',
  remove = 'remove',
}

export enum ProjectionCategory {
  custom = 'custom',
  logo = 'logo',
  article = 'article',
  order = 'order',
}
