export enum TemplateProgress {
  setup = 'setup',
  modifier = 'modifier',
  curves = 'curves',
  embossing = 'embossing',
  render = 'render',
}

export enum OrderProgress {
  setup = 'setup',
  adaptation = 'adaptation',
  crop = 'crop',
  calculation = 'calculation',
  zip = 'zip',
  spacer = 'spacer',
  embossing = 'embossing',
  render = 'render',
}

export enum CustomizeProgress {
  product = 'product',
  embossing = 'embossing',
  color = 'color',
  //embossingColor = 'embossingColor',
  //size = 'size',
  form = 'form',
  overview = 'overview',
}
