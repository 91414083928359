<template>
  <custom-header
    class="p-4"
    :isEditor="
      activeCustomizeProgress === CustomizeProgress.embossing ||
      activeCustomizeProgress === CustomizeProgress.color
    "
    :workflowModel="workflowModel"
    :productName="selectedName"
    v-on:productQualityChanged="productQualityChanged"
    :authentication="authentication"
    :supplier="supplierUrl"
    :selectedProductQualityIndex="
      Object.keys(ProductQuality).indexOf(selectedProductQuality)
    "
    :mesh-editor="$refs.meshEditor"
  />
  <div
    class="p-4 flex-grow-1"
    v-loading="productsLoading"
    element-loading-background="transparent"
  >
    <div v-if="activeCustomizeProgress === CustomizeProgress.product">
      <!-- Title -->
      <h1>{{ $t('views.customize.workflow.productVariants.title') }}</h1>

      <button
        type="button"
        v-if="!selectedModel?.product.name?.toLowerCase().includes('s-cover')"
        class="btn btn-secondary mb-3"
        @click="$router.back()"
      >
        {{ '< ' + $t('components.embossing-footer.backButton') }}
      </button>

      <!-- Products -->
      <div class="d-flex flex-wrap">
        <div
          class="mr-4 mb-3"
          v-for="model in productList"
          :key="model.modelId"
        >
          <product-card
            v-if="!model.product.name?.toLowerCase().includes('s-cover')"
            :show-delete-icon="Boolean(authentication?.user)"
            @delete-product="onDeleteProduct(model)"
            :title="model.product.name"
            :secondary-button-text="
              $t(
                'views.customize.workflow.productVariants.productCardSecondaryButton'
              )
            "
            :primary-button-text="
              $t(
                'views.customize.workflow.productVariants.productCardPrimaryButtonOption1'
              )
            "
            @primary-button-clicked="showProductParameterModalDialog(model)"
            @secondary-button-clicked="showProductDetailsModalDialog(model)"
          >
            <preview-renderer-img
              :meshes="[
                {
                  thumbnail: model.product?.name,
                  thumbnailUrl: convertToUrl(model),
                },
              ]"
              imgSize="10.5625"
            />
          </product-card>
          <product-card
            v-if="model.product.name?.toLowerCase().includes('s-cover')"
            @on-delete="onDeleteProduct(model)"
            :show-delete-icon="Boolean(authentication?.user)"
            :title="model.product.name"
            :secondary-button-text="
              $t(
                'views.customize.workflow.productVariants.productCardSecondaryButton'
              )
            "
            :primary-button-text="
              $t(
                'views.customize.workflow.productVariants.productCardPrimaryButtonOption2'
              )
            "
            @primary-button-clicked="showOrderOverview(model)"
            @secondary-button-clicked="showProductDetailsModalDialog(model)"
          >
            <preview-renderer-img
              :meshes="[
                {
                  thumbnail: model.product.name,
                  thumbnailUrl: convertToUrl(model),
                },
              ]"
              imgSize="10.5625"
            />
          </product-card>
        </div>
      </div>
    </div>

    <div v-else-if="activeCustomizeProgress === CustomizeProgress.form">
      <order-form
        :order="orderData"
        :productName="selectedName"
        :workflowModel="workflowModel"
        :product="selectedModel?.product"
        v-on:sizeChanged="updateModelSize()"
        @zip-changed="(zip: string) => zipChanged(zip)"
      />
      <navigation-bar
        :workflowModel="workflowModel"
        :productName="selectedName"
      />
    </div>

    <div
      v-else-if="activeCustomizeProgress === CustomizeProgress.overview"
      v-loading.fullscreen.lock="sendingOrder"
    >
      <order-summary
        :order="orderData"
        :treeData="selectedProductInput.treeData"
        :workflowModel="workflowModel"
        :colorList="[
          ...new Set(meshColorList.map((c) => $t(`views.colors.${c.name}`))),
        ]"
        :embossingList="[
          ...new Set(
            meshEmbossingList.map((e) => $t(`views.designs.${e.name}`)) ?? []
          ),
          ...new Set(
            meshWtransferList.map((w) => $t(`views.designs.${w.name}`)) ?? []
          ),
        ]"
        :embossingColorList="[
          ...new Set(
            meshEmbossingColorList.map((ec) => $t(`views.colors.${ec.name}`))
          ),
          ...new Set(
            meshWtransferColorList.map((wc) => $t(`views.colors.${wc.name}`))
          ),
        ]"
        :product="selectedModel?.product"
        :meshTreeData="selectedProductInput"
        ref="orderSummary"
        @update:calculatingAmortisation="getCalculatingAmortisation"
      />
      <navigation-bar
        :workflowModel="workflowModel"
        :supplier="supplierUrl"
        :selectedModelId="selectedModelId"
        :offerData="requestData"
        :loadingOfferPdf="loadingOfferPdf"
        :productName="selectedName"
        @confirmOfferPdf="confirmOfferPdf()"
        @confirmOfferPdfV2="downloadOfferPDF()"
        @confirmPartListPdfV2="downloadPartListPDF()"
        :isLoadingButtonPartList="isLoadingButtonPartList"
      />
    </div>

    <div
      v-show="
        activeCustomizeProgress === CustomizeProgress.embossing ||
        activeCustomizeProgress === CustomizeProgress.color
      "
    >
      <mesh-editor
        ref="meshEditor"
        v-model="selectedProductInput"
        :canTogglePivotMode="false"
        :canSelect="
          activeCustomizeProgress === CustomizeProgress.color ||
          activeCustomizeProgress === CustomizeProgress.embossing
        "
        :canToggleBones="false"
        :canDeform="false"
        :canCurve="false"
        :activateAlineToFloor="false"
        :activateBones="false"
        :activeEditorMode="false"
        :activateDisplayBoundingBox="false"
        :resetOnPivotChanged="true"
        :fitCameraToScene="true"
        :canUndo="false"
        :canPartSelect="false"
        :canToggleOpacity="true"
        :hasNavigationBar="true"
        :canXr="true"
        @meshes-loaded="meshesLoaded()"
      />
      <div class="z-1 fixed-bottom">
        <div v-if="activeCustomizeProgress === CustomizeProgress.embossing">
          <embossing-footer
            :selected-engraving="selectedEmbossing"
            :selected-texture="selectedWtransfer"
            :mesh-engravings="getMeshEngravings2()"
            :mesh-textures="getMeshTextures2()"
            :mesh-tree-data="selectedProductInput"
            :productId="selectedModel?.product.productId"
            :productTypeString="
              selectedModel?.product?.type.name?.toLowerCase()
              "
            @refetch-embossing="() => refetchEmbossingListDB()"
            @refetch-texture="() => refetchWTransferListDB()"
            @part-selected="(part: MeshTreeDataItem | null) => selectedProductInput.selectItem(part, true, true)"
            @engraving-selected="handleSelectEmbossing"
            @texture-selected="handleMultipleWtransfer"
          />
          <div class="row ml-5 mr-5 mt-3" v-if="selectedModel">
            <div class="d-flex justify-content-center gap-4">
              <el-button type="primary" circle size="large" @click="showProductParameterModalDialog(selectedModel, true)">
                  <font-awesome-icon icon="sliders" class="text-dark" />
              </el-button>
            </div>
          </div>
          <navigation-bar
            :workflowModel="workflowModel"
            :productName="selectedName"
          />
        </div>
        <div v-else-if="activeCustomizeProgress === CustomizeProgress.color">
          <color-footer
            :properties="[orderData]"
            :selected-mesh-color="selectedColor"
            :selected-mesh-embossing-color="selectedEmbossingColor"
            :selected-mesh-texture-color="selectedTextureColor"
            :mesh-colors="getAllColors()"
            :mesh-embossing-colors="getMeshEngravingColors()"
            :mesh-texture-colors="getMeshTextureColors()"
            :mesh-tree-data="selectedProductInput"
            :productTypeString="selectedModel?.product.type.name?.toLowerCase()"
            :supplier="supplierUrl"
            @part-selected="(part: MeshTreeDataItem | null) => selectedProductInput.selectItem(part, true, true)"
            @mesh-color-selected="colorizeSelectedMesh"
            @mesh-embossing-color-selected="colorizeSelectedEmbossing"
            @mesh-texture-color-selected="colorizeSelectWtransfer"
            @mesh-varnish-selected="handleMultipleWtransfer"
          />
          <navigation-bar
            :workflowModel="workflowModel"
            :productName="selectedName"
          />
        </div>
      </div>
    </div>
    <!-- Product details modal dialog -->
    <div
      class="modal fade"
      id="product-details"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div
            class="modal-header border-bottom-0 bg-transparent"
            id="product-details-modal-header"
          >
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0" id="product-details-modal-body">
            <product-details
              v-if="selectedModel != null && !selectedModel.product.name?.toLowerCase().includes('s-cover')"
              :product="selectedModel.product"
              @button-clicked="() => { hideProductDetailsModalDialog(); showProductParameterModalDialog(selectedModel!); }"
            >
             <template v-if="selectedModel.product?.sampleImagePaths?.length > 0 && selectedModel.product?.type.name !== 'Covers'">
                <div class="block text-center" m="t-4">
                  <span class="demonstration">Switch when indicator is clicked</span>
                  <el-carousel trigger="click" :autoplay="false">
                    <el-carousel-item v-for="(imagePath, index) in selectedModel.product.sampleImagePaths" :key="index">
                   
                      <preview-renderer-img
                        :key="index"
                        :meshes="[
                          {
                            thumbnail: selectedModel.product?.name,
                            thumbnailUrl: imagePath.content,
                          },
                        ]"
                        :imgSize="'auto'"
                      />
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </template>
              <template v-else>
                <preview-renderer-img
                v-if="selectedModel.product"
                :meshes="[
                  {
                    thumbnail: selectedModel.product?.name,
                    thumbnailUrl: convertToUrl(selectedModel),
                  },
                ]"
                imgSize="13.25"
                />
              </template>
            </product-details>
            <product-details
              v-if="selectedModel != null && selectedModel.product.name?.toLowerCase().includes('s-cover')"
              :product="selectedModel.product"
              @button-clicked="() => { hideProductDetailsModalDialog(); showOrderOverview(selectedModel!); }"
            >
             <template v-if="selectedModel.product?.sampleImagePaths?.length > 0 && selectedModel.product?.type.name !== 'Covers'">
                <div class="block text-center" m="t-4">
                  <span class="demonstration">Switch when indicator is clicked</span>
                  <el-carousel trigger="click" :autoplay="false">
                    <el-carousel-item v-for="(imagePath, index) in selectedModel.product.sampleImagePaths" :key="index">
                   
                      <preview-renderer-img
                        :key="index"
                        :meshes="[
                          {
                            thumbnail: selectedModel.product?.name,
                            thumbnailUrl: imagePath.content,
                          },
                        ]"
                        :imgSize="'auto'"
                      />
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </template>
              <template v-else>
                <preview-renderer-img
                v-if="selectedModel.product"
                :meshes="[
                  {
                    thumbnail: selectedModel.product?.name,
                    thumbnailUrl: convertToUrl(selectedModel),
                  },
                ]"
                imgSize="13.25"
                />
              </template>
            </product-details>
          </div>
        </div>
      </div>
    </div>
    <!-- Product parameter modal dialog -->
    <div
      class="modal fade"
      id="product-parameter"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div
            class="modal-header border-bottom-0 bg-transparent"
            id="product-parameter-modal-header"
          >
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0" id="product-parameter-modal-body">
            <product-parameter
              v-if="selectedModel != null"
              :key="selectedModel.product.productId"
              :product="selectedModel.product"
              :is-authenticated="Boolean(authentication?.user)"
              :properties="[orderData]"
              :videoMetadata="videoMetadata"
              :hideInfo="productParameterEmbossing"
              @zip-changed="(zip: string) => zipChanged(zip)"
              @product-updated="({productId, name}) => updateProductListState({productId, name})"
              @update-properties="
                (newProperties) => {
                  // TODOS: Move to function
                  orderData = {
                    ...orderData,
                    ...newProperties,
                  };
                }
              "
              @button-clicked="async (selectedBodyAttribute: { scanFile: UtilityFile | null; skeleton: UtilityFile | null; }) => {
                if(productParameterEmbossing) return update3DModel()
                
                hideProductParameterModalDialog(); 
                selectedScan = selectedBodyAttribute?.scanFile;
                selectedSkeleton = selectedBodyAttribute?.skeleton;
                handleSelectProduct(selectedModel); 
                workflowModel.OnClickNextAction(1);
              }"
            >
              <template v-if="selectedModel && selectedModel.product?.sampleImagePaths?.length > 0 && selectedModel!.product?.type.name !== 'Covers'">
                <div class="block text-center" m="t-4">
                  <span class="demonstration">Switch when indicator is clicked</span>
                  <el-carousel trigger="click" :autoplay="false">
                    <el-carousel-item v-for="(imagePath, index) in selectedModel.product.sampleImagePaths" :key="index">
                   
                      <preview-renderer-img
                        :key="index"
                        :meshes="[
                          {
                            thumbnail: selectedModel.product?.name,
                            thumbnailUrl: imagePath.content,
                          },
                        ]"
                        :imgSize="'auto'"
                      />
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </template>
              <template v-else>
                <preview-renderer-img
                v-if="selectedModel"
                :meshes="[
                  {
                    thumbnail: selectedModel.product?.name,
                    thumbnailUrl: convertToUrl(selectedModel),
                  },
                ]"
                imgSize="13.25"
                />
              </template>
            </product-parameter>
          </div>
        </div>
      </div>
    </div>

    <!-- <button class="btn btn-primary" @click="downloadOfferPDF()">Offer download pdf</button> -->
    <!-- <button class="btn btn-primary" @click="downloadPartListPDF()">PartList download pdf</button> -->
  </div>
  <custom-footer
    :isEditor="
      activeCustomizeProgress === CustomizeProgress.embossing ||
      activeCustomizeProgress === CustomizeProgress.color
    "
    class="p-4"
  />

  <OfferTemplate
    ref="pdfContent"
    :title="title"
    :description="description"
  />
</template>

<script lang="ts">
import ProductCard from '@/components/ProductCard.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch, Ref as RefVue} from 'vue-property-decorator';
import MeshEditor from '@/components/three/MeshEditor.vue';
import { MeshTreeData, MeshTreeDataItem } from '@/types/ui/MeshTreeData';
import * as ProductDbService from '@/services/api/productDbService';
import {
  EmbossingColors,
  ProductColors,
} from '@/services/api/productDbService';
import {
  FileType3D,
  FileTypeImage,
  FileTypeVideo,
  getFileType,
  UploadCategory,
} from '@/types/enum/upload';
import * as THREEMaterial from '@/utils/three/material';
import { cleanUpScene, ObjModel, StlModel } from '@/utils/three/importExport';
import * as THREE from 'three';
import { Color } from 'three';

import {
  BalconyType,
  BodySide,
  MountingType,
  PaneelType,
  ZipType,
  FenceType,
  OrientationType,
  MountingProfileType,
  LightColorType,
  LightIntensityType,
  ProfileColor,
  IPRatingType,
  LightCoverType,
  BathtubType,
  PanelVariant
} from '@/types/enum/order';
import { ElMessage, ElMessageBox } from 'element-plus';
import type {
  UploadFile,
  UploadStatus,
} from 'element-plus/es/components/upload/src/upload';
import * as LayoutUtility from '@/utils/layout';
import { deleteFileFromAzure, deleteFileMetadataToAzureByProudctId, fileContentToBase64, getFileMetadataToAzureByProudctId, TableField } from '@/utils/file';
import ValidationForm from '@/components/element-plus/ValidationForm.vue';
import * as OrderService from '@/services/api/orderService';
import PreviewRenderer from '@/components/three/PreviewRenderer.vue';
import PreviewRendererImg from '@/components/three/PreviewRendererImg.vue';
import Workflow from '@/components/workflow/Workflow.vue';
import { WorkflowModel } from '@/types/ui/WorkflowModel';
import { CustomizeProgress } from '@/types/enum/workflow';
import ToggleSidebar from '@/components/element-plus/ToggleSidebar.vue';
import * as THREEEnum from '@/types/enum/three';
import { DefaultUndoRedo } from '@/types/ui/HistoryList';
import * as modelService from '@/services/api/modelService';
import * as customerService from '@/services/api/customerService';
import { Model, Model2 } from '@/types/api/Model/Model';
import * as TemplateService from '@/services/api/templateService';
import { ProductQuality } from '@/types/enum/template';
import { Color as AttributeColor } from '@/types/api/Model/Attributes/Color';
import { RGB } from '@/types/api/Utility/RGB';
import { Palette } from '@/types/api/Utility/Palette';
import { File as UtilityFile } from '@/types/api/Utility/File';
import { Component } from '@/types/api/Model/Component';
import { Engraving } from '@/types/api/Model/Attributes/Engraving';
import { User } from '@/types/api/User/User';
import * as UserService from '@/services/api/userService';
import { Reseller } from '@/types/api/User/Reseller';
import Auth from '@/services/api/auth';
import { Print } from '@/types/api/Model/Attributes/Print';
import { Modal } from 'bootstrap';
import ProductDetails from '@/components/ProductDetails.vue';
import ProductParameter from '@/components/productParameters/ProductParameter.vue';
import EmbossingFooter from '@/components/three/EmbossingFooter.vue';
import ColorFooter from '@/components/three/ColorFooter.vue';
import { inject, type Ref, ref } from 'vue';
import { KEY_CURRENT_STEP } from '@/utils/keys';
import OrderForm from '@/components/OrderForm.vue';
import NavigationBar from '@/components/NavigationBar.vue';
import OrderSummary from '@/components/OrderSummary.vue';
import CustomHeader from '@/components/CustomHeader.vue';
import CustomFooter from '@/components/CustomFooter.vue';
import { Supplier } from '@/types/api/User/Supplier';
import { waitFor } from '@/utils/three/webGlContext';
import { Side } from '@/types/api/Patient/Side';
import { ApiResponse } from '@/types/api/Utility/ApiResponse';
import { Size as UtilitySize } from '@/types/api/Utility/Size';
import * as FormBuilderService from '@/services/api/formBuilderService';
import * as THREECamera from '@/utils/three/camera';
import { unit_type } from '@/types/api/Model/UnitType';
import { Size } from '@/types/api/Model/Attributes/Size';
import {
  SupportedValues,
  SupportedValuesTextures,
} from '@/types/api/Utility/SupportedValues';
import { BlobServiceClient } from '@azure/storage-blob';
import { cloneDeep } from '@apollo/client/utilities';
import OfferTemplate from '@/components/generatePDF/OfferTemplate.vue'

//#region interfaces
interface UploadData {
  name: string;
  url: string;
  uid: number;
  status?: UploadStatus;
  base64?: string;
}

interface ImageData {
  uuid: string;
  name: string;
  url: string;
  thumbnailUrl: string;
}

interface Embossing {
  name: string;
  colors: string[];
}

interface MeshConfig {
  filename: string;
  colors: { [name: string]: string[] } | undefined;
  embossing: Embossing[] | undefined;
  wtransfer: string[] | undefined;
  thumbnail: string;
}

interface ProductConfig {
  type: string;
  name: string;
  thumbnail: string;
  meshes: MeshConfig[];
}

interface OrderData {
  technicianFirstname: string;
  technicianLastname: string;
  technician: string;
  company: string;
  customerNr: string;
  orderNr: string;
  billingNr: string;
  commissionNo: string;
  vrNumber: string;
  vgNumber: string;
  vsgNumber: string;
  mail: string;
  coverModel: string;
  prothesisType: string;
  bodySide: string;
  kneeJoint: string;
  foot: string;
  zip: string;
  sewInByCustomer: boolean;
  cosmeticEndingAtFoot: boolean;
  design: string;
  individualDesign: boolean;
  designFiles: UploadData[];
  heightWithoutDeduction: number;
  heightDeduction: number;
  heightWithDeduction: number;
  depth: number;
  depthRight: number;
  calfCircumferenceWithoutDeduction: number;
  calfCircumferenceDeduction: number;
  calfCircumferenceWithDeduction: number;
  ankleCircumference: number;
  imageFiles: UploadData[];
  scanFiles: UploadData[];
  remarks: string;
  isPrivacy: boolean;
  privacy: string;
  balconyType: string;
  mountingType: string;
  paneelType: string;
  orientationType: string;
  fenceType: string;
  color: string;
  mountingProfileType: string;
  lightCoverType: string;
  iPRatingType: string;
  lightColorType: string;
  lightIntensityType: string;
  vFour: boolean;
  eloxed: boolean;
  lightLength: number;
  bathtubType: string;
  wallpanels: boolean;
  antiSlip: boolean;
  panelVariant: string;
  singleType: string[];
  comboType: string;
  panelWidth: number;
  angleRight: number;
  angleLeft: number;
  varnish: string;
}

//#endregion interfaces

@Options({
  methods: { ref },
  components: {
    OrderSummary,
    NavigationBar,
    OrderForm,
    ColorFooter,
    EmbossingFooter,
    ProductDetails,
    ProductParameter,
    ProductCard,
    PreviewRenderer,
    PreviewRendererImg,
    ValidationForm,
    MeshEditor,
    ObjModel,
    StlModel,
    Workflow,
    ToggleSidebar,
    CustomFooter,
    CustomHeader,
    OfferTemplate,
  },
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class CustomizeWorkflow extends Vue implements DefaultUndoRedo {
  @Prop({ default: null }) productTypeId!: number | null;
  @Prop({ default: null }) supplierUrl!: string | null;

  @Prop({ default: null }) productId!: number | null;
  @Prop({ default: null }) embossingId!: number | number[] | null;
  @Prop({ default: null }) wtransferId!: number | number[] | null;
  @Prop({ default: null }) colorId!: number | number[] | null;
  @Prop({ default: null }) embossingColorId!: number | number[] | null;

  authentication = Auth.getInstance();
  //#region properties
  productConfigurationDB: ProductConfig[] = [];
  productDB: ProductDbService.ProductData[] = [];
  selectedProductId = '';
  selectedEmbossingContent = '';
  selectedModelId: number | null = null;
  selectedProductInput: MeshTreeData = new MeshTreeData(null, this);
  selectedModel: Model2 | null = null;
  colors: { [name: string]: string[] } = ProductColors;
  embossingDB: ImageData[] = [];
  wtransferDB: ImageData[] = [];
  fullscreenLoading = false;
  productsLoading = false;
  meshEditorLoading = false;
  footerLoading = false;
  currentPage = 1;
  dataLoaded = false;
  width = window.innerWidth;
  lastUpdateDB = -1;
  transparentColor = '#d3d3d3';
  transparentColorRgb = {
    name: 'none',
    red: 211,
    green: 211,
    blue: 211,
  } as RGB;
  blankFileName = 'blank';
  automaticSize = false;
  selectedProductQuality = ProductQuality.low.toString();
  sendingOrder = false;

  zipperColors: RGB[] = [
    { name: 'white', red: 255, green: 255, blue: 255 } as RGB,
    { name: 'beige', red: 242, green: 231, blue: 191 } as RGB,
    { name: 'black', red: 40, green: 42, blue: 42 } as RGB,
    { name: 'gray', red: 136, green: 140, blue: 136 } as RGB,
  ];

  colorListLoading = new Map<string, boolean>();
  embossingListLoading = new Map<string, boolean>();
  wtransferListLoading = new Map<string, boolean>();
  sizeListLoading = new Map<string, boolean>();

  abortController = new AbortController();

  modelList: Model2[] = [];
  selectedScan: UtilityFile | null = null;
  selectedSkeleton: UtilityFile | null = null;

  loadingOfferPdf = false;

  loadedScan: Component | null = null;

  workflowModel: WorkflowModel = new WorkflowModel(
    'customize-progress',
    CustomizeProgress,
    this.showProductParameterModalDialog.bind(this)
  );
  CustomizeProgress = CustomizeProgress;
  reseller: Reseller | null = null;
  user: User | null = null;
  filteredBalconyModels: Model2[] | null = null;
  filteredFenceModels: Model2[] | null = null;
  filteredLighModels: Model2[] | null = null;
  filteredBathtubModels: Model2[] | null = null;

  BodySide = BodySide;
  FileType = FileType3D;
  ZipType = ZipType;
  ProductQuality = ProductQuality;
  getFileType = getFileType;
  productList: Model2[] = [];

  THREEEnum = THREEEnum;

  embossingColors: string[] = EmbossingColors;

  orderData: OrderData = {
    technicianFirstname: '',
    technicianLastname: '',
    technician: '',
    company: this.getCompanyDomain(),
    customerNr: '',
    orderNr: '',
    billingNr: '',
    commissionNo: '',
    vrNumber: '',
    vgNumber: '',
    vsgNumber: '',
    mail: '',
    coverModel: '',
    prothesisType: '',
    bodySide: 'Right',
    kneeJoint: '',
    foot: '',
    zip: 'yes',
    sewInByCustomer: false,
    cosmeticEndingAtFoot: false,
    balconyType: BalconyType.uForm,
    mountingType: MountingType.front,
    individualDesign: false,
    design: '',
    designFiles: [],
    heightWithoutDeduction: 1015,
    heightDeduction: -1,
    heightWithDeduction: 49,
    depth: 1000,
    depthRight: 1000,
    calfCircumferenceWithoutDeduction: 4000,
    calfCircumferenceDeduction: -2,
    calfCircumferenceWithDeduction: 48,
    ankleCircumference: 50,
    imageFiles: [],
    scanFiles: [],
    remarks: '',
    isPrivacy: false,
    privacy: '',
    paneelType: PaneelType.landscape,
    orientationType: OrientationType.south,
    fenceType: FenceType.uForm,
    color: ProfileColor.black,
    mountingProfileType: MountingProfileType.lltwentyfive,
    lightCoverType: LightCoverType.versionOne,
    iPRatingType: IPRatingType.iPtwenty,
    lightColorType: LightColorType.threethousandK,
    lightIntensityType: LightIntensityType.six,
    vFour: false,
    eloxed: false,
    lightLength: 5,
    bathtubType: BathtubType.relax,
    wallpanels: false,
    antiSlip: false,
    panelVariant: PanelVariant.single,
    singleType: [],
    comboType: 'right',
    panelWidth: 210,
    angleRight: 90,
    angleLeft: 90,
    varnish: 'none'
  };

  screenshotSrc: string[] = [];

  private productDetailsModalDialog: any = null;
  private productParameterModalDialog: any = null;
  private isLoadingButtonPartList = false; 
  private videoMetadata: TableField = {
    productId: '',
    filename: '',
    fileType: FileTypeVideo.WEBM,
    fileUrl: '',
  }
  productParameterEmbossing = false

  getCompanyDomain() {
  try {
    if (this.authentication?.domains && this.authentication.domains.length > 0) {
      const urlSuffix = this.authentication.domains[0].urlSuffix;
      return urlSuffix.charAt(0).toUpperCase() + urlSuffix.slice(1);
    } else {
      return '';
    }
  } catch (error) {
    return '';
  }
}

  //#endregion properties

  /*meshBoundingBox(axis: THREEEnum.AxisType): number {
    if (this.selectedProductInput?.getSelection()[0]?.mesh) {
      const bbox = new THREE.Box3().setFromObject(this.selectedProductInput?.getSelection()[0]?.mesh);
      switch(axis) {
        case THREEEnum.AxisType.x:
          return bbox.max.x - bbox.min.x;
        case THREEEnum.AxisType.y:
          return bbox.max.y - bbox.min.y;
        case THREEEnum.AxisType.z:
          return bbox.max.z - bbox.min.z;
      }      
    }
    return 1;
  }*/

  // Referensi ke komponen child
  @RefVue('orderSummary') orderSummaryRef!: OrderSummary; // Ref for OrderSummary component

  // Create Offer Pdf Content
  downloadOfferPDF(): void {
    if (!this.orderSummaryRef) return;
    this.orderSummaryRef.downloadOfferPDF(); // Call method `downloadOfferPDF` in OrderSummary component
  }

  // Create Part List Pdf Content
  downloadPartListPDF(): void {
    if (!this.orderSummaryRef) return;
    this.orderSummaryRef.downloadPartListPDF(); // Call method `downloadOfferPDF` in OrderSummary component
  }

  getCalculatingAmortisation(isLoading: boolean): void {
    this.isLoadingButtonPartList = isLoading; // Updated state isLoadingButtonPartList
  }

  @Watch('isLoadingButtonPartList')
  onIsLoadingChanged(newValue: boolean) {
    console.log('Parent detected isLoading change:', newValue);
    // Additional actions if needed when isLoading changes...
  }


  get meshHeight() {
    return this.orderData.heightWithoutDeduction;
    /*const num = this.selectedProductInput?.getSelection()[0]?.mesh?.scale?.y * 50;
    return Math.round(num * 100) / 100;*/
  }

  set meshHeight(value: number) {
    this.orderData.heightWithoutDeduction = value;
    /*this.selectedProductInput.treeData.forEach(function (data) {
      const mesh = data.mesh;
      mesh.scale.set(mesh.scale.x, value / 50, mesh.scale.z);
    });*/
  }

  get meshWidth() {
    return this.orderData.calfCircumferenceWithoutDeduction;
    /*const num = this.selectedProductInput?.getSelection()[0]?.mesh?.scale?.z * 50;
    return Math.round(num * 100) / 100;*/
  }

  set meshWidth(value: number) {
    this.orderData.calfCircumferenceWithoutDeduction = value;
    /*this.selectedProductInput.treeData.forEach(function (data) {
      const mesh = data.mesh;
      mesh.scale.set(mesh.scale.x, mesh.scale.y, value / 50);
    });*/
  }

  get meshCircumreference() {
    return this.orderData.ankleCircumference;
    /*const num = this.selectedProductInput?.getSelection()[0]?.mesh?.scale?.x * 50;
    return Math.round(num * 100) / 100;*/
  }

  set meshCircumreference(value: number) {
    this.orderData.ankleCircumference = value;
    /*this.selectedProductInput.treeData.forEach(function (data) {
      const mesh = data.mesh;
      mesh.scale.set(value / 50, mesh.scale.y, mesh.scale.z);
    });*/
  }

  get activeCustomizeProgress(): CustomizeProgress {
    if (this.workflowModel.active < Object.keys(CustomizeProgress).length) {
      LayoutUtility.refresh();
      const progress =
        Object.values(CustomizeProgress)[this.workflowModel.active];

      if (progress == CustomizeProgress.overview) {
        this.workflowModel.OnClickNextAction = this.confirmOrder;
      } else {
        this.workflowModel.OnClickNextAction =
          this.workflowModel.changeActiveStep;
      }

      return progress;
    }
    return CustomizeProgress.product;
  }

  set activeCustomizeProgress(value: CustomizeProgress) {
    this.workflowModel.active = Object.values(CustomizeProgress).indexOf(value);
  }

 async deleteFormBuilderAzureFiles(productId){
  return new Promise((resolve, reject) => {
    FormBuilderService.getProductFormBuilder(productId)
      .then((res) => {
        const parsedFields = JSON.parse(res || '[]');
        parsedFields.forEach(field => {
          if(field.image && field.imageType === 'file') deleteFileFromAzure(field.image)
          if(field.options){
            field.options.forEach(opt => {
              if(opt.image && opt.imageType === 'file') deleteFileFromAzure(opt.image)
            });
          }
        });
        resolve(1)
      })
      .catch((err) => {
        reject(0)
        console.error('err delete azure form builder', err);
      });
    })
  }

  onDeleteProduct(model: Model2) {
    ElMessageBox.confirm(
      (this as any).$t('confirm.delete.message'),
      (this as any).$t('confirm.delete.title'),
      {
        confirmButtonText: (this as any).$t('enum.yesNoType.true'),
        cancelButtonText: (this as any).$t('enum.yesNoType.false'),
        type: 'warning',
      }
    )
      .then(() => {
        ElMessageBox.confirm(
          (this as any).$t('confirm.historyDelete.message'),
          (this as any).$t('confirm.historyDelete.title'),
        {
          confirmButtonText: (this as any).$t('confirm.delete.ok'),
          cancelButtonText: (this as any).$t('confirm.delete.cancel'),
          type: 'warning',
        }
      ).then(() => {
        modelService.deleteModel(model.modelId).then(async(result) => {
          if (!result) {
            ElMessage.error((this as any).$t('error.api.delete'));
          } else {
            ElMessage.success((this as any).$t('success.api.delete'));

            const newProductList = this.productList.filter(
              (p) => p.product.productId !== model.product.productId
            );
            this.productList = newProductList;
            try {
              await this.deleteFormBuilderAzureFiles(model.product.productId)
              FormBuilderService.deleteProductFormBuilder(model.product.productId)
              .then((res) => {
                console.log('deleted form builder status:', res);
              })
              .catch((err) => {
                console.error('err', err);
              });
            } catch (error) {
              console.log('error delete formbuilder product',error)
            }

            // Delete video metadata from azure tables storage 
            await deleteFileMetadataToAzureByProudctId(model.product.productId.toString())
          }
        });
      }).catch((err) => {
        console.error('err', err);
      });
      })
    .catch((err) => {
      console.error('err', err);
    });
  }

  getMeshColorPalettes(selection: MeshTreeDataItem | null = null): Palette[] {
    if (selection == null) {
      selection = this.getActivePart();
    }
    if (selection?.colors?.supportedValues) {
      return selection.colors.supportedValues;
    }
    return [];
  }

  getAllColors(treeData: MeshTreeDataItem | null = null) {
    if (treeData == null) {
      treeData = this.getActivePart();
    }
    const result: RGB[] = [];
    for (const colorCategory of this.getMeshColorPalettes(treeData)) {
      for (const color of colorCategory.supportedColors) {
        result.push(color);
      }
    }

    return result;
  }
  /*
  getMeshEngravings(selection: MeshTreeDataItem | null = null): UtilityFile[] {
    if (selection == null) {
      selection = this.getActivePart();
    } 
    if (selection?.engraving?.supportedValues) {     
      return selection.engraving.supportedValues
        .map((x) => x.file)
        .sort((a, b) => (a.name == 'blank' ? -1 : b.name == 'blank' ? 1 : 0));
    }
    return [];
  }*/

  getMeshEngravings2(
    selection: MeshTreeDataItem | null = null
  ): SupportedValues[] {
    if (selection == null) {
      selection = this.getActivePart();
    }
    if (selection?.engraving?.supportedValues) {
      const blankFile = selection.engraving.supportedValues
        .map((item) => item.thumbnail)
        .find((th) => th?.name === 'blank');

      /* const supportedValues: SupportedValues[] = selection.engraving.supportedValues.map((item) => ({
      fileId: item.file.fileId,
      fileTextureId: item.fileTextureId,
      thumbnail: (item.thumbnail?.name === 'blank'||item.thumbnail?.name === '' || item.thumbnail?.name === null) ? blankFile : item.thumbnail
    }))
    .sort((a, b) => (a.thumbnail?.name == 'blank' ? -1 : b.thumbnail?.name == 'blank' ? 1 : 0));*/
      const supportedValues: SupportedValues[] =
        selection.engraving.supportedValues
          .map((item) => ({
            fileId: item.file.fileId,
            fileTextureId: item.fileTextureId,
            thumbnail:
              item.thumbnail?.name === 'blank' ||
              item.thumbnail?.name === '' ||
              item.thumbnail?.name === null
                ? blankFile
                : item.thumbnail,
          }))
          .sort((a, b) => {
            const nameA = a.thumbnail?.name?.toLowerCase() || '';
            const nameB = b.thumbnail?.name?.toLowerCase() || '';

            if (nameA === 'blank' && nameB !== 'blank') return -1;
            if (nameA !== 'blank' && nameB === 'blank') return 1;

            if (nameA !== 'blank' && nameB !== 'blank') {
              return nameA.localeCompare(nameB);
            }
            return 0; // When both names are 'blank' or both are empty, they are considered equal
          });
          console.log("inside MeshEngravings2: " + supportedValues);
      return supportedValues;
    }

    return [];
  }

  /*getMeshTextures(selection: MeshTreeDataItem | null = null): UtilityFile[] {
    if (selection == null) {
      selection = this.getActivePart();
    }
    if (selection?.texture?.supportedValues) {
      return selection.texture.supportedValues
        .map((x) => x.file)
        .sort((a, b) => (a.name > b.name ? 1 : -1));
    }
    return [];
  }*/

  getMeshTextures2(
    selection: MeshTreeDataItem | null = null
  ): SupportedValuesTextures[] {
    if (selection == null) {
      selection = this.getActivePart();
    }
    if (selection?.texture?.supportedValues) {
      const supportedValues: SupportedValuesTextures[] =
        selection.texture.supportedValues
          .map((item) => ({
            fileId: item.file.fileId,
            fileName: item.file.name,
            fileContent: item.file.content,
            fileTextureId: item.fileTextureId,
            thumbnail: item.file,
          }))
          .sort((a, b) => (a.fileName > b.fileName ? 1 : -1));
          console.log("inside MeshTextures2: " + supportedValues);
      return supportedValues;
    }
    return [];
  }

  getMeshEngravingColors(selection: MeshTreeDataItem | null = null): RGB[] {
    if (selection == null) {
      selection = this.getActivePart();
    }
    const texture = selection?.engraving?.supportedValues?.find(
      (x) => x.file.fileId == this.selectedEmbossing
    );
    if (texture && texture.palette) {
      const colors: RGB[] = [];
      for (const color of texture.palette.supportedColors) {
        colors.push(color);
      }
      colors.unshift(this.transparentColorRgb);
      return colors;
    }
    return [];
  }

  getMeshTextureColors(selection: MeshTreeDataItem | null = null): RGB[] {
    if (selection == null) {
      selection = this.getActivePart();
    }
    const texture = selection?.texture?.supportedValues?.find(
      (x) => x.file.fileId == this.selectedWtransfer
    );
    if (texture && texture.palette) {
      const colors: RGB[] = [];
      for (const color of texture.palette.supportedColors) {
        colors.push(color);
      }
      return colors.sort((a, b) => a?.name.localeCompare(b?.name));
    }
    return [];
  }

  get isAutomaticScanActive(): boolean {
    return (
      this.selectedScan != null &&
      this.selectedSkeleton != null &&
      this.selectedScan.fileId != null &&
      this.selectedSkeleton.fileId != null
    );
  }
  //#region load
  mounted(): void {
    LayoutUtility.refresh();
    if (!this.productTypeId) {
      this.$router.replace(`/page/not-found`);
    }
    this.fullscreenLoading = true;
    this.productsLoading = true;
    this.authentication.handleAuthentication(this.supplierUrl).then(() => {
      //get user information
      this.user = this.authentication.user;
      const domain = this.authentication.primaryDomain;
      if (this.user) {
        modelService
          .getModelsByUserId(this.user.userId, this.productTypeId)
          .then((models) => {
            if (models) {
              const mList = models.filter((item) => !item.basedOn);
              this.modelList = mList;
              this.getProducts(mList);
            } else {
              this.getProducts([]);
              this.modelList = [];
            }
            this.dataLoaded = true;
            this.checkParameters();
          })
          .finally(() => {
            this.productsLoading = false;
          });

        this.orderData.technicianFirstname = this.user.firstname;
        this.orderData.technicianLastname = this.user.lastname;
        this.orderData.mail = this.user.email;
      } else if (domain) {
        modelService
          .getModelsByUrlSuffix(this.supplierUrl!, this.productTypeId)
          .then((models) => {
            if (models) {
              const mList = models.filter((item) => !item.basedOn);
              this.modelList = mList;
              this.getProducts(mList);
            } else {
              this.getProducts([]);
              this.modelList = [];
            }
            this.dataLoaded = true;
            this.checkParameters();
          })
          .finally(() => {
            this.productsLoading = false;
          });
      } else {
        this.$router.push(`/page/not-found`);
      }

      const modalDialogOptions = {};
      this.productDetailsModalDialog = new Modal(
        '#product-details',
        modalDialogOptions
      );
      this.productParameterModalDialog = new Modal(
        '#product-parameter',
        modalDialogOptions
      );
    });
  }

  checkParameters() {
    if (this.productId && this.productId < this.modelList.length) {
      this.handleSelectProduct(this.modelList[this.productId]);
      this.workflowModel.OnClickNextAction(1);
    }

    if (this.embossingId) {
      this.workflowModel.active = 2;
    } else if (this.wtransferId) {
      this.workflowModel.active = 1;
    }
  }

  getProducts(models: Model2[]) {
    const productList: Model2[] = [];
    for (const model of models) {
      if (
        productList.findIndex(
          (pt) => pt.product.productId == model.product.productId
        ) === -1
      ) {
        const newModel = cloneDeep(model)
        const sampleImagePaths = model.product.sampleImagePaths?.map(s=>s.content)
        const thumbnailContent = model.product.thumbnail.content
        if(!sampleImagePaths?.includes(thumbnailContent) && sampleImagePaths){
          newModel.product = {
            ...model.product,
            sampleImagePaths:[...model.product.sampleImagePaths, model.product.thumbnail]
          } as any
        }
        productList.push(newModel);
      }
    }
    this.productList = productList;
  }
  
  created(): void {
    window.addEventListener('resize', this.resize);
  }

  resize(): void {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  showProductDetailsModalDialog(model?: Model2): void {
    if (model != null) {
      this.selectedModel = model;
    }
    this.productDetailsModalDialog.show();
  }

  hideProductDetailsModalDialog(): void {
    this.productDetailsModalDialog.hide();
  }

  async showProductParameterModalDialog(model: Model2, isEmbossing?:boolean): Promise<void> {
    if (model != null) {
      
      this.selectedModel = model;
      // Get video metadata from Azure Tables Storage ... 
      if(model.product.productId.toString() !== this.videoMetadata.productId) {
        const metadataFiles = await getFileMetadataToAzureByProudctId(model.product.productId.toString())
        const video = metadataFiles?.find(item => Object.values(FileTypeVideo).includes(item.fileType as FileTypeVideo))
        if(video) this.videoMetadata = video;
      }
      
    }

    this.productParameterModalDialog.show();
    this.productParameterEmbossing = Boolean(isEmbossing)
  }

  showOrderOverview(model: Model2): void {
    this.handleSelectProduct(model);
    this.activeCustomizeProgress = CustomizeProgress.form;
  }

  hideProductParameterModalDialog(): void {
    this.productParameterModalDialog.hide();
  }

  update3DModel(): void{
    // TODOS: HIT API TO UPDATE 3D MODEL
    console.log('SELECTED MODEL',this.selectedModel)
    console.log('ORDER DATA',this.orderData)
    this.productParameterEmbossing = false
    this.hideProductParameterModalDialog()
  }

  loadFromDB(): void {
    this.selectedProductInput.clear();
    this.meshEditorLoading = true;
    //this.footerLoading = true;
    //this.abortController = new AbortController();

    if (this.selectedModelId) {
      if (this.isAutomaticScanActive) {
        modelService
          .automaticAdaption(
            this.selectedModel!.product.productId,
            this.selectedScan!.fileId!,
            this.selectedSkeleton!.fileId!,
            {
              height: this.orderData.heightWithoutDeduction,
              depth: this.orderData.depth,
              width: 0,
            } as UtilitySize,
            0.0,
            false,
            this.productQualityToInt(this.selectedProductQuality),
            Side[this.orderData.bodySide]
          )
          .then(async (model) => {
            this.selectedModel = model;
            this.selectedModelId = model.modelId;
            await this.loadScan();
            this.loadTemplate(model);
          });
      } else if (this.selectedModel?.product.type?.name?.toLowerCase() == 'balkone') {
        this.generateBalconyModel();
      } else if (this.selectedModel?.product.type?.name?.toLowerCase() == 'zäune') {
        this.generateFenceModel();
      } else if (this.selectedModel?.product.name.toLowerCase() == 'abgehängt') {
        this.generateSuspendedLightModel();
      } else if (this.selectedModel?.product.type?.name?.toLowerCase() == 'duschen und bäder') {
        this.generateBathtubModel();
      } else {
        this.loadTemplateFromDB();
      }
    }
  }

  loadTemplateFromDB(): void {
    this.meshEditorLoading = true;
    this.abortController = new AbortController();
    this.selectedProductInput.clear();
    TemplateService.getTemplateData(
      this.selectedModelId!,
      ProductQuality[this.selectedProductQuality],
      this.abortController
    )
      .then((templateData) => {
        this.loadTemplate(templateData);
      })
      .catch(() => {
        this.meshEditorLoading = false;
      });
  }

  loadTemplate(templateData: Model2) {
    this.meshEditorLoading = true;
    if (this.selectedModelId) {
      this.selectedModel = templateData;
      if (templateData && templateData?.components?.length > 0) {
        const loadChildren = (
          parent: string | MeshTreeDataItem | null,
          children: Component[]
        ): void => {
          for (const child of children) {
            const level = Object.keys(ProductQuality).indexOf(
              this.selectedProductQuality
            );
            const data = modelService.parseToMeshExportData(
              child,
              child.resolutions[level] ?? child.resolutions[0]
            );
            let treeData!: MeshTreeDataItem;
            if (data.fileType !== FileType3D.NONE) {
              treeData = this.selectedProductInput.addBase64Import(
                data.uniqueKey,
                data.componentId,
                data.name,
                data.fileType,
                data.base64,
                data.thumbnailType,
                data.thumbnail,
                parent,
                false,
                data.color,
                data.position,
                data.rotation,
                data.scale
              );
              treeData.bones = data.bones.map((boneInfo) => {
                const bone = new THREE.Bone();
                bone.name = boneInfo.name;
                bone.position.copy(boneInfo.position);
                return bone;
              });
            } else {
              treeData = this.selectedProductInput.addGroup(
                data.name,
                [],
                data.position,
                data.rotation,
                data.scale,
                parent
                  ? parent
                  : (this as any).$t(`enum.upload-category.${data.category}`),
                false
              );
              treeData.id = data.uniqueKey;
            }
            loadChildren(
              treeData,
              templateData.components.filter(
                (item) => item.parent?.uuid === data.uniqueKey
              )
            );
          }
        };
        loadChildren(
          null,
          templateData.components.filter((item) => item.parent === null)
        );

        if (this.loadedScan) {
          loadChildren(null, [this.loadedScan]);
        }
      }
    }

    //this.meshEditorLoading = false;
    this.loadAllAttributeListDB();
  }

  privacyChanged(): void {
    if (this.orderData.isPrivacy) {
      this.orderData.privacy = 'yes';
    } else {
      this.orderData.privacy = '';
    }
  }

  componentToHex(c: number): string {
    const hex = c.toString(16);
    return hex.length == 1 ? '0' + hex : hex;
  }

  rgbToHexString(value: RGB): string {
    return (
      '#' +
      this.componentToHex(value.red) +
      this.componentToHex(value.green) +
      this.componentToHex(value.blue)
    );
  }

  get productCount(): number {
    return this.productDB.length;
  }

  get productPagesCount(): number {
    return Math.ceil(this.modelList.length / this.productsPerPage);
  }

  @Watch('window.innerWidth', { immediate: true })
  getProductsForPage(page: number): Model2[] {
    return this.modelList.slice(
      (page - 1) * this.productsPerPage,
      page * this.productsPerPage
    );
  }

  get productsPerPage(): number {
    return 50;
  }

  @Watch('selectedProductInput.lastUpdateVisibility', { immediate: true })
  selectionChanged(): void {
    //this.loadColorListDB();
    //this.loadEmbossingListDB();
    //this.loadWtransferListDB();
  }

  async loadAllAttributeListDB(): Promise<void> {
    this.embossingDB.length = 0;

    for (const component of this.selectedProductInput.treeData) {
      this.colorListLoading.set(component.uuid, false);
      await this.loadColorListDB(component);
      this.embossingListLoading.set(component.uuid, false);
      await this.loadEmbossingListDB(component);
      this.wtransferListLoading.set(component.uuid, false);
      await this.loadWtransferListDB(component);
      this.sizeListLoading.set(component.uuid, false);
      await this.loadSizeListDB(component);
    }
  }

  async refetchEmbossingListDB(){
    for (const component of this.selectedProductInput.treeData) {
      this.loadEmbossingListDB(component, true)
    }
  }

  async refetchWTransferListDB(){
    for (const component of this.selectedProductInput.treeData) {
      this.loadWtransferListDB(component, true)
    }
  }

  async loadEmbossingListDB(
    component: MeshTreeDataItem | null = null,
    shouldRefetch = false
  ): Promise<void> {
    this.embossingDB.length = 0;
    if (component == null) {
      component = this.getActivePart();
    }
    if ((component && component.componentId && !component.engraving && !this.embossingListLoading.get(component.uuid)) || (shouldRefetch && component && component.componentId)
    ) {
      if (this.activeCustomizeProgress === CustomizeProgress.embossing) {
        this.footerLoading = true;
      }
      const engraving = await TemplateService.getEmbossingData(
        component.componentId,
        shouldRefetch
      );
      if (engraving) {
        component.engraving = engraving;
        if (component.engraving?.engravingFile) {
          this.handleSelectEmbossing(
            component.engraving?.engravingFile,
            component
          );
        }
        this.embossingListLoading.set(component.uuid, true);
      } else {
        if (component) this.embossingListLoading.set(component.uuid, false);
      }
      this.footerLoading = false;
    }
  }

  async loadColorListDB(
    component: MeshTreeDataItem | null = null
  ): Promise<void> {
    this.embossingDB.length = 0;
    if (component == null) {
      component = this.getActivePart();
    }
    if (
      component &&
      component.componentId &&
      !component.colors &&
      !this.colorListLoading.get(component.uuid)
    ) {
      if (this.activeCustomizeProgress === CustomizeProgress.color) {
        this.footerLoading = true;
      }
      const colors = await TemplateService.getColorData(component.componentId);
      if (colors) {
        component.colors = colors;
        if (component.colors?.value) {
          this.colorizeSingleMesh(component.colors?.value, component);
        }
        this.colorListLoading.set(component.uuid, true);
      } else {
        if (component) this.colorListLoading.set(component.uuid, false);
      }
      this.footerLoading = false;
    }
  }

  async loadSizeListDB(
    component: MeshTreeDataItem | null = null,
  ): Promise<void> {
    this.embossingDB.length = 0;
    if (component == null) {
      component = this.getActivePart();
    }
    if (
      component &&
      component.componentId &&
      !component.size &&
      !this.sizeListLoading.get(component.uuid)
    ) {
      const size = await TemplateService.getSizeData(component.componentId);
      if (size) {
        component.size = size;
        this.sizeListLoading.set(component.uuid, true);
      } else {
        if (component) this.sizeListLoading.set(component.uuid, false);
      }
    }
  }
  
  async loadWtransferListDB(
    component: MeshTreeDataItem | null = null,
    shouldRefetch = false
  ): Promise<void> {
    this.wtransferDB.length = 0;
    if (component == null) {
      component = this.getActivePart();
    }
    if ((component && component.componentId && !component.texture && !this.wtransferListLoading.get(component.uuid)) || (shouldRefetch && component && component.componentId)) {
      if (this.activeCustomizeProgress === CustomizeProgress.embossing) {
        this.footerLoading = true;
      }
      const texture = await TemplateService.getTextureData(
        component.componentId,
        shouldRefetch
      );
      if (texture) {
        component.texture = texture;
        if (component.texture?.textureFile) {
          this.handleSelectWtransfer(component.texture.textureFile, component);
        }
        this.wtransferListLoading.set(component.uuid, true);
      } else {
        if (component) {
          this.wtransferListLoading.set(component.uuid, false);
        }
      }

      this.footerLoading = false;
    }
  }

  //#endregion load

  //#region selection
  handleSelectProduct(model: Model2 | null): void {
    if (model) {
      this.selectedModelId = model.modelId;
      this.selectedModel = model;
      this.loadFromDB();
    } else {
      this.selectedProductId = '';
      this.selectedProductInput.clear();
      const troisRenderer: any = this.$refs.troisRenderer;
      const three: any = troisRenderer.three;
      cleanUpScene(three.scene);
    }
  }

  get hasSelectedMesh(): boolean {
    for (const item of this.selectedProductInput.treeData) {
      if (item.isSelected) {
        return true;
      }
    }
    return false;
  }

  get selectedName(): string {
    if (this.selectedModel?.product) {
      return this.selectedModel.product?.name;
    }
    return '';
  }

  get selectedConfig(): ProductConfig | null {
    const product = this.productDB.find(
      (item) => item.id === this.selectedProductId
    );
    if (product) {
      const filename = product.mesh[0].filename;
      const name = product?.name;
      const config = this.productConfigurationDB.find(
        (item) => item.meshes[0].filename === filename && item?.name === name
      );
      if (config) return config;
    }
    return null;
  }

  getActivePart(): MeshTreeDataItem | null {
    const selection = this.selectedProductInput.getSelection();
    /*const data =
      selection.length > 0 ? selection : this.selectedProductInput.treeData;*/
    if (selection.length > 0) return selection[0];
    return null;
  }
  //#endregion selection

  get selectedColor(): RGB | null {
    const treeData = this.getActivePart();
    if (treeData) {
      if (treeData.colors) return treeData.colors.value;
    }
    return null;
  }

  colorizeSelectedMesh(
    color: RGB,
    treeData: MeshTreeDataItem | null = null
  ): void {
    if (!treeData) {
      treeData = this.getActivePart();
    }
    if (
      this.selectedModel?.product?.type?.name?.toLowerCase() == 'balkone' ||
      this.selectedModel?.product?.type?.name?.toLowerCase() == 'zäune'
    ) {
      this.colorizeMultipleMeshes(color, treeData);
    } else if (
      this.selectedProductInput.treeData.some(
        (meshTreeData) => meshTreeData?.name === 'Reißverschluss'
      )
    ) {
      this.colorizeCoverAndZipper(color, treeData);
    } else {
      this.colorizeSingleMesh(color, treeData);
    }
  }

  getEuclideanDistance(color1: RGB, color2: RGB): number {
    return Math.sqrt(
      Math.pow(color1.red - color2.red, 2) +
        Math.pow(color1.green - color2.green, 2) +
        Math.pow(color1.blue - color2.blue, 2)
    );
  }

  getBestMatchingZipperColor(coverColor: RGB, supportedColors: RGB[]): RGB {
    let bestMatch = supportedColors[0];
    let minDistance = this.getEuclideanDistance(coverColor, bestMatch);

    for (const zipperColor of supportedColors) {
      const distance = this.getEuclideanDistance(coverColor, zipperColor);
      if (distance < minDistance) {
        bestMatch = zipperColor;
        minDistance = distance;
      }
    }

    return bestMatch;
  }

  colorizeCoverAndZipper(
    color: RGB,
    treeData: MeshTreeDataItem | null = null
  ): void {
    if (!treeData) {
      treeData = this.getActivePart();
    }
    if (treeData && color) {
      const zipper = this.selectedProductInput.treeData.find(
        (meshTreeData) => meshTreeData?.name === 'Reißverschluss'
      );
      const supportedValues =
        zipper?.colors?.supportedValues[0].supportedColors;
      if (supportedValues && supportedValues.length > 0) {
        const bestMatchingZipperColor = this.getBestMatchingZipperColor(
          color,
          supportedValues
        );
        this.colorizeSingleMesh(bestMatchingZipperColor, zipper);
        this.colorizeSingleMesh(color, treeData);
      }
    }
  }

  colorizeMultipleMeshes(
    color: RGB,
    treeData: MeshTreeDataItem | null = null
  ): void {
    if (!treeData) {
      treeData = this.getActivePart();
    }
    if (treeData && color) {
      this.colorizeSingleMesh(color, treeData);
      for (const meshTreeData of this.selectedProductInput.treeData) {
        if (meshTreeData?.name.startsWith(treeData?.name.split(' ')[0])) {
          this.colorizeSingleMesh(color, meshTreeData);
        }
      }
    }
  }

  colorizeSingleMesh(
    color: RGB,
    treeData: MeshTreeDataItem | null = null
  ): void {
    if (!treeData) {
      treeData = this.getActivePart();
    }
    //this.meshEditorLoading = true;
    if (treeData && color) {
      treeData.color = this.rgbToHexString(color);
      if (treeData.colors) {
        const palette = treeData.colors.supportedValues.find((x) =>
          x.supportedColors.find((y) => y?.name === color?.name)
        );

        if (palette) {
          treeData.colors.value = color;
          this.workflowModel.setStepPrice(
            treeData.id!,
            Object.keys(CustomizeProgress).indexOf(CustomizeProgress.color),
            0
          );
        }
      }
      if (!treeData.engraving) {
        THREEMaterial.setColor(treeData.mesh, this.rgbToHexString(color));
      } else {
        this.colorizeSelectedEmbossing(
          treeData?.engraving?.engravingColor
            ? treeData.engraving.engravingColor
            : this.transparentColorRgb,
          treeData
        );
      }

      if (treeData.texture?.transparent) {
        this.handleSelectWtransfer(treeData.texture.textureFile, treeData);
      } else {
        this.handleSelectWtransfer(null);
      }
    }
    //this.meshEditorLoading = false;
  }
  //#endregion color

  //#region embossing
  set selectedEmbossing(id: number | null) {
    const treeData = this.getActivePart();
    if (treeData) {
      treeData.engravingId = id;
    }
  }

  get selectedEmbossing(): number | null {
    const treeData = this.getActivePart();
    if (treeData) {
      if (treeData.engravingId) return treeData.engravingId;
    }
    return null;
  }

  async handleSelectEmbossing(
    fileOrFileTextureId: UtilityFile | number | null,
    treeData: MeshTreeDataItem | null = null
  ): Promise<void> {
    if (!treeData) {
      treeData = this.getActivePart();
    }

    let file: UtilityFile | null = null;

    if (typeof fileOrFileTextureId === 'number') {
      file = await TemplateService.getEngravingFile(fileOrFileTextureId);
    } else {
      file = fileOrFileTextureId;
    }

    if (file) {
      this.selectedEmbossing = file.fileId;
      const embossingUrl = await this.selectedEmbossingUrl(
        this.selectedEmbossing!
      );

      new THREE.TextureLoader().load(file.content, (bmap) => {
        if (treeData) {
          const color = treeData.color ? treeData.color : '#ffffff';
          treeData.engravingId = file!.fileId;
          treeData.embossing = this.selectedEmbossingName;
          treeData.embossingThumbnailUrl = this.selectedEmbossingThumbnailUrl;
          treeData.embossingUrl = embossingUrl;
          if (treeData.engraving) {
            treeData.engraving.engravingFile = file;
            const isBlank = file!.name == this.blankFileName;
            this.workflowModel.setStepPrice(
              treeData.id!,
              Object.keys(CustomizeProgress).indexOf(
                CustomizeProgress.embossing
              ),
              0
            );
          }

          treeData.material = new THREE.MeshPhongMaterial({
            color: color,
            shininess: 20,
            bumpMap: bmap,
            bumpScale: 0.5,
          });

          let embossingColor = this.transparentColorRgb;
          if (
            treeData.engraving?.engravingColor &&
            this.getMeshEngravingColors().find(
              (m) => m.name == treeData?.engraving?.engravingColor?.name
            )
          ) {
            embossingColor = treeData.engraving?.engravingColor;
          }
          this.colorizeSelectedEmbossing(embossingColor, treeData);

          this.handleSelectWtransfer(null);
        }
        //this.meshEditorLoading = false;
      });
    } else {
      this.selectedEmbossing = null;
      if (treeData) {
        treeData.engravingId = null;
        treeData.embossing = null;
        treeData.embossingThumbnailUrl = null;
      }
    }
  }

  get selectedEmbossingThumbnailUrl(): string {
    const selectedSupportValue = this.getMeshEngravings2().find(
      (item) => item.fileId === this.selectedEmbossing
    );
    if (
      selectedSupportValue &&
      selectedSupportValue.thumbnail &&
      selectedSupportValue.thumbnail.content
    ) {
      return selectedSupportValue!.thumbnail!.content!;
    }
    return '';
  }

  async selectedEmbossingUrl(selectedEmbossing: number): Promise<string> {
    try {
      if (selectedEmbossing) {
        const selection = await TemplateService.getFile(selectedEmbossing);
        if (selection) {
          this.selectedEmbossingContent = selection.content;
        } else {
          console.error('Error: File selection is null');
        }
        return selection.content;
      } else {
        console.error(
          'Error: No support value found for the selected embossing'
        );
        return '';
      }
    } catch (error) {
      console.error('Error fetching selected embossing thumbnail URL:', error);
      return '';
    }
  }

  get selectedEmbossingName(): string {
    const selectedSupportValue = this.getMeshEngravings2().find(
      (item) => item.fileId === this.selectedEmbossing
    );

    if (selectedSupportValue) {
      return selectedSupportValue.thumbnail!.name;
    }
    return '';
  }
  //#endregion embossing

  //#region wtransfer
  set selectedWtransfer(id: number | null) {
    const treeData = this.getActivePart();
    if (treeData) {
      treeData.textureId = id;
    }
  }

  get selectedWtransfer(): number | null {
    const treeData = this.getActivePart();
    if (treeData) {
      if (treeData.textureId) return treeData.textureId;
    }
    return null;
  }

  async handleSelectWtransfer(
    file: UtilityFile | null,
    treeData: MeshTreeDataItem | null = null
  ): Promise<void> {
    if (!treeData) {
      treeData = this.getActivePart();
    }

    await this.handleSingleWtransfer(file, treeData);
  }

  async handleMultipleWtransfer(
    fileOrFileTextureId: UtilityFile | number | null,
    treeData: MeshTreeDataItem | null = null
  ): Promise<void> {
    if (!treeData) {
      treeData = this.getActivePart();
    }
    let file: UtilityFile | null = null;

    if (typeof fileOrFileTextureId === 'number') {
      file = await TemplateService.getEngravingFile(fileOrFileTextureId);
    } else {
      file = fileOrFileTextureId;
    }

    if (treeData && file) {
      await this.handleSingleWtransfer(file, treeData);
      for (const meshTreeData of this.selectedProductInput.treeData) {
        if (meshTreeData.name.startsWith(treeData.name.split(' ')[0])) {
          await this.handleSingleWtransfer(file, meshTreeData);
        }
      }
    }
  }

  async handleSingleWtransfer(
    file: UtilityFile | null,
    treeData: MeshTreeDataItem | null = null
  ): Promise<void> {
    if (!treeData) {
      treeData = this.getActivePart();
    }
    if (file && file.fileId) {
      const selectedWtransferUrl = await this.selectedWtransferUrl(file.fileId);

      let color = { red: 255, green: 255, blue: 255 } as RGB;
      new THREE.TextureLoader().load(file.content, (bmap) => {
        if (file.name.toLowerCase().includes('bathtub')) {
          bmap.center.set(0.5, 0.5);
          bmap.rotation = Math.PI / 2;
          bmap.wrapS = bmap.wrapT = THREE.RepeatWrapping;
          bmap.repeat.set(0.1, 0.1);
        }

        if (treeData) {
          treeData.textureId = file.fileId;
          treeData.wtransfer = this.selectedWtransferName;
          treeData.wtransferUrl = selectedWtransferUrl;

          if (treeData.texture) {
            treeData.texture.textureFile = file;
            if (this.getMeshTextureColors().length > 0) {
              if (treeData.texture.textureColor) {
                color = treeData.texture.textureColor;
              } else {
                color = this.getMeshTextureColors()[0];
              }
              treeData.texture.textureColor = color;
            }

            const texture = treeData?.texture?.supportedValues?.find(
              (x) => x.file.fileId == this.selectedWtransfer
            );

            if (texture) {
              this.workflowModel.setStepPrice(
                treeData.id!,
                Object.keys(CustomizeProgress).indexOf(CustomizeProgress.color),
                treeData.basePrice *
                  (texture.palette ? texture.palette.additionalPrice : 0)
              );
            }

            this.workflowModel.setStepPrice(
              treeData.id!,
              Object.keys(CustomizeProgress).indexOf(CustomizeProgress.embossing),
              treeData.texture.additionalPrice
            );
          }

          if (treeData.texture?.transparent) {
            if (treeData.colors?.value) color = treeData.colors.value;
            treeData.material = new THREE.MeshPhysicalMaterial({
              color: new Color(this.rgbToHexString(color)),
              roughness: 0.02,
              transmission: 0.5,
              metalness: 0.1,
              opacity: 0.1,
              map: bmap, // Textur auf das Material anwenden
            });
          } else {
            treeData.material = new THREE.MeshPhongMaterial({
              color: new Color(this.rgbToHexString(color)),
              shininess: 20,
              map: bmap, // Textur auf das Material anwenden
            });
          }

          (treeData.mesh as THREE.Mesh).material = treeData.material;

          this.handleSelectEmbossing(null);
        }
      });
    } else {
      this.selectedWtransfer = null;
      if (treeData) {
        treeData.wtransferId = null;
        treeData.wtransfer = null;
        treeData.wtransferUrl = null;
      }
    }
  }


  colorizeSelectWtransfer(
    color: RGB,
    treeData: MeshTreeDataItem | null = null
  ): void {
    if (!treeData) {
      treeData = this.getActivePart();
    }
    if (
      this.selectedModel?.product?.type?.name?.toLowerCase() == 'balkone' ||
      this.selectedModel?.product?.type?.name?.toLowerCase() == 'zäune'
    ) {
      this.colorizeMultipleWtransfer(color, treeData);
    } else {
      this.colorizeSingleWtransfer(color, treeData);
    }
  }

  colorizeSingleWtransfer(
    color: RGB,
    treeData: MeshTreeDataItem | null = null
  ): void {
    if (!treeData) {
      treeData = this.getActivePart();
    }
    if (treeData && treeData.texture) {
      treeData.texture.textureColor = color;
      (treeData.material as THREE.MeshPhongMaterial).color = new Color(
        this.rgbToHexString(color)
      );
      (treeData.mesh as THREE.Mesh).material = treeData.material;
    }
  }

  colorizeMultipleWtransfer(
    color: RGB,
    treeData: MeshTreeDataItem | null = null
  ): void {
    if (!treeData) {
      treeData = this.getActivePart();
    }
    if (treeData && color) {
      this.colorizeSingleWtransfer(color, treeData);
      for (const meshTreeData of this.selectedProductInput.treeData) {
        if (meshTreeData.name.startsWith(treeData.name.split(' ')[0])) {
          this.colorizeSingleWtransfer(color, meshTreeData);
        }
      }
    }
  }

  /*get selectedWtransferUrl(): string {
    const selection = this.getMeshTextures2().find(
      (item) => item.fileId === this.selectedWtransfer
    );
    if (selection) {
      return selection.content;
    }
    return '';
  }*/
  async selectedWtransferUrl(selectedWtransfer: number): Promise<string> {
    try {
      if (selectedWtransfer) {
        const selection = await TemplateService.getFile(selectedWtransfer);
        if (selection) {
          return selection.content;
        } else {
          console.error('Error: File selection is null');
          return '';
        }
      } else {
        console.error(
          'Error: No support value found for the selected embossing'
        );
        return '';
      }
    } catch (error) {
      console.error('Error fetching selected embossing thumbnail URL:', error);
      return '';
    }
  }

  get selectedWtransferName(): string {
    const selection = this.getMeshTextures2().find(
      (item) => item.fileId === this.selectedWtransfer
    );
    if (selection && selection.thumbnail) {
      return selection.thumbnail.name;
    }
    return '';
  }
  //#endregion wtransfer

  set selectedEmbossingColor(color: RGB | null) {
    const treeData = this.getActivePart();
    if (treeData?.engraving) {
      treeData.engraving.engravingColor = color;
    }
  }

  get selectedEmbossingColor(): RGB | null {
    const treeData = this.getActivePart();
    if (treeData) {
      if (treeData.engraving) return treeData.engraving.engravingColor;
    }
    return null;
  }

  set selectedTextureColor(color: RGB | null) {
    const treeData = this.getActivePart();
    if (treeData?.texture) {
      treeData.texture.textureColor = color;
    }
  }

  get selectedTextureColor(): RGB | null {
    const treeData = this.getActivePart();
    if (treeData) {
      if (treeData.texture) return treeData.texture.textureColor;
    }
    return null;
  }

  set selectedVarnish(varnish: string) {
    this.orderData.varnish = varnish;
  }

  get selectedVarnish(): string {
    return this.orderData.varnish;
  }

  colorizeSelectedEmbossing(
    color: RGB,
    treeData: MeshTreeDataItem | null = null
  ): void {
    if (!treeData) {
      treeData = this.getActivePart();
    }
    this.selectedEmbossingColor = color;

    if (treeData) {
      if (treeData.engraving) {
        treeData.engraving.engravingColor = color;
        const texture = treeData?.engraving?.supportedValues?.find(
          (x) => x.file.fileId == this.selectedEmbossing
        );

        this.workflowModel.setStepPrice(
          treeData.id!,
          Object.keys(CustomizeProgress).indexOf(CustomizeProgress.overview),
          treeData.engraving.engravingColor &&
            treeData.engraving.engravingColor != this.transparentColorRgb &&
            texture?.palette?.additionalPrice
            ? texture.palette.additionalPrice
            : 0
        );
      }
      if (
        color.name == this.transparentColorRgb.name &&
        treeData.colors?.value
      ) {
        color = treeData.colors.value;
      }

      const url = treeData.embossingUrl;
      if (url == null) return;

      //this.meshEditorLoading = true;
      new THREE.ImageLoader().load(url, (image) => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;

        const context = canvas.getContext('2d');
        if (!context) return;

        context.drawImage(image, 0, 0);
        const imgData = context.getImageData(0, 0, canvas.width, canvas.height);

        const data = imgData.data;
        const limitValue = 100;

        let selColor: RGB | null = null;
        if (treeData?.colors?.value) {
          selColor = treeData.colors.value;
        }

        for (let i = 0; i < data.length; i += 4) {
          if (
            data[i] >= limitValue &&
            data[i + 1] >= limitValue &&
            data[i + 2] >= limitValue
          ) {
            data[i] = selColor ? selColor.red : 255;
            data[i + 1] = selColor ? selColor.green : 255;
            data[i + 2] = selColor ? selColor.blue : 255;
          } else {
            data[i] = color.red;
            data[i + 1] = color.green;
            data[i + 2] = color.blue;
          }
        }

        context.putImageData(imgData, 0, 0);
        const map = new THREE.CanvasTexture(context.canvas);
        if (treeData) {
          (treeData.material as THREE.MeshPhongMaterial).color = new Color(
            '#ffffff'
          );
          (treeData.material as THREE.MeshPhongMaterial).map = map;
          (treeData.mesh as THREE.Mesh).material = treeData.material;
        }

        //this.meshEditorLoading = false;
      });
    }
  }

  //#region form
  dialogImageUrl: UploadData | null = null;
  dialogVisible = false;

  handlePictureCardPreview(uploadFile: UploadData | null): void {
    if (uploadFile)
      this.dialogImageUrl = {
        name: uploadFile.name,
        url: uploadFile.url,
        uid: uploadFile.uid,
      };
    this.dialogVisible = true;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  uploadFile(file: File, list: UploadData[]): boolean {
    const url = URL.createObjectURL(file);
    fileContentToBase64(file, (encodeString) => {
      const data = {
        name: file.name,
        url: url,
        uid: file.lastModified,
        base64: encodeString,
      };
      list.pop();
      list.push(data);
    });
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  uploadFileDesignFile(res: any): boolean {
    return this.uploadFile(res.file, this.orderData.designFiles);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  uploadFileImageFile(res: any): boolean {
    return this.uploadFile(res.file, this.orderData.imageFiles);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  uploadFileScanFile(res: any): boolean {
    const file = new File([res.file], res.file.name, {
      type: 'application/' + res.file.name.split('.').pop(),
    });
    return this.uploadFile(file, this.orderData.scanFiles);
  }

  handleRemove(file: UploadFile, list: UploadData[]): void {
    ElMessageBox.confirm(
      (this as any).$t('views.customize.workflow.deleteMessage'),
      'info',
      {
        confirmButtonText: (this as any).$t('views.customize.workflow.yes'),
        cancelButtonText: (this as any).$t('views.customize.workflow.no'),
        type: 'warning',
      }
    )
      .then((confirm) => {
        if (confirm) {
          const index = list.findIndex((store) => store.uid === file.uid);
          if (index >= 0) {
            list.splice(index, 1);
          }
        }
      })
      .catch(() => {
        //
      });
  }

  @Watch('orderData.heightWithoutDeduction', { immediate: true })
  heightWithoutDeductionChanged(): void {
    this.orderData.heightWithDeduction =
      this.orderData.heightWithoutDeduction + this.orderData.heightDeduction;
  }

  @Watch('orderData.calfCircumferenceWithoutDeduction', { immediate: true })
  calfCircumferenceWithoutDeductionChanged(): void {
    this.orderData.calfCircumferenceWithDeduction =
      this.orderData.calfCircumferenceWithoutDeduction +
      this.orderData.calfCircumferenceDeduction;
  }

  get meshColorList(): RGB[] {
    return this.selectedProductInput.treeData
      .filter((d) => d.colors && d.colors.value)
      .map((data) => (data.colors as AttributeColor).value);
  }

  get meshEmbossingList(): UtilityFile[] {
    return this.selectedProductInput.treeData
      .filter((d) => d.engraving && d.engraving.engravingFile)
      .map(
        (data) => (data.engraving as Engraving).engravingFile as UtilityFile
      );
  }

  get meshEmbossingColorList(): RGB[] {
    return this.selectedProductInput.treeData
      .filter(
        (d) =>
          d.engraving &&
          d.engraving.engravingColor &&
          d.engraving.engravingColor !== this.transparentColorRgb
      )
      .map((data) => (data.engraving as Engraving).engravingColor as RGB);
  }

  get meshWtransferList(): UtilityFile[] {
    return this.selectedProductInput.treeData
      .filter((d) => d.texture && d.texture.textureFile)
      .map((data) => (data.texture as Print).textureFile);
  }

  get meshWtransferColorList(): RGB[] {
    return this.selectedProductInput.treeData
      .filter(
        (d) =>
          d.texture &&
          d.texture.textureColor &&
          d.texture.textureColor !== this.transparentColorRgb
      )
      .map((data) => (data.texture as Print).textureColor as RGB);
  }

  toNextStep(): void {
    this.workflowModel.changeActiveStep(1);
  }

  findProductZip(): MeshTreeDataItem | undefined {
    return this.selectedProductInput.treeData.find((x) =>
      x.name?.toLowerCase().includes('reißverschluss')
    );
  }

  zipChanged(zip: string): void {
    const zipMesh = this.findProductZip();
    const meshEditor: any = this.$refs.meshEditor;

    if (zipMesh) {
      if (zip == ZipType.no) {
        this.orderData.sewInByCustomer = false;
        meshEditor.setOpacity(zipMesh, 0);
        this.workflowModel.setStepPrice(
          zipMesh.id!,
          Object.keys(CustomizeProgress).indexOf(CustomizeProgress.form),
          0
        );
      } else if (this.orderData.sewInByCustomer) {
        meshEditor.setOpacity(zipMesh, 100);
        this.workflowModel.setStepPrice(
          zipMesh.id!,
          Object.keys(CustomizeProgress).indexOf(CustomizeProgress.form),
          0
        );
      } else {
        meshEditor.setOpacity(zipMesh, 100);
        this.workflowModel.setStepPrice(
          zipMesh.id!,
          Object.keys(CustomizeProgress).indexOf(CustomizeProgress.form),
          0
        );
      }
    }
  }

  updateProductListState({
    productId,
    name,
  }:{
    productId:number
    name:string
  }){
    this.productList = cloneDeep(this.productList).map(p=>{
      if(p.product.productId === productId) p.product.name = name
      return p
    })
  }

  confirmOrder(): void {
    this.takeScreenshotOfAllViews();
    this.sendRequest();
  }

  addToCart(): void {
    //TODO
  }

  async uploadFileToAzure(
    file: any,
    sasUrl: string,
    orderId: string,
    type: string,
    index: number
  ) {
    const blobServiceClient = new BlobServiceClient(sasUrl);

    const containerName = orderId;
    const containerClient = blobServiceClient.getContainerClient(containerName);

    const blobName = type + '-' + index + '.' + file.name?.split('.')?.pop();
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    try {
      await blockBlobClient.uploadBrowserData(file);
    } catch (error) {
      console.error('Error uploading file to Azure Blob Storage:');
    }
  }

  sendRequest(): void {
    const requestData: OrderService.OrderRequestData = this.requestData;
    this.sendingOrder = true;

    const scanFiles = requestData.scanFiles;
    const imageFiles = requestData.imageFiles;
    requestData.scanFiles = [];
    requestData.imageFiles = [];

    requestData.scanFiles.length = 0;
    requestData.imageFiles.length = 0;

    OrderService.sendOrderAsync(
      requestData,
      this.selectedModelId!,
      this.authentication.primaryDomain?.domainId ?? 2,
      this.authentication.user?.userId ?? null
    ).then(
      (result: ApiResponse) => {
        ElMessage({
          showClose: true,
          message: (this as any).$t('views.customize.workflow.form.success'),
          type: 'success',
          duration: 4000,
        });
        this.workflowModel.finished = false;
        this.fullscreenLoading = false;
        this.workflowModel.active = 0;
        this.sendingOrder = false;

        const orderId = result.sendOrder[0];
        const sasUrl = result.sendOrder[1];

        let i = 1;
        for (const scan of scanFiles) {
          this.uploadFileToAzure(scan, sasUrl, orderId, 'SCAN', i++);
        }

        i = 1;
        for (const image of imageFiles) {
          this.uploadFileToAzure(image, sasUrl, orderId, 'IMAGE', i++);
        }
      },
      () => {
        ElMessage({
          showClose: true,
          message: (this as any).$t('views.customize.workflow.form.error'),
          type: 'error',
          duration: 4000,
        });
        this.workflowModel.finished = false;
        this.fullscreenLoading = false;
        this.sendingOrder = false;
      }
    );
  }
  //#endregion form

  private async saveAll(): Promise<void> {
    await this.save();
  }

  private async saveUpdated(): Promise<void> {
    await this.save(this.lastUpdateDB);
  }

  //TODO: implement correct save method
  private async save(lastUpdateDB = -1): Promise<void> {
    this.fullscreenLoading = true;
    await setTimeout(() => {
      this.lastUpdateDB = Date.now();
      for (const category of this.selectedProductInput.treeData) {
        const categoryType = Object.values(UploadCategory).find(
          (categoryType) =>
            category.name ===
            (this as any).$t(`enum.upload-category.${categoryType}`)
        );
        if (categoryType) {
          //console.debug(lastUpdateDB);
          //this.updateChildren(categoryType, category, lastUpdateDB);
        }
      }
      this.fullscreenLoading = false;
      //(this.$router as any).askForChanges = this.hasChanges;
    }, 100);
  }

  get hasChanges(): boolean {
    return this.lastUpdateDB < this.selectedProductInput.lastUpdateDB;
  }

  get requestData(): OrderService.OrderRequestData {
    return {
      product: this.selectedModel!.product?.name,
      colorList: this.meshColorList.map((c) =>
        (this as any).$t(`views.colors.${c.name}`)
      ),
      embossingList: this.meshEmbossingList.map((e) => e.name),
      embossingColorList: this.meshEmbossingColorList.map((ec) =>
        (this as any).$t(`views.colors.${ec.name}`)
      ),
      wtransferList: this.meshWtransferList.map((w) => w.name),
      varnish: this.orderData.varnish,
      technicianFirstname: this.orderData.technicianFirstname,
      technicianLastname: this.orderData.technicianLastname,
      technician: this.orderData.technician,
      company: this.orderData.company,
      customerNr: this.orderData.customerNr,
      orderNr: this.orderData.orderNr,
      billingNr: this.orderData.billingNr,
      commissionNo: this.orderData.commissionNo,
      vrNumber: this.orderData.vrNumber,
      vgNumber: this.orderData.vgNumber,
      vsgNumber: this.orderData.vsgNumber,
      mail: this.orderData.mail,
      orderMail:
        (this.user as Reseller)?.supplier?.email ??
        (this.user as Supplier)?.email ??
        '',
      coverModel: this.selectedModel!.product?.name,
      prothesisType: this.selectedModel!.product.type.name,
      bodySide: (this as any).$t(`enum.bodySide.${this.orderData.bodySide}`),
      kneeJoint: this.orderData.kneeJoint,
      foot: this.orderData.foot,
      zip: (this as any).$t(`enum.zipType.${this.orderData.zip}`),
      sewInByCustomer: this.orderData.sewInByCustomer,
      cosmeticEndingAtFoot: this.orderData.cosmeticEndingAtFoot,
      heightWithoutDeduction: this.orderData.heightWithoutDeduction,
      heightDeduction: this.orderData.heightDeduction,
      heightWithDeduction: this.orderData.heightWithDeduction,
      depth: this.orderData.depth,
      calfCircumferenceWithoutDeduction:
        this.orderData.calfCircumferenceWithoutDeduction,
      calfCircumferenceDeduction: this.orderData.calfCircumferenceDeduction,
      calfCircumferenceWithDeduction:
        this.orderData.calfCircumferenceWithDeduction,
      ankleCircumference: this.orderData.ankleCircumference,
      imageFiles: this.orderData.imageFiles,
      scanFiles: this.orderData.scanFiles,
      designFiles: this.screenshotSrc,
      remarks: this.orderData.remarks,
      balconyType: (this as any).$t(
        `enum.balconyType.${this.orderData.balconyType}`
      ),
      mountingType: (this as any).$t(
        `enum.mountingType.${this.orderData.mountingType}`
      ),
      paneelType: (this as any).$t(
        `enum.paneelType.${this.orderData.paneelType}`
      ),
      orientationType: (this as any).$t(
        `enum.orientationType.${this.orderData.orientationType}`
      ),
      privacy: this.orderData.privacy,
      isPrivacyAccepted: this.orderData.isPrivacy,
      price: this.workflowModel.totalPrice,
    };
  }

  /*@Watch('selectedProductInput.lastUpdateDB', { immediate: false })
  async onMeshTreeDataDBChanged(): Promise<void> {
    (this.$router as any).askForChanges = this.hasChanges;
  }*/
  //#endregion save

  takeScreenshotOfAllViews(): void {
    const meshEditor: any = this.$refs.meshEditor;
    if (meshEditor) {
      this.screenshotSrc = [];

      this.takeScreenshotOfView(meshEditor, THREEEnum.Views.custom);
      this.takeScreenshotOfView(meshEditor, THREEEnum.Views.front);
      this.takeScreenshotOfView(meshEditor, THREEEnum.Views.back);
      this.takeScreenshotOfView(meshEditor, THREEEnum.Views.top);
      this.takeScreenshotOfView(meshEditor, THREEEnum.Views.bottom);
      this.takeScreenshotOfView(meshEditor, THREEEnum.Views.left);
      this.takeScreenshotOfView(meshEditor, THREEEnum.Views.right);
    }
  }

  @Watch('workflowModel.active', { immediate: true })
  onActiveStepChanged(): void {
    this.workflowModel.IsForm =
      this.activeCustomizeProgress === CustomizeProgress.form;

    const component = this.getActivePart();
    switch (this.activeCustomizeProgress) {
      case CustomizeProgress.product:
        this.selectedProductInput.clear();
        this.workflowModel.totalPriceArray.clear();
        this.abortController.abort();
        this.colorListLoading.clear();
        this.embossingListLoading.clear();
        this.wtransferListLoading.clear();
        this.selectedScan = null;
        this.screenshotSrc = [];
        this.loadedScan = null;
        break;
      case CustomizeProgress.color:
        if (component) {
          if (this.colorListLoading.get(component.uuid)) {
            this.footerLoading = true;
          } else {
            this.footerLoading = false;
          }
        }
        break;
      case CustomizeProgress.embossing:
        if (component) {
          if (
            this.embossingListLoading.get(component.uuid) ||
            this.wtransferListLoading.get(component.uuid)
          ) {
            this.footerLoading = true;
          } else {
            this.footerLoading = false;
          }
        }
        break;
    }
  }

  takeScreenshotOfView(meshEditor: MeshEditor, view: THREEEnum.Views): void {
    const rendererExport = new THREE.WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true,
      alpha: true,
    });
    rendererExport.setClearColor(0xffffff, 0);
    rendererExport.setSize(window.innerWidth, window.innerHeight);

    const camera = this.newCameraFromView(meshEditor, view);

    const img = new Image();
    if (meshEditor.troisRenderer.scene && camera) {
      rendererExport.render(meshEditor.troisRenderer.scene, camera);
      const dataURL = rendererExport.domElement.toDataURL('image/png');

      img.src = dataURL;
      this.screenshotSrc?.push(img.src);
    }

    rendererExport.dispose();
  }

  newCameraFromView(
    meshEditor: MeshEditor,
    view: THREEEnum.Views
  ): THREE.PerspectiveCamera {
    if (!meshEditor.troisRenderer.camera) {
      return new THREE.PerspectiveCamera();
    }
    const editorCamera = meshEditor.troisRenderer
      .camera as THREE.PerspectiveCamera;

    const camera = new THREE.PerspectiveCamera(
      editorCamera.fov,
      window.innerWidth / window.innerHeight,
      editorCamera.near,
      editorCamera.far
    );
    camera.position.x = editorCamera.position.x;
    camera.position.y = editorCamera.position.y;
    camera.position.z = editorCamera.position.z;

    const direction = this.selectView(view);
    THREECamera.fitControlCameraToSelection(
      camera,
      meshEditor.troisRenderer.three.cameraCtrl!,
      meshEditor.troisRenderer.three.scene!.children
    );

    const distance = camera.position.distanceTo(
      meshEditor.troisRenderer.three.cameraCtrl!.target
    );
    camera!.position.copy(
      direction
        .multiplyScalar(distance)
        .add(meshEditor.troisRenderer.three.cameraCtrl!.target)
    );
    camera!.lookAt(meshEditor.troisRenderer.three.cameraCtrl!.target);

    return camera;
  }

  selectView(view: THREEEnum.Views): THREE.Vector3 {
    const direction = new THREE.Vector3();
    switch (view) {
      case THREEEnum.Views.front:
        direction.set(0, 0, 1);
        break;
      case THREEEnum.Views.back:
        direction.set(0, 0, -1);
        break;
      case THREEEnum.Views.top:
        direction.set(0, 1, 0);
        break;
      case THREEEnum.Views.bottom:
        direction.set(0, -1, 0);
        break;
      case THREEEnum.Views.right:
        direction.set(1, 0, 0);
        break;
      case THREEEnum.Views.left:
        direction.set(-1, 0, 0);
        break;
      case THREEEnum.Views.custom:
        direction.set(0.7, 0.7, 0.7);
        break;
    }
    return direction;
  }

  confirmOfferPdf(): void {
    this.takeScreenshotOfView(
      this.$refs.meshEditor as MeshEditor,
      THREEEnum.Views.custom
    );
    //await new Promise(res => setTimeout(res, 5000));
    this.createOfferPdf();
  }

  async createOfferPdf(): Promise<void> {
    this.loadingOfferPdf = true;
    this.requestData.scanFiles = [];
    this.requestData.imageFiles = [];
    const downloadPdfBase64 = await OrderService.createPdfOffer(
      this.requestData,
      this.selectedModelId!,
      this.authentication.primaryDomain?.domainId ?? 2
    );

    console.log('downloadPdfBase64', downloadPdfBase64)

    const pdfPartListHTMLElement = document.getElementById(
      'pdf-offer'
    ) as HTMLAnchorElement;

    console.log('pdfPartListHTMLElement', pdfPartListHTMLElement)

    if (pdfPartListHTMLElement) {
      pdfPartListHTMLElement.href = downloadPdfBase64.toString();
      pdfPartListHTMLElement.click();
    }
    this.loadingOfferPdf = false;
  }


  @Watch('window.innerWidth', { immediate: true })
  isSmallWindow(): boolean {
    if (window.innerWidth < 800) {
      // Small Device
      return true;
    } else {
      // Large Device
      return false;
    }
  }

  async defaultUndo(): Promise<void> {
    //
  }

  async defaultRedo(): Promise<void> {
    //
  }

  convertToUrl(model: Model2): string | null {
    if (model.product && model.product.thumbnail) {
      return model.product.thumbnail.content;
    }
    return null;
  }

  productQualityChanged(selectedProductQuality: string): void {
    this.selectedProductQuality = selectedProductQuality;
    this.loadTemplateFromDB();
  }

  async loadScan(): Promise<void> {
    //no automatic adaption to scan file
    if (!this.selectedScan || !this.selectedScan.fileId) return;

    this.loadedScan = await customerService.getScanById(
      this.selectedScan.fileId
    );
  }

  updateModelSize() {
    this.abortController.abort();
    this.meshEditorLoading = true;
    this.selectedProductInput.clear();
    this.abortController = new AbortController();

    if (this.filteredFenceModels) {
      if (
        this.orderData.mountingType == MountingType.front &&
        this.orderData.paneelType == PaneelType.landscape
      ) {
        this.selectedModel = this.filteredFenceModels[0];
      } else if (
        this.orderData.mountingType == MountingType.bottom &&
        this.orderData.paneelType == PaneelType.landscape
      ) {
        this.selectedModel = this.filteredFenceModels[1];
      } else if (
        this.orderData.mountingType == MountingType.front &&
        this.orderData.paneelType == PaneelType.portrait
      ) {
        this.selectedModel = this.filteredFenceModels[2];
      }

      if (this.selectedModel) {
        this.selectedModelId = this.selectedModel.modelId;

        modelService
          .createNewFenceModel(
            this.selectedModelId,
            this.orderData.calfCircumferenceWithoutDeduction,
            this.orderData.heightWithoutDeduction,
            this.orderData.depth,
            this.orderData.fenceType,
            this.abortController
          )
          .then((model) => {
            modelService.deleteModel(this.selectedModelId!);

            this.selectedModelId = model.modelId;
            this.loadTemplate(model);
          });
      }
    } else if (this.filteredBalconyModels) {
      if (
        this.orderData.mountingType == MountingType.front &&
        this.orderData.paneelType == PaneelType.landscape
      ) {
        this.selectedModel = this.filteredBalconyModels[0];
      } else if (
        this.orderData.mountingType == MountingType.bottom &&
        this.orderData.paneelType == PaneelType.landscape
      ) {
        this.selectedModel = this.filteredBalconyModels[1];
      } else if (
        this.orderData.mountingType == MountingType.front &&
        this.orderData.paneelType == PaneelType.portrait
      ) {
        this.selectedModel = this.filteredBalconyModels[2];
      }

      if (this.selectedModel) {
        this.selectedModelId = this.selectedModel.modelId;

        modelService
          .createNewBalconyModel(
            this.selectedModelId,
            this.orderData.calfCircumferenceWithoutDeduction,
            this.orderData.heightWithoutDeduction,
            this.orderData.depth,
            this.orderData.depthRight,
            this.orderData.balconyType,
            this.abortController
          )
          .then((model) => {
            modelService.deleteModel(this.selectedModelId!);

            this.selectedModelId = model.modelId;
            this.loadTemplate(model);
          });
      }
    }
  }

  generateBalconyModel(): void {
    this.selectedProductInput.clear();
    this.meshEditorLoading = true;
    this.abortController = new AbortController();
    if (this.selectedModelId && this.selectedModel?.product?.productId) {
      modelService
        .getModelsByProductId(this.selectedModel.product.productId)
        .then((models) => {
          this.filteredBalconyModels = models.filter((item) => !item.basedOn);
          if (
            this.orderData.mountingType == MountingType.front &&
            this.orderData.paneelType == PaneelType.landscape
          ) {
            this.selectedModel = this.filteredBalconyModels[0];
          } else if (
            this.orderData.mountingType == MountingType.bottom &&
            this.orderData.paneelType == PaneelType.landscape
          ) {
            this.selectedModel = this.filteredBalconyModels[1];
          } else if (
            this.orderData.mountingType == MountingType.front &&
            this.orderData.paneelType == PaneelType.portrait
          ) {
            this.selectedModel = this.filteredBalconyModels[2];
          }

          if (this.selectedModel) {
            this.selectedModelId = this.selectedModel.modelId;

            modelService
              .createNewBalconyModel(
                this.selectedModelId,
                this.orderData.calfCircumferenceWithoutDeduction,
                this.orderData.heightWithoutDeduction,
                this.orderData.depth,
                this.orderData.depthRight,
                this.orderData.balconyType,
                this.abortController
              )
              .then((model) => {
                if(model){
                  this.selectedModelId = model.modelId;
                }
                this.loadTemplate(model);
              });
          }
        });
    }
  }

  generateFenceModel(): void {
    this.selectedProductInput.clear();
    this.meshEditorLoading = true;
    this.abortController = new AbortController();

    if (this.selectedModelId && this.selectedModel?.product?.productId) {
      modelService
        .getModelsByProductId(this.selectedModel.product.productId)
        .then((models) => {
          this.filteredFenceModels = models.filter((item) => !item.basedOn);
          this.selectedModel = this.filteredFenceModels[0];

          if (this.selectedModel) {
            this.selectedModelId = this.selectedModel.modelId;

            modelService
              .createNewFenceModel(
                this.selectedModelId,
                this.orderData.calfCircumferenceWithoutDeduction,
                this.orderData.heightWithoutDeduction,
                this.orderData.depth,
                this.orderData.fenceType,
                this.abortController
              )
              .then((model) => {
                this.selectedModelId = model.modelId;
                this.loadTemplate(model);
              });
          }
        });
    }
  }

  generateSuspendedLightModel(): void {
    this.selectedProductInput.clear();
    this.meshEditorLoading = true;
    this.abortController = new AbortController();

    if (this.selectedModelId && this.selectedModel?.product?.productId) {
      modelService
        .getModelsByProductId(this.selectedModel.product.productId)
        .then((models) => {
          this.filteredLighModels = models.filter((item) => !item.basedOn);
          this.selectedModel = this.filteredLighModels[0];

          if (this.selectedModel) {
            this.selectedModelId = this.selectedModel.modelId;

            modelService
              .createNewSuspendedLightModel(
                this.selectedModelId,
                this.orderData.color,
                this.orderData.mountingProfileType,
                this.orderData.lightCoverType,
                this.orderData.vFour,
                this.orderData.iPRatingType,
                this.orderData.lightColorType,
                this.orderData.lightLength,
                this.abortController
              )
              .then((model) => {
                this.selectedModelId = model.modelId;
                this.loadTemplate(model);
              });
          }
        });
    }
  }

  generateBathtubModel(): void {
    this.meshEditorLoading = true;
    this.abortController = new AbortController();
    this.selectedProductInput.clear();

    if (this.selectedModel) {

      this.selectedModelId = this.selectedModel!.modelId;
      console.log("Badewanne 1a: " + this.selectedModelId);

      modelService.createNewBathtubModel(
            this.selectedModelId, 
            this.orderData.bathtubType, 
            this.orderData.wallpanels, 
            this.orderData.panelVariant,
            this.orderData.singleType,
            this.orderData.comboType,
            this.abortController
          ).then((model) => {
            this.selectedModelId = model.modelId;
            console.log("Badewanne 1b: " + this.selectedModelId);
            this.loadTemplate(model);
          });
    }
    console.log("Badewanne 1c: " + this.selectedModelId);
  }

  meshesLoaded() {
    if (this.selectedProductInput.treeData?.length > 0) {
      this.setPartSelection();
      this.setTotalPrice();
      this.setAttributes();
    }
    this.orderData.coverModel = this.selectedName;
    this.meshEditorLoading = false;
  }

  async setAttributes() {
    let i = 0;
    this.selectedProductInput.treeData.forEach(async (treeData) => {
      if (this.embossingId) {
        await waitFor(
          () => this.embossingListLoading.get(treeData.uuid) == true
        );
        if (this.embossingId) {
          let selectedEngravingFile;
          if (Array.isArray(this.embossingId)) {
            selectedEngravingFile = await TemplateService.getEngravingFile(
              this.getMeshEngravings2(treeData)[this.embossingId[i]]
                .fileTextureId
            );
          } else {
            selectedEngravingFile = await TemplateService.getEngravingFile(
              this.getMeshEngravings2(treeData)[this.embossingId].fileTextureId
            );
          }
          this.handleSelectEmbossing(selectedEngravingFile, treeData);
          if (this.embossingColorId) {
            this.colorizeSelectedEmbossing(
              Array.isArray(this.embossingColorId)
                ? this.getMeshEngravingColors(treeData)[
                    this.embossingColorId[i]
                  ]
                : this.getMeshEngravingColors(treeData)[this.embossingColorId],
              treeData
            );
          }
        }
      } else if (this.wtransferId) {
        await waitFor(
          () => this.wtransferListLoading.get(treeData.uuid) == true
        );
        if (this.wtransferId) {
          let selectedTextureFile;
          if (Array.isArray(this.wtransferId)) {
            selectedTextureFile = await TemplateService.getEngravingFile(
              this.getMeshTextures2(treeData)[this.wtransferId[i]].fileTextureId
            );
          } else {
            selectedTextureFile = await TemplateService.getEngravingFile(
              this.getMeshTextures2(treeData)[this.wtransferId].fileTextureId
            );
          }
          this.handleSelectWtransfer(selectedTextureFile, treeData);
        }
      }

      if (this.colorId && !this.wtransferId) {
        await waitFor(() => this.colorListLoading.get(treeData.uuid) == true);
        if (this.colorId) {
          const colorList = this.getAllColors(treeData);
          this.colorizeSelectedMesh(
            Array.isArray(this.colorId)
              ? colorList[this.colorId[i]]
              : colorList[this.colorId],
            treeData
          );
        }
      }

      i++;
    });
  }

  productQualityToInt(productQuality: string): number {
    switch (productQuality) {
      case ProductQuality.original:
        return 1;
      case ProductQuality.high:
        return 4;
      case ProductQuality.middle:
        return 8;
      case ProductQuality.low:
        return 16;
    }
    return 1;
  }

  setPartSelection() {
    this.selectedProductInput.selectItem(
      this.selectedProductInput.treeData[0],
      true,
      true
    );
  }

  async setTotalPrice() {
    this.workflowModel.initTotalPrice(this.selectedProductInput);
    this.workflowModel.setStepPrice(
      'product',
      Object.keys(CustomizeProgress).indexOf(CustomizeProgress.product),
      this.selectedModel?.product?.basePrice ?? 0
    );

    // set base price for each component
    if (this.selectedModel?.components) {
      for (const component of this.selectedModel.components) {
        let price = 0.0;
        const treeData = this.selectedProductInput.treeData.find(
          (t) => t.componentId == component.componentId
        );
        switch (component.unit) {
          case unit_type.Pcs: {
            price = component.basePrice;
            break;
          }
          case unit_type.M: {
            if (treeData) {
              await waitFor(
                () => this.sizeListLoading.get(treeData.uuid) == true
              );
              if (treeData.size)
                price = (treeData.size.width / 1000) * component.basePrice;
            }
            break;
          }
          case unit_type.Qm: {
            if (treeData) {
              await waitFor(
                () => this.sizeListLoading.get(treeData.uuid) == true
              );
              if (treeData.size)
                price =
                  (treeData.size.width / 1000) *
                  (treeData.size.height / 1000) *
                  component.basePrice;
            }
            break;
          }
        }
        if (treeData) treeData.basePrice = price;

        this.workflowModel.setStepPrice(
          component.uuid,
          Object.keys(CustomizeProgress).indexOf(CustomizeProgress.product),
          price
        );
      }
    }

    this.zipChanged(this.orderData.zip);
  }
}
</script>

<style lang="scss" scoped>
#product-details-modal-header,
#product-parameter-modal-header {
  background-color: transparent;
  z-index: 1;
}
#product-details-modal-body,
#product-parameter-modal-body {
  margin-top: -3.6rem;
  z-index: 0;
}
.fixed-bottom {
  padding-right: 0 !important;
}
.demonstration{
  opacity: 0;
}
</style>
