import * as vue from 'vue';
import * as trois from 'troisjs';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { FileType3D, getFileType } from '@/types/enum/upload';
import { LayoutColor } from '@/types/enum/color';
import { STLExporter } from 'three/examples/jsm/exporters/STLExporter.js';
import { OBJExporter } from 'three/examples/jsm/exporters/OBJExporter.js';
import {
  fitControlCameraToSelection,
  fitCameraToSelection,
} from '@/utils/three/camera';
import { setColor } from '@/utils/three/material';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';

export enum ModelSrcType {
  URL = 'url',
  BASE64 = 'base64',
}

/* eslint-disable @typescript-eslint/no-explicit-any*/

const Model = vue.defineComponent({
  extends: trois.Object3D,
  emits: ['load', 'progress', 'error'],
  props: {
    src: { type: String, required: true },
    srcType: { type: String, default: ModelSrcType.URL },
    autoScale: { type: Boolean, default: true },
  },
  data() {
    return {
      progress: 0,
    };
  },
  methods: {
    onLoad(model) {
      this.$emit('load', model);
      this.initObject3D(model);

      if (
        this.autoScale &&
        this.renderer &&
        this.renderer.camera instanceof THREE.PerspectiveCamera
      ) {
        if ((this.renderer as any).orbitCtrl) {
          fitControlCameraToSelection(
            this.renderer.camera,
            (this.renderer as any).orbitCtrl,
            [model]
          );
        } else {
          fitCameraToSelection(this.renderer.camera, [model]);
        }
      }
    },
    onProgress(progress) {
      this.progress = progress.loaded / progress.total;
      this.$emit('progress', progress);
    },
    onError(error) {
      this.$emit('error', error);
    },
  },
});



const OBJ = vue.defineComponent({
  extends: Model,
  created() {
    const loader = new OBJLoader();
    if (this.srcType === ModelSrcType.URL) {
      loader.load(
        this.src,
        (obj) => {
          this.onLoad(obj);
        },
        this.onProgress,
        this.onError
      );
    } else {
      const obj = loader.parse(this.src);
      this.onLoad(obj);
    }
  },
});

/*const MTL = vue.defineComponent({
  extends: Model,
  created() {
    const loader = new MTLLoader();
    if (this.srcType === ModelSrcType.URL) {
      loader.load(
        this.src,
        (materials) => {
          materials.preload();
          this.onLoad(materials);
        },
        this.onProgress,
        this.onError
      );
    } else {
      const materials = loader.parse(this.src);
      materials.preload();
      this.onLoad(materials);
    }
  },
});*/

const MTL = vue.defineComponent({
  extends: Model,
  created() {
    const loader = new MTLLoader();
    if (this.srcType === ModelSrcType.URL) {
      loader.load(
        this.src,
        (materials) => {
          materials.preload();
          this.onLoad(materials);
        },
        this.onProgress,
        this.onError
      );
    }
  }
});


const STL = vue.defineComponent({
  extends: Model,
  created() {
    const loader = new STLLoader();
    if (this.srcType === ModelSrcType.URL) {
      loader.load(
        this.src,
        (stl) => {
          const material = new THREE.MeshLambertMaterial({
            color: LayoutColor.primary,
          });
          const mesh = new THREE.Mesh(stl, material);
          this.onLoad(mesh);
        },
        this.onProgress,
        this.onError
      );
    } else {
      const obj = loader.parse(this.src);
      this.onLoad(obj);
    }
  },
});

const getLoader = (fileType: FileType3D): THREE.Loader | undefined => {
  if (fileType === FileType3D.FBX) {
    return new FBXLoader();
  }
  if (fileType === FileType3D.OBJ) {
    return new OBJLoader();
  }
  if (fileType === FileType3D.STL) {
    return new STLLoader();
  }
  if (fileType === FileType3D.MTL) {
    return new MTLLoader();
  }
  return undefined;
};

const updateImport = (
  data: THREE.BufferGeometry | THREE.Object3D,
  color: string | null
): THREE.Object3D | undefined => {
  if (data instanceof THREE.Object3D) {
    if ((data as any).isGroup && data.children.length === 1) {
      data = data.children[0];
    }
    if (color) setColor(data, color);
    return data;
  } else {
    const material = new THREE.MeshLambertMaterial({
      color: color ? color : LayoutColor.primary,
    });
    return new THREE.Mesh(data, material);
  }
};

export const getMeshImport = async (
  fileName: string,
  url: string,
  color: string | null = null
): Promise<THREE.Object3D | undefined> => {
  const modelLoader = (
    loader: THREE.Loader,
    url: string
  ): Promise<THREE.BufferGeometry | THREE.Object3D> => {
    return new Promise((resolve, reject) => {
      if ((loader as any).load)
        (loader as any).load(url, (data) => resolve(data), null, reject);
    });
  };

  const fileType = getFileType(fileName) as FileType3D;
  const loader: THREE.Loader | undefined = getLoader(fileType);
  if (loader) {
    const data = await modelLoader(loader, url);
    return updateImport(data, color);
  }
  return undefined;
};

export const parseMeshImport = async (
  fileType: FileType3D,
  base64: string,
  color: string | null = null
): Promise<THREE.Object3D | undefined> => {
  const modelLoader = (
    loader: THREE.Loader,
    base64: string
  ): Promise<THREE.BufferGeometry | THREE.Object3D> => {
    return new Promise((resolve) => {
      if ((loader as any).parse) {
        const data = (loader as any).parse(base64);
        resolve(data);
      }
    });
  };

  const loader: THREE.Loader | undefined = getLoader(fileType);
  if (loader) {
    const data = await modelLoader(loader, base64);
    return updateImport(data, color);
  }
  return undefined;
};

/*export const getMeshExport = async (
  mesh: THREE.Object3D,
  fileType: FileType3D
): Promise<string | undefined> => {
  if (fileType === FileType3D.OBJ) {
    const exporter = new OBJExporter();
    return window.btoa(exporter.parse(mesh));
  }
  if (fileType === FileType3D.STL) {
    const exporter = new STLExporter();
    return window.btoa(exporter.parse(mesh));
  }
  return undefined;
};*/

export const getMeshExport = async (
  mesh: THREE.Object3D,
  fileType: FileType3D
): Promise<{ objData?: string, mtlData?: string, stlData?: string } | undefined> => {
  if (fileType === FileType3D.OBJ) {
    const exporter = new OBJExporter();
    const objData = exporter.parse(mesh);

    // If there's material information in the mesh, export it as MTL
    let mtlData;
    const materials = new Set<string>(); // Collect material definitions here if applicable

    mesh.traverse((child) => {
      if (child instanceof THREE.Mesh && child.material) {
        // Assuming the material has a name, color, etc., add it to the MTL data
        const material = child.material as THREE.MeshStandardMaterial; // or appropriate material type
        materials.add(`newmtl ${material.name}\nKd ${material.color.r} ${material.color.g} ${material.color.b}\n`);
      }
    });

    if (materials.size > 0) {
      mtlData = Array.from(materials).join("\n");
    }

    return {
      objData: window.btoa(objData),
      mtlData: mtlData ? window.btoa(mtlData) : undefined
    };
  }

  if (fileType === FileType3D.STL) {
    const exporter = new STLExporter();
    const stlData = exporter.parse(mesh);
    return {
      stlData: window.btoa(stlData)
    };
  }

  return undefined;
};

/*
export const createMeshImport = (
  scene: THREE.Scene,
  fileName: string,
  url: string,
  cleanUp = false
): void => {
  if (cleanUp) cleanUpScene(scene);

  let mesh: THREE.Object3D | undefined = undefined;
  const fileType = getFileType(fileName);
  if (fileType === FileType3D.FBX) {
    const loader = new FBXLoader();
    loader.load(url, (fbx) => {
      mesh = fbx;
      scene.add(mesh);
    });
  }
  if (fileType === FileType3D.OBJ) {
    const loader = new OBJLoader();
    loader.load(url, (obj) => {
      mesh = obj;
      scene.add(mesh);
    });
  }
  if (fileType === FileType3D.STL) {
    const loader = new STLLoader();
    loader.load(url, (stl) => {
      const material = new THREE.MeshLambertMaterial({
        color: LayoutColor.primary,
      });
      mesh = new THREE.Mesh(stl, material);
      scene.add(mesh);
    });
  }
};*/

export const createMeshImport = (
  scene: THREE.Scene,
  fileName: string,
  url: string,
  cleanUp = false,
  mtlUrl?: string // optional mtl file URL
): void => {
  if (cleanUp) cleanUpScene(scene);

  let mesh: THREE.Object3D | undefined = undefined;
  const fileType = getFileType(fileName);

  if (fileType === FileType3D.FBX) {
    const loader = new FBXLoader();
    loader.load(url, (fbx) => {
      mesh = fbx;
      scene.add(mesh);
    });
  }

  if (fileType === FileType3D.OBJ) {
    const loader = new OBJLoader();

    // If there's an associated MTL file, load it first
    if (mtlUrl) {
      const mtlLoader = new MTLLoader();
      mtlLoader.load(mtlUrl, (materials) => {
        materials.preload();
        loader.setMaterials(materials);
        loader.load(url, (obj) => {
          mesh = obj;
          scene.add(mesh);
        });
      });
    } else {
      // No MTL file, just load the OBJ
      loader.load(url, (obj) => {
        mesh = obj;
        scene.add(mesh);
      });
    }
  }

  if (fileType === FileType3D.STL) {
    const loader = new STLLoader();
    loader.load(url, (stl) => {
      const material = new THREE.MeshLambertMaterial({
        color: LayoutColor.primary,
      });
      mesh = new THREE.Mesh(stl, material);
      scene.add(mesh);
    });
  }
};


export const ObjModel = OBJ;
export const StlModel = STL;
export const MtlModel = MTL;

export const cleanUpScene = (scene: THREE.Scene): void => {
  const list = scene.children.filter((child) => !(child as any).isLight);
  for (const child of list) {
    scene.remove(child);
  }
};
