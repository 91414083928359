<template>
    <div v-if="!readonly">
        <div class="row mb-2">
            <div class="col">
                <label for="bathtub-type" class="form-label mb-2">{{ $t('components.product-parameter.bathtub.type') }}</label><br />
                <el-radio-group v-model="properties[0].bathtubType" size="large" id="bathtubType">
                    <el-radio-button
                    v-for="bathtubType in Object.values(BathtubType)"
                    :key="bathtubType"
                    :label="bathtubType"
                    :value="bathtubType"
                    @change="$emit('sizeChanged')"
                    :disabled="readonly"
                    >
                    {{ $t(`enum.bathtubType.${bathtubType}`) }} <br />
                    </el-radio-button>
                </el-radio-group>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <label for="anti-slip" class="form-label mb-2">{{ $t('components.product-parameter.bathtub.antiSlip') }}</label><br />
                <el-switch v-model="properties[0].antiSlip"/>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <label for="wallpanels" class="form-label mb-2">{{ $t('components.product-parameter.bathtub.wallpanels') }}</label><br />
                <el-switch v-model="properties[0].wallpanels"/>
            </div>
        </div>

        <div v-if="properties[0].wallpanels">
            <div class="row mb-2">
                <div class="col">
                    <label for="panel-variant" class="form-label mb-2">{{ $t('components.product-parameter.bathtub.panelVariant') }}</label><br />
                    <el-radio-group v-model="properties[0].panelVariant" size="large" id="panel-variant">
                        <el-radio-button
                        v-for="panelVariant in Object.values(PanelVariant)"
                        :key="panelVariant"
                        :label="panelVariant"
                        :value="panelVariant"
                        @change="[$emit('sizeChanged')]"
                        >
                        {{ $t(`enum.panelVariant.${panelVariant}`) }} <br />
                        </el-radio-button>
                    </el-radio-group>
                </div>
            </div>
        
            <div class="row mb-2" v-if="properties[0].panelVariant == PanelVariant.single">
                <div class="col">
                    <el-checkbox
                        style="color: #fff"
                        value="right"
                        label="right"
                        size="large"
                        v-model="properties[0].singleType"
                    >{{ $t('components.product-parameter.bathtub.right') }}</el-checkbox>
                    
                    <el-checkbox
                        style="color: #fff"
                        value="left"
                        label="left"
                        size="large"
                        v-model="properties[0].singleType"
                    >{{ $t('components.product-parameter.bathtub.left') }}</el-checkbox>
                    
                    <el-checkbox
                        style="color: #fff"
                        value="back"
                        label="back"
                        size="large"
                        v-model="properties[0].singleType"
                    >{{ $t('components.product-parameter.bathtub.back') }}</el-checkbox>
                </div>
            </div>

            <div class="row mb-2" v-if="properties[0].panelVariant == PanelVariant.combo">
                <el-radio-group v-model="properties[0].comboType" size="medium" id="panel-variant">
                    <el-radio-button value="right" label="right">{{ $t(`components.product-parameter.bathtub.combo1`) }}</el-radio-button>
                    <el-radio-button value="left" label="right">{{ $t(`components.product-parameter.bathtub.combo2`) }}</el-radio-button>
                </el-radio-group>
            </div>

            <div class="row mb-2" v-if="properties[0].panelVariant == PanelVariant.complete">
                <el-radio-group size="medium" id="panel-variant">
                    <el-radio-button>{{ $t(`components.product-parameter.bathtub.complete`) }}</el-radio-button>
                </el-radio-group>
            </div>

            <div class="row" v-if="(properties[0].panelVariant == PanelVariant.combo && properties[0].comboType === 'right') || properties[0].panelVariant == PanelVariant.complete">
                <div class="col">
                <label for="right-angle-range" class="form-label mb-0">{{ $t('components.order-form.angle') + ' ' + $t('enum.views.right') + ' (' + $t('components.order-form.degree') + ')' }}</label>
                <el-slider
                    v-model="properties[0].angleRight"
                    show-input
                    :precision="0"
                    :min="0"
                    :max="360"
                    :step="1"
                    :show-tooltip="false"
                    if="right-angle-range"
                    :disabled="readonly"
                    />
                </div>
            </div>

            <div class="row" v-if="(properties[0].panelVariant == PanelVariant.combo && properties[0].comboType === 'left') || properties[0].panelVariant == PanelVariant.complete">
                <div class="col">
                <label for="left-angle-range" class="form-label mb-0">{{ $t('components.order-form.angle') + ' ' + $t('enum.views.left') + ' (' + $t('components.order-form.degree') + ')' }}</label>
                <el-slider
                    v-model="properties[0].angleLeft"
                    show-input
                    :precision="0"
                    :min="0"
                    :max="360"
                    :step="1"
                    :show-tooltip="false"
                    if="left-angle-range"
                    :disabled="readonly"
                    />
                </div>
            </div>
        
            <div class="row" v-if="properties[0].panelVariant == PanelVariant.complete">
                <div class="col">
                <label for="width-range" class="form-label mb-0">{{ $t('components.order-form.panelWidth') }}</label>
                <el-slider
                    v-model="properties[0].panelWidth"
                    show-input
                    :precision="0"
                    :min="210"
                    :max="400"
                    :step="10"
                    :show-tooltip="false"
                    id="width-range"
                    @change="$emit('sizeChanged')"
                    :disabled="readonly"
                />
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <h3 class="mt-3">{{ $t('components.order-summary.parameterTitle') }}</h3>
        <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.product-parameter.bathtub.type') }}</label>
            <label class="col form-label mb-1">{{ $t(`enum.bathtubType.${properties[0].bathtubType}`) }}</label>
        </div>
        <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.product-parameter.bathtub.antiSlip') }}</label>
            <label class="col form-label mb-1">{{ $t(`enum.yesNoType.${properties[0].antiSlip}`) }}</label>
        </div>
        <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.product-parameter.bathtub.wallpanels') }}</label>
            <label class="col form-label mb-1">{{ $t(`enum.yesNoType.${properties[0].wallpanels}`) }}</label>
        </div>
        <div v-if="properties[0].wallpanels">
            <div class="row1">
                <label class="col form-label text-secondary mb-1">{{ $t('components.product-parameter.bathtub.panelVariant') }}</label>
                <label class="col form-label mb-1">{{ $t(`enum.panelVariant.${properties[0].panelVariant}`) }}</label>
            </div>
            <div class="row1">
                <label class="col form-label text-secondary mb-1">{{ $t('components.product-parameter.bathtub.description') }}</label>
                <label class="col form-label mb-1">{{ getPanelDescription() }}</label>
            </div>
        </div>
        <div class="row1" v-if="(properties[0].panelVariant == PanelVariant.combo && properties[0].comboType === 'right') || properties[0].panelVariant == PanelVariant.complete">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.angle') + ' ' + $t('enum.views.right') + ' (' + $t('components.order-form.degree') + ')' }}</label>
            <label class="col form-label mb-1">{{ properties[0].angleRight }}</label>
        </div>
        <div class="row1" v-if="(properties[0].panelVariant == PanelVariant.combo && properties[0].comboType === 'left') || properties[0].panelVariant == PanelVariant.complete">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.angle') + ' ' + $t('enum.views.left') + ' (' + $t('components.order-form.degree') + ')' }}</label>
            <label class="col form-label mb-1">{{ properties[0].angleLeft }}</label>
        </div>
        <div class="row1" v-if="properties[0].panelVariant == PanelVariant.complete">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.panelWidth') }}</label>
            <label class="col form-label mb-1">{{ properties[0].panelWidth }}</label>
        </div>
    </div>
</template>
  
<script lang="ts">
import { BathtubType, PanelVariant } from "@/types/enum/order";
import { Options, Vue, prop } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Check, Close, Connection } from '@element-plus/icons-vue';
import { shallowRef } from "vue";
import i18n from "@/i18n";

export default class ProductParameterBathtub extends Vue {
    @Prop() properties!: any[];
    @Prop({ default: false }) readonly!: boolean;

    BathtubType = BathtubType;
    PanelVariant = PanelVariant;

    Check = shallowRef(Check);
    Close = shallowRef(Close);
    Connection = shallowRef(Connection);

    getPanelDescription() {
        let panelDescription;

        if (this.properties[0].panelVariant == 'single') {
            panelDescription = [];

            if (this.properties[0].singleType.toString().includes("right")) {
                panelDescription.push(i18n.t('components.product-parameter.bathtub.right'));
            }
            if (this.properties[0].singleType.toString().includes("left")) {
                panelDescription.push(i18n.t('components.product-parameter.bathtub.left'));
            }
            if (this.properties[0].singleType.toString().includes("back")) {
                panelDescription.push(i18n.t('components.product-parameter.bathtub.back'));
            }
        } else if (this.properties[0].panelVariant == 'combo') {
            if (this.properties[0].comboType.toString().includes("right")) {
                panelDescription = i18n.t(`components.product-parameter.bathtub.combo1`);
            } else if (this.properties[0].comboType.toString().includes("left")) {
                panelDescription = i18n.t(`components.product-parameter.bathtub.combo2`);
            }
        } else if (this.properties[0].panelVariant == 'complete') {
            panelDescription = i18n.t(`components.product-parameter.bathtub.complete`);
        }

        return panelDescription;
    }
}
</script>

<style scoped lang="scss">
.text-secondary {
color: #0000008D !important;
}
.form-label {
width: 20rem;
}

.parameter-img {
padding-top: 12px;
width: 100px;
height: 100px;
}

.row1 {
  border-bottom: 0.1rem solid #6767678d;
}
</style>