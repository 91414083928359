/**
 * Created on: 2022-09-06T08:19:59.4200271+00:00
 */
export class MarkingPoint {
  public constructor() {
    //
  }

  protected _markingPointId!: number;
  protected _name!: string;

  /**
   * @returns _markingPointId
   */
  public get markingPointId() {
    return this._markingPointId;
  }

  /**
   * Sets _markingPointId
   */
  public set markingPointId(value: number) {
    this._markingPointId = value;
  }

  /**
   * @returns _name
   */
  public get name() {
    return this._name;
  }

  /**
   * Sets _name
   */
  public set name(value: string) {
    this._name = value;
  }

  // Start Userdefined content - don't change anything above

  public deepCopy(toCopy: MarkingPoint): MarkingPoint {
    if (!toCopy) return toCopy;

    const markingPoint = {
      name: toCopy.name
    } as MarkingPoint;

    return markingPoint;
  }

  // End Userdefined content - don't change anything below
}
