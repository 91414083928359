import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ec717ef6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = {
  for: "width-range",
  class: "form-label mb-0"
}
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = {
  for: "height-range",
  class: "form-label mb-0"
}
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = {
  for: "length-range",
  class: "form-label mb-0"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "col mb-2" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col form-label text-secondary mb-1" }
const _hoisted_15 = { class: "col form-label mb-1" }
const _hoisted_16 = { class: "col mb-2" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "col form-label text-secondary mb-1" }
const _hoisted_19 = { class: "col form-label mb-1" }
const _hoisted_20 = { class: "col mb-2" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { class: "col form-label text-secondary mb-1" }
const _hoisted_23 = { class: "col form-label mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_slider = _resolveComponent("el-slider")!

  return (!_ctx.readonly)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('components.order-form.width')), 1),
            _createVNode(_component_el_slider, {
              modelValue: _ctx.properties[0].calfCircumferenceWithoutDeduction,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.properties[0].calfCircumferenceWithoutDeduction) = $event)),
              "show-input": "",
              precision: 0,
              min: 0,
              max: 20000,
              step: 1,
              "show-tooltip": false,
              id: "width-range"
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('components.order-form.height')), 1),
            _createVNode(_component_el_slider, {
              modelValue: _ctx.properties[0].heightWithoutDeduction,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.properties[0].heightWithoutDeduction) = $event)),
              "show-input": "",
              precision: 0,
              min: 0,
              max: 20000,
              step: 1,
              "show-tooltip": false,
              if: "height-range"
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('components.order-form.depth')), 1),
            _createVNode(_component_el_slider, {
              modelValue: _ctx.properties[0].depth,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.properties[0].depth) = $event)),
              "show-input": "",
              precision: 0,
              min: 0,
              max: 20000,
              step: 1,
              "show-tooltip": false,
              id: "length-range"
            }, null, 8, ["modelValue"])
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('components.order-form.width')), 1),
            _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.properties[0].calfCircumferenceWithoutDeduction), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('components.order-form.height')), 1),
            _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.properties[0].heightWithoutDeduction), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t('components.order-form.depth')), 1),
            _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.properties[0].depth), 1)
          ])
        ])
      ]))
}