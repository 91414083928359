<template>
  <div
    v-if="canDeform"
    class="level-item action"
    v-on:click="activatePaintModification"
    :class="{ active: paintModificationActive }"
  >
    <font-awesome-icon icon="paintbrush" />
  </div>
  <div
    v-if="canDeform"
    class="level-item action"
    v-on:click="activateSoftModification"
    :class="{ active: softModificationActive }"
  >
    <font-awesome-icon icon="hand-pointer" />
  </div>
  <div
    v-if="canDeform"
    class="level-item action"
    v-on:click="activateFFD"
    :class="{ active: ffdActive }"
  >
    <!--<font-awesome-icon icon="vector-polygon" />-->
    <font-awesome-icon icon="question" />
  </div>
  <el-popover
    v-if="canDeform && ffdActive"
    placement="bottom-start"
    :title="$t('components.mesh-editor.ffd.title')"
    :width="200"
    trigger="click"
  >
    <template #reference>
      <div class="level-item action" @click="showFfdOptions = !showFfdOptions">
        <font-awesome-icon icon="gear" />
      </div>
    </template>
    {{ $t('components.mesh-editor.ffd.net_resolution') }}
    <el-slider
      v-model="ffdResolutionY"
      :min="2"
      :max="20"
      v-on:change="changeResolution"
    />
    {{ $t('components.mesh-editor.ffd.axis') }}
    <el-select v-model="ffdAxis">
      <el-option
        v-for="item of availableAxis"
        :key="item"
        :label="$t(`enum.ffdAxis.${item}`)"
        :value="item"
      />
    </el-select>
    <div
      v-if="
        !editGrid &&
        (ffdAxis === FFDAxis.x ||
          ffdAxis === FFDAxis.y ||
          ffdAxis === FFDAxis.z)
      "
    >
      {{ $t('components.mesh-editor.ffd.snapAlongAxis') }}
      <el-switch v-model="snap" />
    </div>
    {{ $t('components.mesh-editor.ffd.editGrid') }}
    <el-switch v-model="editGrid" />
    <br />
    <br />
    <el-button @click="rebuildMesh" type="primary">
      {{ $t('components.mesh-editor.ffd.rebuild') }}
    </el-button>
  </el-popover>
  <el-popover
    v-if="canDeform && softModificationActive"
    placement="bottom-start"
    :title="$t('components.mesh-editor.softModification.title')"
    :width="200"
    trigger="click"
  >
    <template #reference>
      <div class="level-item action">
        <font-awesome-icon icon="gear" />
      </div>
    </template>
    {{ $t('components.mesh-editor.softModification.radius') }}
    <el-slider v-model="softModificationSize" :min="1" :max="50" />
  </el-popover>
  <el-popover
    v-if="canDeform && paintModificationActive"
    placement="bottom-start"
    :title="$t('components.mesh-editor.paintModification.title')"
    :width="200"
    trigger="click"
  >
    <template #reference>
      <div class="level-item action">
        <font-awesome-icon icon="gear" />
      </div>
    </template>
    {{ $t('components.mesh-editor.paintModification.radius') }}
    <el-slider v-model="paintModificationSize" :min="1" :max="50" />
    {{ $t('components.mesh-editor.paintModification.depth') }}
    <el-slider v-model="paintModificationDepth" :min="1" :max="50" />
  </el-popover>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as THREE from 'three';
import Controller, {
  GridData,
} from '@/plugin/free-form-deformation/Controller';
import { RendererPublicInterface } from 'troisjs';
import { MeshSelector, SelectionList } from '@/types/ui/SelectionList';
import SoftModification from '@/utils/three/SoftModification';
import { FFDAxis, isSpecialObject } from '@/types/enum/three';
import PaintModification from '@/utils/three/PaintModification';
import { FFDExportData } from '@/services/api/templateService';
import { MeshTreeData } from '@/types/ui/MeshTreeData';
import {
  HistoryInfo,
  HistoryList,
  HistoryOperationType,
} from '@/types/ui/HistoryList';
import { ElMessageBox } from 'element-plus';

const defaultResolution = 5;

export enum DeformationToolType {
  ffd,
  softModification,
  paintModification,
}

@Options({
  components: {},
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class DeformationTools
  extends Vue
  implements HistoryInfo, MeshSelector
{
  @Prop() modelValue!: MeshTreeData;
  @Prop({ default: true }) canDeform!: boolean;
  @Prop() troisRenderer!: RendererPublicInterface;
  @Prop() selectionList!: SelectionList;
  @Prop() historyList!: HistoryList;
  @Prop() meshSelector!: MeshSelector;
  @Prop() readonly selectMeshById!: (meshId: string) => Promise<void>;

  //history
  lastUpdateTime = -1;

  //Free Form Deformation
  ffd!: Controller;
  ffdActive = false;
  ffdActivePrevious = false;
  ffdResolutionX = 2;
  ffdResolutionY = 5;
  ffdResolutionZ = 2;
  ffdAxis: FFDAxis = FFDAxis.all;
  showFfdOptions = false;
  editGrid = false;
  snap = false;

  //Soft Modification Tool
  softModificationActive = false;
  softModificationActivePrevious = false;
  softModification!: SoftModification;
  softModificationSize = 20;

  //paint
  paintModificationActive = false;
  paintModificationActivePrevious = false;
  paintModification!: PaintModification;
  paintModificationSize = 20;
  paintModificationDepth = 10;

  //data
  gridDataPerMesh: {
    [name: string]: GridData;
  } = {};
  latticeDataPerMesh: {
    [name: string]: {
      spanCounts: number[];
      gridPositionList: THREE.Vector3[];
    };
  } = {};
  mergedGeometry: string[] = [];

  FFDAxis = FFDAxis;

  get availableAxis(): FFDAxis[] {
    if (this.editGrid) {
      return [FFDAxis.x, FFDAxis.y, FFDAxis.z];
    }
    return Object.values(FFDAxis);
  }

  saveCurrentGridData(): void {
    const selection = this.selectionList.getSelectedObjectSaveId();
    if (selection) {
      this.gridDataPerMesh[selection] = this.ffd.getGridData();
      this.latticeDataPerMesh[selection] = {
        spanCounts: this.gridDataPerMesh[selection].spanCounts,
        gridPositionList: this.gridDataPerMesh[selection].gridPositionList,
      };
    }
  }

  mounted(): void {
    if (
      this.troisRenderer &&
      this.troisRenderer.scene &&
      this.troisRenderer.camera
    ) {
      //Free Form Deformation Tool
      this.ffd = new Controller(
        this.ffdResolutionX,
        this.ffdResolutionY,
        this.ffdResolutionZ,
        this,
        this
      );
      this.ffd.initView(
        this.troisRenderer.scene,
        this.troisRenderer.camera,
        this.troisRenderer.renderer,
        this.troisRenderer.three.cameraCtrl
      );

      //Soft Modification Tool
      this.softModification = new SoftModification(
        this.softModificationSize,
        this,
        this
      );
      this.softModification.initView(
        this.troisRenderer.scene,
        this.troisRenderer.camera,
        this.troisRenderer.renderer,
        this.troisRenderer.three.cameraCtrl,
        this.selectionList
      );

      //Paint Modification Tool
      this.paintModification = new PaintModification(
        this.paintModificationSize,
        this.paintModificationDepth,
        this,
        this
      );
      this.paintModification.initView(
        this.troisRenderer.scene,
        this.troisRenderer.camera,
        this.troisRenderer.renderer,
        this.troisRenderer.three.cameraCtrl,
        this.selectionList
      );
    }
  }

  unmounted(): void {
    this.ffd.cleanupView();
    this.softModification.cleanupView();
    this.paintModification.cleanupView();
  }

  @Watch('canDeform', { immediate: true })
  onCanDeformChanged(): void {
    this.setToolForSelection();
  }

  @Watch('softModificationSize', { immediate: false })
  onSoftModificationSizeChanged(): void {
    this.softModification.changeSize(this.softModificationSize);
  }

  activateSoftModification(): void {
    this.softModificationActive = !this.softModificationActive;
    this.ffdActive = false;
    this.paintModificationActive = false;
    this.setToolForSelection();
  }

  @Watch('paintModificationSize', { immediate: false })
  onPaintModificationSizeChanged(): void {
    this.paintModification.changeSize(this.paintModificationSize);
  }

  @Watch('paintModificationDepth', { immediate: false })
  onPaintModificationDepthChanged(): void {
    this.paintModification.changeDepth(this.paintModificationDepth);
  }

  @Watch('lastUpdateTime', { immediate: true })
  onDataChanged(): void {
    if (this.lastUpdateTime > -1) {
      const mesh = this.selectionList.getSelectedObject();
      this.modelValue.updateDB((mesh as any).apiData.uuid);
    }
  }

  activatePaintModification(): void {
    this.paintModificationActive = !this.paintModificationActive;
    this.softModificationActive = false;
    this.ffdActive = false;
    this.setToolForSelection();
  }

  activateFFD(): void {
    this.ffdActive = !this.ffdActive;
    this.softModificationActive = false;
    this.paintModificationActive = false;
    this.setToolForSelection();
  }

  //@Watch('ffdResolutionX', { immediate: false })
  //@Watch('ffdResolutionY', { immediate: false })
  //@Watch('ffdResolutionZ', { immediate: false })
  onFfdResolutionChanged(): void {
    if (
      this.ffd.getResolutionX() !== this.ffdResolutionX ||
      this.ffd.getResolutionY() !== this.ffdResolutionY ||
      this.ffd.getResolutionZ() !== this.ffdResolutionZ
    ) {
      this.ffd.changeResolution(
        this.ffdResolutionX,
        this.ffdResolutionY,
        this.ffdResolutionZ
      );
      this.saveCurrentGridData();
    }
  }

  changeResolution(): void {
    ElMessageBox.confirm(
      (this as any).$t('confirm.historyDelete.message'),
      (this as any).$t('confirm.historyDelete.title'),
      {
        confirmButtonText: (this as any).$t('confirm.historyDelete.ok'),
        cancelButtonText: (this as any).$t('confirm.historyDelete.cancel'),
        type: 'warning',
      }
    )
      .then(() => {
        this.onFfdResolutionChanged();
        const meshId = this.selectionList.getSelectedObjectUuid();
        if (meshId) this.historyList.remove(meshId, HistoryOperationType.ffd);
      })
      .catch(() => {
        this.ffdResolutionY = this.ffd.getResolutionY();
      });
  }

  @Watch('ffdAxis', { immediate: false })
  onFfdAxisChanged(): void {
    this.ffd.changeAxis(this.ffdAxis);
  }

  @Watch('editGrid', { immediate: false })
  onEditGridChanged(): void {
    if (this.editGrid) {
      ElMessageBox.confirm(
        (this as any).$t('confirm.historyDelete.message'),
        (this as any).$t('confirm.historyDelete.title'),
        {
          confirmButtonText: (this as any).$t('confirm.historyDelete.ok'),
          cancelButtonText: (this as any).$t('confirm.historyDelete.cancel'),
          type: 'warning',
        }
      )
        .then(() => {
          this.ffdAxis = FFDAxis.x;
          this.ffd.changeMode(this.editGrid);
          this.snap = this.editGrid;
        })
        .catch(() => {
          this.editGrid = false;
        });
    } else {
      this.ffdAxis = FFDAxis.all;
      this.ffd.changeMode(this.editGrid);
      this.snap = this.editGrid;
    }
  }

  @Watch('snap', { immediate: false })
  onSnapChanged(): void {
    this.ffd.changeSnap(this.snap);
  }

  rebuildMesh(): void {
    ElMessageBox.confirm(
      (this as any).$t('confirm.historyDelete.message'),
      (this as any).$t('confirm.historyDelete.title'),
      {
        confirmButtonText: (this as any).$t('confirm.historyDelete.ok'),
        cancelButtonText: (this as any).$t('confirm.historyDelete.cancel'),
        type: 'warning',
      }
    ).then(() => {
      const meshId = this.selectionList.getSelectedObjectSaveId();
      if (meshId && this.gridDataPerMesh[meshId])
        delete this.gridDataPerMesh[meshId];
      if (meshId && this.latticeDataPerMesh[meshId])
        delete this.latticeDataPerMesh[meshId];
      this.setFfdForSelection();
      const meshUuid = this.selectionList.getSelectedObjectUuid();
      if (meshUuid) this.historyList.remove(meshUuid, HistoryOperationType.ffd);
    });
  }

  async selectMesh(meshId: string, type: DeformationToolType): Promise<void> {
    await this.selectMeshById(meshId);
    switch (type) {
      case DeformationToolType.ffd:
        if (!this.ffdActive) this.activateFFD();
        break;
      case DeformationToolType.softModification:
        if (!this.softModificationActive) this.activateSoftModification();
        break;
      case DeformationToolType.paintModification:
        if (!this.paintModificationActive) this.activatePaintModification();
        break;
    }
  }

  loadedMeshId: string | null = null;
  setToolForSelection(): void {
    const meshId = this.selectionList.getSelectedObjectSaveId();
    if (
      this.ffdActive !== this.ffdActivePrevious ||
      this.softModificationActive !== this.softModificationActivePrevious ||
      this.paintModificationActive !== this.paintModificationActivePrevious
    ) {
      if (this.ffd) this.ffd.clearMesh();
      if (this.softModification) this.softModification.clearMesh();
      if (this.paintModification) this.paintModification.clearMesh();
      if (this.canDeform) {
        const selection = this.selectionList.getSelectedObject();
        if (selection && !isSpecialObject(selection.name)) {
          this.setFfdForSelection();
          this.setSoftModificationForSelected();
          this.setPaintModificationForSelected();
          if (
            this.ffdActive ||
            this.softModificationActive ||
            this.paintModificationActive
          )
            this.loadedMeshId = meshId;
        }
      }
    } else if (meshId !== this.loadedMeshId) {
      if (!meshId) {
        this.loadedMeshId = meshId;
      }
      if (this.ffdActive) {
        if (this.ffd) this.ffd.clearMesh();
        if (this.canDeform) {
          const selection = this.selectionList.getSelectedObject();
          if (selection && !isSpecialObject(selection.name)) {
            this.setFfdForSelection();
          }
        }
      } else if (this.softModificationActive) {
        if (this.softModification) this.softModification.clearMesh();
        if (this.canDeform) {
          const selection = this.selectionList.getSelectedObject();
          if (selection && !isSpecialObject(selection.name)) {
            this.setSoftModificationForSelected();
          }
        }
      } else if (this.paintModificationActive) {
        if (this.paintModification) this.paintModification.clearMesh();
        if (this.canDeform) {
          const selection = this.selectionList.getSelectedObject();
          if (selection && !isSpecialObject(selection.name)) {
            this.setPaintModificationForSelected();
          }
        }
      }
    } else {
      if (!this.canDeform) {
        if (this.ffd) this.ffd.clearMesh();
        if (this.softModification) this.softModification.clearMesh();
        if (this.paintModification) this.paintModification.clearMesh();
        this.loadedMeshId = null;
      }
    }
  }

  setFfdForSelection(): void {
    if (!this.ffd) return;
    this.ffd.clearMesh();
    if (this.ffdActive && this.canDeform) {
      const meshId = this.selectionList.getSelectedObjectSaveId();
      const mesh = this.selectionList.getSelectedObject();
      if (mesh && meshId) {
        const grid = this.gridDataPerMesh[meshId];
        const lattice = this.latticeDataPerMesh[meshId];
        if (grid) {
          this.ffd.reloadMesh(
            grid.spanCounts,
            grid.boundingBox,
            grid.gridPositionList,
            grid.ctrlPositionList,
            grid.vertexPositionUndeformed,
            mesh as THREE.Mesh,
            false
          );
          this.ffdResolutionY = grid.spanCounts[1];
        } else if (lattice) {
          const isMerged = this.mergedGeometry.includes(
            (mesh as any).apiData.uuid
          );
          this.ffd.initMesh(mesh as THREE.Mesh, !isMerged);
          if (!isMerged) this.mergedGeometry.push((mesh as any).apiData.uuid);
          this.ffd.changeResolution(
            lattice.spanCounts[0],
            lattice.spanCounts[1],
            lattice.spanCounts[2]
          );
          this.ffd.changeGrid(lattice.gridPositionList);
          this.ffdResolutionY = lattice.spanCounts[1];
        } else {
          this.ffd.initMesh(mesh as THREE.Mesh);
          this.ffdResolutionY = defaultResolution;
        }
        this.editGrid = false;
        this.ffdAxis = FFDAxis.all;
        this.onFfdResolutionChanged();
        this.saveCurrentGridData();
      }
      this.loadedMeshId = meshId;
    } else {
      this.loadedMeshId = null;
    }
    this.ffdActivePrevious = this.ffdActive;
  }

  setSoftModificationForSelected(): void {
    if (!this.softModification) return;
    this.softModification.clearMesh();
    if (this.softModificationActive && this.canDeform) {
      const mesh = this.selectionList.getSelectedObject();
      if (mesh) {
        const isMerged = this.mergedGeometry.includes(
          (mesh as any).apiData.uuid
        );
        this.softModification.initMesh(mesh as THREE.Mesh, !isMerged);
        if (!isMerged) this.mergedGeometry.push((mesh as any).apiData.uuid);
      }
      this.loadedMeshId = this.selectionList.getSelectedObjectSaveId();
    } else {
      this.loadedMeshId = null;
    }
    this.softModificationActivePrevious = this.softModificationActive;
  }

  setPaintModificationForSelected(): void {
    if (!this.paintModification) return;
    this.paintModification.clearMesh();
    if (this.paintModificationActive && this.canDeform) {
      const mesh = this.selectionList.getSelectedObject();
      if (mesh) {
        const isMerged = this.mergedGeometry.includes(
          (mesh as any).apiData.uuid
        );
        this.paintModification.initMesh(mesh as THREE.Mesh, !isMerged);
        if (!isMerged) this.mergedGeometry.push((mesh as any).apiData.uuid);
      }
      this.loadedMeshId = this.selectionList.getSelectedObjectSaveId();
    } else {
      this.loadedMeshId = null;
    }
    this.paintModificationActivePrevious = this.paintModificationActive;
  }

  //#region import / export
  exportFfdForMeshId(meshId: string): FFDExportData | null {
    const data = this.latticeDataPerMesh[meshId];
    if (data) {
      return {
        spanCounts: data.spanCounts,
        gridPositionList: data.gridPositionList,
      };
    }
    return null;
  }

  importFfdForMeshId(
    meshId: string,
    spanCount: number[],
    gridPositionList: THREE.Vector3[]
  ): void {
    this.latticeDataPerMesh[meshId] = {
      spanCounts: spanCount,
      gridPositionList: gridPositionList,
    };
  }
  //#endregion import / export
}
</script>

<style lang="scss" scoped>
.active {
  color: var(--el-color-primary);
}
</style>
