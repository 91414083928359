import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f528748"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "level-item"
}
const _hoisted_2 = { class: "level-item action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.editorMode.isOrientationMode && _ctx.canTransform)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.$t('components.mesh-editor.damping')) + "  ", 1),
          _createVNode(_component_el_select, {
            modelValue: _ctx.activeDamping,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeDamping) = $event)),
            "fit-input-width": true,
            style: {"width":"5rem"}
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dampingRotation, (damping) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: damping,
                  value: damping,
                  label: `${damping}°`
                }, null, 8, ["value", "label"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.editorMode.isOrientationMode && _ctx.canTransform)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["level-item action", {
      active: _ctx.orientationDirection === _ctx.OrientationAxis.x,
    }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setOrientationDirection(_ctx.OrientationAxis.x)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "arrow-right-long" })
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.editorMode.isOrientationMode && _ctx.canTransform)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["level-item action", {
      active: _ctx.orientationDirection === _ctx.OrientationAxis.y,
    }]),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setOrientationDirection(_ctx.OrientationAxis.y)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "arrow-up-long" })
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.editorMode.isOrientationMode && _ctx.canTransform)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: _normalizeClass(["level-item action", {
      active: _ctx.orientationDirection === _ctx.OrientationAxis.z,
    }]),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setOrientationDirection(_ctx.OrientationAxis.z)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "arrow-down-right" })
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.editorMode.isOrientationMode && _ctx.canTransform)
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: _normalizeClass(["level-item action", {
      active: _ctx.orientationDirection === _ctx.OrientationAxis.all,
    }]),
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setOrientationDirection(_ctx.OrientationAxis.all)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: ['fac', 'axes'] })
        ], 2))
      : _createCommentVNode("", true),
    (
      (_ctx.editorMode.isDefaultMode || _ctx.editorMode.isPivotMode) &&
      _ctx.canTransform &&
      _ctx.canTogglePivotMode
    )
      ? (_openBlock(), _createElementBlock("div", {
          key: 5,
          class: _normalizeClass(["level-item action", { active: _ctx.editorMode.isPivotMode }]),
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.togglePivotMode()))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "location-crosshairs" })
        ], 2))
      : _createCommentVNode("", true),
    (
      (_ctx.editorMode.isDefaultMode || _ctx.editorMode.isPivotMode) &&
      _ctx.canTransform &&
      _ctx.canManualTransform
    )
      ? (_openBlock(), _createBlock(_component_el_popover, {
          key: 6,
          ref: "popoverManualTransformation",
          placement: "bottom-start",
          title: _ctx.$t('components.mesh-editor.coordinates'),
          width: 200,
          trigger: "click"
        }, {
          reference: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_font_awesome_icon, { icon: "keyboard" })
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_form, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, { label: "x" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input_number, {
                      modelValue: _ctx.manualTransformationX,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.manualTransformationX) = $event)),
                      placeholder: "x"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "y" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input_number, {
                      modelValue: _ctx.manualTransformationY,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.manualTransformationY) = $event)),
                      placeholder: "y"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "z" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input_number, {
                      modelValue: _ctx.manualTransformationZ,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.manualTransformationZ) = $event)),
                      placeholder: "z"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      onClick: _ctx.resetManualTransformation,
                      type: "primary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('components.mesh-editor.reset')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true),
    ((_ctx.editorMode.isDefaultMode || _ctx.editorMode.isPivotMode) && _ctx.canTransform)
      ? (_openBlock(), _createElementBlock("div", {
          key: 7,
          class: _normalizeClass(["level-item action", { active: _ctx.isModifyTypeTransform }]),
          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.setModifyType(_ctx.ModifyType.translate)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "arrows-alt" })
        ], 2))
      : _createCommentVNode("", true),
    ((_ctx.editorMode.isDefaultMode || _ctx.editorMode.isPivotMode) && _ctx.canTransform)
      ? (_openBlock(), _createElementBlock("div", {
          key: 8,
          class: _normalizeClass(["level-item action", { active: _ctx.isModifyTypeRotate }]),
          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.setModifyType(_ctx.ModifyType.rotate)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "sync" })
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.editorMode.isDefaultMode && _ctx.canTransform)
      ? (_openBlock(), _createElementBlock("div", {
          key: 9,
          class: _normalizeClass(["level-item action", {
      active: _ctx.isModifyTypeScale,
      disabled: _ctx.editorMode.isPivotMode,
    }]),
          onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.setModifyType(_ctx.ModifyType.scale)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "expand-arrows-alt" })
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}