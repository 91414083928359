import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1fa67048"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "level-item action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.canRender)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "level-item",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.renderActiveView && _ctx.renderActiveView(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "play" })
        ]))
      : _createCommentVNode("", true),
    (_ctx.canRender)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "level-item",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.renderAllViews && _ctx.renderAllViews(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "forward" })
        ]))
      : _createCommentVNode("", true),
    (_ctx.canRender)
      ? (_openBlock(), _createBlock(_component_el_popover, {
          key: 2,
          ref: "popoverAddRenderView",
          placement: "bottom-start",
          title: _ctx.$t('components.mesh-editor.render.title'),
          width: 200,
          trigger: "click"
        }, {
          reference: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_font_awesome_icon, { icon: "circle-plus" })
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_form, {
              ref: "dataForm",
              model: _ctx.cameraData,
              onSubmit: _ctx.addCamera
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('components.mesh-editor.render.view'),
                  prop: "view"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_radio_group, {
                      modelValue: _ctx.cameraData.view,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.cameraData.view) = $event)),
                      onChange: _ctx.changeView
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_ctx.Views), (item) => {
                          return (_openBlock(), _createBlock(_component_el_radio, {
                            key: item,
                            label: item
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_font_awesome_icon, {
                                icon: ['fac', item]
                              }, null, 8, ["icon"]),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t(`enum.views.${item}`)), 1)
                            ]),
                            _: 2
                          }, 1032, ["label"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "onChange"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('components.mesh-editor.render.name'),
                  prop: "name",
                  rules: {
          required: true,
        }
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.cameraData.name,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.cameraData.name) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      onClick: _ctx.addCamera,
                      type: "primary",
                      disabled: _ctx.cameraData.name.length === 0
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('components.mesh-editor.render.add')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model", "onSubmit"])
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true)
  ], 64))
}