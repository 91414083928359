import { BodyPart } from '@/types/api/Patient/Anatomy/BodyPart';
import { Patient_Attribute } from '@/types/api/Patient/PatientAttribute';
import { ROI } from '@/types/api/Patient/ROI';
import { Side } from '@/types/api/Patient/Side';
import { File } from '../Utility/File';

/**
 * Created on: 2022-09-06T08:19:59.4011336+00:00
 */
export class Body_Attribute extends Patient_Attribute {
  public constructor() {
    super();
  }

  protected _scanFile!: File;
  protected _skeleton!: File;
  protected _scaleFactor!: number;
  protected _side!: Side;
  protected _roi!: ROI;
  protected _bodyPart!: BodyPart;

  /**
   * @returns _scanPath
   */
  public get scanFile() {
    return this._scanFile;
  }

  /**
   * Sets _scanPath
   */
  public set scanPath(value: File) {
    this._scanFile = value;
  }

  /**
   * @returns _skeleton
   */
  public get skeleton() {
    return this._skeleton;
  }

  /**
   * Sets _scanPath
   */
  public set skeleton(value: File) {
    this._skeleton = value;
  }

  /**
   * @returns _scaleFactor
   */
  public get scaleFactor() {
    return this._scaleFactor;
  }

  /**
   * Sets _scaleFactor
   */
  public set scaleFactor(value: number) {
    this._scaleFactor = value;
  }

  /**
   * @returns _side
   */
  public get side() {
    return this._side;
  }

  /**
   * Sets _side
   */
  public set side(value: Side) {
    this._side = value;
  }

  /**
   * @returns _roi
   */
  public get roi() {
    return this._roi;
  }

  /**
   * Sets _roi
   */
  public set roi(value: ROI) {
    this._roi = value;
  }

  /**
   * @returns _bodyPart
   */
  public get bodyPart() {
    return this._bodyPart;
  }

  /**
   * Sets _bodyPart
   */
  public set bodyPart(value: BodyPart) {
    this._bodyPart = value;
  }

  // Start Userdefined content - don't change anything above

  public deepCopy(toCopy: Body_Attribute): Body_Attribute {
    if (!toCopy) return toCopy;

    const bodyAttribute = {
      skeleton: new File().deepCopy(toCopy.skeleton),
      scanFile: new File().deepCopy(toCopy.scanFile),
      scaleFactor: toCopy.scaleFactor,
      side: toCopy.side,
      roi: new ROI().deepCopy(toCopy.roi),
      bodyPart: new BodyPart().deepCopy(toCopy.bodyPart)
    } as Body_Attribute;

    return bodyAttribute
  }

  // End Userdefined content - don't change anything below
}
