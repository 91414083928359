import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5922a066"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "expander-position" }
const _hoisted_2 = {
  key: 0,
  class: "expander-side"
}
const _hoisted_3 = {
  key: 1,
  class: "expander-side expander-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_aside = _resolveComponent("el-aside")!

  return (_openBlock(), _createBlock(_component_el_aside, {
    width: "3rem",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', !_ctx.modelValue))),
    class: "minimize"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.useLeftSidebar)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.modelValue)
                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 0,
                    icon: "caret-left"
                  }))
                : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 1,
                    icon: "caret-right"
                  }))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_ctx.modelValue)
                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 0,
                    icon: "caret-right"
                  }))
                : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 1,
                    icon: "caret-left"
                  }))
            ]))
      ])
    ]),
    _: 1
  }))
}