<template>
  <el-container class="screenHeight">
    <el-header class="level">
      <span class="level-left">
        <el-page-header
          class="level-item"
          :content="$t('components.menu-sidebar.orthopedics.orders')"
          @back="$router.back()"
        />
      </span>
      <span class="level-right"> </span>
    </el-header>
    <el-container>
      <el-aside class="withFooterAndHeader" width="20rem" v-if="showMenu">
        <el-scrollbar>
          <side-menu active-menu-item="3-3"> </side-menu>
        </el-scrollbar>
      </el-aside>
      <ToggleSidebar v-model="showMenu" />
      <el-main v-loading="modelsLoading">
        <div class="media">
          <div class="media-content">
            <el-input
              v-model="searchText"
              :placeholder="$t('views.workInProgress.search')"
            />
          </div>
          <div class="media-right">
            <el-dropdown max-height="calc(100vh - 10rem)">
              <el-button type="primary">
                {{ $t('views.workInProgress.create') }}
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-for="model in templateList"
                    :key="model.modelId"
                  >
                    <el-card
                      v-on:click="createNewModel(model, $event)"
                      v-if="model.product"
                    >
                      <template #header>
                        {{ model.product.name }}
                      </template>
                      <PreviewRendererImg
                        :url="convertToUrl(model)"              
                      />
                    </el-card>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
        <el-space wrap>
          <el-card
            v-for="model in filterList"
            :key="model.modelId"
            v-on:click="displayModel(model, $event)"
          >
            <template #header>
              <span class="media" v-if="model.product">
                <span class="media-content">
                  {{ model.product.name }}
                </span>
                <span class="media-right">
                  <span id="deleteModel" @click="deleteModel(model.modelId)">
                    <font-awesome-icon icon="trash" />
                  </span>
                </span>
              </span>
            </template>
            <PreviewRendererImg
              :url="convertToUrl(model)"              
            />
          </el-card>
        </el-space>
      </el-main>
    </el-container>
  </el-container>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import * as modelService from '@/services/api/modelService';
import { Model, Model2 } from '@/types/api/Model/Model';
import { FileTypeImage } from '@/types/enum/upload';
import PreviewRenderer from '@/components/three/PreviewRenderer.vue';
import PreviewRendererImg from '@/components/three/PreviewRendererImg.vue';
import SideMenu from '@/components/workflow/SideMenu.vue';
import ToggleSidebar from '@/components/element-plus/ToggleSidebar.vue';
import { ElMessage, ElMessageBox } from 'element-plus';

@Options({
  components: {
    SideMenu,
    ToggleSidebar,
    PreviewRenderer,
    PreviewRendererImg
  },
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class OrderOverview extends Vue {
  modelList: Model2[] = [];
  templateList: Model2[] = [];
  FileTypeImage = FileTypeImage;
  showMenu = true;
  searchText = '';
  modelsLoading = false;

  mounted(): void {
    this.modelsLoading = true;
    modelService.getModelOverviewList().then(async (result) => {
      if (result) {
        const modelList = result.filter((item) => item.basedOn);
        const templateList = result.filter((item) => !item.basedOn);

        this.modelList = modelList;
        this.templateList = templateList;
      } else {
        this.modelList = [];
        this.templateList = [];
      }
      this.modelsLoading = false;
    });
  }

  get filterList(): Model2[] {
    if (this.searchText.length > 0)
      return this.modelList.filter(
        (item) =>
          item.product &&
          item.product.name?.toLowerCase().includes(this.searchText.toLowerCase())
      );
    return this.modelList;
  }

  convertToUrl(model: Model): string | null {
    if (
      model.product &&
      model.product.thumbnail
    ) {
      return model.product.thumbnail.content;
    }
    return null;
  }

  deleteModel(modelId: number): void {
    ElMessageBox.confirm(
      (this as any).$t('confirm.delete.message'),
      (this as any).$t('confirm.delete.title'),
      {
        confirmButtonText: (this as any).$t('confirm.delete.ok'),
        cancelButtonText: (this as any).$t('confirm.delete.cancel'),
        type: 'warning',
      }
    ).then(() => {
      modelService.deleteModel(modelId).then((result) => {
        if (!result) {
          ElMessage.error((this as any).$t('error.api.delete'));
        } else {
          ElMessage.success((this as any).$t('success.api.delete'));
        }
      });
      const index = this.modelList.findIndex(
        (item) => item.modelId === modelId
      );
      if (index > -1) {
        this.modelList.splice(index, 1);
      }
    });
  }

  displayModel(model: Model, event: PointerEvent): void {
    const path = event.composedPath();
    if (!path.find((item) => (item as any).id === 'deleteModel'))
      this.$router.push(
        `/order-workflow?templateId=${model.basedOn.modelId}&orderId=${model.modelId}`
      );
  }

  createNewModel(model: Model, event: PointerEvent): void {
    const path = event.composedPath();
    if (!path.find((item) => (item as any).id === 'deleteModel'))
      this.$router.push(`/order-workflow?templateId=${model.modelId}`);
  }
}
</script>

<style lang="scss" scoped>
.el-main {
  padding: 1rem;

  > .media {
    padding-bottom: 1rem;
  }
}

.level:not(:last-child) {
  margin-bottom: unset;
}

.el-container .el-container {
  max-height: calc(100vh - var(--el-header-height));
}

.el-dropdown-menu {
  padding: 0.1rem;
  width: 50vw;
  display: flex;
  flex-wrap: wrap;
}
</style>
