<script setup lang="ts">
import ButtonCard from "@/components/ButtonCard.vue";
import { SupportedValues } from "@/types/api/Utility/SupportedValues";
import * as ModelService from '@/services/api/modelService';
import { ref } from "vue";
import { useI18n } from 'vue3-i18n';
import { DesignType, getImageFileType } from "@/types/enum/upload";
import { fileContentToBase64 } from "@/utils/file";
import { ElMessageBox } from "element-plus";
import { MeshTreeData } from "@/types/ui/MeshTreeData";

enum ImageType {
  LR = "LR",
  HR = "HR"
}
const { t: $t } = useI18n();
const isLoading = ref<boolean>(false)
const fileInputLR = ref<HTMLInputElement | null>(null)
const fileInputHR = ref<HTMLInputElement | null>(null)
const selectedFilesLR = ref<ModelService.ImageFileType[]>([])
const selectedFilesHR = ref<ModelService.ImageFileType[]>([])

interface Props {
  engravingList: SupportedValues[];
  textureList: SupportedValues[];
  selectedEngraving: number | null;
  selectedTexture: number | null;
  productId: number;
  meshTreeData: MeshTreeData;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (event: 'refetch-embossing'): void;
  (event: 'refetch-texture'): void;
}>();

const triggerFileInput = (type:ImageType) => {
  if(type===ImageType.LR) return fileInputLR.value?.click();
  return fileInputHR.value?.click()
};

const setFiles = async (files: File[], type: ImageType)=> {
  try {
    files.forEach(file=>{
      fileContentToBase64(file, (encodedString) => {
        const currentFiles = type === ImageType.LR ? selectedFilesLR : selectedFilesHR
        const newId = currentFiles.value.length === 0 ? 1 : (currentFiles.value[currentFiles.value.length-1].id + 1)
        const newFile = {
          id: newId,
          name:file.name,
          type: getImageFileType(file.name),
          content:encodedString,
        }
        currentFiles.value = [...currentFiles.value, newFile]; 
      });
    })
  } catch (error) {
    console.error('[ERROR UPLOAD FILE]',error)
  }
}

const handleFile = (event: Event, type: ImageType) => {
  const target = event.target as HTMLInputElement;
  const files = target.files;
  if (files) {
    setFiles([...files], type)
    target.value = '';
  }
};

const removeImage = (id, type: ImageType)=>{
  const currentFiles = type === ImageType.LR ? selectedFilesLR : selectedFilesHR 
  currentFiles.value = currentFiles.value.filter(f=>f.id !== id)
}

const btnText = props.textureList.length > 0 ? DesignType.texture : DesignType.design

const handleUploadDesign = ({
  selectedFilesLR:selectedFilesLRArg,
  selectedFilesHR:selectedFilesHRArg,
})=>{
  isLoading.value = true
  if(!props.productId) return
  const abortController = new AbortController();

  const createEngraving = ModelService.createEngravingDesign({
      withoutBlank: true,
      productId: props.productId,
      designImageFilesLR: selectedFilesLRArg,
      designImageFilesHR: selectedFilesHRArg,
      abortController: abortController,
      type: btnText
    })
    
    // TODOS: UPLOAD TEXTURE BASED ON SELECTED PART LATER
    // const selectedPart = props.meshTreeData.getSelection()

  createEngraving.then(()=>{
      ElMessageBox.alert(
      $t('success.api.create'), 
      'Upload Success', {
        type: 'success', 
        confirmButtonText: 'OK',
      })
      isLoading.value = false
      selectedFilesLR.value = []
      selectedFilesHR.value = []
      btnText === 'Design' ? emit('refetch-embossing') : emit('refetch-texture')
    }).catch((err)=>{
      ElMessageBox.alert(
        $t('error.api.server'), 
        'Upload Failed', {
          type: 'error', 
          confirmButtonText: 'OK',
        })
      isLoading.value = false
      console.error('ERROR CREATE ENGRAVING', err)
    })
 
}
</script>

<template>
  <div id="design-selection" class="pl-5 pr-5 pt-3 pb-3 rounded-3 text-white">
    <h1>{{ $t('components.design-selection.title') }}</h1>
    <p class="form-text text-white">{{ $t('components.design-selection.info') }}</p>
    <p class="text-warning">{{ $t('components.mesh-loader.uploadDesigninfo') }}</p>
    <input 
      type="file" 
      ref="fileInputLR" 
      multiple
      :disabled="isLoading"
      accept="image/*" 
      style="display: none" 
      @change="(e)=>handleFile(e, ImageType.LR)"
    />
    <input 
      type="file" 
      ref="fileInputHR" 
      multiple
      :disabled="isLoading"
      accept="image/*" 
      style="display: none" 
      @change="(e)=>handleFile(e, ImageType.HR)"
    />
    <div class="row">
      <div class="col-lg-6">
        <button type="button" :disabled="isLoading" class="btn btn-primary mt-2" @click="()=>triggerFileInput(ImageType.LR)">{{ $t(`components.design-selection.upload${btnText}Button`) }} (LR)</button>
        <div class="mt-3" v-if="selectedFilesLR.length > 0">
          <p>Preview</p>
          <div class="d-flex flex-wrap gap-3 mt-1">
            <button-card v-for="(item, index) in selectedFilesLR" :key="item.id" :title="`(${index+1}) ${item.name}`">
              <img :src="item.content" class="img" :alt="item.name">
              <el-icon class="text-danger pointer" @click="()=>removeImage(item.id, ImageType.LR)">
                <font-awesome-icon icon="trash" />
              </el-icon>
            </button-card>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <button type="button" :disabled="isLoading" class="btn btn-primary mt-2" @click="()=>triggerFileInput(ImageType.HR)">{{ $t(`components.design-selection.upload${btnText}Button`) }} (HR)</button>
        <div class="mt-3" v-if="selectedFilesHR.length > 0">
          <p>Preview</p>
          <div class="d-flex flex-wrap gap-3 mt-1">
            <button-card v-for="(item, index) in selectedFilesHR" :key="item.id" :title="`(${index+1}) ${item.name}`">
              <img :src="item.content" class="img" :alt="item.name">
              <el-icon class="text-danger pointer" @click="()=>removeImage(item.id, ImageType.HR)">
                <font-awesome-icon icon="trash" />
              </el-icon>
            </button-card>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3" v-if="
     (selectedFilesLR.length === selectedFilesHR.length) && (selectedFilesLR.length > 0 && selectedFilesHR.length > 0)
    ">
      <button class="btn btn-primary"
      :disabled="isLoading"
      @click="()=>handleUploadDesign({
        selectedFilesLR,
        selectedFilesHR,
      })">Upload Images</button>
    </div>
    <hr/>
    <div class="d-flex flex-wrap gap-3 mt-5">
      <p v-if="props.engravingList.length == 0 && props.textureList.length == 0">{{ $t('views.customize.workflow.noEmbossing') }}</p>
      <div v-else class="d-flex flex-wrap gap-3 mt-5">
        <button-card v-for="item in props.engravingList" :key="item.thumbnail?.fileId!"
                     :title="$t(`views.designs.${item.thumbnail?.name}`) ?? item.thumbnail?.name"
                     :selected="props.selectedEngraving != undefined && props.selectedEngraving === item.fileId"
                     @click="$emit('engraving-selected', item.fileTextureId)">
          <img :src="item.thumbnail?.content" class="img" :alt="item.thumbnail?.name">
        </button-card>
        <button-card v-for="item in props.textureList" :key="item.thumbnail?.fileId!"
                     :title="$t(`views.designs.${item.thumbnail?.name}`) ?? item.thumbnail?.name"
                     :selected="props.selectedTexture != undefined && props.selectedTexture === item.fileId"
                     @click="$emit('texture-selected', item.fileTextureId)">
          <img :src="item.thumbnail?.content" class="img" :alt="item.thumbnail?.name">
        </button-card>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
#design-selection {
  background: transparent radial-gradient(closest-side at 50% 50%, rgba(75,75,75,0.6) 0%, rgba(0,0,0,0.6) 100%) 0% 0% no-repeat padding-box;
}
.img {
  height: 9rem;
  width: 9rem;
}

.pointer{
  cursor: pointer;
}
</style>
