import * as THREE from 'three';

// Author: Glenn Chun
// Date: April 15, 2016
// As my first coding exercise in JavaScript, I implemented the FFD algorithm presented
// in Sederberg & Parry's '86 paper, Free-Form Deformation of Solid Geometric Models.

export default class FFD {
  ///////////////////////////////////////////////////////////////////////////
  // Privileged members
  ///////////////////////////////////////////////////////////////////////////
  private mBBox!: THREE.Box3;
  private mCtrlPtCounts: number[];
  private mSpanCounts: number[];
  private mTotalCtrlPtCount: number;
  private readonly mAxes: THREE.Vector3[];
  private mCtrlPts: THREE.Vector3[];
  private mGridPts: THREE.Vector3[];
  private meshId: string;

  ///////////////////////////////////////////////////////////////////////////
  // Private members
  ///////////////////////////////////////////////////////////////////////////

  constructor() {
    this.meshId = '';
    // The bounding box of the undeformed lattice.
    this.mBBox = new THREE.Box3();

    // Number of spans in each parameter direction, S/T/U.
    this.mSpanCounts = [0, 0, 0];

    // Number of control points in each parameter direction, S/T/U.
    this.mCtrlPtCounts = [0, 0, 0];

    // Total number of control points.
    this.mTotalCtrlPtCount = 0;

    // S/T/U axes.
    this.mAxes = [
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 0, 0),
    ];

    // Positions of all control points.
    this.mCtrlPts = [];
    this.mGridPts = [];
  }

  // Returns the bounding box of the undeformed lattice.
  getBoundingBox(): THREE.Box3 {
    return this.mBBox;
  }

  // Returns the number of control points on the given parameter direction.
  // direction: 0 for S, 1 for T, and 2 for U.
  getCtrlPtCount(direction: number): number {
    return this.mCtrlPtCounts[direction];
  }

  // Returns the total number of control points.
  getTotalCtrlPtCount(): number {
    return this.mTotalCtrlPtCount;
  }

  // Converts the given ternary index to a unary index.
  getIndex(i: number, j: number, k: number): number {
    return (
      i * this.mCtrlPtCounts[1] * this.mCtrlPtCounts[2] +
      j * this.mCtrlPtCounts[2] +
      k
    );
  }

  // Rebuilds the lattice with new control points.
  rebuildLattice(
    meshId: string,
    bbox: THREE.Box3,
    span_counts: number[],
    span_count_change_only: boolean
  ): void {
    const xPositionList: number[] = [];
    const zPositionList: number[] = [];
    for (let i = 0; i < this.mCtrlPtCounts[0]; i++) {
      xPositionList.push(this.getGridPositionTernary(i, 0, 0).x);
    }
    for (let k = 0; k < this.mCtrlPtCounts[2]; k++) {
      zPositionList.push(this.getGridPositionTernary(0, 0, k).z);
    }
    this.initLattice(meshId, bbox, span_counts, span_count_change_only);
    // Assign a new position to each control point.
    for (let i = 0; i < this.mCtrlPtCounts[0]; i++) {
      for (let j = 0; j < this.mCtrlPtCounts[1]; j++) {
        for (let k = 0; k < this.mCtrlPtCounts[2]; k++) {
          let position = new THREE.Vector3(
            this.mBBox.min.x + (i / this.mSpanCounts[0]) * this.mAxes[0].x,
            this.mBBox.min.y + (j / this.mSpanCounts[1]) * this.mAxes[1].y,
            this.mBBox.min.z + (k / this.mSpanCounts[2]) * this.mAxes[2].z
          );
          if (span_count_change_only) {
            position = new THREE.Vector3(
              xPositionList[i],
              this.mBBox.min.y + (j / this.mSpanCounts[1]) * this.mAxes[1].y,
              zPositionList[k]
            );
          }
          this.setPositionTernary(i, j, k, position);
          this.setGridPositionTernary(i, j, k, position.clone());
        }
      }
    }
    this.meshId = meshId;
  }

  initLattice(
    meshId: string,
    bbox: THREE.Box3,
    span_counts: number[],
    span_count_change_only: boolean
  ): void {
    // Do not rebuild the lattice if the bounding box and span counts are the same as before.
    if (
      this.mBBox.equals(bbox) &&
      this.mSpanCounts[0] == span_counts[0] &&
      this.mSpanCounts[1] == span_counts[1] &&
      this.mSpanCounts[2] == span_counts[2] &&
      this.meshId == meshId
    )
      return;

    this.mBBox = bbox;
    this.mSpanCounts = span_counts;
    this.mCtrlPtCounts = [
      this.mSpanCounts[0] + 1,
      this.mSpanCounts[1] + 1,
      this.mSpanCounts[2] + 1,
    ];
    this.mTotalCtrlPtCount =
      this.mCtrlPtCounts[0] * this.mCtrlPtCounts[1] * this.mCtrlPtCounts[2];

    // Set the S/T/U axes.
    this.mAxes[0].x = this.mBBox.max.x - this.mBBox.min.x;
    this.mAxes[1].y = this.mBBox.max.y - this.mBBox.min.y;
    this.mAxes[2].z = this.mBBox.max.z - this.mBBox.min.z;

    // Reset the array for control points.
    if (span_count_change_only) {
      this.mCtrlPts.length = this.mTotalCtrlPtCount;
      this.mGridPts.length = this.mTotalCtrlPtCount;
    } else {
      this.mCtrlPts = new Array(this.mTotalCtrlPtCount);
      this.mGridPts = new Array(this.mTotalCtrlPtCount);
    }
    this.meshId = meshId;
  }

  reloadLattice(
    meshId: string,
    bbox: THREE.Box3,
    span_counts: number[],
    gridPositionList: THREE.Vector3[],
    ctrlPositionList: THREE.Vector3[]
  ): void {
    this.initLattice(meshId, bbox, span_counts, false);
    // Assign a new position to each control point.
    for (let i = 0; i < this.mCtrlPtCounts[0]; i++) {
      for (let j = 0; j < this.mCtrlPtCounts[1]; j++) {
        for (let k = 0; k < this.mCtrlPtCounts[2]; k++) {
          const index = this.getIndex(i, j, k);
          this.setPositionTernary(i, j, k, ctrlPositionList[index]);
          this.setGridPositionTernary(i, j, k, gridPositionList[index]);
        }
      }
    }
    this.meshId = meshId;
  }

  reloadCtrlPositionList(ctrlPositionList: THREE.Vector3[]): void {
    // Assign a new position to each control point.
    for (let i = 0; i < this.mCtrlPtCounts[0]; i++) {
      for (let j = 0; j < this.mCtrlPtCounts[1]; j++) {
        for (let k = 0; k < this.mCtrlPtCounts[2]; k++) {
          const index = this.getIndex(i, j, k);
          this.setPositionTernary(i, j, k, ctrlPositionList[index]);
        }
      }
    }
  }

  // Evaluates the volume at (s, t, u) parameters
  // where each parameter ranges from 0 to 1.
  private evalTrivariate(
    s: number,
    t: number,
    u: number
  ): THREE.Vector3 | null {
    const gridX: number[] = [];
    for (let i = 0; i < this.mCtrlPtCounts[0]; i++) {
      gridX.push(this.getGridPositionRelative(i, 0, 0).x);
    }
    const gridY: number[] = [];
    for (let i = 0; i < this.mCtrlPtCounts[1]; i++) {
      gridY.push(this.getGridPositionRelative(0, i, 0).y);
    }
    const gridZ: number[] = [];
    for (let i = 0; i < this.mCtrlPtCounts[2]; i++) {
      gridZ.push(this.getGridPositionRelative(0, 0, i).z);
    }

    const eval_pt = new THREE.Vector3(0, 0, 0);
    for (let i = -1; i <= this.mCtrlPtCounts[0]; i++) {
      const indexI = i < 0 ? 0 : i == this.mCtrlPtCounts[0] ? i - 1 : i;
      const point1 = new THREE.Vector3(0, 0, 0);
      for (let j = -1; j <= this.mCtrlPtCounts[1]; j++) {
        const indexJ = j < 0 ? 0 : j == this.mCtrlPtCounts[1] ? j - 1 : j;
        const point2 = new THREE.Vector3(0, 0, 0);
        for (let k = -1; k <= this.mCtrlPtCounts[2]; k++) {
          const indexK = k < 0 ? 0 : k == this.mCtrlPtCounts[2] ? k - 1 : k;
          const position = this.getPositionTernary(
            indexI,
            indexJ,
            indexK
          ).clone();
          if (i < 0) position.x = this.mBBox.min.x;
          if (j < 0) position.y = this.mBBox.min.y;
          if (k < 0) position.z = this.mBBox.min.z;
          if (i == this.mCtrlPtCounts[0]) position.x = this.mBBox.max.x;
          if (j == this.mCtrlPtCounts[1]) position.y = this.mBBox.max.y;
          if (k == this.mCtrlPtCounts[2]) position.z = this.mBBox.max.z;

          FFD.addCoefficient(gridZ, indexK, u, k, position, point2);
        }
        FFD.addCoefficient(gridY, indexJ, t, j, point2, point1);
      }
      FFD.addCoefficient(gridX, indexI, s, i, point1, eval_pt);
    }
    return eval_pt;
  }

  private static addCoefficient(
    n: number[],
    k: number,
    u: number,
    kIndex: number,
    position: THREE.Vector3,
    point: THREE.Vector3
  ): THREE.Vector3 | null {
    const poly_u = FFD.coefficient(n, k, u);
    if (poly_u === null) return null;
    if (kIndex !== k) {
      const outside = FFD.coefficientIsOutside(n, k, u);
      if (outside) point.addScaledVector(position, 1 - poly_u);
    } else {
      point.addScaledVector(position, poly_u);
    }
    return point;
  }

  // Converts the given point (x, y, z) in world space to (s, t, u) in parameter space.
  private convertToParam(world_pt: THREE.Vector3): THREE.Vector3 {
    // A vector from the mininmum point of the bounding box to the given world point.
    const min2world = new THREE.Vector3(world_pt.x, world_pt.y, world_pt.z);
    min2world.sub(this.mBBox.min);

    const cross = [
      new THREE.Vector3(),
      new THREE.Vector3(),
      new THREE.Vector3(),
    ];
    cross[0].crossVectors(this.mAxes[1], this.mAxes[2]);
    cross[1].crossVectors(this.mAxes[0], this.mAxes[2]);
    cross[2].crossVectors(this.mAxes[0], this.mAxes[1]);

    const param = new THREE.Vector3();
    for (let i = 0; i < 3; i++) {
      const numer = cross[i].dot(min2world);
      const denom = cross[i].dot(this.mAxes[i]);
      param.setComponent(i, numer / denom);
    }
    return param;
  }

  private convertToWorld(s: number, t: number, u: number): THREE.Vector3 {
    return new THREE.Vector3(
      this.mBBox.min.x + s * this.mAxes[0].x,
      this.mBBox.min.y + t * this.mAxes[1].y,
      this.mBBox.min.z + u * this.mAxes[2].z
    );
  }

  // Returns the position of the control point at the given unary index.
  getPosition(index: number): THREE.Vector3 {
    return this.mCtrlPts[index];
  }

  // Sets the position of the control point at the given unary index.
  setPosition(index: number, position: THREE.Vector3): void {
    this.mCtrlPts[index] = position.clone();
  }

  // Returns the position of the control point at the given ternary index.
  private getPositionTernary(i: number, j: number, k: number): THREE.Vector3 {
    return this.mCtrlPts[this.getIndex(i, j, k)];
  }

  // Sets the position of the control point at the given ternary index.
  private setPositionTernary(
    i: number,
    j: number,
    k: number,
    position: THREE.Vector3
  ): void {
    this.mCtrlPts[this.getIndex(i, j, k)] = position;
  }

  // Returns the position of the control point at the given unary index.
  getGridPosition(index: number): THREE.Vector3 {
    return this.mGridPts[index];
  }

  // Sets the position of the control point at the given unary index.
  setGridPosition(index: number, position: THREE.Vector3): void {
    this.mGridPts[index] = position.clone();
    this.mCtrlPts[index] = position.clone();
  }

  // Returns the position of the control point at the given ternary index.
  private getGridPositionRelative(
    i: number,
    j: number,
    k: number
  ): THREE.Vector3 {
    const gridPos = this.mGridPts[this.getIndex(i, j, k)].clone();
    return new THREE.Vector3(
      (gridPos.x - this.mBBox.min.x) / this.mAxes[0].x,
      (gridPos.y - this.mBBox.min.y) / this.mAxes[1].y,
      (gridPos.z - this.mBBox.min.z) / this.mAxes[2].z
    );
  }

  // Returns the position of the control point at the given ternary index.
  private getGridPositionTernary(
    i: number,
    j: number,
    k: number
  ): THREE.Vector3 {
    return this.mGridPts[this.getIndex(i, j, k)];
  }

  // Sets the position of the control point at the given ternary index.
  private setGridPositionTernary(
    i: number,
    j: number,
    k: number,
    position: THREE.Vector3
  ): void {
    this.mGridPts[this.getIndex(i, j, k)] = position;
  }

  // Returns n-factorial.
  private static facto(n: number): number {
    let fac = 1;
    for (let i = n; i > 0; i--) fac *= i;
    return fac;
  }

  private static coefficientIsOutside(
    n: number[],
    k: number,
    u: number
  ): boolean {
    if (u < n[0] && k === 0) {
      return true;
    }
    if (u > n[n.length - 1] && k === n.length - 1) {
      return true;
    }
    return false;
  }

  private static coefficient(n: number[], k: number, u: number): number | null {
    const value = n[k];
    if (u < n[0] && k === 0) {
      return 1 - (value - u) / value;
    }
    if (u > n[n.length - 1] && k === n.length - 1) {
      return 1 - (u - value) / (1 - value);
    }
    if (k > 0) {
      const previousValue = n[k - 1];
      if (u >= previousValue && u <= value) {
        const pointDistance = value - previousValue;
        const distance = value - u;
        return 1 - distance / pointDistance;
      }
    }
    if (k < n.length - 1) {
      const nextValue = n[k + 1];
      if (u >= value && u <= nextValue) {
        const pointDistance = nextValue - value;
        const distance = u - value;
        return 1 - distance / pointDistance;
      }
    }
    return 0;
  }

  // Returns the Bernstein polynomial in one parameter, u.
  private static bernstein(n: number, k: number, u: number): number {
    // Binomial coefficient
    const coeff = FFD.facto(n) / (FFD.facto(k) * FFD.facto(n - k));
    return coeff * Math.pow(1 - u, n - k) * Math.pow(u, k);
  }

  evalWorld(world_pt: THREE.Vector3): THREE.Vector3 | null {
    const param = this.convertToParam(world_pt);
    return this.evalTrivariate(param.x, param.y, param.z);
  }

  getGridPositionList(): THREE.Vector3[] {
    return this.mGridPts;
  }

  getCtrlPositionList(): THREE.Vector3[] {
    return this.mCtrlPts;
  }
}
