<script lang="ts">
import { type Ref, ref, inject, onMounted } from 'vue';
import SidebarTools from "@/components/Tools/SidebarTools.vue";
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { WorkflowModel } from '@/types/ui/WorkflowModel';
import Auth from '@/services/api/auth';
import MeshEditor from './three/MeshEditor.vue';
import { removeWordFromString } from '@/utils/formBuilder';

@Options({
  methods: {ref},
  components: {
    SidebarTools
  },
  emits: ['productQualityChanged'],
})

export default class CustomHeader extends Vue {
  @Prop({ default: false }) isEditor!: boolean;
  @Prop() workflowModel!: WorkflowModel;
  @Prop({ default: ''}) productName!: string;
  @Prop({ default: null }) authentication!: Auth;
  @Prop({ default: null }) supplier!: string;
  @Prop({ default: 3 }) selectedProductQualityIndex!:number;
  @Prop({ default: null }) meshEditor!: MeshEditor;

  productQualityChanged(selectedProductQuality: string) {
    this.$emit('productQualityChanged', selectedProductQuality);
  }

  // Truncate 'phase1' word in title string 
  get productTitle(): string {
    return removeWordFromString(this.productName, 'phase1', 20)
  }
}
</script>

<template>
  <div class="row absolute pe-none" :class="isEditor ? 'editor-view' : ''">
    <!-- Header logo -->
    <div class="col text-start pe-auto">
      <div class="d-flex align-items-start justify-content-start logo" v-if="authentication.primaryDomain">
        <button class="btn"><img id="logo" :src="authentication.primaryDomain.logo" alt="Home" @click="supplier ? $router.push('/' + supplier) : $router.push('/')" ></button>
      </div>
    </div>

    <div v-if="isEditor" class="col text-center justify-content-center user-select-none">
      <div class="d-flex flex-column">
        <div class="bg-grey-transparent rounded text-white pt-1 pb-1 fw-normal">
          {{ $t("components.custom-header.stepText") }} {{ workflowModel.active }}/{{ workflowModel.stepCount }}: {{ $t('enum.' + workflowModel.localeIdentifier + '.' + workflowModel.activeProgressName()) }}
        </div>
        <div class="mt-2 text-color">
          <h5 class="text-center text-size fw-semibold mb-0">{{ productTitle }}</h5>
          <!--<div class="fw-normal">Aktueller Preis: € {{ (workflowModel.totalPrice * 1.2).toFixed(2) }}</div>-->
        </div>
      </div>
    </div>

    <div class="col d-flex justify-content-end pe-auto">
      <!-- Menu button -->
      <div v-if="!isEditor && !authentication.isAuthenticated()">
        <button type="button" class="btn btn-primary" @click="authentication.handleLogin()">{{ $t('components.custom-header.login') }}</button>
      </div>
      <div v-if="!isEditor && authentication.isAuthenticated()">
        <button type="button" class="btn btn-secondary" @click="authentication.handleLogout()">{{ $t('components.custom-header.logout') }}</button>
      </div>
      <sidebar-tools 
        v-on:productQualityChanged="productQualityChanged"
        :selectedProductQualityIndex="selectedProductQualityIndex"
        :workflowModel="workflowModel"
        :meshEditor="meshEditor"
        :authentication="authentication"
        v-if="isEditor">
      </sidebar-tools>
    </div>
  </div>
</template>

<style scoped lang="scss">
.editor-view {
  position: relative;
  z-index: 1;
}

#logo {
  max-height: 7rem;
}

#navbar-icon {
  width: 1.63rem;
  height: 2.69rem;
}

.bg-grey-transparent {
  background: #00000033 0% 0% no-repeat padding-box;
}

.text-color {
  color: #0000008D;
  ;
}
</style>
