import { RendererPublicInterface } from 'troisjs';
import OrientationGizmo from '@/components/three/OrientationGizmo.vue';
import * as THREE from 'three';
import * as THREECamera from '@/utils/three/camera';
import * as THREETransform from '@/utils/three/transform';
import * as THREEEnum from '@/types/enum/three';

export class ViewCtrl {
  troisRenderer: RendererPublicInterface;
  orientationGizmo: OrientationGizmo | null = null;
  fitCameraToScene = false;

  constructor(
    troisRenderer: RendererPublicInterface,
    orientationGizmo: OrientationGizmo | null = null,
    fitCameraToScene = false
  ) {
    this.troisRenderer = troisRenderer;
    this.orientationGizmo = orientationGizmo;
    this.fitCameraToScene = fitCameraToScene;
  }

  public setOrbitCtrl(enable: boolean): void {
    if (
      this.troisRenderer &&
      this.troisRenderer.three &&
      this.troisRenderer.three.cameraCtrl
    ) {
      this.troisRenderer.three.cameraCtrl.enabled = enable;
    }
  }

  public isOrbitCtrl(): boolean {
    if (
      this.troisRenderer &&
      this.troisRenderer.three &&
      this.troisRenderer.three.cameraCtrl
    ) {
      return this.troisRenderer.three.cameraCtrl.enabled;
    }
    return false;
  }

  public zoom(factor: number): void {
    if (this.orientationGizmo) {
      this.orientationGizmo.zoom(factor);
      this.orientationGizmo.update();
    }
  }

  public getCameraDistance(
    object: THREE.Object3D | null = null
  ): number | undefined {
    if (object && this.troisRenderer.camera) {
      return THREECamera.getCameraObjectDistance(
        this.troisRenderer.three.camera as THREE.PerspectiveCamera,
        object
      );
    } else if (
      this.troisRenderer.three.camera &&
      this.troisRenderer.three.cameraCtrl &&
      this.troisRenderer.three.scene
    ) {
      return THREECamera.getCameraDistance(
        this.troisRenderer.three.camera as THREE.PerspectiveCamera,
        this.troisRenderer.three.cameraCtrl,
        this.troisRenderer.three.scene.children
      );
    }
  }

  public adaptPointerSize(pointer: THREE.Object3D, sceneScaleFactor = 1): void {
    if (this.troisRenderer.camera)
      THREETransform.adaptPointerSize(
        pointer,
        this.troisRenderer.camera,
        sceneScaleFactor
      );
  }

  public adaptSize(item: THREE.Object3D, initSize: number): void {
    if (this.troisRenderer.camera)
      THREETransform.adaptSize(item, this.troisRenderer.camera, initSize);
  }

  public updateOrientationGizmo(): void {
    if (this.orientationGizmo) this.orientationGizmo.update();
  }

  public changeView(value: string): void {
    if (this.troisRenderer.camera && this.troisRenderer.three.cameraCtrl) {
      if (this.orientationGizmo) {
        this.orientationGizmo.selectView(THREEEnum.Views[value]);
        this.orientationGizmo.update();
      }
      this.updateOrientationGizmo();
    }
  }

  fitControlCameraToScene(): void {
    setTimeout(() => {
      if (
        this.fitCameraToScene &&
        this.troisRenderer.three.camera instanceof THREE.PerspectiveCamera &&
        this.troisRenderer.three.cameraCtrl &&
        this.troisRenderer.three.scene
      ) {
        THREECamera.fitControlCameraToSelection(
          this.troisRenderer.three.camera as THREE.PerspectiveCamera,
          this.troisRenderer.three.cameraCtrl,
          this.troisRenderer.three.scene.children
        );
      }
    }, 100);
  }
}
