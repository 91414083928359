import { mutateWithVariables, queryWithVariables } from '@/services/api/api';
/* eslint-disable @typescript-eslint/no-explicit-any*/

export const createProductFormBuilder = async (payload: {
  productId: number;
  formFields: string;
}): Promise<any> => {
  const result = await mutateWithVariables(
    `
        mutation CreateProductFormBuilder($productFormBuilder: ProdFormBuilderInput!){
          addProductFormBuilder(input: $productFormBuilder) {        
            productId,
            formFields
          }
        }
      `,
    {
      productFormBuilder: payload,
    }
  );
  return result.data;
};

export const getProductFormBuilder = async (productId): Promise<any> => {
  try {
    const result = await queryWithVariables(
      `
      query($productIds: [Long!]) {
        productFormBuilder(ids: $productIds) {
          formFields
        }
      }
    `,
      {
        productIds: [productId],
      }
    );
    if (!result.data?.productFormBuilder)
      return null
    return result.data?.productFormBuilder?.[0]?.formFields;
  } catch (error) {
    console.error('error', error);
    return null;
  }
};

export const deleteProductFormBuilder = async (productId): Promise<any> => {
  try {
    const result = await mutateWithVariables(
      `
      mutation DeleteProductFormBuilder($id: Long!){
        deleteProductFormBuilder(id: $id)
      }
    `,
      {
        id: productId,
      }
    );
    return result.data.deleteProductFormBuilder;
  } catch (error) {
    console.error('error', error);
    return null;
  }
};
