import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ebb4743"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "noFreeWebGlContext"
}
const _hoisted_2 = {
  key: 2,
  class: "el-upload-list__item-actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Camera = _resolveComponent("Camera")!
  const _component_AmbientLight = _resolveComponent("AmbientLight")!
  const _component_PointLight = _resolveComponent("PointLight")!
  const _component_FbxModel = _resolveComponent("FbxModel")!
  const _component_ObjModel = _resolveComponent("ObjModel")!
  const _component_StlModel = _resolveComponent("StlModel")!
  const _component_Scene = _resolveComponent("Scene")!
  const _component_Renderer = _resolveComponent("Renderer")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["upload-item-obj", { selected: _ctx.isSelected && _ctx.canSelect }]),
    onClick: _cache[2] || (_cache[2] = 
      () => {
        if (!_ctx.canRemove) _ctx.selectItem();
      }
    )
  }, [
    (_ctx.isActive && !_ctx.isEmpty)
      ? (_openBlock(), _createBlock(_component_Renderer, {
          key: 0,
          ref: "troisRenderer",
          antialias: "",
          width: _ctx.size,
          height: _ctx.size,
          alpha: true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Camera),
            _createVNode(_component_Scene, null, {
              default: _withCtx(() => [
                _createVNode(_component_AmbientLight, { color: "#808080" }),
                _createVNode(_component_PointLight),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getMeshesOfType(_ctx.FileType.FBX), (mesh) => {
                  return (_openBlock(), _createBlock(_component_FbxModel, {
                    key: mesh.filename,
                    src: mesh.url
                  }, null, 8, ["src"]))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getMeshesOfType(_ctx.FileType.OBJ), (mesh) => {
                  return (_openBlock(), _createBlock(_component_ObjModel, {
                    key: mesh.filename,
                    srcType: mesh.url ? _ctx.ModelSrcType.URL : _ctx.ModelSrcType.BASE64,
                    src: mesh.url ? mesh.url : mesh.base64
                  }, null, 8, ["srcType", "src"]))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getMeshesOfType(_ctx.FileType.STL), (mesh) => {
                  return (_openBlock(), _createBlock(_component_StlModel, {
                    key: mesh.filename,
                    srcType: mesh.url ? _ctx.ModelSrcType.URL : _ctx.ModelSrcType.BASE64,
                    src: mesh.url ? mesh.url : mesh.base64
                  }, null, 8, ["srcType", "src"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["width", "height"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createVNode(_component_font_awesome_icon, { icon: "file" })
          ])
        ])),
    (_ctx.canRemove)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          (_ctx.canSelect)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "el-upload-list__item-preview",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectItem && _ctx.selectItem(...args)))
              }, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, { icon: "magnifying-glass" })
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.canRemove)
            ? (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: "el-upload-list__item-delete",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.removeItem && _ctx.removeItem(...args)))
              }, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, { icon: "trash" })
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}