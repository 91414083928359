import * as modelService from '@/services/api/modelService';

/* eslint-disable @typescript-eslint/no-explicit-any*/
export interface HistoryInfo {
  lastUpdateTime: number;
  historyList: HistoryList;
}

export interface DefaultUndoRedo {
  defaultUndo(): Promise<void>;
  defaultRedo(): Promise<void>;
}

export enum HistoryOperationType {
  transform,
  color,
  mirror,
  aline,
  bonePosition,
  ffd,
  softModification,
  paintModification,
  curvePoints,
  embossing,
  renderViews,
  hierarchy,
}

export class HistoryEntry {
  meshIds: string[];
  operationType: HistoryOperationType;
  undo: () => Promise<void>;
  redo: () => Promise<void>;

  constructor(
    meshIds: string[],
    operationType: HistoryOperationType,
    undo: () => Promise<void>,
    redo: () => Promise<void>
  ) {
    this.meshIds = meshIds;
    this.operationType = operationType;
    this.undo = undo;
    this.redo = redo;
  }
}

export class HistoryList {
  history: HistoryEntry[];
  pointer = -1;
  mId: number | null = null;
  defaultUndoRedo: DefaultUndoRedo;
  hasAdditionalBackendUndo = false;
  hasAdditionalBackendRedo = false;

  constructor(modelId: number | null = null, defaultUndoRedo: DefaultUndoRedo) {
    this.history = [];
    this.modelId = modelId;
    this.defaultUndoRedo = defaultUndoRedo;
    this.refreshHasAdditionalBackendUndoRedo();
  }

  public get modelId(): number | null {
    return this.mId;
  }

  public set modelId(value: number | null) {
    this.mId = value;
    //this.initHistoryListFromBackend(value);
    this.refreshHasAdditionalBackendUndoRedo();
  }

  private refreshHasAdditionalBackendUndoRedo(): void {
    if (this.modelId) {
      modelService.undoSteps(this.modelId).then((result) => {
        this.hasAdditionalBackendUndo = result > 0;
      });
      modelService.redoSteps(/*this.modelId*/).then((result) => {
        this.hasAdditionalBackendRedo = result > 0;
      });
    }
  }

  public get hasRedo(): boolean {
    return (
      this.pointer + 1 < this.history.length || this.hasAdditionalBackendRedo
    );
  }

  public get hasUndo(): boolean {
    return this.pointer > -1 || this.hasAdditionalBackendUndo;
  }

  add(
    meshId: string,
    operationType: HistoryOperationType,
    undo: () => Promise<void>,
    redo: () => Promise<void>
  ): void {
    this.addList([meshId], operationType, undo, redo);
  }

  addList(
    meshIds: string[],
    operationType: HistoryOperationType,
    undo: () => Promise<void>,
    redo: () => Promise<void>,
    position: number | null = null
  ): void {
    const entry = new HistoryEntry(meshIds, operationType, undo, redo);
    this.history.length = this.pointer + 1;
    if (position === null) this.history.push(entry);
    else this.history.splice(position, 0, entry);
    this.pointer = this.history.length - 1;
  }

  remove(meshId: string, operationType: HistoryOperationType | null): void {
    for (let index = this.history.length - 1; index >= 0; index--) {
      if (
        this.history[index].meshIds.includes(meshId) &&
        (operationType === null ||
          this.history[index].operationType === operationType)
      ) {
        if (this.pointer >= index) this.pointer--;
        this.history.splice(index, 1);
      }
    }
  }

  goBack(): void {
    if (this.pointer >= this.history.length)
      this.pointer = this.history.length - 1;
    if (this.pointer > -1) {
      const entry = this.history[this.pointer];
      entry.undo().then(() => {
        this.refreshHasAdditionalBackendUndoRedo();
        setTimeout(() => {
          this.refreshHasAdditionalBackendUndoRedo();
        }, 3000);
      });
      this.pointer -= 1;
    } else {
      this.defaultUndoRedo.defaultUndo().then(() => {
        this.refreshHasAdditionalBackendUndoRedo();
        setTimeout(() => {
          this.refreshHasAdditionalBackendUndoRedo();
        }, 3000);
      });
    }
  }

  goForward(): void {
    if (this.pointer < -1) this.pointer = -1;
    if (this.pointer + 1 < this.history.length) {
      const entry = this.history[this.pointer + 1];
      entry.redo().then(() => {
        this.refreshHasAdditionalBackendUndoRedo();
        setTimeout(() => {
          this.refreshHasAdditionalBackendUndoRedo();
        }, 3000);
      });
      this.pointer += 1;
    } else {
      this.defaultUndoRedo.defaultRedo().then(() => {
        this.refreshHasAdditionalBackendUndoRedo();
        setTimeout(() => {
          this.refreshHasAdditionalBackendUndoRedo();
        }, 3000);
      });
    }
  }
}
