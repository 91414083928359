import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aa8c2844"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "design-selection",
  class: "pl-5 pr-5 pt-3 pb-3 rounded-3 text-white"
}
const _hoisted_2 = { class: "form-text text-white" }
const _hoisted_3 = { class: "d-flex flex-wrap gap-3 mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PreviewRendererImg = _resolveComponent("PreviewRendererImg")!
  const _component_button_card = _resolveComponent("button-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t('components.part-selection.title')), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('components.part-selection.info')), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.meshCountItemList, (mesh) => {
        return (_openBlock(), _createBlock(_component_button_card, {
          key: mesh.meshTreeDataItem.id,
          title: _ctx.$t('components.part-selection.' + _ctx.productTypeString + '.' + mesh.meshTreeDataItem.name) ? mesh.cnt + 'x ' + _ctx.$t('components.part-selection.' + _ctx.productTypeString + '.' + mesh.meshTreeDataItem.name) : mesh.cnt + 'x ' + mesh.meshTreeDataItem.name,
          selected: mesh.meshTreeDataItem.isSelected,
          onClick: ($event: any) => (_ctx.$emit('part-selected', mesh.meshTreeDataItem))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_PreviewRendererImg, {
              meshes: [{
                            thumbnail: mesh.meshTreeDataItem.thumbnail,
                            thumbnailUrl: mesh.meshTreeDataItem.thumbnailUrl,
                          }],
              imgSize: "9"
            }, null, 8, ["meshes"])
          ]),
          _: 2
        }, 1032, ["title", "selected", "onClick"]))
      }), 128))
    ])
  ]))
}