<script setup lang="ts">
import { computed, getCurrentInstance } from 'vue';
import  { removeWordFromString } from '@/utils/formBuilder'

interface Props {
  title: string;
  primaryButtonText: string;
  secondaryButtonText?: string;
  showDeleteIcon?: boolean;
}
const thisInstance = getCurrentInstance();
const emit = defineEmits([
  'deleteProduct',
  'secondaryButtonClicked',
  'primaryButtonClicked',
]);
const props = defineProps<Props>();
const showDeleteIcon = Boolean(
  thisInstance?.vnode?.props?.onDeleteProduct && props.showDeleteIcon
);

// Truncate 'phase1' word in title string 
const productTitle = computed(() => {
  return removeWordFromString(props.title, 'Phase1')
})

</script>

<template>
  <div class="card">
    <div class="delete-icon__wrapper" v-if="showDeleteIcon">
      <span id="deleteModel" @click="emit('deleteProduct')">
        <font-awesome-icon icon="trash" class="text-danger" />
      </span>
    </div>
    <div class="card-body">
      <el-tooltip
        class="box-item"
        effect="dark"
        :content="productTitle"
        placement="top-start"
      >
        <h5 class="card-title">{{ productTitle }}</h5>
      </el-tooltip>
      <div class="d-flex justify-content-center m-2">
        <slot></slot>
      </div>
      <div v-if="props.secondaryButtonText" class="row m-2">
        <button
          type="button"
          class="btn btn-secondary"
          @click="$emit('secondaryButtonClicked')"
        >
          {{ props.secondaryButtonText }}
        </button>
      </div>
      <div class="row m-2 mb-0">
        <button
          type="button"
          class="btn btn-primary"
          @click="$emit('primaryButtonClicked')"
        >
          {{ props.primaryButtonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.delete-icon__wrapper {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.card-title {
  overflow: hidden;
  max-width: 11rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
