import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.editorMode.isBoneMode)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["level-item action", { active: _ctx.includeChildren }]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleIncludeChildren && _ctx.toggleIncludeChildren(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "object-group" })
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.editorMode.isBoneMode)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["level-item action", { active: _ctx.boneTool === _ctx.BoneTool.transform }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setBoneTool(_ctx.BoneTool.transform)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "arrows-up-down-left-right" })
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.editorMode.isBoneMode)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["level-item action", { active: _ctx.boneTool === _ctx.BoneTool.intersect }]),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setBoneTool(_ctx.BoneTool.intersect)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "location-crosshairs" })
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}