<template>
    <custom-header class="p-4" :isEditor="false" :workflowModel="null" :authentication="authentication"/>
    <div class="p-4 flex-grow-1 overflow-auto">
      <h1>{{ $t('views.default.overview.header') }}</h1>
      <h1 v-if="authentication.user">{{ authentication.user?.firstname + ' ' + authentication.user?.lastname }}</h1>
      <div class="menu" v-if="authentication.user" v-loading="menuLoading">
        <el-row>
          <el-col
            v-if="authentication.roles?.includes(Role.Supplier)"
            :span="12"
          >
            <el-button
              type="primary"
              v-on:click="$router.push(`/template-overview`)"
            >
              <div>
                <font-awesome-icon icon="puzzle-piece" />
              </div>
              <div>
                {{ $t('views.default.overview.template') }}
              </div>
            </el-button>
          </el-col>
          <el-col
            v-if="authentication.roles?.includes(Role.Supplier)"
            :span="12"
          >
            <el-button
              type="primary"
              v-on:click="$router.push(`/order-overview`)"
            >
              <div>
                <font-awesome-icon icon="cart-shopping" />
              </div>
              <div>
                {{ $t('views.default.overview.order') }}
              </div>
            </el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="primary" v-on:click="$router.push(`/customize-overview`)">
              <div>
                <font-awesome-icon icon="screwdriver-wrench" />
              </div>
              <div>
                {{ $t('views.default.overview.customize') }}
              </div>
            </el-button>
          </el-col>
          <el-col :span="12">
            <el-button 
              type="primary" v-on:click="authentication.handleLogout()"
            >
              <div>
                <font-awesome-icon icon="sign-out-alt" />
              </div>
              <div>
                {{ $t('views.default.overview.logout') }}
              </div>
            </el-button>
          </el-col>
        </el-row>
      </div>
      <!--<div class="menu">
        <el-row>
          <el-col :span="12">
            <el-button
              type="primary"
              v-on:click="$router.push(`/template-workflow-setup?id=${templateId}`)"
            >
              <div>
                <font-awesome-icon icon="puzzle-piece" />
              </div>
              <div>
                {{ $t('views.default.overview.template') }}
              </div>
            </el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="primary">
              <div>
                <font-awesome-icon icon="users" />
              </div>
              <div>
                {{ $t('views.default.overview.customer') }}
              </div>
            </el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-button type="primary">
              <div>
                <font-awesome-icon icon="cube" />
              </div>
              <div>
                {{ $t('views.default.overview.meshes') }}
              </div>
            </el-button>
          </el-col>
        </el-row>
      </div>-->
    </div>
    <custom-footer :isEditor="false" class="p-4" />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Auth from '@/services/api/auth';
import { Role } from '@/types/enum/user';
import { User } from '@/types/api/User/User';
import CustomHeader from '@/components/CustomHeader.vue';
import CustomFooter from '@/components/CustomFooter.vue';
import * as LayoutUtility from '@/utils/layout'

@Options({
  components: {
    CustomHeader,
    CustomFooter
  },
})
export default class Home extends Vue {
  authentication = Auth.getInstance();
  menuLoading = false;
  Role = Role;

  created(): void {
    this.menuLoading = true;
    this.authentication.handleAuthentication().then(() => {
      if (this.authentication.isAuthenticated()) {
        return;
      }
      this.$router.push("/aidddo");
    }).finally(() => {
      this.menuLoading = false;
    });
    //this.$router.push("/aidddo");
  }
}
</script>

<style lang="scss" scoped>
h1 {
  margin-top: 5rem;
  margin-bottom: 3rem;
  text-align: center;
}

.menu {
  margin: auto;
  width: 20rem;
}

.el-button::v-deep {
  display: block;
  padding: 1rem;
  height: 9rem;
  width: 9rem;
  margin: 0.5rem;
  white-space: normal;

  > span {
    display: block;
  }

  svg {
    font-size: 3.5rem;
    padding-bottom: 0.5rem;
  }
}
</style>
