<template>
  <!-- Progress bars -->
  <div v-if="!readonly">

    <div class="row">
      <div class="col">
        <label for="width-range" class="form-label mb-0">{{ $t('components.order-form.width') }}</label>
        <el-slider
          v-model="properties[0].calfCircumferenceWithoutDeduction"
          show-input
          :precision="0"
          :min="0"
          :max="20000"
          :step="1"
          :show-tooltip="false"
          id="width-range" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label for="height-range" class="form-label mb-0">{{ $t('components.order-form.height') }}</label>
        <el-slider
          v-model="properties[0].heightWithoutDeduction"
          show-input
          :precision="0"
          :min="0"
          :max="20000"
          :step="1"
          :show-tooltip="false"
          if="height-range"
          />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label for="length-range" class="form-label mb-0">{{ $t('components.order-form.depth') }}</label>
        <el-slider
          v-model="properties[0].depth"
          show-input
          :precision="0"
          :min="0"
          :max="20000"
          :step="1"
          :show-tooltip="false"
          id="length-range" />
      </div>
    </div>
  </div>
  <div v-else>
    <div class="col mb-2">
      <div class="row">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.width') }}</label>
        <label class="col form-label mb-1">{{ properties[0].calfCircumferenceWithoutDeduction }}</label>
      </div>
    </div>
    <div class="col mb-2">
      <div class="row">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.height') }}</label>
        <label class="col form-label mb-1">{{ properties[0].heightWithoutDeduction }}</label>
      </div>
    </div>
    <div class="col mb-2">
      <div class="row">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.depth') }}</label>
        <label class="col form-label mb-1">{{ properties[0].depth }}</label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { BodySide } from "@/types/enum/order";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
})

export default class ProductParamterCuboid extends Vue {
  @Prop() properties!: any[];
  @Prop({default: false}) readonly!: boolean;
}
</script>

<style scoped lang="scss">
.text-secondary {
  color: #0000008D !important;
}
.form-label {
  width: 20rem;
}
</style>