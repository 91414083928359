import FFD from '@/plugin/free-form-deformation/ffd';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import GeometryAdapterFactory, {
  GeometryAdapterInterface,
} from '@/plugin/ffd/adapter/GeometryAdapterFactory';
import { AxisType, FFDAxis, SpecialObjectName } from '@/types/enum/three';

const adapterFactory = new GeometryAdapterFactory();

const visibleAxis = {
  x: [AxisType.x],
  y: [AxisType.y],
  z: [AxisType.z],
  xy: [AxisType.x, AxisType.y],
  xz: [AxisType.x, AxisType.z],
  yz: [AxisType.y, AxisType.z],
  all: [AxisType.x, AxisType.y, AxisType.z],
};

/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class Lattice {
  // FFD: control points of a lattice
  ffd: FFD;
  readonly MIN_SPAN_COUNT = 1;
  readonly MAX_SPAN_COUNT = 8;
  span_counts: number[];
  ctrl_pt_geom: THREE.SphereGeometry;
  ctrl_pt_material: THREE.MeshLambertMaterial;
  ctrl_pt_meshes: THREE.Object3D[];
  ctrl_pt_mesh_selected: THREE.Object3D | null;
  lattice_lines: { x: THREE.Line[]; y: THREE.Line[]; z: THREE.Line[] };
  lattice_line_material: THREE.LineBasicMaterial;
  ffdAxis: FFDAxis = FFDAxis.all;
  pointPosition: THREE.Vector3[];
  editGrid = false;
  meshId: string;

  // Evaluated points
  eval_pt_spans: THREE.Vector3;
  eval_pt_counts: THREE.Vector3;
  eval_pts_geom: THREE.BufferGeometry;
  eval_pts_mesh!: THREE.Mesh;
  show_eval_pts_checked: boolean;
  smooth_verts_undeformed: THREE.Vector3[];

  raycaster: THREE.Raycaster;
  mouse: THREE.Vector2;

  //snap
  snapToAxis = false;

  scene!: THREE.Scene;
  camera!: THREE.Camera;
  renderer!: THREE.Renderer;
  controls!: OrbitControls;
  geometry!: THREE.BufferGeometry;
  geometryAdapter!: GeometryAdapterInterface;
  controlPoints: THREE.Mesh[];
  container: THREE.Object3D;
  defaultColor = 0x4d4dff;
  selectionColor = 0xff0000;

  constructor(x = 3, y = 5, z = 3) {
    this.meshId = '';
    // FFD: control points of a lattice
    this.ffd = new FFD();
    this.span_counts = [x, y, z];
    this.ctrl_pt_geom = new THREE.SphereGeometry(3);
    this.ctrl_pt_material = new THREE.MeshLambertMaterial({
      color: this.defaultColor,
    });
    this.ctrl_pt_meshes = [];
    this.ctrl_pt_mesh_selected = null;
    this.lattice_lines = { x: [], y: [], z: [] };
    this.lattice_line_material = new THREE.LineBasicMaterial({
      color: 0x4d4dff,
    });
    this.pointPosition = [];

    // Evaluated points
    this.eval_pt_spans = new THREE.Vector3(16, 16, 16);
    this.eval_pt_counts = new THREE.Vector3(
      this.eval_pt_spans.x + 1,
      this.eval_pt_spans.y + 1,
      this.eval_pt_spans.z + 1
    );
    this.eval_pts_geom = new THREE.BufferGeometry();
    //this.eval_pts_mesh;
    this.show_eval_pts_checked = false;

    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();

    this.smooth_verts_undeformed = [];
    this.controlPoints = [];
    this.container = new THREE.Object3D();
  }

  changeResolution(x: number, y: number, z: number): void {
    this.span_counts = [x, y, z];
    if (this.geometry) {
      this.rebuildFFD(this.geometry, true);
    }
  }

  changeGrid(gridPositionList: THREE.Vector3[]): void {
    for (let i = 0; i < this.ffd.getTotalCtrlPtCount(); i++) {
      this.ffd.setGridPosition(i, gridPositionList[i].clone());
      this.ctrl_pt_meshes[i].position.copy(gridPositionList[i].clone());
      this.pointPosition[i] = this.ctrl_pt_meshes[i].position.clone();
    }
    this.updateLatticeLines();
  }

  changeAxis(axis: FFDAxis): void {
    this.ffdAxis = axis;
    this.updateAxisVisibility();
  }

  changeMode(editGrid = false): void {
    this.editGrid = editGrid;
    for (let i = 0; i < this.ffd.getTotalCtrlPtCount(); i++) {
      const position = editGrid
        ? this.ffd.getGridPosition(i)
        : this.ffd.getPosition(i);
      this.ctrl_pt_meshes[i].position.copy(position.clone());
    }
    this.updateLatticeLines();
  }

  changeSnap(snap: boolean): void {
    this.snapToAxis = snap;
  }

  clearObjects(): void {
    let parent = this.container.parent;
    if (!parent) parent = this.scene;
    parent.remove(this.container);
    this.controlPoints = [];
    this.container = new THREE.Object3D();
    this.scene.add(this.container);
  }

  initView(
    scene: THREE.Scene,
    camera: THREE.Camera,
    renderer: THREE.Renderer,
    controls: OrbitControls | undefined
  ): void {
    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;
    if (controls) this.controls = controls;
    this.scene.add(this.container);
  }

  initModel(meshId: string, geometry: THREE.BufferGeometry): void {
    this.meshId = meshId;
    this.geometry = geometry;
    this.geometryAdapter = adapterFactory.getAdapter(geometry);

    // Store the vert positions of the smooth model. Empty the storage first.
    this.smooth_verts_undeformed = [];
    for (let i = 0; i < this.geometryAdapter.numVertices; i++) {
      const copy_pt = new THREE.Vector3();
      copy_pt.copy(this.geometryAdapter.getVertex(i));
      this.smooth_verts_undeformed.push(copy_pt);
    }

    this.rebuildFFD(geometry, false);
  }

  reloadModel(
    meshId: string,
    spanCounts: number[],
    boundingBox: THREE.Box3,
    gridPositionList: THREE.Vector3[],
    ctrlPositionList: THREE.Vector3[],
    vertexPositionUndeformed: THREE.Vector3[],
    geometry: THREE.BufferGeometry,
    initialReload = true
  ): void {
    this.meshId = meshId;
    this.geometry = geometry;
    this.geometryAdapter = adapterFactory.getAdapter(geometry);
    const measure = new THREE.Vector3(0, 0, 0);
    boundingBox.getSize(measure);
    this.ctrl_pt_geom = new THREE.SphereGeometry(measure.x / 100);

    // Store the vert positions of the smooth model. Empty the storage first.
    this.smooth_verts_undeformed = vertexPositionUndeformed;

    this.reloadFFD(
      spanCounts,
      boundingBox,
      gridPositionList,
      ctrlPositionList,
      initialReload
    );
  }

  reloadFFD(
    spanCounts: number[],
    boundingBox: THREE.Box3,
    gridPositionList: THREE.Vector3[],
    ctrlPositionList: THREE.Vector3[],
    initialReload = true
  ): void {
    this.removeCtrlPtMeshes();
    this.removeLatticeLines();
    this.span_counts = spanCounts;
    // Rebuild the lattice with new control points.
    this.ffd.reloadLattice(
      this.meshId,
      boundingBox,
      [...spanCounts],
      gridPositionList,
      ctrlPositionList
    );

    this.addCtrlPtMeshes();
    this.addLatticeLines();
    this.updateAxisVisibility();

    if (initialReload) this.deform();
  }

  reloadCtrlPositionList(ctrlPositionList: THREE.Vector3[]): void {
    this.ffd.reloadCtrlPositionList(ctrlPositionList);
    for (let index = 0; index < this.ctrl_pt_meshes.length; index++) {
      this.ctrl_pt_meshes[index].position.copy(ctrlPositionList[index].clone());
    }
    this.updateLatticeLines();
    this.deform();
  }

  rebuildFFD(
    geometry: THREE.BufferGeometry,
    span_count_change_only: boolean
  ): void {
    this.removeCtrlPtMeshes();
    this.removeLatticeLines();

    let bbox: THREE.Box3;
    if (span_count_change_only) {
      bbox = this.ffd.getBoundingBox();
    } else {
      bbox = new THREE.Box3();
      const geometryAdapter = adapterFactory.getAdapter(geometry);
      // Compute the bounding box that encloses all vertices of the smooth model.
      bbox.setFromPoints(geometryAdapter.vertices);
    }
    const measure = new THREE.Vector3(0, 0, 0);
    bbox.getSize(measure);
    this.ctrl_pt_geom = new THREE.SphereGeometry(measure.x / 100);

    // Do not pass span_counts to ffd.
    const span_counts_copy = [
      this.span_counts[0],
      this.span_counts[1],
      this.span_counts[2],
    ];

    // Rebuild the lattice with new control points.
    this.ffd.rebuildLattice(
      this.meshId,
      bbox,
      span_counts_copy,
      span_count_change_only
    );

    this.addCtrlPtMeshes();
    this.addLatticeLines();
    this.updateAxisVisibility();

    this.deform();
  }

  removeCtrlPtMeshes(): void {
    for (let i = 0; i < this.ctrl_pt_meshes.length; i++)
      this.container.remove(this.ctrl_pt_meshes[i]);
    this.ctrl_pt_meshes.length = 0;
    this.pointPosition.length = 0;
  }

  removeLatticeLines(): void {
    for (const axis of Object.keys(this.lattice_lines)) {
      for (let i = 0; i < this.lattice_lines[axis].length; i++)
        this.container.remove(this.lattice_lines[axis][i]);
      this.lattice_lines[axis].length = 0;
    }
  }

  private isPointVisible(i: number, j: number, k: number): boolean {
    let visible = true;
    switch (this.ffdAxis) {
      case FFDAxis.yz:
        visible = i === 0;
        break;
      case FFDAxis.xz:
        visible = j === 0;
        break;
      case FFDAxis.xy:
        visible = k === 0;
        break;
      case FFDAxis.x:
        visible = j === 0 && k === 0;
        break;
      case FFDAxis.y:
        visible = i === 0 && k === 0;
        break;
      case FFDAxis.z:
        visible = i === 0 && j === 0;
        break;
    }
    return visible;
  }

  updateAxisVisibility(): void {
    for (const axis of Object.keys(this.lattice_lines)) {
      const axisVisibility =
        this.ffdAxis === FFDAxis.all ||
        visibleAxis[this.ffdAxis].includes(axis as AxisType);
      for (let i = 0; i < this.lattice_lines[axis].length; i++) {
        const line = this.lattice_lines[axis][i];
        const x = (line as any).indexX;
        const y = (line as any).indexY;
        const z = (line as any).indexZ;
        line.visible = axisVisibility && this.isPointVisible(x, y, z);
      }
    }
    for (let i = 0; i < this.ffd.getCtrlPtCount(0); i++) {
      for (let j = 0; j < this.ffd.getCtrlPtCount(1); j++) {
        for (let k = 0; k < this.ffd.getCtrlPtCount(2); k++) {
          const index = this.ffd.getIndex(i, j, k);
          this.ctrl_pt_meshes[index].visible = this.isPointVisible(i, j, k);
        }
      }
    }
  }

  addCtrlPtMeshes(): void {
    this.controlPoints = [];
    for (let i = 0; i < this.ffd.getCtrlPtCount(0); i++) {
      for (let j = 0; j < this.ffd.getCtrlPtCount(1); j++) {
        for (let k = 0; k < this.ffd.getCtrlPtCount(2); k++) {
          const index = this.ffd.getIndex(i, j, k);
          const ctrl_pt_mesh = new THREE.Mesh(
            this.ctrl_pt_geom,
            this.ctrl_pt_material.clone()
          );
          ctrl_pt_mesh.name = SpecialObjectName.FFDPoint;
          const position = this.editGrid
            ? this.ffd.getGridPosition(index)
            : this.ffd.getPosition(index);
          ctrl_pt_mesh.position.copy(position);
          (ctrl_pt_mesh.material as any).ambient = ctrl_pt_mesh.material.color;
          (ctrl_pt_mesh as any).indexX = i;
          (ctrl_pt_mesh as any).indexY = j;
          (ctrl_pt_mesh as any).indexZ = k;

          this.ctrl_pt_meshes.push(ctrl_pt_mesh);
          this.pointPosition.push(ctrl_pt_mesh.position.clone());
          this.container.add(ctrl_pt_mesh);
          this.controlPoints.push(ctrl_pt_mesh);
        }
      }
    }
  }

  private addLatticeLine(
    axis: AxisType,
    i: number,
    j: number,
    k: number
  ): void {
    const i2 = axis === AxisType.x ? i + 1 : i;
    const j2 = axis === AxisType.y ? j + 1 : j;
    const k2 = axis === AxisType.z ? k + 1 : k;
    const geometry = new THREE.BufferGeometry().setFromPoints([
      this.ctrl_pt_meshes[this.ffd.getIndex(i, j, k)].position,
      this.ctrl_pt_meshes[this.ffd.getIndex(i2, j2, k2)].position,
    ]);
    const line = new THREE.Line(geometry, this.lattice_line_material);
    (line as any).indexX = i;
    (line as any).indexY = j;
    (line as any).indexZ = k;

    this.lattice_lines[axis].push(line);
    this.container.add(line);
  }

  addLatticeLines(): void {
    // Lines in S direction.
    for (let i = 0; i < this.ffd.getCtrlPtCount(0) - 1; i++) {
      for (let j = 0; j < this.ffd.getCtrlPtCount(1); j++) {
        for (let k = 0; k < this.ffd.getCtrlPtCount(2); k++) {
          this.addLatticeLine(AxisType.x, i, j, k);
        }
      }
    }
    // Lines in T direction.
    for (let i = 0; i < this.ffd.getCtrlPtCount(0); i++) {
      for (let j = 0; j < this.ffd.getCtrlPtCount(1) - 1; j++) {
        for (let k = 0; k < this.ffd.getCtrlPtCount(2); k++) {
          this.addLatticeLine(AxisType.y, i, j, k);
        }
      }
    }
    // Lines in U direction.
    for (let i = 0; i < this.ffd.getCtrlPtCount(0); i++) {
      for (let j = 0; j < this.ffd.getCtrlPtCount(1); j++) {
        for (let k = 0; k < this.ffd.getCtrlPtCount(2) - 1; k++) {
          this.addLatticeLine(AxisType.z, i, j, k);
        }
      }
    }
  }

  private updateLatticeLine(
    axis: AxisType,
    line_index: number,
    i: number,
    j: number,
    k: number
  ): void {
    const i2 = axis === AxisType.x ? i + 1 : i;
    const j2 = axis === AxisType.y ? j + 1 : j;
    const k2 = axis === AxisType.z ? k + 1 : k;

    const line = this.lattice_lines[axis][line_index];
    const lineAdapter = adapterFactory.getAdapter(line.geometry);
    const vertex1 = this.ctrl_pt_meshes[this.ffd.getIndex(i, j, k)].position;
    lineAdapter.setVertex(0, vertex1.x, vertex1.y, vertex1.z);
    const vertex2 = this.ctrl_pt_meshes[this.ffd.getIndex(i2, j2, k2)].position;
    lineAdapter.setVertex(1, vertex2.x, vertex2.y, vertex2.z);
    lineAdapter.updateVertices();
  }

  private updateLatticeLines(): void {
    // Update the positions of all lines of the lattice.
    let line_index = 0;
    // Lines in S direction.
    for (let i = 0; i < this.ffd.getCtrlPtCount(0) - 1; i++) {
      for (let j = 0; j < this.ffd.getCtrlPtCount(1); j++) {
        for (let k = 0; k < this.ffd.getCtrlPtCount(2); k++) {
          this.updateLatticeLine(AxisType.x, line_index++, i, j, k);
        }
      }
    }
    line_index = 0;
    // Lines in T direction.
    for (let i = 0; i < this.ffd.getCtrlPtCount(0); i++) {
      for (let j = 0; j < this.ffd.getCtrlPtCount(1) - 1; j++) {
        for (let k = 0; k < this.ffd.getCtrlPtCount(2); k++) {
          this.updateLatticeLine(AxisType.y, line_index++, i, j, k);
        }
      }
    }
    line_index = 0;
    // Lines in U direction.
    for (let i = 0; i < this.ffd.getCtrlPtCount(0); i++) {
      for (let j = 0; j < this.ffd.getCtrlPtCount(1); j++) {
        for (let k = 0; k < this.ffd.getCtrlPtCount(2) - 1; k++) {
          this.updateLatticeLine(AxisType.z, line_index++, i, j, k);
        }
      }
    }
  }

  updateLattice(
    mainPoint: THREE.Object3D,
    connectedPoints: THREE.Object3D[] = []
  ): void {
    const pointIndex = (point: THREE.Object3D): number => {
      const x = (point as any).indexX;
      const y = (point as any).indexY;
      const z = (point as any).indexZ;
      return this.ffd.getIndex(x, y, z);
    };

    const mainIndex = pointIndex(mainPoint);
    if (this.snapToAxis) {
      switch (this.ffdAxis) {
        case FFDAxis.x:
          this.ctrl_pt_meshes[mainIndex].position.setY(
            this.pointPosition[mainIndex].y
          );
          this.ctrl_pt_meshes[mainIndex].position.setZ(
            this.pointPosition[mainIndex].z
          );
          break;
        case FFDAxis.y:
          this.ctrl_pt_meshes[mainIndex].position.setX(
            this.pointPosition[mainIndex].x
          );
          this.ctrl_pt_meshes[mainIndex].position.setZ(
            this.pointPosition[mainIndex].z
          );
          break;
        case FFDAxis.z:
          this.ctrl_pt_meshes[mainIndex].position.setX(
            this.pointPosition[mainIndex].x
          );
          this.ctrl_pt_meshes[mainIndex].position.setY(
            this.pointPosition[mainIndex].y
          );
          break;
      }
    }
    const delta = this.ctrl_pt_meshes[mainIndex].position
      .clone()
      .sub(this.pointPosition[mainIndex]);

    const updateConnectedPoint = (point: THREE.Object3D): void => {
      point.position.add(delta);
    };

    const updateConnectedAxis = (point: THREE.Object3D): void => {
      let axisObjects: THREE.Object3D[] = [];
      switch (this.ffdAxis) {
        case FFDAxis.yz:
          axisObjects = this.ctrl_pt_meshes.filter(
            (item) =>
              (item as any).indexX !== (point as any).indexX &&
              (item as any).indexY === (point as any).indexY &&
              (item as any).indexZ === (point as any).indexZ
          );
          break;
        case FFDAxis.xz:
          axisObjects = this.ctrl_pt_meshes.filter(
            (item) =>
              (item as any).indexX === (point as any).indexX &&
              (item as any).indexY !== (point as any).indexY &&
              (item as any).indexZ === (point as any).indexZ
          );
          break;
        case FFDAxis.xy:
          axisObjects = this.ctrl_pt_meshes.filter(
            (item) =>
              (item as any).indexX === (point as any).indexX &&
              (item as any).indexY === (point as any).indexY &&
              (item as any).indexZ !== (point as any).indexZ
          );
          break;
        case FFDAxis.x:
          axisObjects = this.ctrl_pt_meshes.filter(
            (item) =>
              (item as any).indexX === (point as any).indexX &&
              pointIndex(item) !== pointIndex(point)
          );
          break;
        case FFDAxis.y:
          axisObjects = this.ctrl_pt_meshes.filter(
            (item) =>
              (item as any).indexY === (point as any).indexY &&
              pointIndex(item) !== pointIndex(point)
          );
          break;
        case FFDAxis.z:
          axisObjects = this.ctrl_pt_meshes.filter(
            (item) =>
              (item as any).indexZ === (point as any).indexZ &&
              pointIndex(item) !== pointIndex(point)
          );
          break;
      }
      for (const item of axisObjects) {
        updateConnectedPoint(item);
      }
    };

    updateConnectedAxis(mainPoint);
    for (const point of connectedPoints) {
      if (mainIndex !== pointIndex(point)) {
        updateConnectedPoint(point);
        updateConnectedAxis(point);
      }
    }

    // Update the positions of all control point in the FFD object.
    for (let i = 0; i < this.ffd.getTotalCtrlPtCount(); i++) {
      if (this.editGrid)
        this.ffd.setGridPosition(i, this.ctrl_pt_meshes[i].position);
      else this.ffd.setPosition(i, this.ctrl_pt_meshes[i].position);
      this.pointPosition[i] = this.ctrl_pt_meshes[i].position.clone();
    }
    this.updateLatticeLines();
  }

  deform(): void {
    // Update the model vertices.
    for (let i = 0; i < this.geometryAdapter.numVertices; i++) {
      const eval_pt = this.ffd.evalWorld(this.smooth_verts_undeformed[i]);
      if (eval_pt === null || eval_pt.equals(this.geometryAdapter.getVertex(i)))
        continue;
      this.geometryAdapter.setVertex(i, eval_pt.x, eval_pt.y, eval_pt.z);
    }
    this.geometryAdapter.updateVertices();
  }
}
