<template>
  <div class="mr-3 mt-12">
    <!-- Permanent View Menu with Center-Right Alignment and Padding -->
    <div class="bg-grey-transparent d-flex flex-row align-items-center views-container">
      <span class="productViewTitle">
        {{ $t('views.sidebar-tools.view-option') }}
      </span>
      <el-select
        v-model="view"
        placeholder="Select view"
        v-on:change="changeView"
        class="view-menu-select"
      >
        <template v-slot:prefix>
          <font-awesome-icon :icon="['fac', view]" />
        </template>
        <el-option
          v-for="item in viewOptions"
          :key="item"
          :value="item"
          :label="$t(`enum.views.${item}`)"
        >
          <font-awesome-icon :icon="['fac', item]" />
          {{ $t(`enum.views.${item}`) }}
        </el-option>
      </el-select>
    </div>

    <!-- Transparency Toggle Switches with Titles -->
    <!--
    <div class="transparency-toggle">
      <div class="toggle-container">
        <span class="switch-title">Part 1</span>
        <el-switch
          v-model="isTransparent"
          active-text="On"
          inactive-text="Off"
          v-on:change="toggleTransparency"
          class="switch-right"
        />
      </div>
      <div class="toggle-container">
        <span class="switch-title">Part 2</span>
        <el-switch
          v-model="isSecondTransparent"
          active-text="On"
          inactive-text="Off"
          v-on:change="toggleSecondTransparency"
          class="switch-right"
        />
      </div>
    </div>
    -->
  </div>

  <!-- Original UI Elements -->
  <!--
  <div v-if="showSendMenu" class="mr-3 mt-12">
    <div class="bg-grey-transparent rounded-circle d-flex flex-column sidebar-container secondary-container">
      <button class="btn"><img width="34px" src="/assets/svg/mail-button.svg" alt="Mail Button" /></button>
      <button class="btn"><img width="34px" src="/assets/svg/whatsapp-button.svg" alt="Whatsapp Button" /></button>
      <button class="btn"><img width="34px" src="/assets/svg/download-button.svg" alt="Download Button" /></button>
    </div>
  </div>
  <div v-if="showSettingsMenu" class="mr-3 mt-12">
    <div class="bg-grey-transparent rounded-circle d-flex flex-column sidebar-container settings-container pb-3">
      <ProductQuality
        v-if="canResolution"
        v-on:productQualityChanged="productQualityChanged"
        :selectedProductQualityIndex="selectedProductQualityIndex"
      />
    </div>
  </div>
  <div v-if="showViewMenu" class="mr-3 mt-12">
    <div class="bg-grey-transparent rounded-circle d-flex flex-column sidebar-container views-container pb-3">
      <div class="d-flex">
        <span class="productViewTitle">
          {{ $t('views.sidebar-tools.view-option') }}
        </span>
        <el-select
            v-model="view"
            placeholder="Select view"
            v-on:change="meshEditor.changeView"
          >
            <template v-slot:prefix>
              <font-awesome-icon :icon="['fac', view]" />
            </template>
            <el-option
              v-for="item in Object.values(Views).filter(v => v != Views.custom)"
              :key="item"
              :value="item"
              :label="$t(`enum.views.${item}`)"
              :disabled="item === Views.custom"
            >
              <font-awesome-icon :icon="['fac', item]" />
              {{ $t(`enum.views.${item}`) }}
            </el-option>
          </el-select>
      </div>
    </div>
  </div>
  <div class="bg-grey-transparent rounded-circle d-flex flex-column sidebar-container main-container p-0"
       id="view-buttons">
    <button @click="showViewMenu = !showViewMenu; showSendMenu = false; showSettingsMenu = false;" class="btn pb-3 mb-1">
      <img height="40px" src="/assets/svg/rotate-button.svg" alt="Rotate Button" />
    </button>
    <button class="btn pb-3 mb-2" v-on:click="meshEditor.toggleOpacity()">
      <img height="40px" src="/assets/svg/opacity.svg" alt="Opacity Button" />
    </button>
    <button v-if="authentication?.roles?.includes(Role.Admin)"
      @click="downloadModel()"
      v-loading="loadingDownloadModel"
      class="btn pb-3">
        <img height="40px" src="/assets/svg/download-button.svg" alt="Download Button" />
    </button>
  </div>
  -->
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Views } from "@/types/enum/three";
import MeshEditor from "../three/MeshEditor.vue";

@Options({
  components: {},
  emits: ['productQualityChanged'],  // Ensure emits are properly declared
})

export default class SidebarTools extends Vue {
  @Prop({ default: null }) meshEditor!: MeshEditor;

  view: string = Views.custom;
  isTransparent = false; // First switch inferred as boolean
  isSecondTransparent = false; // Second switch inferred as boolean
  viewOptions: string[] = [];

  mounted() {
    // Ensure Views is populated and not null
    if (Views) {
      this.viewOptions = Object.values(Views).filter(v => v !== Views.custom);
    }
  }

  changeView(newView: string) {
    if (this.meshEditor) {
      this.meshEditor.changeView(newView);
    }
  }

  toggleTransparency() {
    if (this.meshEditor) {
      this.meshEditor.toggleOpacity();
    }
  }

  toggleSecondTransparency() {
    // Placeholder for the second switch functionality
    // Implement actual functionality as needed
  }

  // Original methods and properties
  /*
  @Prop({default: true}) canResolution!: boolean;
  @Prop({ default: null }) workflowModel!: WorkflowModel;
  @Prop({ default: 3 }) selectedProductQualityIndex!: number;
  @Prop({ default: null }) authentication!: Auth;

  showSendMenu = false;
  showSettingsMenu = false;
  showViewMenu = false;

  loadingDownloadModel = false;

  Views = Views;
  Role = Role;

  orientationGizmo: OrientationGizmo | null = null;
  troisRenderer: any | null = null;

  productQualityChanged(selectedProductQuality: ProductQuality) {
    this.$emit('productQualityChanged', selectedProductQuality);
  }

  async downloadModel(): Promise<void> {
    this.loadingDownloadModel = true;

    getMeshExport(this.meshEditor.troisRenderer.scene!, FileType3D.OBJ).then((downloadModel) => {
      const link = document.createElement('a');
      link.href = "data:application/octet-stream;base64," + downloadModel;
      link.target = "_blank";
      link.download = "model.obj";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.loadingDownloadModel = false;
    });
  }
  */
}
</script>

<style scoped lang="scss">
.sidebar-container {
  width: 100%; 
  background: #00000033;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.views-container {
  padding: 4px;
  border-radius: 8px; /* Rounded corners */
  display: flex;
  align-items: center;
  justify-content: flex-start; 
  background: #00000033;
}

.productViewTitle {
  width: 50px; /* Set a fixed width for the title container */
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  color: white;
  font-weight: bold;
  flex-shrink: 0;
  padding-right: 2%;
}

.el-select.view-menu-select {
  flex-grow: 1; /* Allow select to grow and fill available space */
  min-width: 150px; /* Minimum width to keep it usable */
  max-width: calc(100% - 75px); 
  border-radius: 8px; /* Rounded corners */
  margin-left: 0px;
  height: 32px; 
  width: auto; 
}



/* Original Styles - Commented Out
.btn {
  @extend .btn;
  padding: 0 !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.btn:hover {
  background-color: rgba(0, 0, 0, 0.25);
}

.rounded-circle {
  @extend .rounded-circle;
  border-radius: 32px !important;
}

.sidebar-container {
  width: 3.25rem;
}

.main-container, .secondary-container, .settings-container, .views-container {
  max-height: 300px !important;
}

.secondary-container, .settings-container, .views-container {
  width: 210px;
}

.secondary-container>.btn, .settings-container>.btn, .views-container>.btn {
  padding: 8px !important;
}

.secondary-container::before, .settings-container::before, .views-container::before {
  content: "";
  width: 0;
  height: 0;
  position: relative;
  border-top: 16px solid #00000033;
  border-right: 16px solid transparent;
  transform: rotate(135deg);
  top: 25px;
  left: 200px;
}

.bg-grey-transparent {
  background: #00000033 0% 0% no-repeat padding-box;
}

.productViewTitle {
  color: white;
  margin: 0.5rem 0.5rem 0 0.5rem;
  vertical-align: middle;
  text-align: left !important;
}

.el-select {
  margin: 0 0.5rem 0 0;
  width: 130px;
}

.mb-bottom {
    padding-bottom: 100px; 
}
*/

</style>
