<template>
  <el-container class="screenHeight">
    <el-header class="level">
      <span class="level-left">
        <el-page-header
          class="level-item"
          :content="$t('components.menu-sidebar.orthopedics.orders')"
          @back="$router.back()"
        />
      </span>
      <span class="level-right"> </span>
    </el-header>
    <el-container>
      <el-aside class="withFooterAndHeader" width="20rem" v-if="showMenu">
        <el-scrollbar>
          <side-menu active-menu-item="3-2"> </side-menu>
        </el-scrollbar>
      </el-aside>
      <ToggleSidebar v-model="showMenu" />
      <el-main v-loading="modelsLoading">
        <el-space wrap>
          <el-card
            v-for="model in modelList"
            :key="model.modelId"
            v-on:click="displayModel(model, $event)"
          >
            <template #header>
              <span v-if="model.product">
                {{ model.product.name }}
              </span>
            </template>
            <PreviewRendererImg
              :url="convertToUrl(model)"              
            />
          </el-card>
        </el-space>
      </el-main>
    </el-container>
  </el-container>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import * as modelService from '@/services/api/modelService';
import { Model, Model2 } from '@/types/api/Model/Model';
import { FileTypeImage } from '@/types/enum/upload';
import PreviewRenderer from '@/components/three/PreviewRenderer.vue';
import PreviewRendererImg from '@/components/three/PreviewRendererImg.vue';
import SideMenu from '@/components/workflow/SideMenu.vue';
import ToggleSidebar from '@/components/element-plus/ToggleSidebar.vue';

@Options({
  components: {
    SideMenu,
    ToggleSidebar,
    PreviewRenderer,
    PreviewRendererImg
  },
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class OrderOverview extends Vue {
  modelList: Model2[] = [];
  FileType3D = FileTypeImage;
  showMenu = true;
  modelsLoading = false;

  mounted(): void {
    this.modelsLoading = true;
    modelService.getModelOverviewList().then(async (result) => {
      if (result) {
        const modelList = result.filter((item) => !item.basedOn);
        this.modelList = modelList;
      } else this.modelList = [];
      this.modelsLoading = false;
    });
  }

  convertToUrl(model: Model): string | null {
    if (
      model.product &&
      model.product.thumbnail
    ) {
      return model.product.thumbnail.content;
    }
    return null;
  }

  displayModel(model: Model, event: PointerEvent): void {
    const path = event.composedPath();
    if (!path.find((item) => (item as any).id === 'deleteModel'))
      this.$router.push(`/order-workflow?templateId=${model.modelId}`);
  }
}
</script>

<style lang="scss" scoped>
.el-space {
  padding: 1rem 1rem 1rem 1rem;
}

.level:not(:last-child) {
  margin-bottom: unset;
}

.el-container .el-container {
  max-height: calc(100vh - var(--el-header-height));
}
</style>
