<script lang="ts">
import {MeshTreeData, MeshTreeDataItem} from "@/types/ui/MeshTreeData";
import {Modal} from "bootstrap";
import {onMounted, ref, watch} from "vue";
import {RGB} from "@/types/api/Utility/RGB";
import PartSelection from "@/components/PartSelection.vue";
import RoundedButton from "@/components/RoundedButton.vue";
import ColorSelection from "@/components/ColorSelection.vue";
import ChangeStepTools from "@/components/Tools/ChangeStepTools.vue";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Auth from "@/services/api/auth";

@Options({
  components: {
    RoundedButton,
    PartSelection,
    ColorSelection
  },
  emits: ['mesh-color-selected', 'mesh-embossing-color-selected', 'part-selected', 'mesh-texture-color-selected'],
})

export default class ColorFooter extends Vue {
  @Prop() properties!: any[];
  @Prop() selectedMeshEmbossingColor!: RGB;
  @Prop() selectedMeshColor!: RGB;
  @Prop() selectedMeshTextureColor!: RGB;
  @Prop() selectedMeshVarnish!: string;
  @Prop() meshEmbossingColors!: RGB[];
  @Prop() meshColors!: RGB[];
  @Prop() meshTextureColors!: RGB[];
  @Prop() meshTreeData!: MeshTreeData;
  @Prop() productTypeString!: string;
  @Prop() supplier!: string;

  authentication = Auth.getInstance();

  ENGRAVING_PRICE = '+145€'

  showMeshColorMenu = false;
  showMeshEmbossingColorMenu = false;
  showMeshVarnishMenu = false;
  showChangeStepMenu = false;
  partSelectionModalDialog: any = null;
  colorSelectionModalDialog: any = null;
  
  varnishStyles = ['none', 'glossy', 'matte'];

  mounted() {
    // Initialize modal dialog
    const modalDialogOptions = {};
    this.partSelectionModalDialog = new Modal('#part-selection-modal-dialog', modalDialogOptions);
    this.colorSelectionModalDialog = new Modal('#color-selection-modal-dialog', modalDialogOptions);

    // Enable vertical scrolling with mouse wheel
    const meshColorSelection = document.getElementById("mesh-color-selection");
    meshColorSelection?.addEventListener("wheel", (e: WheelEvent) => {
      if (e.deltaY > 0) {
        meshColorSelection.scrollLeft += 50;
      } else {
        meshColorSelection.scrollLeft -= 50;
      }
    });
    const meshEmbossingColorSelection = document.getElementById("mesh-embossing-color-selection");
    meshEmbossingColorSelection?.addEventListener("wheel", (e: WheelEvent) => {
      if (e.deltaY > 0) {
        meshEmbossingColorSelection.scrollLeft += 50;
      } else {
        meshEmbossingColorSelection.scrollLeft -= 50;
      }
    });
  }

  get isTeufel() {
    return this.supplier == 'wjtortho';
  }

  get isTextWhite() {
    return getComputedStyle(document.documentElement).getPropertyValue('--primary-text').trim() == '#fff';
  }

  colorPricing(colorName) {
    switch (colorName) {
      case 'none': 
        return '0€';
      default:
        return this.ENGRAVING_PRICE;
    }
  }

  isSecondary(colorName) {
    return colorName != this.selectedMeshEmbossingColor.name
  }
}




</script>

<template>
  <div class="row ml-5 mr-5">
    <!-- Color selection menu -->
    <div v-if="showMeshColorMenu && (meshColors.length > 0 || meshTextureColors.length > 0)" class="d-flex justify-content-center pb-2">
      <div v-if="meshColors.length > 0" class="overflow-x-auto d-flex gap-4 rounded-3 p-3" id="mesh-color-selection">
        <rounded-button v-for="color in meshColors" :key="color.name"
                        @button-clicked="$emit('mesh-color-selected', color)"
                        :is-secondary-button="color.name != selectedMeshColor.name">
          <div selected class="rounded-circle" :style="'background: rgb(' + color.red + ',' + color.green + ',' + color.blue + ')'"></div>
        </rounded-button>
      </div>
      <div v-else-if="meshTextureColors.length > 0" class="overflow-x-auto d-flex gap-4 rounded-3 p-3" id="mesh-color-selection">
        <rounded-button v-for="color in meshTextureColors" :key="color.name"
                        @button-clicked="$emit('mesh-texture-color-selected', color)"
                        :is-secondary-button="color.name != selectedMeshTextureColor.name">
          <div selected class="rounded-circle" :style="'background: rgb(' + color.red + ',' + color.green + ',' + color.blue + ')'"></div>
        </rounded-button>
      </div>
    </div>
    <div v-if="authentication.domains.some((d) => d.urlSuffix == 'festwood') && showMeshVarnishMenu" class="d-flex justify-content-center pb-2">
      <div class="overflow-x-auto d-flex gap-4 rounded-3 p-3" id="mesh-varnish-selection">

        <rounded-button v-for="varnish in varnishStyles" :key="varnish"
                        @button-clicked="properties[0].varnish = varnish; console.log('Prop: ' + properties[0].varnish); $emit('mesh-varnish-selected')"
                        :is-secondary-button="varnish != properties[0].varnish">
          <div class="rounded-circle flex align-items-center justify-content-center">
            <span style="filter: drop-shadow(0.2px 0.2px 1px black); font-size: 10px;">{{ $t(`views.varnish-styles.${varnish}`) }}</span>
          </div>
        </rounded-button>
      </div>
    </div>
    <!--<div v-else-if="showMeshEmbossingColorMenu && meshEmbossingColors.length > 0" class="d-flex justify-content-center pb-2">
      <div class="overflow-x-auto d-flex gap-4 rounded-3 p-3" id="mesh-embossing-color-selection">
        
        <rounded-button v-for="color in meshEmbossingColors" :key="color.name"
                        @button-clicked="$emit('mesh-embossing-color-selected', color)"
                        :is-secondary-button="isSecondary(color.name)">
          <div class="rounded-circle flex align-items-center justify-content-center" :style="'background: rgb(' + color.red + ',' + color.green + ',' + color.blue + ')'">
              <span style="filter: drop-shadow(0.2px 0.2px 1px black); font-size: 19px;" :style="!isSecondary(color.name) ? 'color: var(--color-primary);' : ''">{{ colorPricing(color.name) }}</span>
          </div>
        </rounded-button>
      </div>
    </div>-->

    <!-- Footer -->
    <div class="d-flex justify-content-center gap-4">
      <!-- Parts button -->
      <rounded-button class="mt-4 pt-3" id="parts-button"
                      :title="$t('components.embossing-footer.partsButton')"
                      @button-clicked="partSelectionModalDialog.show()">
        <div v-if="isTextWhite">
          <img src="@/assets/icons/layers_w.svg" class="img" alt="..."/>
        </div>
        <div v-else>
          <img src="@/assets/icons/layers.svg" class="img" alt="..."/>
        </div>
      </rounded-button>

      <!-- Colors button -->
      <rounded-button class="mt-4 pt-3 design-button" id="design-button"
                :title="$t('components.embossing-footer.colorsButton')"
                @button-clicked="colorSelectionModalDialog.show()"
                v-if="meshColors.length > 0 || meshTextureColors.length > 0 || meshEmbossingColors.length > 0">
        <font-awesome-icon icon="ellipsis" />
      </rounded-button>

      <!-- Mesh color button -->
      <div v-if="selectedMeshColor">
        <p class="form-text text-center text-capitalize m-0">{{ $t(`views.colors.${selectedMeshColor.name}`) ?? selectedMeshColor.name }}</p>
        <rounded-button id="mesh-color-button"
                        :title="$t('components.color-footer.meshColorButton')"
                        @click="showMeshColorMenu = !showMeshColorMenu; showMeshEmbossingColorMenu = false;">
          <div class="rounded-circle" :style="'background: rgb(' + selectedMeshColor.red + ',' + selectedMeshColor.green + ',' + selectedMeshColor.blue + ')'"></div>
        </rounded-button>
      </div>

      <!-- Mesh texture color button -->
      <div v-else-if="meshTextureColors.length > 0 && selectedMeshTextureColor">
        <p class="form-text text-center text-capitalize m-0">{{ $t(`views.colors.${selectedMeshTextureColor.name}`) ?? selectedMeshTextureColor.name }}</p>
        <rounded-button id="mesh-color-button"
                        :title="$t('components.color-footer.meshColorButton')"
                        @click="showMeshColorMenu = !showMeshColorMenu; showMeshEmbossingColorMenu = false;">
          <div class="rounded-circle" :style="'background: rgb(' + selectedMeshTextureColor.red + ',' + selectedMeshTextureColor.green + ',' + selectedMeshTextureColor.blue + ')'"></div>
        </rounded-button>
      </div>

      <!-- Mesh texture varnish button -->
      <div v-else-if="authentication.domains.some((d) => d.urlSuffix == 'festwood')">
        <rounded-button id="mesh-varnish-button"
                        :title="$t('components.color-footer.meshVarnishButton')"
                        @click="showMeshVarnishMenu = !showMeshVarnishMenu"
                        style="margin-top: 1.3rem;"
                        >
          <div class="rounded-circle flex align-items-center justify-content-center">
            <span style="filter: drop-shadow(0.2px 0.2px 1px black); font-size: 17px;">{{ $t(`views.varnish-styles.${properties[0].varnish}`) }}</span>
          </div>
        </rounded-button>
      </div>

      <!-- Mesh embossing color button
      <div v-if="meshEmbossingColors.length > 0">
        <p class="form-text text-center text-capitalize m-0">{{ $t(`views.colors.${selectedMeshEmbossingColor.name}`) ?? selectedMeshEmbossingColor.name }}</p>
        <rounded-button id="mesh-embossing-color-button"
                        :title="$t('components.color-footer.meshEmbossingColorButton')"
                        @click="showMeshColorMenu = false; showMeshEmbossingColorMenu = !showMeshEmbossingColorMenu;">
          <div class="rounded-circle" :style="'background: rgb(' + selectedMeshEmbossingColor.red + ',' + selectedMeshEmbossingColor.green + ',' + selectedMeshEmbossingColor.blue + ')'"></div>
        </rounded-button>
      </div>-->
    </div>
  </div>

  <!-- Part selection modal dialog -->
  <div class="modal fade" id="part-selection-modal-dialog" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header border-bottom-0 bg-transparent" id="part-selection-modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body p-0" id="part-selection-modal-body">
          <part-selection :mesh-tree-Data="meshTreeData"
                          @part-selected="(part) => {$emit('part-selected', part); partSelectionModalDialog.hide();}"
                          :productTypeString="productTypeString"/>
        </div>
      </div>
    </div>
  </div>

  <!-- Color selection modal dialog -->
  <div class="modal fade" id="color-selection-modal-dialog" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header border-bottom-0 bg-transparent" id="color-selection-modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body p-0" id="color-selection-modal-body">
          <color-selection :meshColors="meshColors" :meshEmbossingColors="meshEmbossingColors" :meshTextureColors="meshTextureColors"
                            :selectedMeshColor="selectedMeshColor" :selectedMeshEmbossingColor="selectedMeshEmbossingColor"
                            :selectedMeshTextureColor="selectedMeshTextureColor"
                            @mesh-color-selected="(color) => {$emit('mesh-color-selected', color); colorSelectionModalDialog.hide();}"
                            @mesh-texture-color-selected="(color) => {$emit('mesh-texture-color-selected', color); colorSelectionModalDialog.hide();}"
                            @mesh-embossing-color-selected="(color) => {$emit('mesh-embossing-color-selected', color); colorSelectionModalDialog.hide();}"
                            />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#mesh-varnish-button > .btn,
#mesh-color-button > .btn,
#mesh-embossing-color-button > .btn {
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 5.9rem !important;
  width: 5.9rem !important;
  padding: 0 !important;
}
#parts-button > .btn {
  border-radius: 1rem !important;
  height: 3.75rem !important;
  width: 3.75rem !important;
  padding: 0 !important;
}
#mesh-varnish-selection > div > .btn,
#mesh-color-selection > div > .btn,
#mesh-embossing-color-selection > div > .btn {
  display: grid;
  align-items: center;
  justify-content: center;
  height: 3.85rem !important;
  width: 3.85rem !important;
  border-radius: 50%;
  padding: 0 !important;
}
// TODO: Remove custom backdrop style and resolve z-index issue!
.modal-backdrop {
  z-index: -1 !important;
}
</style>
<style scoped lang="scss">
#part-selection-modal-header,
#color-selection-modal-header {
  background-color: transparent;
  z-index: 1;
}
#part-selection-modal-body,
#color-selection-modal-body {
  margin-top: -3.6rem;
  z-index: 0;
}
#mesh-varnish-button > button > .rounded-circle,
#mesh-color-button > button > .rounded-circle,
#mesh-embossing-color-button > button > .rounded-circle {
  height: 5.6rem !important;
  width: 5.6rem !important;
}
.form-text {
  font-size: 1rem;
}
#mesh-varnish-selection,
#mesh-color-selection,
#mesh-embossing-color-selection {
  background: #00000033;
}
#mesh-varnish-selection > div > button > .rounded-circle,
#mesh-color-selection > div > button > .rounded-circle,
#mesh-embossing-color-selection > div > button > .rounded-circle {
  height: 3.6rem !important;
  width: 3.6rem !important;
}

.design-button > .btn {
  border-radius: 50% !important;
  height: 3.75rem !important;
  width: 3.75rem !important;
  padding: 0 !important;
}
</style>
