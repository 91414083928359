<template>
  <div class="d-flex">
    <span class="productQualityTitle">
      {{ $t('views.productQuality.title') }}
    </span>
    <el-select v-model="currentProductQualityIndex" @change="onProductQualityChanged">
      <el-option
        v-for="qualilty in Object.values(ProductQualityType)"
        :key="qualilty"
        :value="Object.values(ProductQualityType).indexOf(qualilty)"
        :label="$t(`enum.productQuality.${qualilty}`)"
      />
    </el-select>
  </div>
</template>

<script lang="ts">
import { ProductQuality as ProductQualityType } from '@/types/enum/template';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
  components: {},
  emits: ['productQualityChanged'],
})
export default class ProductQuality extends Vue {
  @Prop({ default: 3 }) selectedProductQualityIndex!: number

  ProductQualityType = ProductQualityType;
  selectedProductQuality = ProductQualityType.low;

  currentProductQualityIndex = 3;

  mounted(): void {
      this.currentProductQualityIndex = this.selectedProductQualityIndex;
  }

  onProductQualityChanged(): void {
    switch (this.currentProductQualityIndex) {
      case 3:
        this.selectedProductQuality = ProductQualityType.low;
        break;
      case 2:
        this.selectedProductQuality = ProductQualityType.middle;
        break;
      case 1:
        this.selectedProductQuality = ProductQualityType.high;
        break;
      case 0:
        this.selectedProductQuality = ProductQualityType.original;
        break;
      default:
        this.selectedProductQuality = ProductQualityType.low;
    }

    this.$emit('productQualityChanged', this.selectedProductQuality);
  }
}
</script>

<style scoped lang="scss">

.productQualityTitle {
  color: white;
  margin: 0.5rem 0.5rem 0 0.5rem;
  vertical-align: middle;
  text-align: left !important;
}

.el-select {
  margin: 0 0.5rem 0 0;
  width: 130px;
}
</style>
