import * as THREE from 'three';
import * as MathAngle from '@/utils/angle';

/**
 * Created on: 2022-09-06T08:19:59.2326176+00:00
 */
export class Vector3 {
  public constructor() {
    //
  }

  protected _xValue!: number;
  protected _yValue!: number;
  protected _zValue!: number;

  /**
   * @returns _xValue
   */
  public get xValue() {
    return this._xValue;
  }

  /**
   * Sets _xValue
   */
  public set xValue(value: number) {
    this._xValue = value;
  }

  /**
   * @returns _yValue
   */
  public get yValue() {
    return this._yValue;
  }

  /**
   * Sets _yValue
   */
  public set yValue(value: number) {
    this._yValue = value;
  }

  /**
   * @returns _zValue
   */
  public get zValue() {
    return this._zValue;
  }

  /**
   * Sets _zValue
   */
  public set zValue(value: number) {
    this._zValue = value;
  }

  // Start Userdefined content - don't change anything above
  /*get threeVector(): THREE.Vector3 {
    return new THREE.Vector3(this.xValue, this.yValue, this.zValue);
  }

  set threeVector(value: THREE.Vector3) {
    this.xValue = value.x;
    this.yValue = value.y;
    this.zValue = value.z;
  }

  get threeEuler(): THREE.Euler {
    return new THREE.Euler(
      MathAngle.toRadians(this.xValue),
      MathAngle.toRadians(this.yValue),
      MathAngle.toRadians(this.zValue)
    );
  }

  set threeEuler(value: THREE.Euler) {
    this.xValue = value.x;
    this.yValue = value.y;
    this.zValue = value.z;
  }*/

  static convertToThreeVector(
    value: Vector3 | null,
    nullVector = new THREE.Vector3(0, 0, 0)
  ): THREE.Vector3 {
    if (value) {
      return new THREE.Vector3(value.xValue, value.yValue, value.zValue);
    }
    return nullVector;
  }

  static convertToThreeEuler(
    value: Vector3 | null,
    nullVector = new THREE.Euler(0, 0, 0)
  ): THREE.Euler {
    if (value) {
      return new THREE.Euler(
        MathAngle.toRadians(value.xValue),
        MathAngle.toRadians(value.yValue),
        MathAngle.toRadians(value.zValue)
      );
    }
    return nullVector;
  }
  // End Userdefined content - don't change anything below
}
