<template>
  <div>
    <div class="min-vh-100 d-flex flex-column">
      <!--<router-view class="pre-formatted" />-->
      <router-view />
    </div>
  </div>
</template>

<script setup lang="ts">

</script>
