<template>
  <div v-if="canRender" class="level-item" v-on:click="renderActiveView">
    <font-awesome-icon icon="play" />
  </div>
  <div v-if="canRender" class="level-item" v-on:click="renderAllViews">
    <font-awesome-icon icon="forward" />
  </div>
  <el-popover
    ref="popoverAddRenderView"
    v-if="canRender"
    placement="bottom-start"
    :title="$t('components.mesh-editor.render.title')"
    :width="200"
    trigger="click"
  >
    <template #reference>
      <div class="level-item action">
        <font-awesome-icon icon="circle-plus" />
      </div>
    </template>
    <el-form ref="dataForm" :model="cameraData" v-on:submit="addCamera">
      <el-form-item
        :label="$t('components.mesh-editor.render.view')"
        prop="view"
      >
        <el-radio-group v-model="cameraData.view" v-on:change="changeView">
          <el-radio
            v-for="item in Object.values(Views)"
            :key="item"
            :label="item"
          >
            <font-awesome-icon :icon="['fac', item]" />
            {{ $t(`enum.views.${item}`) }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        :label="$t('components.mesh-editor.render.name')"
        prop="name"
        :rules="{
          required: true,
        }"
      >
        <el-input v-model="cameraData.name" />
      </el-form-item>
      <!--<el-form-item :label="$t('components.mesh-editor.render.perspective')">
        <el-checkbox v-model="cameraData.perspective" />
      </el-form-item>-->
      <el-form-item>
        <el-button
          @click="addCamera"
          type="primary"
          :disabled="cameraData.name.length === 0"
        >
          {{ $t('components.mesh-editor.render.add') }}
        </el-button>
      </el-form-item>
    </el-form>
  </el-popover>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { MeshTreeData } from '@/types/ui/MeshTreeData';
import { RendererPublicInterface } from 'troisjs';
import { SelectionList } from '@/types/ui/SelectionList';
import { ViewCtrl } from '@/types/ui/ViewCtrl';
import { EditorMode } from '@/types/ui/EditorMode';
import { Prop, Watch } from 'vue-property-decorator';
import * as THREE from 'three';
import TransformTools from '@/components/Tools/TransformTools.vue';
import * as THREEEnum from '@/types/enum/three';
import { SpecialObjectPrefix } from '@/types/enum/three';
import { ElForm } from 'element-plus';
import {
  HistoryInfo,
  HistoryList,
  HistoryOperationType,
} from '@/types/ui/HistoryList';
import { CameraExportData } from '@/services/api/modelService';

interface CameraData {
  cameraId: number | null;
  name: string;
  view: THREEEnum.Views;
  perspective: boolean;
}

interface CameraDescription {
  info: CameraData;
  uuid: string;
  position: THREE.Vector3;
  rotation: THREE.Euler;
  camera: THREE.Camera;
}

@Options({
  components: { TransformTools },
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class CurveTools extends Vue implements HistoryInfo {
  //#region properties
  @Prop() modelValue!: MeshTreeData;
  @Prop() troisRenderer!: RendererPublicInterface;
  @Prop() editorMode!: EditorMode;
  @Prop() selectionList!: SelectionList;
  @Prop() viewCtrl!: ViewCtrl;
  @Prop({ default: true }) canRender!: boolean;
  @Prop() readonly selectObject!: (
    target: THREE.Object3D | null,
    setSelectionFlag: boolean
  ) => Promise<void>;
  @Prop() historyList!: HistoryList;

  //history
  lastUpdateTime = -1;

  //curve
  cameraList: CameraDescription[] = [];
  cameraData: CameraData = {
    cameraId: null,
    name: THREEEnum.Views.custom,
    view: THREEEnum.Views.custom,
    perspective: true,
  };
  activeCamera: CameraDescription | null = null;

  // enums for use in template section
  Views = THREEEnum.Views;

  //#endregion properties

  //#region init
  mounted(): void {
    this.troisRenderer.three.cameraCtrl?.addEventListener(
      'end',
      this.cameraChanged
    );
    this.troisRenderer.three.cameraCtrl?.addEventListener(
      'start',
      this.cameraChangStart
    );
  }

  unmounted(): void {
    this.troisRenderer.three.cameraCtrl?.removeEventListener(
      'end',
      this.cameraChanged
    );
    this.troisRenderer.three.cameraCtrl?.removeEventListener(
      'start',
      this.cameraChangStart
    );
  }

  private oldPosition: THREE.Vector3 = new THREE.Vector3(0, 0, 0);
  private oldRotation: THREE.Euler = new THREE.Euler(0, 0, 0);
  cameraChangStart(): void {
    if (this.canRender && this.activeCamera) {
      this.oldPosition = this.activeCamera.position.clone();
      this.oldRotation = this.activeCamera.rotation.clone();
    }
  }

  cameraChanged(): void {
    if (this.canRender && this.activeCamera) {
      this.lastUpdateTime = Date.now();
      const cameraId = this.activeCamera.uuid;
      const newPosition = this.activeCamera.position.clone();
      const newRotation = this.activeCamera.rotation.clone();
      const oldPosition = this.oldPosition.clone();
      const oldRotation = this.oldRotation.clone();
      if (cameraId) {
        this.historyList.add(
          cameraId,
          HistoryOperationType.renderViews,
          async () => {
            this.undoRedoCamera(cameraId, oldPosition, oldRotation);
          },
          async () => {
            this.undoRedoCamera(cameraId, newPosition, newRotation);
          }
        );
      }
    }
  }

  @Watch('canRender', { immediate: true })
  onCanRenderChanged(): void {
    const cameraItem = this.modelValue.getItemByName(
      (this as any).$t('enum.upload-category.camera')
    );
    if (cameraItem) {
      cameraItem.active = this.canRender;
      this.modelValue.updateStructure();
    }
  }
  //#endregion init

  //#region add / select camera
  addCamera(event: Event | null = null): void {
    if (event) event.preventDefault();
    const dataForm = this.$refs.dataForm as typeof ElForm;
    dataForm?.validate(async (valid) => {
      if (valid) {
        this.saveCamera(
          this.cameraData.cameraId,
          this.cameraData.name,
          this.cameraData.perspective,
          this.cameraData.view,
          this.troisRenderer.camera?.position,
          this.troisRenderer.camera?.rotation,
          true
        );
        this.modelValue.updateStructure();
        if (this.cameraData.view !== THREEEnum.Views.custom) {
          setTimeout(() => {
            this.viewCtrl.changeView(this.cameraData.view);
          }, 100);
        }
      }
    });
  }

  private saveCamera(
    cameraId: number | null,
    cameraName: string,
    perspective: boolean,
    view: THREEEnum.Views,
    position: THREE.Vector3 | undefined,
    rotation: THREE.Euler | undefined,
    selectNewCamera: boolean
  ): void {
    let camera: THREE.Camera;
    if (perspective) {
      camera = new THREE.PerspectiveCamera();
    } else {
      camera = new THREE.OrthographicCamera();
    }
    camera.name = cameraName;
    if (position && rotation) {
      camera.position.copy(position);
      camera.rotation.copy(rotation);
    }
    (camera as any).apiData = this.modelValue.addCamera(
      `${SpecialObjectPrefix.camera}${cameraName}`,
      camera,
      camera.position,
      camera.rotation,
      (this as any).$t('enum.upload-category.camera'),
      selectNewCamera
    );
    if ((camera as any).apiData) camera.uuid = (camera as any).apiData.uuid;
    const renderData: CameraDescription = {
      info: {
        cameraId: cameraId,
        name: cameraName,
        perspective: perspective,
        view: view,
      },
      uuid: camera.uuid,
      position: camera.position,
      rotation: camera.rotation,
      camera: camera,
    };
    this.activeCamera = renderData;
    this.cameraList.push(renderData);
  }

  @Watch('lastUpdateTime', { immediate: true })
  onDataChanged(): void {
    if (this.lastUpdateTime > -1 && this.activeCamera) {
      this.modelValue.updateDB(this.activeCamera.uuid);
    }
  }

  changeView(): void {
    this.cameraData.name = this.cameraData.view;
  }

  selectedObjectChanged(): void {
    for (const item of this.cameraList) {
      item.position = item.position.clone();
      item.rotation = item.rotation.clone();
    }
    const selection = this.selectionList.getSelectedObject();
    if (selection && (selection as any).isCamera) {
      const item = this.cameraList.find(
        (camera) => camera.uuid === selection.uuid
      );
      if (item) {
        this.activeCamera = item;
        if (this.troisRenderer.camera) {
          this.troisRenderer.camera.position.copy(item.position);
          this.troisRenderer.camera.rotation.copy(item.rotation);
          item.position = this.troisRenderer.camera.position;
          item.rotation = this.troisRenderer.camera.rotation;
        }
      }
    }
  }
  //#endregion add / select camera

  //#region undo / redo
  undoRedoCamera(
    cameraId: string,
    position: THREE.Vector3,
    rotation: THREE.Euler
  ): void {
    const cameraItem = this.cameraList.find(
      (camera) => camera.uuid === cameraId
    );
    if (cameraItem) {
      this.selectObject(cameraItem.camera, true).then(() => {
        if (this.troisRenderer.camera) {
          cameraItem.position.copy(position.clone());
          cameraItem.rotation.copy(rotation.clone());
        }
      });
    }
  }
  //#endregion undo / redo

  //#region render view
  renderActiveView(): void {
    this.renderCamera(this.troisRenderer.camera);
  }

  async renderAllViews(): Promise<void> {
    for (const camera of this.cameraList) {
      camera.camera.position.copy(camera.position);
      camera.camera.rotation.copy(camera.rotation);
      if (camera.camera instanceof THREE.PerspectiveCamera) {
        const perspectiveCamera = camera.camera as THREE.PerspectiveCamera;
        perspectiveCamera.aspect = (
          this.troisRenderer.camera as THREE.PerspectiveCamera
        ).aspect;
        perspectiveCamera.updateProjectionMatrix();
      }
      this.renderCamera(camera.camera);
    }
  }

  renderCamera(camera: THREE.Camera | undefined): void {
    if (this.troisRenderer.scene && camera) {
      this.troisRenderer.renderer.clear();
      this.troisRenderer.renderer.render(this.troisRenderer.scene, camera);
    }
    this.troisRenderer.canvas.toBlob((blob) => {
      if (blob) {
        const blobURL = URL.createObjectURL(blob);
        window.open(blobURL, '_blank');
      }
    });
  }
  //#endregion render view

  //#region import / export
  exportCameras(): CameraExportData[] {
    return this.cameraList.map((data) => {
      return {
        cameraId: data.info.cameraId,
        name: data.info.name,
        view: data.info.view,
        perspective: data.info.perspective,
        position: data.position.clone(),
        rotation: data.rotation.clone(),
      };
    });
  }

  async importCameras(data: CameraExportData[]): Promise<void> {
    if (this.troisRenderer.camera) {
      for (const item of data) {
        this.saveCamera(
          item.cameraId,
          item.name,
          item.perspective,
          item.view,
          item.position,
          item.rotation,
          false
        );
      }
    }
    this.onCanRenderChanged();
  }
  //#endregion import / export
}
</script>

<style lang="scss" scoped>
.active {
  color: var(--el-color-primary);
}
</style>
