import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { DragControls } from 'three/examples/jsm/controls/DragControls';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils';
import GeometryAdapterFactory, {
  GeometryAdapterInterface,
} from '@/plugin/ffd/adapter/GeometryAdapterFactory';
import { MeshSelector, SelectionList } from '@/types/ui/SelectionList';
import { SpecialObjectName } from '@/types/enum/three';
import { HistoryInfo, HistoryOperationType } from '@/types/ui/HistoryList';
import { DeformationToolType } from '@/components/Tools/DeformationTools.vue';

const adapterFactory = new GeometryAdapterFactory();

/* eslint-disable @typescript-eslint/no-explicit-any*/

interface SelectedVertices {
  index: number;
  position: THREE.Vector3;
  weight: number;
}

export default class SoftModification {
  scene!: THREE.Scene;
  camera!: THREE.Camera;
  renderer!: THREE.Renderer;
  controls!: OrbitControls;
  selectionList!: SelectionList;
  size = 1;
  dragControls!: DragControls;
  geometryAdapter: GeometryAdapterInterface | null = null;
  history: HistoryInfo;
  meshSelector: MeshSelector;
  meshId: string;

  softModifier!: THREE.Mesh;

  constructor(size: number, history: HistoryInfo, meshSelector: MeshSelector) {
    this.size = size;
    const geometry = new THREE.SphereGeometry(1, 32, 16);
    const material = new THREE.MeshBasicMaterial({
      color: 0xffff00,
      opacity: 0.2,
      transparent: true,
    });
    this.softModifier = new THREE.Mesh(geometry, material);
    this.softModifier.visible = false;
    this.softModifier.name = SpecialObjectName.SoftModifier;
    this.softModifier.scale.setScalar(size);
    this.history = history;
    this.meshSelector = meshSelector;
    this.meshId = '';
  }

  changeSize(size: number): void {
    this.size = size;
    this.softModifier.scale.setScalar(size);
  }

  initView(
    scene: THREE.Scene,
    camera: THREE.Camera,
    renderer: THREE.Renderer,
    controls: OrbitControls | undefined,
    selectionList: SelectionList
  ): void {
    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;
    if (controls) this.controls = controls;
    this.selectionList = selectionList;
    this.dragControls = new DragControls(
      [this.softModifier],
      this.camera,
      this.renderer.domElement
    );
    scene.add(this.softModifier);
    this.setupDrag();

    this.renderer.domElement.addEventListener(
      'mousedown',
      (e) => this.onPointerDown(e),
      false
    );
    this.renderer.domElement.addEventListener(
      'mousemove',
      (e) => this.onPointerMove(e),
      false
    );
    this.renderer.domElement.addEventListener(
      'mouseup',
      () => this.onPointerUp(),
      false
    );
  }

  cleanupView(): void {
    this.renderer.domElement.removeEventListener(
      'mousedown',
      (e) => this.onPointerDown(e),
      false
    );
    this.renderer.domElement.removeEventListener(
      'mousemove',
      (e) => this.onPointerMove(e),
      false
    );
    this.renderer.domElement.removeEventListener(
      'mouseup',
      () => this.onPointerUp(),
      false
    );
    this.dragControls.removeEventListener('drag', this.drag);
    this.dragControls.removeEventListener('dragstart', this.dragStart);
    this.dragControls.removeEventListener('dragend', this.dragEnd);
  }

  isVisible(): boolean {
    return this.softModifier !== null && this.softModifier.visible;
  }

  clickPoint: THREE.Vector3 | null = null;
  selectedVertices: SelectedVertices[] = [];
  onPointerDown(event: MouseEvent): void {
    if (!this.isVisible()) return;
    this.moveSelectionRegion(event);
    this.selectedVertices = [];

    if (this.selectionList) {
      const selection = this.selectionList.getSelectedObject();
      this.clickPoint = this.selectionList.checkIntersection(
        event,
        false,
        selection
      );
      if (this.clickPoint) {
        this.controls.enabled = false;
        if (this.geometryAdapter) {
          // Update the model vertices.
          for (let i = 0; i < this.geometryAdapter.numVertices; i++) {
            const vertex = this.geometryAdapter.getVertex(i).clone();
            const weight =
              Math.pow(this.size, 2) -
              this.clickPoint.distanceToSquared(vertex);
            if (weight > 0)
              this.selectedVertices.push({
                index: i,
                position: vertex,
                weight: Math.sqrt(weight) / this.size,
              });
          }
        }
      }
    }
  }

  moveSelectionRegion(event: MouseEvent): void {
    if (this.selectionList && !this.clickPoint) {
      const selection = this.selectionList.getSelectedObject();
      const intersectionPoint = this.selectionList.checkIntersection(
        event,
        false,
        selection
      );
      if (intersectionPoint) {
        this.softModifier.position.copy(intersectionPoint);
      }
    }
  }

  onPointerMove(event: MouseEvent): void {
    if (!this.isVisible()) return;
    this.moveSelectionRegion(event);
    //this.deformSoftModification();
  }

  onPointerUp(): void {
    if (!this.isVisible()) return;
    this.clickPoint = null;
    this.controls.enabled = true;
    if (this.history.lastUpdateTime < this.dragTime)
      this.history.lastUpdateTime = Date.now();
  }

  clearMesh(): void {
    this.softModifier.visible = false;
    this.meshId = '';
  }

  initMesh(model: THREE.Object3D, merge = true): void {
    this.meshId = (model as any).apiData?.uuid;
    if (merge) {
      const orig_geom = (model as THREE.Mesh).geometry;
      const mergedGeometry = BufferGeometryUtils.mergeVertices(orig_geom);
      mergedGeometry.computeVertexNormals();
      (model as THREE.Mesh).geometry = mergedGeometry;
    }
    this.geometryAdapter = adapterFactory.getAdapter(
      (model as THREE.Mesh).geometry
    );
    this.softModifier.visible = true;
    model.add(this.softModifier);
  }

  setupDrag(): void {
    if (this.dragControls) this.dragControls.dispose();
    this.dragControls = new DragControls(
      [this.softModifier],
      this.camera,
      this.renderer.domElement
    );
    (this.dragControls as any).modifier = this;
    this.dragControls.removeEventListener('drag', this.drag);
    this.dragControls.addEventListener('drag', this.drag);
    this.dragControls.removeEventListener('dragstart', this.dragStart);
    this.dragControls.addEventListener('dragstart', this.dragStart);
    this.dragControls.removeEventListener('dragend', this.dragEnd);
    this.dragControls.addEventListener('dragend', this.dragEnd);
  }

  dragTime = -1;
  isDrag = false;
  drag(): void {
    const dragControls = this as any as DragControls;
    const modifier = (dragControls as any).modifier as SoftModification;
    if (!modifier.isVisible()) return;
    modifier.isDrag = true;
    modifier.deformSoftModification();
    modifier.dragTime = Date.now();
  }

  dragStart(): void {
    const dragControls = this as any as DragControls;
    const modifier = (dragControls as any).modifier as SoftModification;
    if (!modifier.isVisible()) return;
    modifier.isDrag = false;
  }

  dragEnd(): void {
    const dragControls = this as any as DragControls;
    const modifier = (dragControls as any).modifier as SoftModification;
    if (!modifier.isVisible()) return;
    if (modifier.isDrag && modifier.clickPoint) {
      const delta = modifier.softModifier.position
        .clone()
        .sub(modifier.clickPoint.clone());
      const selectedVertices = modifier.selectedVertices;
      const historyMeshId = modifier.meshId;
      modifier.history.historyList.add(
        modifier.meshId,
        HistoryOperationType.softModification,
        async () => {
          if (modifier.meshId !== historyMeshId) {
            modifier.meshSelector
              .selectMesh(historyMeshId, DeformationToolType.softModification)
              .then(() => {
                modifier.undoRedoSoftModification(selectedVertices, null);
              });
          } else {
            modifier.undoRedoSoftModification(selectedVertices, null);
          }
        },
        async () => {
          if (modifier.meshId !== historyMeshId) {
            modifier.meshSelector
              .selectMesh(historyMeshId, DeformationToolType.softModification)
              .then(() => {
                modifier.undoRedoSoftModification(selectedVertices, delta);
              });
          } else {
            modifier.undoRedoSoftModification(selectedVertices, delta);
          }
        }
      );
    }
  }

  deformSoftModification(): void {
    if (this.clickPoint) {
      const delta = this.softModifier.position
        .clone()
        .sub(this.clickPoint.clone());
      this.undoRedoSoftModification(this.selectedVertices, delta);
    }
  }

  undoRedoSoftModification(
    selectedVertices: SelectedVertices[],
    delta: THREE.Vector3 | null
  ): void {
    if (this.geometryAdapter) {
      for (const vertex of selectedVertices) {
        const newPosition = delta
          ? vertex.position
              .clone()
              .add(delta.clone().multiplyScalar(vertex.weight))
          : vertex.position.clone();
        this.geometryAdapter.setVertex(
          vertex.index,
          newPosition.x,
          newPosition.y,
          newPosition.z
        );
      }
      this.geometryAdapter.updateVertices();
    }
  }
}
