/**
 * Created on: 2022-09-06T08:19:59.2460933+00:00
 */
export enum Axis {
  X = 'X',
  Y = 'Y',
  Z = 'Z',
  // Start Userdefined content - don't change anything above
  // End Userdefined content - don't change anything below
}
