<script setup lang="ts">
import {MeshTreeData} from "@/types/ui/MeshTreeData";
import {Modal} from "bootstrap";
import {nextTick, onMounted, ref} from "vue";
import DesignSelection from "@/components/DesignSelection.vue";
import PartSelection from "@/components/PartSelection.vue";
import RoundedButton from "@/components/RoundedButton.vue";
import { SupportedValues, SupportedValuesTextures } from "@/types/api/Utility/SupportedValues";
import Auth from "@/services/api/auth";
/*
interface SupportedValues {
  fileId: number|null;
  fileTextureId: number;
  thumbnail: UtilityFile|null|undefined; 
}*/
interface Props {
  selectedEngraving: number | null;
  selectedTexture: number | null;
  meshEngravings: SupportedValues[];
  meshTextures: SupportedValuesTextures[];
  meshTreeData: MeshTreeData;
  productTypeString: string;
  productId: number;
}

const authentication = Auth.getInstance();

const props = defineProps<Props>();
let partSelectionModalDialog: any = null;
let designSelectionModalDialog: any = null;

const designSelectionSlider = ref<HTMLDivElement | null>(null);
const thumbnailWidths = ref<number[]>([]);
const designModalKey = ref<number>(1);

const setThumbnailWidth = (index) => {
  nextTick(() => {
    if (designSelectionSlider.value) {
      const thumbnails = designSelectionSlider.value.querySelectorAll('img');
      if (thumbnails && thumbnails[index]) {
        thumbnailWidths.value[index] = thumbnails[index].clientWidth;
      }
    }
  });
};
const scrollToImage = (index) => {
  if (designSelectionSlider.value) {
    const scrollPosition = thumbnailWidths.value.slice(0, index).reduce((sum, width) => sum + width, 0);
    designSelectionSlider.value.scrollLeft =scrollPosition + 70;
  }
};
const layersImgSrc = () => {
  return authentication.domains.some(d => d.urlSuffix === 'wjtortho') ? require('@/assets/icons/layers_w.svg') : require('@/assets/icons/layers.svg');
}

onMounted(() => {
  // Initialize modal dialog
  const modalDialogOptions = {};
  partSelectionModalDialog = new Modal('#part-selection-modal-dialog', modalDialogOptions);
  designSelectionModalDialog = new Modal('#design-selection-modal-dialog', modalDialogOptions);  
  layersImgSrc();

  // Enable vertical scrolling with mouse wheel
  const item = document.getElementById("design-selection-slider");
  item?.addEventListener("wheel", (e: WheelEvent) => {
    if (e.deltaY > 0) {
      item.scrollLeft += 50;
    } else {
      item.scrollLeft -= 50;
    }
  });
});

const isTextWhite = () => {
    return getComputedStyle(document.documentElement).getPropertyValue('--primary-text').trim() == '#fff';
}

</script>

<template>
  <div>
    <div class="row ml-5 mr-5">
      <div class="d-flex justify-content-center gap-4">
        <!-- Parts button -->
        <rounded-button id="parts-button"
                        :title="$t('components.embossing-footer.partsButton')"
                        @button-clicked="partSelectionModalDialog.show()">
          <div v-if="isTextWhite()">
            <img src="@/assets/icons/layers_w.svg" class="img" alt="..."/>
          </div>
          <div v-else>
            <img src="@/assets/icons/layers.svg" class="img" alt="..."/>
          </div>
        </rounded-button>

        <!-- Design modal dialog button -->
        <rounded-button
                        class="design-button"
                        :title="$t('components.embossing-footer.designButton')"
                        @button-clicked="()=>{
                          designModalKey = designModalKey + 1
                          designSelectionModalDialog.show()
                        }">
          <font-awesome-icon icon="ellipsis" />
        </rounded-button>

        <!-- Design selection -->
        <div ref="designSelectionSlider" class="overflow-x-auto d-flex align-content-start gap-4 pb-2" id="design-selection-slider">
         
          <rounded-button v-for="(item,index) in props.meshEngravings" :key="item.thumbnail?.fileId!"
                          class="design-button" :is-secondary-button="item.fileId != props.selectedEngraving"
                          :title="$t(`views.designs.${item.thumbnail?.name}`) || item.thumbnail?.name || '-'"
                          @button-clicked="$emit('engraving-selected', (item.fileTextureId));scrollToImage(index);">
            <div>
              <el-tooltip v-if="item.thumbnail && item.thumbnail.name && item.thumbnail.name.length > 10" placement="top">
                <template #content>{{ $t(`views.designs.${item.thumbnail.name}`) || item.thumbnail.name || '-' }}</template>
                <img :src="item.thumbnail.content" class="img rounded-circle" :alt="item.thumbnail.name" @load="setThumbnailWidth(index)">
              </el-tooltip>
              <div v-else>
                  <img :src="item.thumbnail?.content" class="img rounded-circle" :alt="item.thumbnail?.name" @load="setThumbnailWidth(index)">
              </div>
            </div>
          </rounded-button>
         
          <rounded-button v-for="(item,index) in props.meshTextures" :key="item.fileId!"
                          class="design-button" :is-secondary-button="item.fileId != props.selectedTexture"
                          :title="$t(`views.designs.${item.fileName}`) || item.fileName || '-'"
                          @button-clicked="$emit('texture-selected', item.fileTextureId);scrollToImage(index);">
                          <div>
              <el-tooltip v-if="item.thumbnail && item.thumbnail.name && item.thumbnail.name.length > 18" placement="top">
                <template #content>{{ $t(`views.designs.${item.thumbnail.name}`) }}</template>
                <img :src="item.thumbnail.content" class="img rounded-circle" :alt="item.thumbnail.name" @load="setThumbnailWidth(index)">
              </el-tooltip>
              <div v-else>
                  <img :src="item.thumbnail?.content" class="img rounded-circle" :alt="item.thumbnail?.name" @load="setThumbnailWidth(index)">
              </div>
            </div>
           
          </rounded-button>
        </div>
      </div>
    </div>

    <!-- Part selection modal dialog -->
    <div class="modal fade" id="part-selection-modal-dialog" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header border-bottom-0 bg-transparent" id="part-selection-modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-0" id="part-selection-modal-body">
            <part-selection :mesh-tree-data="meshTreeData"
                            @part-selected="(part) => {$emit('part-selected', part); partSelectionModalDialog.hide();}"
                            :productTypeString="productTypeString"/>
          </div>
        </div>
      </div>
    </div>
    <!-- Design selection modal dialog -->
    <div class="modal fade" id="design-selection-modal-dialog" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header border-bottom-0 bg-transparent" id="design-selection-modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-0" id="design-selection-modal-body">
            <design-selection :engraving-list="props.meshEngravings" :texture-list="props.meshTextures"
                              :productId="props.productId"
                              :meshTreeData="meshTreeData"
                              :key="designModalKey"
                              :selected-engraving="props.selectedEngraving" :selected-texture="props.selectedTexture"
                              @refetch-embossing="() => $emit('refetch-embossing')"
                              @refetch-texture="() => $emit('refetch-texture')"
                              @engraving-selected="(file) => {$emit('engraving-selected', file); designSelectionModalDialog.hide();}"
                              @texture-selected="(file) => {$emit('texture-selected', file); designSelectionModalDialog.hide();}"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#parts-button > .btn {
  border-radius: 0.5rem !important;
  height: 3.75rem !important;
  width: 3.75rem !important;
  padding: 0 !important;
}
.design-button > .btn {
  border-radius: 50%;
  height: 3.85rem !important;
  width: 3.85rem !important;
  padding: 0 !important;
}
.design-button > .text-uppercase {
  overflow: hidden;
  max-width: 3.85rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}
// TODO: Remove custom backdrop style and resolve z-index issue!
.modal-backdrop {
  z-index: -1 !important;
}
</style>
<style scoped lang="scss">
#design-selection-slider {
  max-width: 37.75rem;
}
#part-selection-modal-header,
#design-selection-modal-header {
  background-color: transparent;
  z-index: 1;
}
#part-selection-modal-body,
#design-selection-modal-body {
  margin-top: -3.6rem;
  z-index: 0;
}
.img.rounded-circle {
  height: 3.6rem !important;
  width: 3.6rem !important;
}
</style>
