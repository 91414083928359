export enum UploadProgress {
  Select = 'select',
  Orientation = 'orientation',
  Pivot = 'pivot',
}

export enum FileCategory {
  THREE = 'three',
  IMAGE = 'image',
  VIDEO = 'video',
}

export enum FileType3D {
  NONE = 'none',
  FBX = 'fbx',
  STL = 'stl',
  OBJ = 'obj',
  MTL = 'mtl'
}

export enum FileTypeImage {
  NONE = 'none',
  PNG = 'png',
  JPG = 'jpg',
}

export enum FileTypeVideo {
  NONE = 'none',
  MP4 = 'mp4',
  WEBM = 'webm',
  MOV = 'mov',
  MKV = 'mkv',
  AVI = 'avi',
}

export const getFileType = (
  filename: string,
  category = FileCategory.THREE
): FileType3D => {
  const fileNameParts = filename.split('.');
  if (fileNameParts.length > 1) {
    if (
      category === FileCategory.THREE &&
      Object.values(FileType3D).includes(fileNameParts[1] as FileType3D)
    ) {
      const fileType = fileNameParts[1];
      return FileType3D[fileType.toUpperCase()];
    }
  }
  return FileType3D.NONE;
};

export const getImageFileType = (
  filename: string,
  category = FileCategory.IMAGE
): FileTypeImage => {
  const fileNameParts = filename.split('.');
  if (fileNameParts.length > 1) {
    if (
      category === FileCategory.IMAGE &&
      Object.values(FileTypeImage).includes(fileNameParts[1] as FileTypeImage)
    ) {
      const fileType = fileNameParts[1];
      return FileTypeImage[fileType.toUpperCase()];
    }
  }
  return FileTypeImage.NONE;
};

export const getVideoFileType = (
  filename: string,
  category = FileCategory.VIDEO
): FileTypeVideo => {
  const fileNameParts = filename.split('.');
  if (fileNameParts.length > 1) {
    if (
      category === FileCategory.VIDEO &&
      Object.values(FileTypeVideo).includes(fileNameParts[1] as FileTypeVideo)
    ) {
      const fileType = fileNameParts[1];
      return FileTypeVideo[fileType.toUpperCase()];
    }
  }
  return FileTypeVideo.NONE;
};

export const isValidFileType = (
  filename: string,
  category = FileCategory.THREE
): boolean => {
  const fileType = getFileType(filename, category);
  return fileType !== FileType3D.NONE;
};

export const isValidImageFileType = (
  filename: string,
  category = FileCategory.IMAGE
): boolean => {
  const fileType = getImageFileType(filename, category);
  return fileType !== FileTypeImage.NONE;
};

export const isValidVideoFileType = (
  filename: string,
  category = FileCategory.VIDEO
): boolean => {
  const fileType = getVideoFileType(filename, category);
  return fileType !== FileTypeVideo.NONE;
};

export enum UploadCategory {
  Product = 'product',
  Scan = 'scan',
  Curve = 'curve',
  ReferenceDummy = 'referenceDummy',
}

export enum ProductType {
  fence = 'Fence',
  railling = 'Railling',
  photovoltaic = 'Photovoltaic systems',
  furniture = 'Furniture',
  miscellaneous = 'Miscellaneous',
  orthoses = 'Orthoses',
  covers = 'Covers',
  balconies = 'Balconies',
  carports = 'Carports',
  shower_baths = 'Shower and baths',
  kitchens = 'Kitchens',
  linear_lighting = 'Linear lighting',
  spotlighting = 'Spot lighting',
  orthoses_models = 'Orthoses models',
}

export enum DesignType {
  design = 'Design',
  texture = 'Texture',
}
