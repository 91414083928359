<script setup lang="ts">
import { Product } from '@/types/api/Model/Product';
import { removeWordFromString } from '@/utils/formBuilder';
import { computed } from 'vue';

interface Props {
  product: Product;
}

const props = defineProps<Props>();

// Truncate 'phase1' word in title string
const productTitle = computed(() => {
  return removeWordFromString(props?.product.name, 'phase1', 20);
});

// Check if the product is an S-Cover
const isSCover = computed(() => {
  return props.product.name.toLowerCase().includes('s-cover');
});
</script>

<template>
  <div id="product-details" class="pl-5 pr-5 pt-3 pb-3 rounded-3 text-white">
    <!-- Title -->
    <h1>{{ $t('components.product-details.title') }}</h1>

    <!-- Text and image-slot -->
    <div class="row ml-0 mr-0">
      <div class="col col-lg-8">
        <h2 class="row">{{ productTitle }}</h2>
        <p class="row form-text text-white" id="product-details-info-text">
          {{ props.product?.description }}
        </p>
      </div>
      <div class="col col-lg-4 d-flex justify-content-end">
        <slot></slot>
      </div>
    </div>

    <!-- Button -->
    <div class="row">
      <div class="row ml-3 mb-5 mt-2">
        <!-- Show "Order now" for S-Cover products -->
        <button
          v-if="isSCover"
          type="button"
          class="btn btn-primary"
          @click="$emit('buttonClicked')"
        >
        {{ $t('components.order-summary.orderButton') }}
        </button>
        

        <!-- Show "Configure now" for all other products -->
        <button
          v-else
          type="button"
          class="btn btn-primary"
          @click="$emit('buttonClicked')"
        >
          {{ $t('components.product-details.startConfigurator') }}
        </button>
      </div>
    </div>

    <!-- Product properties -->
    <!-- <div class="row ml-0 mr-0">
      <h2 class="pl-0">{{ $t('components.product-details.productProperties') }}</h2>
      <ul class="form-text p-0 text-white" id="product-details-product-properties">
        <li>1. Lorem ipsum dolor sit amet, consetetur sadipscing</li>
        <li>2. elitr, sed diam nonumy eirmod tempor invidunt</li>
        <li>3. ut labore et dolore magna aliquyam</li>
        <li>4. erat, sed diam voluptua.</li>
        <li>5. At vero eos et accusam et justo duo dolores et</li>
        <li>6. ea rebum. Stet clita</li>
      </ul>
    </div>-->
  </div>
</template>

<style scoped lang="scss">
#product-details {
  background: transparent
    radial-gradient(
      closest-side at 50% 50%,
      rgba(75, 75, 75, 0.85) 0%,
      rgba(0, 0, 0, 0.85) 100%
    )
    0% 0% no-repeat padding-box;
}
#product-details-info-text,
#product-details-product-properties {
  font-size: 1rem;
}
</style>
