import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f3fdaede"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-4 flex-grow-1 overflow-auto" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "menu"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_header = _resolveComponent("custom-header")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_custom_footer = _resolveComponent("custom-footer")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_custom_header, {
      class: "p-4",
      isEditor: false,
      workflowModel: null,
      authentication: _ctx.authentication
    }, null, 8, ["authentication"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('views.default.overview.header')), 1),
      (_ctx.authentication.user)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.authentication.user?.firstname + ' ' + _ctx.authentication.user?.lastname), 1))
        : _createCommentVNode("", true),
      (_ctx.authentication.user)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                (_ctx.authentication.roles?.includes(_ctx.Role.Supplier))
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 0,
                      span: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "primary",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(`/template-overview`)))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createVNode(_component_font_awesome_icon, { icon: "puzzle-piece" })
                            ]),
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t('views.default.overview.template')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.authentication.roles?.includes(_ctx.Role.Supplier))
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 1,
                      span: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "primary",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push(`/order-overview`)))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createVNode(_component_font_awesome_icon, { icon: "cart-shopping" })
                            ]),
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t('views.default.overview.order')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push(`/customize-overview`)))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createVNode(_component_font_awesome_icon, { icon: "screwdriver-wrench" })
                        ]),
                        _createElementVNode("div", null, _toDisplayString(_ctx.$t('views.default.overview.customize')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.authentication.handleLogout()))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createVNode(_component_font_awesome_icon, { icon: "sign-out-alt" })
                        ]),
                        _createElementVNode("div", null, _toDisplayString(_ctx.$t('views.default.overview.logout')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])), [
            [_directive_loading, _ctx.menuLoading]
          ])
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_custom_footer, {
      isEditor: false,
      class: "p-4"
    })
  ], 64))
}