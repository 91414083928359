<template>
  <el-menu
    background-color="#{$color-sidebar}"
    text-color="#{$font-color.sidebar}"
    :default-active="activeMenuItem"
    style="--el-menu-hover-text-color: var(--color-sidebar)"
  >
    <!--<el-sub-menu index="1">
      <template #title>
        <span>
          {{ $t('components.menu-sidebar.category.administration') }}
        </span>
      </template>
      <el-menu-item index="1-1">
        {{ $t('components.menu-sidebar.administration.user') }}
      </el-menu-item>
      <el-menu-item index="1-2">
        {{ $t('components.menu-sidebar.administration.domain') }}
      </el-menu-item>
    </el-sub-menu>-->
    <el-sub-menu index="2">
      <template #title>
        <span>
          {{ $t('components.menu-sidebar.category.configuration') }}
        </span>
      </template>
      <!--<el-menu-item index="2-1">
        {{ $t('components.menu-sidebar.configuration.meshes') }}
      </el-menu-item>-->
      <el-menu-item index="2-2" v-on:click="$router.push(`/template-overview`)">
        {{ $t('components.menu-sidebar.configuration.templates') }}
      </el-menu-item>
      <!--<el-menu-item index="2-3">
        {{ $t('components.menu-sidebar.configuration.packages') }}
      </el-menu-item>
      <el-menu-item index="2-4">
        {{ $t('components.menu-sidebar.configuration.pricing') }}
      </el-menu-item>-->
    </el-sub-menu>
    <el-sub-menu index="3">
      <template #title>
        <span>
          {{ $t('components.menu-sidebar.category.orthopedics') }}
        </span>
      </template>
      <!--<el-menu-item index="3-1">
        {{ $t('components.menu-sidebar.orthopedics.customers') }}
      </el-menu-item>
      <el-menu-item index="3-2" v-on:click="$router.push(`/order-overview`)">
        {{ $t('components.menu-sidebar.orthopedics.orders') }}
      </el-menu-item>-->
      <el-menu-item
        index="3-3"
        v-on:click="$router.push(`/work-in-progress-overview`)"
      >
        {{ $t('components.menu-sidebar.orthopedics.progress') }}
      </el-menu-item>
      <!--<el-menu-item index="3-4">
        {{ $t('components.menu-sidebar.orthopedics.print') }}
      </el-menu-item>-->
    </el-sub-menu>
    <el-sub-menu index="4">
      <template #title>
        <span>
          {{ $t('components.menu-sidebar.category.customize') }}
        </span>
      </template>
      <el-menu-item index="4-1" v-on:click="$router.push(`/customize`)">
        {{ $t('components.menu-sidebar.customize.configuration') }}
      </el-menu-item>
      <!--<el-menu-item index="4-2">
        {{ $t('components.menu-sidebar.customize.orders') }}
      </el-menu-item>
      <el-menu-item index="4-3">
        {{ $t('components.menu-sidebar.customize.shopping_cart') }}
      </el-menu-item>
      <el-menu-item index="4-4">
        {{ $t('components.menu-sidebar.customize.packages') }}
      </el-menu-item>-->
    </el-sub-menu>
  </el-menu>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

export default class SideMenu extends Vue {
  @Prop({ default: '' }) activeMenuItem!: string;
}
</script>

<style lang="scss" scoped>
.el-menu {
  --el-menu-hover-text-color: var(--color-sidebar);
}

</style>
