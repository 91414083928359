import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_page_header = _resolveComponent("el-page-header")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_MeshLoader = _resolveComponent("MeshLoader")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_Workflow = _resolveComponent("Workflow")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, { class: "screenHeight" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_page_header, {
            content: _ctx.$t(`enum.upload-progress.${_ctx.activeUploadProgress}`),
            onBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
          }, null, 8, ["content"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_MeshLoader, {
            uploadProgress: _ctx.activeUploadProgress,
            "onUpdate:uploadProgress": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeUploadProgress) = $event)),
            templateId: _ctx.templateId,
            ref: "meshLoader"
          }, null, 8, ["uploadProgress", "templateId"])
        ]),
        _: 1
      }),
      _createVNode(_component_Workflow, {
        workflowModel: _ctx.workflowModel,
        fullscreenLoading: _ctx.fullscreenLoading
      }, null, 8, ["workflowModel", "fullscreenLoading"])
    ]),
    _: 1
  }))
}