<template>
    <div ref="pdfContent" class="pdf-content hidden">
        <h1>{{ title }}</h1>
        <p>{{ description }}</p>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class OfferTemplate extends Vue {
    // Props untuk menerima data dari komponen parent
    @Prop({ type: String, required: true }) private title!: string;
    @Prop({ type: String, required: true }) private description!: string;

    // Fungsi untuk mengakses elemen yang akan dikonversi ke PDF
    public getPdfElement(): HTMLElement {
        return this.$refs.pdfContent as HTMLElement;
    }
}
</script>

<style scoped>
/* Gaya yang akan diterapkan pada konten PDF */
.pdf-content {
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
}

.hidden {
    display: none;
}
</style>