import IndexedBufferGeometryAdapter from './IndexedBufferGeometryAdapter';
import UnIndexedBufferGeometryAdapter from './UnIndexedBufferGeometryAdapter';
import GeometryAdapter, { FaceIndices, FaceVertices } from './GeometryAdapter';
import * as THREE from 'three';

export interface GeometryAdapterInterface {
  vertices: THREE.Vector3[];
  numVertices: number;
  numFaces: number;
  similarVertex: number[][];
  setVertex(index: number, x: number, y: number, z: number): void;
  setVertexX(index: number, x: number): void;
  setVertexY(index: number, y: number): void;
  setVertexZ(index: number, z: number): void;
  getVertex(index: number): THREE.Vector3;
  getVertexX(index: number): number;
  getVertexY(index: number): number;
  getVertexZ(index: number): number;
  getNormal(index: number): THREE.Vector3;
  getFace(index: number): FaceIndices;
  getFaceVertices(index: number): FaceVertices;
  updateVertices(): void;
  updateFaces(): void;
  getBoundingBox(): THREE.Box3 | null;
}

export const calculateSimilarVertexForAdapter = (
  adapter: GeometryAdapterInterface
): void => {
  adapter.similarVertex = [...Array(adapter.numVertices)].map(() => []);
  for (let i = 0; i < adapter.numVertices; i++) {
    const vertex = adapter.getVertex(i);
    for (let j = i + 1; j < adapter.numVertices; j++) {
      const compareVertex = adapter.getVertex(j);
      if (
        compareVertex.x === vertex.x &&
        compareVertex.y === vertex.y &&
        compareVertex.z === vertex.z
      ) {
        adapter.similarVertex[i].push(j);
        adapter.similarVertex[j].push(i);
      }
    }
  }
};

export default class GeometryAdapterFactory {
  getAdapter(
    geometry: THREE.BufferGeometry,
    calculateSimilarVertex = false
  ): GeometryAdapterInterface {
    const hasPositionAttr = geometry.attributes && geometry.attributes.position;

    if (hasPositionAttr) {
      if (geometry.index) {
        return new IndexedBufferGeometryAdapter(
          geometry,
          calculateSimilarVertex
        );
      } else {
        return new UnIndexedBufferGeometryAdapter(
          geometry,
          calculateSimilarVertex
        );
      }
    } else {
      return new GeometryAdapter(geometry, calculateSimilarVertex);
    }
  }
}
