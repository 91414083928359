import { EditorModeState } from '@/types/enum/editor';
import * as THREEEnum from '@/types/enum/three';

export class EditorMode {
  // editor mode
  editorMode: EditorModeState = EditorModeState.default;

  set state(value: EditorModeState) {
    this.editorMode = value;
  }

  get state(): EditorModeState {
    return this.editorMode;
  }

  get isDefaultMode(): boolean {
    return this.editorMode === EditorModeState.default;
  }

  get isPivotMode(): boolean {
    return this.editorMode === EditorModeState.pivot;
  }

  usePivotMode(orientationDirection: THREEEnum.OrientationAxis): boolean {
    if (this.editorMode === EditorModeState.pivot) return true;
    if (this.editorMode === EditorModeState.orientation)
      return orientationDirection === THREEEnum.OrientationAxis.all;
    return false;
  }

  get isOrientationMode(): boolean {
    return this.editorMode === EditorModeState.orientation;
  }

  get isBoneMode(): boolean {
    return this.editorMode === EditorModeState.bone;
  }

  togglePivotMode(): void {
    this.editorMode = this.isPivotMode
      ? EditorModeState.default
      : EditorModeState.pivot;
  }

  getModeOpacity(): number {
    if (this.isPivotMode || this.isOrientationMode || this.isBoneMode)
      return 50;
    return 100;
  }
}
