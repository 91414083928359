import {
  FileType3D,
  UploadCategory,
  getFileType,
  getImageFileType,
  FileTypeImage,
} from '@/types/enum/upload';
import { v4 as uuidv4 } from 'uuid';
import colors from '@/assets/data/color.json';
import { apiExecuteGet } from '@/services/api/api';
import { MeshImportData } from '@/types/ui/MeshTreeData';

const colorConfigUrl = `assets/config/color.json`;
const embossingColorConfigUrl = `assets/config/embossingColor.json`;
apiExecuteGet(colorConfigUrl).then((result) => {
  Object.keys(ProductColors).map((key) => delete ProductColors[key]);
  for (const key of Object.keys(result.colors)) {
    ProductColors[key] = result.colors[key];
  }
});

apiExecuteGet(embossingColorConfigUrl).then((result) => {
  EmbossingColors.splice(0);
  for (const color of result.colors) {
    EmbossingColors.push(color);
  }
});

/* eslint-disable @typescript-eslint/no-explicit-any*/

export interface ProductMeshData extends MeshImportData {
  filetype: FileType3D;
  filename: string;
  url: string;
  thumbnail: string;
  thumbnailUrl: string;
  parentId: string | null;
}

export interface ProductData {
  id: string;
  category: UploadCategory;
  name: string;
  thumbnail: string;
  thumbnailUrl: string;
  mesh: ProductMeshData[];
}

export const ProductColors: { [name: string]: string[] } = colors.colors;
export const EmbossingColors: string[] = [];

const localProductDB: ProductData[] = [];

export const getData = async (
  productId: string
): Promise<ProductMeshData[]> => {
  const product = localProductDB.find((item) => item.id === productId);
  if (product) return product.mesh;
  return [];
};

export const getList = (): ProductData[] => {
  return localProductDB;
};

export const getFirst = (): ProductData => {
  return localProductDB[0];
};

export const addData = async (
  productCategory: UploadCategory,
  productName: string,
  productThumbnail: string,
  meshes: string[],
  path: string,
  thumbnails: string[],
  thumbnailsPath: string,
  parentId: string | null = null
): Promise<string> => {
  if (!path.endsWith('/')) path = `${path}/`;
  let product = localProductDB.find(
    (item) => item.category === productCategory && item.name === productName
  );
  if (!product) {
    product = {
      id: uuidv4(),
      category: productCategory,
      name: productName,
      thumbnail: productThumbnail,
      thumbnailUrl: `${thumbnailsPath}/${productThumbnail}`,
      mesh: [],
    };
    localProductDB.push(product);
  }
  for (let i = 0; i < meshes.length; i++) {
    const filename = meshes[i];
    const thumbnail = thumbnails[i];
    const mesh = product.mesh.find((item) => item.filename === filename);
    if (mesh) {
      mesh.filetype = getFileType(filename) as FileType3D;
      mesh.url = `${path}${filename}`;
      mesh.parentId = parentId;
    } else {
      product.mesh.push({
        filetype: getFileType(filename) as FileType3D,
        filename: filename,
        url: `${path}${filename}`,
        base64: null,
        thumbnail: thumbnail,
        thumbnailUrl: `${thumbnailsPath}${thumbnail}`,
        thumbnailFiletype: getImageFileType(thumbnail) as FileTypeImage,
        parentId: parentId,
      });
    }
  }
  return product.id;
};

export const updateData = async (
  id: string,
  productCategory: UploadCategory,
  productName: string,
  productThumbnail: string,
  filename: string,
  path: string,
  thumbnails: string[],
  thumbnailsPath: string,
  parentId: string | null = null
): Promise<string> => {
  if (!path.endsWith('/')) path = `${path}/`;
  const product = localProductDB.find((item) => item.id === id);
  if (!product) {
    return addData(
      productCategory,
      productName,
      productThumbnail,
      [filename],
      path,
      thumbnails,
      thumbnailsPath,
      parentId
    );
  } else {
    product.category = productCategory;
    product.name = productName;
    product.thumbnail = productThumbnail;
    product.thumbnailUrl = `${thumbnailsPath}/${productThumbnail}`;

    const mesh = product.mesh.find((item) => item.filename === filename);
    if (mesh) {
      mesh.filetype = getFileType(filename) as FileType3D;
      mesh.url = `${path}${filename}`;
      mesh.parentId = parentId;
    } else {
      await addData(
        productCategory,
        productName,
        productThumbnail,
        [filename],
        path,
        thumbnails,
        thumbnailsPath,
        parentId
      );
    }
  }
  return id;
};

export const deleteData = async (id: string): Promise<void> => {
  const dbIndex = localProductDB.findIndex((item) => item.id === id);
  if (dbIndex > -1) {
    localProductDB.splice(dbIndex, 1);
  }
};
