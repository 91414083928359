import * as THREE from 'three';
import { MeshTreeData } from '@/types/ui/MeshTreeData';

export const getActiveBoneIndex = (data: MeshTreeData): number => {
  const selection = data.getSelection();
  if (selection.length > 0) {
    return selection[0].activeBoneIndex;
  }
  return -1;
};

export const setActiveBoneIndex = (data: MeshTreeData, index: number): void => {
  const selection = data.getSelection();
  if (selection.length > 0) {
    selection[0].activeBoneIndex = index;
  }
};

export const getContainerBonePosition = (
  boneIndex: number,
  boneContainer: THREE.Group
): THREE.Vector3 | null => {
  if (boneIndex > -1) {
    const bone = boneContainer.children[boneIndex];
    return bone.position;
  }
  return null;
};

export const getActiveBonePosition = (
  data: MeshTreeData,
  boneContainer: THREE.Group
): THREE.Vector3 | null => {
  const selection = data.getSelection();
  if (selection.length > 0) {
    const boneIndex = selection[0].activeBoneIndex;
    if (boneIndex > -1) {
      const bone = boneContainer.children[boneIndex];
      return bone.position;
    }
  }
  return null;
};

export const getActiveContainerBone = (
  data: MeshTreeData,
  boneContainer: THREE.Group
): THREE.Object3D | null => {
  const selection = data.getSelection();
  if (selection.length > 0) {
    const boneIndex = selection[0].activeBoneIndex;
    if (boneIndex > -1) {
      return boneContainer.children[boneIndex];
    }
  }
  return null;
};

export const getActiveBone = (data: MeshTreeData): THREE.Bone | null => {
  const selection = data.getSelection();
  if (selection.length > 0) {
    const boneIndex = selection[0].activeBoneIndex;
    if (boneIndex > -1) {
      return selection[0].bones[boneIndex];
    }
  }
  return null;
};

export const pivotBoneActive = (data: MeshTreeData): boolean => {
  const selection = data.getSelection();
  if (selection.length > 0) {
    const boneIndex = selection[0].activeBoneIndex;
    return boneIndex === 0;
  }
  return false;
};

export const getActiveChildBones = (data: MeshTreeData): THREE.Bone[] => {
  const selection = data.getSelection();
  if (selection.length > 0) {
    const boneIndex = selection[0].activeBoneIndex;
    if (boneIndex > -1) {
      return selection[0].bones.slice(boneIndex);
    }
  }
  return [];
};
