import * as THREE from 'three';
import { transform } from '@/utils/three/transform';
import { initCurveMaterial } from '@/utils/three/init';

/* eslint-disable @typescript-eslint/no-explicit-any*/
export const initSphereGeometry = (): THREE.BufferGeometry => {
  // load sphere
  return new THREE.SphereGeometry(0.5, 32, 16);
};

export const initSphere = (
  name: string,
  color = '#ffffff',
  position?: THREE.Vector3,
  rotation?: THREE.Euler,
  scale?: THREE.Vector3
): THREE.Object3D => {
  // load sphere
  const geometry = new THREE.SphereGeometry(0.5, 32, 16);
  const material = new THREE.MeshLambertMaterial({ color: color });
  const mesh = new THREE.Mesh(geometry, material);
  mesh.name = name;
  return transform(mesh, position, rotation, scale);
};

export const initBoxGeometry = (): THREE.BufferGeometry => {
  // load box
  return new THREE.BoxGeometry(1, 1, 1);
};

export const initBox = (
  name: string,
  color = '#ffffff',
  position?: THREE.Vector3,
  rotation?: THREE.Euler,
  scale?: THREE.Vector3
): THREE.Object3D => {
  // load box
  const geometry = new THREE.BoxGeometry(1, 1, 1);
  const material = new THREE.MeshLambertMaterial({ color: color });
  const mesh = new THREE.Mesh(geometry, material);
  mesh.name = name;
  return transform(mesh, position, rotation, scale);
};

export const initTorusKnotGeometry = (): THREE.BufferGeometry => {
  // load torus
  return new THREE.TorusKnotGeometry(0.5, 0.2);
};

export const initTorusKnot = (
  name: string,
  color = '#ffffff',
  position?: THREE.Vector3,
  rotation?: THREE.Euler,
  scale?: THREE.Vector3
): THREE.Object3D => {
  // load torus
  const geometry = new THREE.TorusKnotGeometry(0.5, 0.2);
  const material = new THREE.MeshLambertMaterial({ color: color });
  const mesh = new THREE.Mesh(geometry, material);
  mesh.name = name;
  return transform(mesh, position, rotation, scale);
};

export const initGroup = (
  name: string,
  content: THREE.Object3D[],
  position?: THREE.Vector3,
  rotation?: THREE.Euler,
  scale?: THREE.Vector3
): THREE.Object3D => {
  // load group
  const group = new THREE.Group();
  group.name = name;
  content.forEach((item) => {
    group.add(item);
  });
  return transform(group, position, rotation, scale);
};

export const initCurveGeometry = (): THREE.BufferGeometry => {
  const curve = new THREE.EllipseCurve(
    0,
    0, // ax, aY
    2,
    2, // xRadius, yRadius
    0,
    2 * Math.PI, // aStartAngle, aEndAngle
    false, // aClockwise
    0 // aRotation
  );
  const points = curve.getPoints(50);
  return new THREE.BufferGeometry().setFromPoints(points);
};

export const initCurve = (
  name: string,
  color = '#ffffff',
  position?: THREE.Vector3,
  rotation?: THREE.Euler,
  scale?: THREE.Vector3
): THREE.Object3D => {
  const geometry = initCurveGeometry();
  const lineMaterial = initCurveMaterial(color);
  const curveObject = new THREE.Line(geometry, lineMaterial);
  curveObject.name = name;
  return transform(curveObject, position, rotation, scale);
};
