import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Lattice from '@/plugin/free-form-deformation/Lattice';
import { DragControls } from 'three/examples/jsm/controls/DragControls';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils';
import { FFDAxis } from '@/types/enum/three';
import * as THREEMaterial from '@/utils/three/material';
import { HistoryInfo, HistoryOperationType } from '@/types/ui/HistoryList';
import { MeshSelector } from '@/types/ui/SelectionList';
import { DeformationToolType } from '@/components/Tools/DeformationTools.vue';

/* eslint-disable @typescript-eslint/no-explicit-any*/

export interface GridData {
  spanCounts: number[];
  boundingBox: THREE.Box3;
  gridPositionList: THREE.Vector3[];
  ctrlPositionList: THREE.Vector3[];
  vertexPositionUndeformed: THREE.Vector3[];
}

export default class Controller {
  scene!: THREE.Scene;
  camera!: THREE.Camera;
  renderer!: THREE.Renderer;
  controls!: OrbitControls;
  history: HistoryInfo;
  meshSelector: MeshSelector;
  meshId: string;

  lattice: Lattice;
  dragControls!: DragControls;

  constructor(
    x: number,
    y: number,
    z: number,
    history: HistoryInfo,
    meshSelector: MeshSelector
  ) {
    this.lattice = new Lattice(x, y, z);
    this.history = history;
    this.meshSelector = meshSelector;
    this.meshId = '';
  }

  getResolutionX(): number {
    return this.lattice.span_counts[0];
  }

  getResolutionY(): number {
    return this.lattice.span_counts[1];
  }

  getResolutionZ(): number {
    return this.lattice.span_counts[2];
  }

  changeResolution(x: number, y: number, z: number): void {
    this.lattice.changeResolution(x, y, z);
    this.setupDrag(this.lattice.controlPoints);
    this.history.lastUpdateTime = Date.now();
    //this.history.historyList.remove(this.meshId, HistoryOperationType.ffd);
  }

  changeGrid(gridPositionList: THREE.Vector3[]): void {
    this.lattice.changeGrid(gridPositionList);
  }

  changeAxis(axis: FFDAxis): void {
    this.lattice.changeAxis(axis);
    this.deselectAllDragPoints();
  }

  changeMode(editGrid = false): void {
    this.lattice.changeMode(editGrid);
    this.deselectAllDragPoints();
  }

  changeSnap(snap: boolean): void {
    this.lattice.changeSnap(snap);
  }

  initView(
    scene: THREE.Scene,
    camera: THREE.Camera,
    renderer: THREE.Renderer,
    controls: OrbitControls | undefined
  ): void {
    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;
    if (controls) this.controls = controls;
    this.lattice.initView(scene, camera, renderer, controls);
    this.dragControls = new DragControls(
      [],
      this.camera,
      this.renderer.domElement
    );
  }

  cleanupView(): void {
    this.dragControls.removeEventListener('drag', this.drag);
    this.dragControls.removeEventListener('dragstart', this.dragStart);
    this.dragControls.removeEventListener('dragend', this.dragEnd);
  }

  clearMesh(): void {
    if (this.dragControls) this.dragControls.dispose();
    this.lattice.clearObjects();
    this.meshId = '';
  }

  initMesh(model: THREE.Object3D, merge = true): void {
    this.meshId = (model as any).apiData?.uuid;
    if (merge) {
      const orig_geom = (model as THREE.Mesh).geometry;
      const mergedGeometry = BufferGeometryUtils.mergeVertices(orig_geom);
      mergedGeometry.computeVertexNormals();
      (model as THREE.Mesh).geometry = mergedGeometry;
    }
    this.lattice.initModel(this.meshId, (model as THREE.Mesh).geometry);
    model.add(this.lattice.container);
    this.setupDrag(this.lattice.controlPoints);
    this.connectedDragPoints.length = 0;
  }

  reloadMesh(
    spanCounts: number[],
    boundingBox: THREE.Box3,
    gridPositionList: THREE.Vector3[],
    ctrlPositionList: THREE.Vector3[],
    vertexPositionUndeformed: THREE.Vector3[],
    model: THREE.Object3D,
    initialReload = true,
    merge = false
  ): void {
    this.meshId = (model as any).apiData.uuid;
    if (merge) {
      const orig_geom = (model as THREE.Mesh).geometry;
      const mergedGeometry = BufferGeometryUtils.mergeVertices(orig_geom);
      mergedGeometry.computeVertexNormals();
      (model as THREE.Mesh).geometry = mergedGeometry;
    }
    this.lattice.reloadModel(
      this.meshId,
      spanCounts,
      boundingBox,
      gridPositionList,
      ctrlPositionList,
      vertexPositionUndeformed,
      (model as THREE.Mesh).geometry,
      initialReload
    );
    model.add(this.lattice.container);
    this.setupDrag(this.lattice.controlPoints);
    this.connectedDragPoints.length = 0;
  }

  setupDrag(objects: THREE.Object3D[]): void {
    if (this.dragControls) this.dragControls.dispose();
    this.dragControls = new DragControls(
      objects,
      this.camera,
      this.renderer.domElement
    );
    (this.dragControls as any).controller = this;
    this.dragControls.removeEventListener('drag', this.drag);
    this.dragControls.addEventListener('drag', this.drag);
    this.dragControls.removeEventListener('dragstart', this.dragStart);
    this.dragControls.addEventListener('dragstart', this.dragStart);
    this.dragControls.removeEventListener('dragend', this.dragEnd);
    this.dragControls.addEventListener('dragend', this.dragEnd);
  }

  connectedDragPoints: THREE.Object3D[] = [];
  private connectedDragPointIndex(point: THREE.Object3D): number {
    return this.connectedDragPoints.findIndex(
      (item) => item.uuid === point.uuid
    );
  }

  private selectDragPoint(point: THREE.Object3D): void {
    const index = this.connectedDragPointIndex(point);
    if (index === -1) this.connectedDragPoints.push(point);
    THREEMaterial.setColor(point, this.lattice.selectionColor);
  }

  private deselectDragPoint(point: THREE.Object3D): void {
    const index = this.connectedDragPointIndex(point);
    if (index > -1) this.connectedDragPoints.splice(index, 1);
    THREEMaterial.setColor(point, this.lattice.defaultColor);
  }

  private deselectAllDragPoints(): void {
    for (const point of this.connectedDragPoints) {
      this.deselectDragPoint(point);
    }
  }

  isDrag = false;
  oldPositionList: THREE.Vector3[] = [];
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  dragStart(event: any): void {
    const dragControls = this as any as DragControls;
    const controller = (dragControls as any).controller as Controller;
    controller.oldPositionList = controller.lattice.ffd
      .getCtrlPositionList()
      .map((item) => item.clone());
    controller.isDrag = false;
    const point = event.object as THREE.Object3D;
    const index = controller.connectedDragPointIndex(point);
    if (index > -1) controller.deselectDragPoint(point);
    else controller.selectDragPoint(point);
    controller.controls.enabled = false;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  dragEnd(event: any): void {
    const dragControls = this as any as DragControls;
    const controller = (dragControls as any).controller as Controller;
    if (controller.isDrag) {
      const point = event.object as THREE.Object3D;
      if (controller.connectedDragPoints.length === 1)
        controller.deselectDragPoint(point);
      if (!controller.lattice.editGrid) {
        const positionList = controller.lattice.ffd
          .getCtrlPositionList()
          .map((item) => item.clone());
        const historyPositionList = controller.oldPositionList;
        const historyMeshId = controller.meshId;
        controller.history.historyList.add(
          controller.meshId,
          HistoryOperationType.ffd,
          async () => {
            if (controller.meshId !== historyMeshId) {
              controller.meshSelector
                .selectMesh(historyMeshId, DeformationToolType.ffd)
                .then(() => {
                  controller.lattice.reloadCtrlPositionList(
                    historyPositionList
                  );
                });
            } else {
              controller.lattice.reloadCtrlPositionList(historyPositionList);
            }
          },
          async () => {
            if (controller.meshId !== historyMeshId) {
              controller.meshSelector
                .selectMesh(historyMeshId, DeformationToolType.ffd)
                .then(() => {
                  controller.lattice.reloadCtrlPositionList(positionList);
                });
            } else {
              controller.lattice.reloadCtrlPositionList(positionList);
            }
          }
        );
      } else {
        controller.history.historyList.remove(
          controller.meshId,
          HistoryOperationType.ffd
        );
      }
    }
    controller.controls.enabled = true;
    if (controller.history.lastUpdateTime < controller.dragTime)
      controller.history.lastUpdateTime = Date.now();
  }

  dragTime = -1;
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  drag(event: any): void {
    const dragControls = this as any as DragControls;
    const controller = (dragControls as any).controller as Controller;
    controller.isDrag = true;
    const point = event.object as THREE.Object3D;
    controller.selectDragPoint(point);
    controller.lattice.updateLattice(point, controller.connectedDragPoints);
    controller.lattice.deform();
    controller.dragTime = Date.now();
  }

  getGridData(): GridData {
    return {
      spanCounts: this.lattice.span_counts,
      boundingBox: this.lattice.ffd.getBoundingBox(),
      gridPositionList: this.lattice.ffd.getGridPositionList(),
      ctrlPositionList: this.lattice.ffd.getCtrlPositionList(),
      vertexPositionUndeformed: this.lattice.smooth_verts_undeformed,
    };
  }
}
