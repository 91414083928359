export const activeWebGLContextList: string[] = [];
const maxWebGLContextCount = 16;

export const addWebGlContext = (uuid: string): boolean => {
  if (activeWebGLContextList.length < maxWebGLContextCount) {
    if (!isActiveWebGlContext(uuid)) activeWebGLContextList.push(uuid);
    return true;
  }
  return false;
};

export const deleteWebGlContext = (uuid: string): void => {
  const index = activeWebGLContextList.indexOf(uuid);
  if (index) activeWebGLContextList.splice(index, 1);
};

export const isActiveWebGlContext = (uuid: string): boolean => {
  return activeWebGLContextList.includes(uuid);
};

export const waitFor = async (
  conditionFunction: () => boolean
): Promise<void> => {
  const poll = (resolve) => {
    if (conditionFunction()) resolve();
    else setTimeout(() => poll(resolve), 100);
  };

  return new Promise(poll);
};
