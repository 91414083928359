<script lang="ts">
import { OrderRequestData } from '@/services/api/orderService';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ValidationForm from './element-plus/ValidationForm.vue';
import { BodySide, ZipType } from '@/types/enum/order';
import { WorkflowModel } from '@/types/ui/WorkflowModel';
import { Product } from '@/types/api/Model/Product';
import { LinearLightNameType } from '@/types/enum/order';
import ProductParameterCover from '@/components/productParameters/ProductParameterCover.vue';
import ProductParameterCuboid from '@/components/productParameters/ProductParameterCuboid.vue';
import ProductParameterBalcony from '@/components/productParameters/ProductParameterBalcony.vue';
import ProductParameterFence from '@/components/productParameters/ProductParameterFence.vue';
import ProductParameterOrthesis from '@/components/productParameters/ProductParameterOrthesis.vue';
import ProductParameterTemplate from '@/components/productParameters/ProductParameterTemplate.vue';
import ProductParameterLinearLight from '@/components/productParameters/ProductParameterLinearLight.vue';
import ProductParameterBathtub from '@/components/productParameters/ProductParameterBathtub.vue';
import Auth from '@/services/api/auth';
import MeasurementInfoCard from './MeasurementInfoCard.vue';
import { Modal } from 'bootstrap';
import { removeWordFromString } from '@/utils/formBuilder';

@Options({
  components: {
    ValidationForm,
    MeasurementInfoCard,
    ProductParameterCover,
    ProductParameterCuboid,
    ProductParameterBalcony,
    ProductParameterFence,
    ProductParameterOrthesis,
    ProductParameterTemplate,
    ProductParameterLinearLight,
    ProductParameterBathtub
  },
  emits: ['zipChanged', 'sizeChanged'],
})
export default class OrderForm extends Vue {
  @Prop() order!: OrderRequestData;
  @Prop() workflowModel!: WorkflowModel;
  @Prop() product!: Product;

  authentication = Auth.getInstance();

  BodySide = BodySide;
  ZipType = ZipType;
  LinearLightNameType = LinearLightNameType;

  isValidationError = false;
  noCustomerNr = false;

  private measurementInfoModalDialog: any = null;
  selectedContent: string | null = null;
  localColor = 'Schwarz';

  colorOptions: string[] = ['Schwarz', 'Haut'];

  technician = '';

  technicians = ['Christa Becker', 'Ralf Huppelsberg', 'Philippe Knieps', 'Christoph Emschermann', 'Dennis Hewitt', 'Guido Grünke', 'Niklas Hirsch', 'Moritz Engelhardt', 'Florian Kalkmann'];

  // Truncate 'phase1' word in title string 
  get productTitle(): string {
    return removeWordFromString(this.product?.name, 'phase1')
  }

  updateColorList(): void {
    if (this.product.name?.toLowerCase().includes('s-cover')) {
      this.order.remarks = 'Farbauswahl: ' + this.localColor;
    }
  }

  mounted(): void {
    if (this.product.name.toLowerCase().includes('s-cover')) {
      this.order.remarks = 'Farbauswahl: ' + this.localColor;
    }
    this.order.isPrivacyAccepted = false;
    this.privacyChanged();

    const modalDialogOptions = {};
    this.measurementInfoModalDialog = new Modal(
      '#measurement-info',
      modalDialogOptions
    );
  }

  privacyChanged(): void {
    if (this.order.isPrivacyAccepted) {
      this.order.privacy = 'yes';
    } else {
      this.order.privacy = '';
    }
  }

  triggerCheckboxGrow(): void {
    this.isValidationError = true;
    setTimeout(() => (this.isValidationError = false), 250);
  }

  handleCustomerNr() {
    if (this.noCustomerNr) {
      this.order.customerNr = '';
    }
  }

  showMeasurementInfoModalDialog(content): void {
    this.selectedContent = content;
    this.measurementInfoModalDialog.show();
  }

  addScanFile(event) {
    for (const scan of event.target.files) {
      this.order.scanFiles.push(scan);
    }
  }

  addImageFiles(event) {
    for (const image of event.target.files) {
      this.order.imageFiles.push(image);
    }
  }
}
</script>

<template>
  <ValidationForm
    :formData="order"
    v-on:submitDataValid="
      () => {
        workflowModel.changeActiveStep(1);
      }
    "
  >
    <div>
      <h2 style="font-size: 24px; font-weight: bold" class="mb-4">
        {{ $t('components.order-form.selectedProduct') }}:
        <span>{{ productTitle }}</span>
      </h2>

      <h2 v-if="authentication.domains.some((d) => d.urlSuffix == 'luttermann')">{{ $t('components.order-form.companyTitle') }}</h2>
      <h2 v-else>{{ $t('components.order-form.customerTitle') }}</h2>
      <div class="row">
        <div class="col mb-2">
          <el-form-item
            prop="company"
            :label="$t('views.customize.workflow.form.company')"
            :rules="{
              required: true,
              message: $t('error.vuelidate.required'),
              trigger: ['blur', 'change'],
            }"
          >
            <el-input v-model="order.company" />
          </el-form-item>
        </div>

        <div
          class="col mb-2"
          v-if="!authentication.domains.some((d) => d.urlSuffix == 'luttermann')"
        >
          <el-form-item
            prop="commissionNo"
            :label="$t('views.customize.workflow.form.commissionNo')"
            :rules="[
              {
                required: true,
                type: 'string',
                message: $t('error.vuelidate.required'),
                trigger: 'blur',
              },
            ]"
          >
            <el-input v-model.string="order.commissionNo" />
          </el-form-item>
        </div>

        <div
          class="col mb-2"
          v-if="authentication.domains.some((d) => d.urlSuffix == 'wjtortho')"
        >
          <el-form-item
            prop="orderNr"
            :label="$t('views.customize.workflow.form.orderNr')"
            :rules="[
              {
                required: false,
                message: $t('error.vuelidate.required'),
                trigger: 'blur',
              },
            ]"
          >
            <el-input v-model.number="order.orderNr" />
          </el-form-item>
        </div>

        <div
          class="col mb-2"
          v-if="authentication.domains.some((d) => d.urlSuffix == 'wjtortho')"
        >
          <el-form-item
            prop="billingNr"
            :label="$t('views.customize.workflow.form.billingNr')"
            :rules="[
              {
                required: false,
                message: $t('error.vuelidate.required'),
                trigger: 'blur',
              },
            ]"
          >
            <el-input v-model.number="order.billingNr" />
          </el-form-item>
        </div>

        <div
          class="col mb-2"
          v-if="authentication.domains.some((d) => d.urlSuffix == 'wjtortho')"
        >
          <el-form-item
            prop="customerNr"
            :label="
              $t('views.customize.workflow.form.customerNr') + ' (WJT GmbH)'
            "
            :rules="[
              {
                required: !noCustomerNr,
                message: $t('error.vuelidate.required'),
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="order.customerNr"
              :disabled="noCustomerNr"
              :placeholder="$t('views.customize.workflow.form.input')"
            >
              <template #append>
                <el-checkbox
                  v-model="noCustomerNr"
                  @change="handleCustomerNr"
                  :label="$t('views.customize.workflow.form.noCustomerNr')"
                />
              </template>
            </el-input>
          </el-form-item>
        </div>
      </div>

      <h2>{{ $t('views.customize.workflow.form.contactPerson') }}</h2>
      <h4 v-if="authentication.domains.some((d) => d.urlSuffix == 'luttermann')">{{ $t('components.order-form.customerTitle') }}</h4>
      <div class="row">
        <div class="col mb-2">
          <el-form-item
            prop="technicianFirstname"
            :label="$t('views.customize.workflow.form.firstname')"
            :rules="{
              required: true,
              message: $t('error.vuelidate.required'),
              trigger: ['blur', 'change'],
            }"
          >
            <el-input v-model="order.technicianFirstname" />
          </el-form-item>
        </div>

        <div class="col mb-2">
          <el-form-item
            prop="technicianLastname"
            :label="$t('views.customize.workflow.form.lastname')"
            :rules="{
              required: true,
              message: $t('error.vuelidate.required'),
              trigger: ['blur', 'change'],
            }"
          >
            <el-input v-model="order.technicianLastname" />
          </el-form-item>
        </div>

        <div class="col mb-2">
          <el-form-item
            prop="mail"
            :label="$t('views.customize.workflow.form.mail')"
            :rules="[
              {
                required: true,
                message: $t('error.vuelidate.required'),
                trigger: 'blur',
              },
              {
                type: 'email',
                message: $t('error.vuelidate.email'),
                trigger: ['blur', 'change'],
              },
            ]"
          >
            <el-input v-model="order.mail" />
          </el-form-item>
        </div>
      </div>

      <div
        class="col mb-2"
        v-if="product?.name?.toLowerCase().includes('s-cover')"
      >
        <el-form-item
          prop="localColor"
          :label="$t('views.customize.workflow.form.color')"
        >
          <el-select
            v-model="localColor"
            placeholder="Bitte wählen"
            @change="updateColorList"
          >
            <el-option
              v-for="option in colorOptions"
              :key="option"
              :label="option"
              :value="option"
            />
          </el-select>
        </el-form-item>
      </div>

      <div
        class="row"
        v-if="authentication.domains.some((d) => d.urlSuffix == 'luttermann')"
      >
        <h4>{{ $t('components.order-form.technician') }}</h4>

        <div class="col mb-2">
          <el-form-item
            prop="technician"
            :label="$t('components.order-form.technician')"
            :rules="{
              required: true,
              message: $t('error.vuelidate.required'),
              trigger: 'blur',
            }"
          >
            <el-select
              v-model="order.technician"
              :placeholder="$t('components.order-form.pleaseSelect')"
              style="font-weight: normal"
            >
              <el-option
                v-for="item in technicians"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </div>

        <div class="col mb-2">
          <el-form-item
            prop="vg-number"
            :label="$t('views.customize.workflow.form.vg-number')"
            :rules="[
              {
                message: $t('error.vuelidate.required'),
                trigger: 'blur',
              },
              {
                type: 'number',
                message: $t('error.vuelidate.number'),
                trigger: ['blur', 'change'],
              },
            ]"
          >
            <el-input v-model="order.vgNumber" />
          </el-form-item>
        </div>

        <div class="col mb-2">
          <el-form-item
            prop="vsg-number"
            :label="$t('views.customize.workflow.form.vsg-number')"
            :rules="[
              {
                message: $t('error.vuelidate.required'),
                trigger: 'blur',
              },
              {
                type: 'number',
                message: $t('error.vuelidate.number'),
                trigger: ['blur', 'change'],
              },
            ]"
          >
            <el-input v-model="order.vsgNumber" />
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item
            prop="customerNr"
            :label="
              $t('views.customize.workflow.form.customerNr') + ' (bei Luttermann)'
            "
            :rules="[
              {
                required: !noCustomerNr,
                message: $t('error.vuelidate.required'),
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="order.customerNr"
              :disabled="noCustomerNr"
              :placeholder="$t('views.customize.workflow.form.input')"
            >
              <template #append>
                <el-checkbox
                  v-model="noCustomerNr"
                  @change="handleCustomerNr"
                  :label="$t('views.customize.workflow.form.noCustomerNr')"
                />
              </template>
            </el-input>
          </el-form-item>
        </div>
      </div>
    </div>

    <!-- Data upload -->
    <div
      v-if="
        product?.name?.toLowerCase().includes('cover') &&
        !product?.name?.toLowerCase().includes('s-cover')
      "
    >
      <h2 class="mt-3">{{ $t('components.order-form.dataUploadTitle') }}</h2>
      <div>
        <div class="row mb-4">
          <div class="col-12 mb-3">
            <h4>3D-Scans</h4>
            <input
              type="file"
              @change="addScanFile"
              target="_blank"
              class="btn btn-primary p-2 equal-width-buttons"
              multiple
              accept=".stl,.obj,.fbx"
            />
          </div>
          <div class="col-12 mb-2">
            <h4>Referenzbilder</h4>
            <input
              type="file"
              @change="addImageFiles"
              target="_blank"
              class="btn btn-primary p-2 equal-width-buttons"
              multiple
              accept=".png,.jpg"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Parameter -->
    <h2 class="mt-3">{{ $t('components.order-form.parameterTitle') }}</h2>
    <!-- Custom Parameters -->
    <product-parameter-cover
      v-if="product.type.name.toLowerCase() == 'covers'"
      :properties="[order]"
      :product="product"
      @zip-changed="(zip) => $emit('zipChanged', zip)"
      @info-button-clicked="showMeasurementInfoModalDialog"
    />
    <!-- TODOS: ADJUST IF PROPS -->
    <product-parameter-template
      v-if="product.name.replace(/\s/g, '').toLowerCase().includes('phase1')"
      :properties="[order]"
      :product="product"
    />
    <!-- TODOS: ADJUST IF PROPS-->
    <product-parameter-orthesis
      v-if="
        product.type.name.toLowerCase() == 'orthesen' &&
        !product.name.replace(/\s/g, '').toLowerCase().includes('phase1')
      "
      :properties="[order]"
      :product="product"
      @zip-changed="(zip) => $emit('zipChanged', zip)"
    />
    <product-parameter-cuboid
      v-if="
        product.type.name.toLowerCase() == 'möbel' ||
        product.type.name.toLowerCase() == 'autos' ||
        product.type.name.toLowerCase() == 'gebäude'
      "
      :properties="[order]"
    />
    <product-parameter-balcony
      v-if="product.type.name.toLowerCase() == 'balkone'"
      :properties="[order]"
      v-on:sizeChanged="$emit('sizeChanged')"
    />
    <product-parameter-fence
      v-if="product.type.name.toLowerCase() == 'zäune'"
      :properties="[order]"
      v-on:sizeChanged="$emit('sizeChanged')"
    />
    <product-parameter-linear-light
      v-if="product.type.name.toLowerCase() == 'lineare beleuchtung'"
      :properties="[order]"
      :isHangingLight="
        product.name.toLowerCase() === LinearLightNameType.hanging.toLowerCase()
      "
      :isSurfaceMountedLight="
        product.name.toLowerCase() ===
        LinearLightNameType.surfaceMounted.toLowerCase()
      "
      v-on:sizeChanged="$emit('sizeChanged')"
    />
    <product-parameter-bathtub
      v-if="product.type.name.toLowerCase() == 'duschen und bäder'"
      :properties="[order]"
      style="pointer-events: none; opacity: 0.8;"
    />

    <div
      class="modal fade"
      id="measurement-info"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content" id="info-content">
          <div
            class="modal-header border-bottom-0 bg-transparent"
            id="info-header"
          >
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0" id="info-body">
            <measurement-info-card>
              <div class="row">
                <div class="col-12" v-if="selectedContent == 'content1'">
                  <h2>{{ $t('components.order-form.scope-top') }}</h2>
                  <div class="row">
                    <div class="col-md-6" id="txtcol">
                      <p class="form-text text-black">
                        {{
                          $t(
                            'components.product-parameter.measurementsInfo.scope.top.info'
                          )
                        }}
                      </p>
                      <ul style="list-style-type: disc; padding-left: 20px">
                        <li class="form-text text-black">
                          {{
                            $t(
                              'components.product-parameter.measurementsInfo.scope.bullet1'
                            )
                          }}
                        </li>
                        <li class="form-text text-black">
                          {{
                            $t(
                              'components.product-parameter.measurementsInfo.scope.bullet2'
                            )
                          }}
                        </li>
                        <li class="form-text text-black">
                          {{
                            $t(
                              'components.product-parameter.measurementsInfo.scope.bullet3'
                            )
                          }}
                        </li>
                        <li class="form-text text-black">
                          {{
                            $t(
                              'components.product-parameter.measurementsInfo.scope.bullet4'
                            )
                          }}
                        </li>
                        <li class="form-text text-black">
                          {{
                            $t(
                              'components.product-parameter.measurementsInfo.scope.bullet5'
                            )
                          }}
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-6" id="imgcol">
                      <img
                        id="infoimg"
                        src="../assets/images/covers/scope_calve.png"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12" v-if="selectedContent == 'content2'">
                  <h2>{{ $t('components.order-form.height') }}</h2>
                  <div class="row">
                    <div class="col-md-6" id="txtcol">
                      <div
                        v-if="
                          product.name.toLowerCase().includes('unterschenkel')
                        "
                      >
                        <p class="form-text text-black">
                          {{
                            $t(
                              'components.product-parameter.measurementsInfo.height-leg.upper.info'
                            )
                          }}
                        </p>
                        <ul style="list-style-type: disc; padding-left: 20px">
                          <li class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.height-leg.bullet1'
                              )
                            }}
                          </li>
                          <li class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.height-leg.bullet2'
                              )
                            }}
                          </li>
                          <li class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.height-leg.bullet3'
                              )
                            }}
                          </li>
                          <li class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.height-leg.bullet4'
                              )
                            }}
                          </li>
                          <li class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.height-leg.bullet5'
                              )
                            }}
                          </li>
                        </ul>
                      </div>
                      <div v-else>
                        <p class="form-text text-black">
                          {{
                            $t(
                              'components.product-parameter.measurementsInfo.height-leg.lower.info'
                            )
                          }}
                        </p>
                        <ul style="list-style-type: disc; padding-left: 20px">
                          <li class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.height-leg.bullet1'
                              )
                            }}
                          </li>
                          <li class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.height-leg.bullet2'
                              )
                            }}
                          </li>
                          <li class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.height-leg.bullet3'
                              )
                            }}
                          </li>
                          <li class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.height-leg.bullet4'
                              )
                            }}
                          </li>
                          <li class="form-text text-black">
                            {{
                              $t(
                                'components.product-parameter.measurementsInfo.height-leg.bullet5'
                              )
                            }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-6" id="imgcol">
                      <img
                        v-if="
                          product.name.toLowerCase().includes('unterschenkel')
                        "
                        id="infoimg"
                        src="../assets/images/covers/height_lower_leg.png"
                      />
                      <img
                        v-else
                        id="infoimg"
                        src="../assets/images/covers/height_upper_leg.png"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12" v-if="selectedContent == 'content3'">
                  <h2>{{ $t('components.order-form.scope-bottom') }}</h2>
                  <div class="row">
                    <div class="col-md-6" id="txtcol">
                      <p class="form-text text-black">
                        {{
                          $t(
                            'components.product-parameter.measurementsInfo.scope.bottom.info'
                          )
                        }}
                      </p>
                      <ul style="list-style-type: disc; padding-left: 20px">
                        <li class="form-text text-black">
                          {{
                            $t(
                              'components.product-parameter.measurementsInfo.scope.bullet1'
                            )
                          }}
                        </li>
                        <li class="form-text text-black">
                          {{
                            $t(
                              'components.product-parameter.measurementsInfo.scope.bullet2'
                            )
                          }}
                        </li>
                        <li class="form-text text-black">
                          {{
                            $t(
                              'components.product-parameter.measurementsInfo.scope.bullet3'
                            )
                          }}
                        </li>
                        <li class="form-text text-black">
                          {{
                            $t(
                              'components.product-parameter.measurementsInfo.scope.bullet4'
                            )
                          }}
                        </li>
                        <li class="form-text text-black">
                          {{
                            $t(
                              'components.product-parameter.measurementsInfo.scope.bullet5'
                            )
                          }}
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-6" id="imgcol">
                      <img
                        id="infoimg"
                        src="../assets/images/covers/scope_ankle.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </measurement-info-card>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <label for="remarks" class="form-label mb-1">{{
        $t('components.order-form.remarks')
      }}</label>
      <div class="input-group">
        <textarea
          type="text"
          class="form-control"
          id="remarks"
          rows="4"
          v-model="order.remarks"
        />
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col mb-2">
        <el-form-item
          prop="privacy"
          :label="$t('views.customize.workflow.form.privacy')"
          :rules="{
            required: true,
            message: $t('error.vuelidate.required'),
            trigger: ['blur', 'change'],
          }"
        >
          <el-checkbox
            v-model="order.isPrivacyAccepted"
            @change="privacyChanged"
          >
            {{ $t('views.customize.workflow.form.privacySettings.info') }}
            <br />
            {{
              $t('views.customize.workflow.form.privacySettings.extendedInfo')
            }}
            <a
              href="https://www.wako3d.com/datenschutzerklaerung/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ $t('views.customize.workflow.form.privacy') }}.
            </a>
          </el-checkbox>
          <el-input
            v-model="order.privacy"
            style="visibility: hidden; width: 0"
          />
        </el-form-item>
      </div>
    </div>
  </ValidationForm>
</template>

<style scoped lang="scss">
.form-control,
.form-select {
  width: 20rem;
}
#height-range-text,
#width-range-text,
#scope-range-text,
#height,
#width,
#scope {
  margin-top: -0.65rem;
}
#height,
#width,
#scope {
  padding: 0.5rem;
}

.el-input,
.el-select {
  --el-border-color: var(--color-primary);
  --el-border-radius-base: var(--bs-border-radius);
  --el-input-focus-border-color: var(--color-primary);
  --el-border-color-hover: var(--color-primary);
  --el-input-height: 3rem;
  --el-select-height: 3rem;
  --el-input-color: 0;
  --el-text-color-regular: rgba(0, 0, 0, 0.5529411765);
  min-width: 10rem;
  max-width: 30rem;
}

::v-deep .el-select__wrapper {
  min-height: 3rem;
}

.el-form-item {
  --el-text-color-regular: var(--bs-body-color);
  --el-form-label-font-size: var(--bs-body-font-size);
}

.privacy-policy {
  display: block;
  scale: 1;
  transition: scale ease-out 0.15s;
}

.expand-privacy-policy {
  scale: 1.05;
}

#measurement-info {
  box-sizing: border-box;
  height: 90%;
  width: 80%;
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

#info-content {
  background-color: transparent;
}

#info-header {
  background-color: transparent;
  z-index: 1;
}

#info-body {
  background-color: rgba(0, 0, 0, 0.85);
  margin-top: -3.6rem;
  z-index: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

#infoimg {
  max-height: 330px;
  max-width: auto;
  top: -10%;
  margin-left: 20%;
  margin-right: 10%;
  margin-bottom: 10%;
}
#txtcol {
  overflow: auto;
  float: left;
  width: 60%;
}
#imgcol {
  overflow-x: hidden;
  float: right;
  width: 40%;
}

#equal-width-buttons {
  width: 200px;
  display: block;
  margin: 0 auto;
  text-align: center;
}

@media only screen and (max-width: 1000px) {
  #txtcol,
  #imgcol {
    width: 100%;
  }
  #infoimg {
    margin-left: 25%;
  }
}
</style>
