import { mutate, mutateWithVariables, queryWithVariables } from '@/services/api/api';
import { MeshTreeData } from '@/types/ui/MeshTreeData';
import { FileTypeImage } from '@/types/enum/upload';
/* eslint-disable @typescript-eslint/no-explicit-any*/


export const GetAllDistinctProductTypes = async (): Promise<any> => {
  const result = await queryWithVariables(
    `
        query {
          allDistinctProductTypes {
            productTypeId
            name
          }
        }
      `,
    {

    }
  );
  return result.data;
};

export const GetProductTypeByName = async (name): Promise<any> => {
  try {
    const result = await queryWithVariables(
      `
      query ($name: String!) {
        productTypeByName (name: $name){
          name
        }
      }
    `,
      {
        name: [name],
      }
    );
    return result.data.productTypeByName[0].formFields;
  } catch (error) {
    console.error('error', error);
    return null;
  }
};

export const CreateProductType = async (productType): Promise<any> => {
  try {
    const result = await mutateWithVariables(
      `
      mutation ($productType: String!) {
        createProductType(productType: $productType)
      }
    `,
      {
        "productType": productType
      }
    );
    return result.data.createProductType;
  } catch (error) {
    console.error('error', error);
    return null;
  }
};

export const UpdateProductTypeByName = async (name: string, newName: string): Promise<any> => {
  try {
    const result = await mutateWithVariables(
      `
      mutation ($name: String!, $newName: String!) {
        updateProductTypeByName
        (
          name: $name,
          newName: $newName
        )
      }
    `,
      {
        "name": name,
        "newName": newName
      }
    );
    return result.data.updateProductTypeByName;
  } catch (error) {
    console.error('error', error);
    return null;
  }
};


type UpdateProductParams = {
  productId: number | null;
  name: string;
  description: string | null;
  addressedDiseases?: [];
  sampleImagePaths?: [];
  defaultCharacteristics?: [];
  supportedGridTypes?: [];
  basePrice?: number;
  packages?: [];
  type?: any;
  bodyPart?: any | null;
  template?: MeshTreeData;
};

export const updateProduct = async ({
  productId,
  name,
  description,
  addressedDiseases = [],
  sampleImagePaths = [],
  defaultCharacteristics = [],
  basePrice = 0,
  packages = [],
  type = {
    name: 'Testing Category',
    attributes: [],
  },
  bodyPart,
  supportedGridTypes = [],
  template,
}: UpdateProductParams): Promise<unknown> => {
  const mutationUpdateProduct = `
        mutation UpdateProduct($product: ProductInput!) {
            updateProduct(product: $product)
        }
    `;

  const data = {
    product: {
      addressedDiseases,
      basePrice,
      bodyPart,
      defaultCharacteristics,
      name,
      description,
      sampleImagePaths,
      supportedGridTypes,
      thumbnail: {
        content: template?.thumbnailUrl,
        name: template?.thumbnail,
        type: template?.thumbnailFiletype,
        textures: [],
        thumbnailTextures: [],
      },
      packages,
      productId,
      type,
    },
  };

  const updateResult = await mutateWithVariables(mutationUpdateProduct, data);
  return updateResult;
};