import {
  IconPack,
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-common-types';

export const prefix = 'fac' as IconPrefix;

export const fac = {
  front: {
    prefix: 'fac' as IconPrefix,
    iconName: 'front' as IconName,
    icon: [
      512,
      512,
      [],
      'e001',
      'M506.3,33.7c0-0.2,0-0.3,0-0.5c-0.1-1.4-0.3-2.7-0.6-4.1c0-0.1,0-0.1,0-0.2\n' +
        '\tc-0.3-1.4-0.7-2.7-1.2-4.1c0,0,0-0.1,0-0.1c-0.5-1.3-1.1-2.6-1.8-3.8c0-0.1-0.1-0.1-0.1-0.2c-0.7-1.2-1.4-2.3-2.3-3.4\n' +
        '\tc-0.1-0.1-0.2-0.2-0.2-0.3c-0.4-0.5-0.8-0.9-1.2-1.4c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.4-0.4-0.6-0.6c0,0,0,0,0,0\n' +
        '\tc-0.2-0.2-0.5-0.5-0.7-0.7c-0.1-0.1-0.2-0.2-0.4-0.4c-0.5-0.4-0.9-0.8-1.4-1.2c0,0,0,0-0.1-0.1c-0.2-0.2-0.4-0.4-0.7-0.5\n' +
        '\tc0,0,0,0,0,0c-0.3-0.2-0.6-0.5-0.9-0.7c-0.1-0.1-0.2-0.2-0.3-0.2c-1.1-0.8-2.3-1.5-3.6-2.2c0,0-0.1,0-0.1-0.1\n' +
        '\tc-2.1-1.1-4.4-1.9-6.8-2.4c0,0,0,0-0.1,0c-0.5-0.1-1-0.2-1.5-0.3c-0.1,0-0.2,0-0.2,0c-0.7-0.1-1.3-0.2-2-0.3c-0.1,0-0.2,0-0.3,0\n' +
        '\tc0,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.4,0c-0.4,0-0.7,0-1.1-0.1c-0.2,0-0.4,0-0.5,0c-0.1,0-0.3,0-0.4,0h0H168.4h0c-0.5,0-1,0-1.5,0\n' +
        '\tc-0.3,0-0.5,0-0.7,0.1c-0.2,0-0.5,0-0.7,0.1c-0.4,0-0.8,0.1-1.2,0.2c-0.1,0-0.1,0-0.2,0c-2.4,0.4-4.8,1-7,1.9c0,0,0,0-0.1,0\n' +
        '\tc-0.4,0.2-0.8,0.3-1.1,0.5c-0.1,0-0.2,0.1-0.2,0.1c-0.3,0.1-0.6,0.3-0.9,0.4c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0.1-0.4,0.2-0.6,0.3\n' +
        '\tc-0.2,0.1-0.5,0.3-0.7,0.4c-0.1,0.1-0.2,0.1-0.3,0.2c-2,1.2-3.8,2.6-5.4,4.3L14.9,147.2c-0.1,0.1-0.3,0.3-0.4,0.5\n' +
        '\tc-0.3,0.3-0.5,0.5-0.8,0.8c-0.2,0.2-0.4,0.5-0.6,0.7c-0.3,0.4-0.6,0.7-0.9,1.1c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2,0.3-0.4,0.6-0.6,0.9\n' +
        '\tc-0.2,0.2-0.3,0.5-0.5,0.7c-0.3,0.5-0.6,0.9-0.8,1.4c-0.1,0.2-0.2,0.4-0.3,0.7c-0.2,0.4-0.4,0.7-0.5,1.1c-0.1,0.2-0.2,0.4-0.3,0.6\n' +
        '\tc-0.2,0.5-0.4,1.1-0.6,1.6c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.4-0.3,0.8-0.4,1.3c-0.1,0.2-0.1,0.4-0.2,0.7c0,0.1-0.1,0.3-0.1,0.4\n' +
        '\tc0,0.1,0,0.1,0,0.2c-0.1,0.4-0.2,0.7-0.2,1.1c0,0.2-0.1,0.5-0.1,0.7c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.3-0.1,0.5\n' +
        '\tc0,0.2-0.1,0.5-0.1,0.7c-0.1,0.5-0.1,1-0.1,1.5c0,0.1,0,0.2,0,0.4c0,0.5,0,1,0,1.5v307.9c0,0.1,0,0.2,0,0.3c0,0.3,0,0.6,0,1\n' +
        '\tc0,0.1,0,0.3,0,0.4c0,0.3,0,0.6,0.1,0.9c0,0.2,0,0.4,0.1,0.6c0,0.1,0,0.1,0,0.2c0.1,0.5,0.1,1,0.2,1.5c0,0.1,0,0.2,0.1,0.4\n' +
        '\tc0,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.3,0.1,0.4c0.1,0.4,0.2,0.7,0.2,1.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1,0.1,0.3,0.1,0.4\n' +
        '\tc0.2,0.8,0.5,1.7,0.8,2.5c0,0,0,0,0,0.1c0.2,0.4,0.3,0.9,0.5,1.3c0,0,0,0.1,0,0.1c3,7.1,8.7,12.9,16.1,16c3.7,1.5,7.6,2.3,11.5,2.3\n' +
        '\tc0.5,0,1,0,1.5,0H344c0,0,0,0,0,0c0.5,0,1,0,1.5,0c0.3,0,0.5,0,0.8-0.1c0.2,0,0.4,0,0.6,0c0.4,0,0.9-0.1,1.3-0.2c0,0,0.1,0,0.1,0\n' +
        '\tc3.4-0.5,6.7-1.6,9.6-3.1c0,0,0.1,0,0.1,0c0.4-0.2,0.7-0.4,1.1-0.6c0.1,0,0.1-0.1,0.2-0.1c0.3-0.2,0.6-0.4,1-0.6\n' +
        '\tc0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.6-0.4,0.8-0.6c0.1-0.1,0.3-0.2,0.4-0.3c0.3-0.2,0.5-0.4,0.8-0.6c0.1-0.1,0.3-0.2,0.4-0.3\n' +
        '\tc0.3-0.2,0.5-0.4,0.7-0.6c0.1-0.1,0.3-0.2,0.4-0.3c0.4-0.3,0.7-0.7,1.1-1c0,0,0,0,0,0l132.1-132.4c5.2-5.1,8.6-12.1,9-19.9\n' +
        '\tc0-0.3,0-0.6,0-1c0-0.2,0-0.3,0-0.5v0V35.7C506.4,35,506.4,34.4,506.3,33.7z M138.4,108.3v30.1h-30L138.4,108.3z M331.6,138.4H198.4\n' +
        '\tV65.7h205.6L331.6,138.4z M374.1,403.7v-30.1h30L374.1,403.7z M446.4,313.7h-72.3V180.8l72.3-72.5V313.7z',
    ],
  } as IconDefinition,
  back: {
    prefix: 'fac' as IconPrefix,
    iconName: 'back' as IconName,
    icon: [
      512,
      512,
      [],
      'e002',
      'M506.3,33.7c0-0.2,0-0.3,0-0.5c-0.1-1.4-0.3-2.7-0.6-4.1c0-0.1,0-0.1,0-0.2c-0.3-1.4-0.7-2.7-1.2-4.1\n' +
        '\tc0,0,0-0.1,0-0.1c-0.5-1.3-1.1-2.6-1.8-3.8c0-0.1-0.1-0.1-0.1-0.2c-0.7-1.2-1.4-2.3-2.3-3.4c-0.1-0.1-0.2-0.2-0.2-0.3\n' +
        '\tc-0.4-0.5-0.8-0.9-1.2-1.4c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.4-0.4-0.6-0.6c0,0,0,0,0,0c-0.2-0.2-0.5-0.5-0.7-0.7\n' +
        '\tc-0.1-0.1-0.2-0.2-0.4-0.4c-0.5-0.4-0.9-0.8-1.4-1.2c0,0,0,0-0.1-0.1c-0.2-0.2-0.4-0.4-0.7-0.5c0,0,0,0,0,0\n' +
        '\tc-0.3-0.2-0.6-0.5-0.9-0.7c-0.1-0.1-0.2-0.2-0.3-0.2c-1.1-0.8-2.3-1.5-3.6-2.2c0,0-0.1,0-0.1-0.1c-2.1-1.1-4.4-1.9-6.8-2.4\n' +
        '\tc0,0,0,0-0.1,0c-0.5-0.1-1-0.2-1.5-0.3c-0.1,0-0.2,0-0.2,0c-0.7-0.1-1.3-0.2-2-0.3c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0\n' +
        '\tc-0.1,0-0.3,0-0.4,0c-0.4,0-0.7,0-1.1-0.1c-0.2,0-0.4,0-0.5,0c-0.1,0-0.3,0-0.4,0h0H168.4h0c-0.5,0-1,0-1.5,0c-0.3,0-0.5,0-0.7,0.1\n' +
        '\tc-0.2,0-0.5,0-0.7,0.1c-0.4,0-0.8,0.1-1.2,0.2c-0.1,0-0.1,0-0.2,0c-2.4,0.4-4.8,1-7,1.9c0,0,0,0-0.1,0c-0.4,0.2-0.8,0.3-1.1,0.5\n' +
        '\tc-0.1,0-0.2,0.1-0.2,0.1c-0.3,0.1-0.6,0.3-0.9,0.4c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.3-0.7,0.4\n' +
        '\tc-0.1,0.1-0.2,0.1-0.3,0.2c-2,1.2-3.8,2.6-5.4,4.3L14.9,147.2c-0.1,0.1-0.3,0.3-0.4,0.5c-0.3,0.3-0.5,0.5-0.8,0.8\n' +
        '\tc-0.2,0.2-0.4,0.5-0.6,0.7c-0.3,0.4-0.6,0.7-0.9,1.1c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2,0.2-0.3,0.5-0.5,0.7\n' +
        '\tc-0.3,0.5-0.6,0.9-0.8,1.4c-0.1,0.2-0.2,0.4-0.3,0.7c-0.2,0.4-0.4,0.7-0.5,1.1c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2,0.5-0.4,1.1-0.6,1.6\n' +
        '\tc-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.4-0.3,0.8-0.4,1.3c-0.1,0.2-0.1,0.4-0.2,0.7c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.1,0,0.2\n' +
        '\tc-0.1,0.4-0.2,0.7-0.2,1.1c0,0.2-0.1,0.5-0.1,0.7c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.3-0.1,0.5\n' +
        '\tc0,0.2-0.1,0.5-0.1,0.7c-0.1,0.5-0.1,1-0.1,1.5c0,0.1,0,0.2,0,0.4c0,0.5,0,1,0,1.5v307.9c0,0.1,0,0.2,0,0.3c0,0.3,0,0.6,0,1\n' +
        '\tc0,0.1,0,0.3,0,0.4c0,0.3,0,0.6,0.1,0.9c0,0.2,0,0.4,0.1,0.6c0,0.1,0,0.1,0,0.2c0.1,0.5,0.1,1,0.2,1.5c0,0.1,0,0.2,0.1,0.4\n' +
        '\tc0,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.3,0.1,0.4c0.1,0.4,0.2,0.7,0.2,1.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1,0.1,0.3,0.1,0.4\n' +
        '\tc0.2,0.8,0.5,1.7,0.8,2.5c0,0,0,0,0,0.1c0.2,0.4,0.3,0.9,0.5,1.3c0,0,0,0.1,0,0.1c3,7.1,8.7,12.9,16.1,16c3.7,1.5,7.6,2.3,11.5,2.3\n' +
        '\tc0.5,0,1,0,1.5,0H344c0,0,0,0,0,0c0.5,0,1,0,1.5,0c0.3,0,0.5,0,0.8-0.1c0.2,0,0.4,0,0.6,0c0.4,0,0.9-0.1,1.3-0.2c0,0,0.1,0,0.1,0\n' +
        '\tc3.4-0.5,6.7-1.6,9.6-3.1c0,0,0.1,0,0.1,0c0.4-0.2,0.7-0.4,1.1-0.6c0.1,0,0.1-0.1,0.2-0.1c0.3-0.2,0.6-0.4,1-0.6\n' +
        '\tc0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.6-0.4,0.8-0.6c0.1-0.1,0.3-0.2,0.4-0.3c0.3-0.2,0.5-0.4,0.8-0.6c0.1-0.1,0.3-0.2,0.4-0.3\n' +
        '\tc0.3-0.2,0.5-0.4,0.7-0.6c0.1-0.1,0.3-0.2,0.4-0.3c0.4-0.3,0.7-0.7,1.1-1c0,0,0,0,0,0l132.1-132.4c5.2-5.1,8.6-12.1,9-19.9\n' +
        '\tc0-0.3,0-0.6,0-1c0-0.2,0-0.3,0-0.5v0V35.7C506.4,35,506.4,34.4,506.3,33.7z M138.4,108.3v30.1h-30L138.4,108.3z M66.1,198.4h72.3\n' +
        '\tv132.9l-72.3,72.5V198.4z M314.1,446.3H108.5l72.4-72.6h133.1V446.3z M374.1,403.7v-30.1h30L374.1,403.7z',
    ],
  } as IconDefinition,
  right: {
    prefix: 'fac' as IconPrefix,
    iconName: 'right' as IconName,
    icon: [
      512,
      512,
      [],
      'e003',
      'M506.3,33.7c0-0.2,0-0.3,0-0.5c-0.1-1.4-0.3-2.7-0.6-4.1c0-0.1,0-0.1,0-0.2\n' +
        '\tc-0.3-1.4-0.7-2.7-1.2-4.1c0,0,0-0.1,0-0.1c-0.5-1.3-1.1-2.6-1.8-3.8c0-0.1-0.1-0.1-0.1-0.2c-0.7-1.2-1.4-2.3-2.3-3.4\n' +
        '\tc-0.1-0.1-0.2-0.2-0.2-0.3c-0.4-0.5-0.8-0.9-1.2-1.4c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.4-0.4-0.6-0.6c0,0,0,0,0,0\n' +
        '\tc-0.2-0.2-0.5-0.5-0.7-0.7c-0.1-0.1-0.2-0.2-0.4-0.4c-0.5-0.4-0.9-0.8-1.4-1.2c0,0,0,0-0.1-0.1c-0.2-0.2-0.4-0.4-0.7-0.5\n' +
        '\tc0,0,0,0,0,0c-0.3-0.2-0.6-0.5-0.9-0.7c-0.1-0.1-0.2-0.2-0.3-0.2c-1.1-0.8-2.3-1.5-3.6-2.2c0,0-0.1,0-0.1-0.1\n' +
        '\tc-2.1-1.1-4.4-1.9-6.8-2.4c0,0,0,0-0.1,0c-0.5-0.1-1-0.2-1.5-0.3c-0.1,0-0.2,0-0.2,0c-0.7-0.1-1.3-0.2-2-0.3c-0.1,0-0.2,0-0.3,0\n' +
        '\tc0,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.4,0c-0.4,0-0.7,0-1.1-0.1c-0.2,0-0.4,0-0.5,0c-0.1,0-0.3,0-0.4,0h0H168.4h0c-0.5,0-1,0-1.5,0\n' +
        '\tc-0.3,0-0.5,0-0.7,0.1c-0.2,0-0.5,0-0.7,0.1c-0.4,0-0.8,0.1-1.2,0.2c-0.1,0-0.1,0-0.2,0c-2.4,0.4-4.8,1-7,1.9c0,0,0,0-0.1,0\n' +
        '\tc-0.4,0.2-0.8,0.3-1.1,0.5c-0.1,0-0.2,0.1-0.2,0.1c-0.3,0.1-0.6,0.3-0.9,0.4c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0.1-0.4,0.2-0.6,0.3\n' +
        '\tc-0.2,0.1-0.5,0.3-0.7,0.4c-0.1,0.1-0.2,0.1-0.3,0.2c-2,1.2-3.8,2.6-5.4,4.3L14.9,147.2c-0.1,0.1-0.3,0.3-0.4,0.5\n' +
        '\tc-0.3,0.3-0.5,0.5-0.8,0.8c-0.2,0.2-0.4,0.5-0.6,0.7c-0.3,0.4-0.6,0.7-0.9,1.1c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2,0.3-0.4,0.6-0.6,0.9\n' +
        '\tc-0.2,0.2-0.3,0.5-0.5,0.7c-0.3,0.5-0.6,0.9-0.8,1.4c-0.1,0.2-0.2,0.4-0.3,0.7c-0.2,0.4-0.4,0.7-0.5,1.1c-0.1,0.2-0.2,0.4-0.3,0.6\n' +
        '\tc-0.2,0.5-0.4,1.1-0.6,1.6c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.4-0.3,0.8-0.4,1.3c-0.1,0.2-0.1,0.4-0.2,0.7c0,0.1-0.1,0.3-0.1,0.4\n' +
        '\tc0,0.1,0,0.1,0,0.2c-0.1,0.4-0.2,0.7-0.2,1.1c0,0.2-0.1,0.5-0.1,0.7c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.3-0.1,0.5\n' +
        '\tc0,0.2-0.1,0.5-0.1,0.7c-0.1,0.5-0.1,1-0.1,1.5c0,0.1,0,0.2,0,0.4c0,0.5,0,1,0,1.5v307.9c0,0.1,0,0.2,0,0.3c0,0.3,0,0.6,0,1\n' +
        '\tc0,0.1,0,0.3,0,0.4c0,0.3,0,0.6,0.1,0.9c0,0.2,0,0.4,0.1,0.6c0,0.1,0,0.1,0,0.2c0.1,0.5,0.1,1,0.2,1.5c0,0.1,0,0.2,0.1,0.4\n' +
        '\tc0,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.3,0.1,0.4c0.1,0.4,0.2,0.7,0.2,1.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1,0.1,0.3,0.1,0.4\n' +
        '\tc0.2,0.8,0.5,1.7,0.8,2.5c0,0,0,0,0,0.1c0.2,0.4,0.3,0.9,0.5,1.3c0,0,0,0.1,0,0.1c3,7.1,8.7,12.9,16.1,16c3.7,1.5,7.6,2.3,11.5,2.3\n' +
        '\tc0.5,0,1,0,1.5,0H344c0,0,0,0,0,0c0.5,0,1,0,1.5,0c0.3,0,0.5,0,0.8-0.1c0.2,0,0.4,0,0.6,0c0.4,0,0.9-0.1,1.3-0.2c0,0,0.1,0,0.1,0\n' +
        '\tc3.4-0.5,6.7-1.6,9.6-3.1c0,0,0.1,0,0.1,0c0.4-0.2,0.7-0.4,1.1-0.6c0.1,0,0.1-0.1,0.2-0.1c0.3-0.2,0.6-0.4,1-0.6\n' +
        '\tc0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.6-0.4,0.8-0.6c0.1-0.1,0.3-0.2,0.4-0.3c0.3-0.2,0.5-0.4,0.8-0.6c0.1-0.1,0.3-0.2,0.4-0.3\n' +
        '\tc0.3-0.2,0.5-0.4,0.7-0.6c0.1-0.1,0.3-0.2,0.4-0.3c0.4-0.3,0.7-0.7,1.1-1c0,0,0,0,0,0l132.1-132.4c5.2-5.1,8.6-12.1,9-19.9\n' +
        '\tc0-0.3,0-0.6,0-1c0-0.2,0-0.3,0-0.5v0V35.7C506.4,35,506.4,34.4,506.3,33.7z M138.4,108.3v30.1h-30L138.4,108.3z M66.1,198.4h72.3\n' +
        '\tv132.9l-72.3,72.5V198.4z M314.1,446.3H108.5l72.4-72.6h133.1V446.3z M314.1,313.7H198.4V198.4h115.6V313.7z M331.6,138.4H198.4\n' +
        '\tV65.7h205.6L331.6,138.4z',
    ],
  } as IconDefinition,
  left: {
    prefix: 'fac' as IconPrefix,
    iconName: 'left' as IconName,
    icon: [
      512,
      512,
      [],
      'e004',
      'M506.3,33.7c0-0.2,0-0.3,0-0.5c-0.1-1.4-0.3-2.7-0.6-4.1c0-0.1,0-0.1,0-0.2c-0.3-1.4-0.7-2.7-1.2-4.1\n' +
        '\tc0,0,0-0.1,0-0.1c-0.5-1.3-1.1-2.6-1.8-3.8c0-0.1-0.1-0.1-0.1-0.2c-0.7-1.2-1.4-2.3-2.3-3.4c-0.1-0.1-0.2-0.2-0.2-0.3\n' +
        '\tc-0.4-0.5-0.8-0.9-1.2-1.4c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.4-0.4-0.6-0.6c0,0,0,0,0,0c-0.2-0.2-0.5-0.5-0.7-0.7\n' +
        '\tc-0.1-0.1-0.2-0.2-0.4-0.4c-0.5-0.4-0.9-0.8-1.4-1.2c0,0,0,0-0.1-0.1c-0.2-0.2-0.4-0.4-0.7-0.5c0,0,0,0,0,0\n' +
        '\tc-0.3-0.2-0.6-0.5-0.9-0.7c-0.1-0.1-0.2-0.2-0.3-0.2c-1.1-0.8-2.3-1.5-3.6-2.2c0,0-0.1,0-0.1-0.1c-2.1-1.1-4.4-1.9-6.8-2.4\n' +
        '\tc0,0,0,0-0.1,0c-0.5-0.1-1-0.2-1.5-0.3c-0.1,0-0.2,0-0.2,0c-0.7-0.1-1.3-0.2-2-0.3c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0\n' +
        '\tc-0.1,0-0.3,0-0.4,0c-0.4,0-0.7,0-1.1-0.1c-0.2,0-0.4,0-0.5,0c-0.1,0-0.3,0-0.4,0h0H168.4h0c-0.5,0-1,0-1.5,0c-0.3,0-0.5,0-0.7,0.1\n' +
        '\tc-0.2,0-0.5,0-0.7,0.1c-0.4,0-0.8,0.1-1.2,0.2c-0.1,0-0.1,0-0.2,0c-2.4,0.4-4.8,1-7,1.9c0,0,0,0-0.1,0c-0.4,0.2-0.8,0.3-1.1,0.5\n' +
        '\tc-0.1,0-0.2,0.1-0.2,0.1c-0.3,0.1-0.6,0.3-0.9,0.4c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.3-0.7,0.4\n' +
        '\tc-0.1,0.1-0.2,0.1-0.3,0.2c-2,1.2-3.8,2.6-5.4,4.3L14.9,147.2c-0.1,0.1-0.3,0.3-0.4,0.5c-0.3,0.3-0.5,0.5-0.8,0.8\n' +
        '\tc-0.2,0.2-0.4,0.5-0.6,0.7c-0.3,0.4-0.6,0.7-0.9,1.1c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2,0.2-0.3,0.5-0.5,0.7\n' +
        '\tc-0.3,0.5-0.6,0.9-0.8,1.4c-0.1,0.2-0.2,0.4-0.3,0.7c-0.2,0.4-0.4,0.7-0.5,1.1c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2,0.5-0.4,1.1-0.6,1.6\n' +
        '\tc-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.4-0.3,0.8-0.4,1.3c-0.1,0.2-0.1,0.4-0.2,0.7c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.1,0,0.2\n' +
        '\tc-0.1,0.4-0.2,0.7-0.2,1.1c0,0.2-0.1,0.5-0.1,0.7c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.3-0.1,0.5\n' +
        '\tc0,0.2-0.1,0.5-0.1,0.7c-0.1,0.5-0.1,1-0.1,1.5c0,0.1,0,0.2,0,0.4c0,0.5,0,1,0,1.5v307.9c0,0.1,0,0.2,0,0.3c0,0.3,0,0.6,0,1\n' +
        '\tc0,0.1,0,0.3,0,0.4c0,0.3,0,0.6,0.1,0.9c0,0.2,0,0.4,0.1,0.6c0,0.1,0,0.1,0,0.2c0.1,0.5,0.1,1,0.2,1.5c0,0.1,0,0.2,0.1,0.4\n' +
        '\tc0,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.3,0.1,0.4c0.1,0.4,0.2,0.7,0.2,1.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1,0.1,0.3,0.1,0.4\n' +
        '\tc0.2,0.8,0.5,1.7,0.8,2.5c0,0,0,0,0,0.1c0.2,0.4,0.3,0.9,0.5,1.3c0,0,0,0.1,0,0.1c3,7.1,8.7,12.9,16.1,16c3.7,1.5,7.6,2.3,11.5,2.3\n' +
        '\tc0.5,0,1,0,1.5,0H344c0,0,0,0,0,0c0.5,0,1,0,1.5,0c0.3,0,0.5,0,0.8-0.1c0.2,0,0.4,0,0.6,0c0.4,0,0.9-0.1,1.3-0.2c0,0,0.1,0,0.1,0\n' +
        '\tc3.4-0.5,6.7-1.6,9.6-3.1c0,0,0.1,0,0.1,0c0.4-0.2,0.7-0.4,1.1-0.6c0.1,0,0.1-0.1,0.2-0.1c0.3-0.2,0.6-0.4,1-0.6\n' +
        '\tc0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.6-0.4,0.8-0.6c0.1-0.1,0.3-0.2,0.4-0.3c0.3-0.2,0.5-0.4,0.8-0.6c0.1-0.1,0.3-0.2,0.4-0.3\n' +
        '\tc0.3-0.2,0.5-0.4,0.7-0.6c0.1-0.1,0.3-0.2,0.4-0.3c0.4-0.3,0.7-0.7,1.1-1c0,0,0,0,0,0l132.1-132.4c5.2-5.1,8.6-12.1,9-19.9\n' +
        '\tc0-0.3,0-0.6,0-1c0-0.2,0-0.3,0-0.5v0V35.7C506.4,35,506.4,34.4,506.3,33.7z M314.1,446.3H108.5l72.4-72.6h133.1V446.3z\n' +
        '\t M314.1,313.7H198.4V198.4h115.6V313.7z M331.6,138.4H198.4V65.7h205.6L331.6,138.4z M374.1,403.7v-30.1h30L374.1,403.7z\n' +
        '\t M446.4,313.7h-72.3V180.8l72.3-72.5V313.7z',
    ],
  } as IconDefinition,
  top: {
    prefix: 'fac' as IconPrefix,
    iconName: 'top' as IconName,
    icon: [
      512,
      512,
      [],
      'e005',
      'M506.3,33.7c0-0.2,0-0.3,0-0.5c-0.1-1.4-0.3-2.7-0.6-4.1c0-0.1,0-0.1,0-0.2c-0.3-1.4-0.7-2.7-1.2-4.1\n' +
        '\tc0,0,0-0.1,0-0.1c-0.5-1.3-1.1-2.6-1.8-3.8c0-0.1-0.1-0.1-0.1-0.2c-0.7-1.2-1.4-2.3-2.3-3.4c-0.1-0.1-0.2-0.2-0.2-0.3\n' +
        '\tc-0.4-0.5-0.8-0.9-1.2-1.4c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.4-0.4-0.6-0.6c0,0,0,0,0,0c-0.2-0.2-0.5-0.5-0.7-0.7\n' +
        '\tc-0.1-0.1-0.2-0.2-0.4-0.4c-0.5-0.4-0.9-0.8-1.4-1.2c0,0,0,0-0.1-0.1c-0.2-0.2-0.4-0.4-0.7-0.5c0,0,0,0,0,0\n' +
        '\tc-0.3-0.2-0.6-0.5-0.9-0.7c-0.1-0.1-0.2-0.2-0.3-0.2c-1.1-0.8-2.3-1.5-3.6-2.2c0,0-0.1,0-0.1-0.1c-2.1-1.1-4.4-1.9-6.8-2.4\n' +
        '\tc0,0,0,0-0.1,0c-0.5-0.1-1-0.2-1.5-0.3c-0.1,0-0.2,0-0.2,0c-0.7-0.1-1.3-0.2-2-0.3c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0\n' +
        '\tc-0.1,0-0.3,0-0.4,0c-0.4,0-0.7,0-1.1-0.1c-0.2,0-0.4,0-0.5,0c-0.1,0-0.3,0-0.4,0h0H168.4h0c-0.5,0-1,0-1.5,0c-0.3,0-0.5,0-0.7,0.1\n' +
        '\tc-0.2,0-0.5,0-0.7,0.1c-0.4,0-0.8,0.1-1.2,0.2c-0.1,0-0.1,0-0.2,0c-2.4,0.4-4.8,1-7,1.9c0,0,0,0-0.1,0c-0.4,0.2-0.8,0.3-1.1,0.5\n' +
        '\tc-0.1,0-0.2,0.1-0.2,0.1c-0.3,0.1-0.6,0.3-0.9,0.4c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.3-0.7,0.4\n' +
        '\tc-0.1,0.1-0.2,0.1-0.3,0.2c-2,1.2-3.8,2.6-5.4,4.3L14.9,147.2c-0.1,0.1-0.3,0.3-0.4,0.5c-0.3,0.3-0.5,0.5-0.8,0.8\n' +
        '\tc-0.2,0.2-0.4,0.5-0.6,0.7c-0.3,0.4-0.6,0.7-0.9,1.1c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2,0.2-0.3,0.5-0.5,0.7\n' +
        '\tc-0.3,0.5-0.6,0.9-0.8,1.4c-0.1,0.2-0.2,0.4-0.3,0.7c-0.2,0.4-0.4,0.7-0.5,1.1c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2,0.5-0.4,1.1-0.6,1.6\n' +
        '\tc-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.4-0.3,0.8-0.4,1.3c-0.1,0.2-0.1,0.4-0.2,0.7c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.1,0,0.2\n' +
        '\tc-0.1,0.4-0.2,0.7-0.2,1.1c0,0.2-0.1,0.5-0.1,0.7c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.3-0.1,0.5\n' +
        '\tc0,0.2-0.1,0.5-0.1,0.7c-0.1,0.5-0.1,1-0.1,1.5c0,0.1,0,0.2,0,0.4c0,0.5,0,1,0,1.5v307.9c0,0.1,0,0.2,0,0.3c0,0.3,0,0.6,0,1\n' +
        '\tc0,0.1,0,0.3,0,0.4c0,0.3,0,0.6,0.1,0.9c0,0.2,0,0.4,0.1,0.6c0,0.1,0,0.1,0,0.2c0.1,0.5,0.1,1,0.2,1.5c0,0.1,0,0.2,0.1,0.4\n' +
        '\tc0,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.3,0.1,0.4c0.1,0.4,0.2,0.7,0.2,1.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1,0.1,0.3,0.1,0.4\n' +
        '\tc0.2,0.8,0.5,1.7,0.8,2.5c0,0,0,0,0,0.1c0.2,0.4,0.3,0.9,0.5,1.3c0,0,0,0.1,0,0.1c3,7.1,8.7,12.9,16.1,16c3.7,1.5,7.6,2.3,11.5,2.3\n' +
        '\tc0.5,0,1,0,1.5,0H344c0,0,0,0,0,0c0.5,0,1,0,1.5,0c0.3,0,0.5,0,0.8-0.1c0.2,0,0.4,0,0.6,0c0.4,0,0.9-0.1,1.3-0.2c0,0,0.1,0,0.1,0\n' +
        '\tc3.4-0.5,6.7-1.6,9.6-3.1c0,0,0.1,0,0.1,0c0.4-0.2,0.7-0.4,1.1-0.6c0.1,0,0.1-0.1,0.2-0.1c0.3-0.2,0.6-0.4,1-0.6\n' +
        '\tc0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.6-0.4,0.8-0.6c0.1-0.1,0.3-0.2,0.4-0.3c0.3-0.2,0.5-0.4,0.8-0.6c0.1-0.1,0.3-0.2,0.4-0.3\n' +
        '\tc0.3-0.2,0.5-0.4,0.7-0.6c0.1-0.1,0.3-0.2,0.4-0.3c0.4-0.3,0.7-0.7,1.1-1c0,0,0,0,0,0l132.1-132.4c5.2-5.1,8.6-12.1,9-19.9\n' +
        '\tc0-0.3,0-0.6,0-1c0-0.2,0-0.3,0-0.5v0V35.7C506.4,35,506.4,34.4,506.3,33.7z M66.1,198.4h72.3v132.9l-72.3,72.5V198.4z M314.1,446.3\n' +
        '\tH108.5l72.4-72.6h133.1V446.3z M314.1,313.7H198.4V198.4h115.6V313.7z M374.1,403.7v-30.1h30L374.1,403.7z M446.4,313.7h-72.3V180.8\n' +
        '\tl72.3-72.5V313.7z',
    ],
  } as IconDefinition,
  bottom: {
    prefix: 'fac' as IconPrefix,
    iconName: 'bottom' as IconName,
    icon: [
      512,
      512,
      [],
      'e006',
      'M506.3,33.7c0-0.2,0-0.3,0-0.5c-0.1-1.4-0.3-2.7-0.6-4.1c0-0.1,0-0.1,0-0.2\n' +
        '\tc-0.3-1.4-0.7-2.7-1.2-4.1c0,0,0-0.1,0-0.1c-0.5-1.3-1.1-2.6-1.8-3.8c0-0.1-0.1-0.1-0.1-0.2c-0.7-1.2-1.4-2.3-2.3-3.4\n' +
        '\tc-0.1-0.1-0.2-0.2-0.2-0.3c-0.4-0.5-0.8-0.9-1.2-1.4c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.4-0.4-0.6-0.6c0,0,0,0,0,0\n' +
        '\tc-0.2-0.2-0.5-0.5-0.7-0.7c-0.1-0.1-0.2-0.2-0.4-0.4c-0.5-0.4-0.9-0.8-1.4-1.2c0,0,0,0-0.1-0.1c-0.2-0.2-0.4-0.4-0.7-0.5\n' +
        '\tc0,0,0,0,0,0c-0.3-0.2-0.6-0.5-0.9-0.7c-0.1-0.1-0.2-0.2-0.3-0.2c-1.1-0.8-2.3-1.5-3.6-2.2c0,0-0.1,0-0.1-0.1\n' +
        '\tc-2.1-1.1-4.4-1.9-6.8-2.4c0,0,0,0-0.1,0c-0.5-0.1-1-0.2-1.5-0.3c-0.1,0-0.2,0-0.2,0c-0.7-0.1-1.3-0.2-2-0.3c-0.1,0-0.2,0-0.3,0\n' +
        '\tc0,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.4,0c-0.4,0-0.7,0-1.1-0.1c-0.2,0-0.4,0-0.5,0c-0.1,0-0.3,0-0.4,0h0H168.4h0c-0.5,0-1,0-1.5,0\n' +
        '\tc-0.3,0-0.5,0-0.7,0.1c-0.2,0-0.5,0-0.7,0.1c-0.4,0-0.8,0.1-1.2,0.2c-0.1,0-0.1,0-0.2,0c-2.4,0.4-4.8,1-7,1.9c0,0,0,0-0.1,0\n' +
        '\tc-0.4,0.2-0.8,0.3-1.1,0.5c-0.1,0-0.2,0.1-0.2,0.1c-0.3,0.1-0.6,0.3-0.9,0.4c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0.1-0.4,0.2-0.6,0.3\n' +
        '\tc-0.2,0.1-0.5,0.3-0.7,0.4c-0.1,0.1-0.2,0.1-0.3,0.2c-2,1.2-3.8,2.6-5.4,4.3L14.9,147.2c-0.1,0.1-0.3,0.3-0.4,0.5\n' +
        '\tc-0.3,0.3-0.5,0.5-0.8,0.8c-0.2,0.2-0.4,0.5-0.6,0.7c-0.3,0.4-0.6,0.7-0.9,1.1c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2,0.3-0.4,0.6-0.6,0.9\n' +
        '\tc-0.2,0.2-0.3,0.5-0.5,0.7c-0.3,0.5-0.6,0.9-0.8,1.4c-0.1,0.2-0.2,0.4-0.3,0.7c-0.2,0.4-0.4,0.7-0.5,1.1c-0.1,0.2-0.2,0.4-0.3,0.6\n' +
        '\tc-0.2,0.5-0.4,1.1-0.6,1.6c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.4-0.3,0.8-0.4,1.3c-0.1,0.2-0.1,0.4-0.2,0.7c0,0.1-0.1,0.3-0.1,0.4\n' +
        '\tc0,0.1,0,0.1,0,0.2c-0.1,0.4-0.2,0.7-0.2,1.1c0,0.2-0.1,0.5-0.1,0.7c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.3-0.1,0.5\n' +
        '\tc0,0.2-0.1,0.5-0.1,0.7c-0.1,0.5-0.1,1-0.1,1.5c0,0.1,0,0.2,0,0.4c0,0.5,0,1,0,1.5v307.9c0,0.1,0,0.2,0,0.3c0,0.3,0,0.6,0,1\n' +
        '\tc0,0.1,0,0.3,0,0.4c0,0.3,0,0.6,0.1,0.9c0,0.2,0,0.4,0.1,0.6c0,0.1,0,0.1,0,0.2c0.1,0.5,0.1,1,0.2,1.5c0,0.1,0,0.2,0.1,0.4\n' +
        '\tc0,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.3,0.1,0.4c0.1,0.4,0.2,0.7,0.2,1.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1,0.1,0.3,0.1,0.4\n' +
        '\tc0.2,0.8,0.5,1.7,0.8,2.5c0,0,0,0,0,0.1c0.2,0.4,0.3,0.9,0.5,1.3c0,0,0,0.1,0,0.1c3,7.1,8.7,12.9,16.1,16c3.7,1.5,7.6,2.3,11.5,2.3\n' +
        '\tc0.5,0,1,0,1.5,0H344c0,0,0,0,0,0c0.5,0,1,0,1.5,0c0.3,0,0.5,0,0.8-0.1c0.2,0,0.4,0,0.6,0c0.4,0,0.9-0.1,1.3-0.2c0,0,0.1,0,0.1,0\n' +
        '\tc3.4-0.5,6.7-1.6,9.6-3.1c0,0,0.1,0,0.1,0c0.4-0.2,0.7-0.4,1.1-0.6c0.1,0,0.1-0.1,0.2-0.1c0.3-0.2,0.6-0.4,1-0.6\n' +
        '\tc0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.6-0.4,0.8-0.6c0.1-0.1,0.3-0.2,0.4-0.3c0.3-0.2,0.5-0.4,0.8-0.6c0.1-0.1,0.3-0.2,0.4-0.3\n' +
        '\tc0.3-0.2,0.5-0.4,0.7-0.6c0.1-0.1,0.3-0.2,0.4-0.3c0.4-0.3,0.7-0.7,1.1-1c0,0,0,0,0,0l132.1-132.4c5.2-5.1,8.6-12.1,9-19.9\n' +
        '\tc0-0.3,0-0.6,0-1c0-0.2,0-0.3,0-0.5v0V35.7C506.4,35,506.4,34.4,506.3,33.7z M446.4,313.7h-72.3V180.8l72.3-72.5V313.7z M198.4,65.7\n' +
        '\th205.6l-72.5,72.6H198.4V65.7z M198.4,198.4h115.6v115.3H198.4V198.4z M138.4,108.3v30.1h-30L138.4,108.3z M66.1,198.4h72.3v132.9\n' +
        '\tl-72.3,72.5V198.4z',
    ],
  } as IconDefinition,
  custom: {
    prefix: 'fac' as IconPrefix,
    iconName: 'custom' as IconName,
    icon: [
      512,
      512,
      [],
      'e007',
      'M506.3,33.7c0-0.2,0-0.3,0-0.5c-0.1-1.4-0.3-2.7-0.6-4.1c0-0.1,0-0.1,0-0.2c-0.3-1.4-0.7-2.7-1.2-4.1\n' +
        '\tc0,0,0-0.1,0-0.1c-0.5-1.3-1.1-2.6-1.8-3.8c0-0.1-0.1-0.1-0.1-0.2c-0.7-1.2-1.4-2.3-2.3-3.4c-0.1-0.1-0.2-0.2-0.2-0.3\n' +
        '\tc-0.4-0.5-0.8-0.9-1.2-1.4c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.4-0.4-0.6-0.6c0,0,0,0,0,0c-0.2-0.2-0.5-0.5-0.7-0.7\n' +
        '\tc-0.1-0.1-0.2-0.2-0.4-0.4c-0.5-0.4-0.9-0.8-1.4-1.2c0,0,0,0-0.1-0.1c-0.2-0.2-0.4-0.4-0.7-0.5c0,0,0,0,0,0\n' +
        '\tc-0.3-0.2-0.6-0.5-0.9-0.7c-0.1-0.1-0.2-0.2-0.3-0.2c-1.1-0.8-2.3-1.5-3.6-2.2c0,0-0.1,0-0.1-0.1c-2.1-1.1-4.4-1.9-6.8-2.4\n' +
        '\tc0,0,0,0-0.1,0c-0.5-0.1-1-0.2-1.5-0.3c-0.1,0-0.2,0-0.2,0c-0.7-0.1-1.3-0.2-2-0.3c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0\n' +
        '\tc-0.1,0-0.3,0-0.4,0c-0.4,0-0.7,0-1.1-0.1c-0.2,0-0.4,0-0.5,0c-0.1,0-0.3,0-0.4,0h0H168.4h0c-0.5,0-1,0-1.5,0c-0.3,0-0.5,0-0.7,0.1\n' +
        '\tc-0.2,0-0.5,0-0.7,0.1c-0.4,0-0.8,0.1-1.2,0.2c-0.1,0-0.1,0-0.2,0c-2.4,0.4-4.8,1-7,1.9c0,0,0,0-0.1,0c-0.4,0.2-0.8,0.3-1.1,0.5\n' +
        '\tc-0.1,0-0.2,0.1-0.2,0.1c-0.3,0.1-0.6,0.3-0.9,0.4c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.3-0.7,0.4\n' +
        '\tc-0.1,0.1-0.2,0.1-0.3,0.2c-2,1.2-3.8,2.6-5.4,4.3L14.9,147.2c-0.1,0.1-0.3,0.3-0.4,0.5c-0.3,0.3-0.5,0.5-0.8,0.8\n' +
        '\tc-0.2,0.2-0.4,0.5-0.6,0.7c-0.3,0.4-0.6,0.7-0.9,1.1c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2,0.2-0.3,0.5-0.5,0.7\n' +
        '\tc-0.3,0.5-0.6,0.9-0.8,1.4c-0.1,0.2-0.2,0.4-0.3,0.7c-0.2,0.4-0.4,0.7-0.5,1.1c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2,0.5-0.4,1.1-0.6,1.6\n' +
        '\tc-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.4-0.3,0.8-0.4,1.3c-0.1,0.2-0.1,0.4-0.2,0.7c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.1,0,0.2\n' +
        '\tc-0.1,0.4-0.2,0.7-0.2,1.1c0,0.2-0.1,0.5-0.1,0.7c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.3-0.1,0.5\n' +
        '\tc0,0.2-0.1,0.5-0.1,0.7c-0.1,0.5-0.1,1-0.1,1.5c0,0.1,0,0.2,0,0.4c0,0.5,0,1,0,1.5v307.9c0,0.1,0,0.2,0,0.3c0,0.3,0,0.6,0,1\n' +
        '\tc0,0.1,0,0.3,0,0.4c0,0.3,0,0.6,0.1,0.9c0,0.2,0,0.4,0.1,0.6c0,0.1,0,0.1,0,0.2c0.1,0.5,0.1,1,0.2,1.5c0,0.1,0,0.2,0.1,0.4\n' +
        '\tc0,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.3,0.1,0.4c0.1,0.4,0.2,0.7,0.2,1.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1,0.1,0.3,0.1,0.4\n' +
        '\tc0.2,0.8,0.5,1.7,0.8,2.5c0,0,0,0,0,0.1c0.2,0.4,0.3,0.9,0.5,1.3c0,0,0,0.1,0,0.1c3,7.1,8.7,12.9,16.1,16c3.7,1.5,7.6,2.3,11.5,2.3\n' +
        '\tc0.5,0,1,0,1.5,0H344c0,0,0,0,0,0c0.5,0,1,0,1.5,0c0.3,0,0.5,0,0.8-0.1c0.2,0,0.4,0,0.6,0c0.4,0,0.9-0.1,1.3-0.2c0,0,0.1,0,0.1,0\n' +
        '\tc3.4-0.5,6.7-1.6,9.6-3.1c0,0,0.1,0,0.1,0c0.4-0.2,0.7-0.4,1.1-0.6c0.1,0,0.1-0.1,0.2-0.1c0.3-0.2,0.6-0.4,1-0.6\n' +
        '\tc0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.6-0.4,0.8-0.6c0.1-0.1,0.3-0.2,0.4-0.3c0.3-0.2,0.5-0.4,0.8-0.6c0.1-0.1,0.3-0.2,0.4-0.3\n' +
        '\tc0.3-0.2,0.5-0.4,0.7-0.6c0.1-0.1,0.3-0.2,0.4-0.3c0.4-0.3,0.7-0.7,1.1-1c0,0,0,0,0,0l132.1-132.4c5.2-5.1,8.6-12.1,9-19.9\n' +
        '\tc0-0.3,0-0.6,0-1c0-0.2,0-0.3,0-0.5v0V35.7C506.4,35,506.4,34.4,506.3,33.7z M446.4,313.7h-72.3V180.8l72.3-72.5V313.7z\n' +
        '\t M138.4,138.4h-30l30-30.1V138.4z M138.4,198.4v132.9l-72.3,72.5V198.4H138.4z M108.5,446.3l72.4-72.6h133.1v72.6H108.5z\n' +
        '\t M314.1,313.7H198.4V198.4h115.6V313.7z M374.1,373.7h30l-30,30.1V373.7z M404.1,65.7l-72.5,72.6H198.4V65.7H404.1z',
    ],
  } as IconDefinition,
  mirror: {
    prefix: 'fac' as IconPrefix,
    iconName: 'mirror' as IconName,
    icon: [
      512,
      512,
      [],
      'e008',
      'M284,470.3v27c0,7.4-6,13.5-13.5,13.5H244c-7.4,0-13.5-6-13.5-13.5v-27c0-7.4,6-13.5,13.5-13.5h26.5\n' +
        '\tC278,456.8,284,462.9,284,470.3z M270.6,373.5H244c-7.4,0-13.5,6-13.5,13.5v27.6c0,7.4,6,13.5,13.5,13.5h26.5\n' +
        '\tc7.4,0,13.5-6,13.5-13.5V387C284,379.6,278,373.5,270.6,373.5z M270.6,290.2H244c-7.4,0-13.5,6-13.5,13.5v27.6\n' +
        '\tc0,7.4,6,13.5,13.5,13.5h26.5c7.4,0,13.5-6,13.5-13.5v-27.6C284,296.3,278,290.2,270.6,290.2z M270.6,206.8H244\n' +
        '\tc-7.4,0-13.5,6-13.5,13.5v27.6c0,7.4,6,13.5,13.5,13.5h26.5c7.4,0,13.5-6,13.5-13.5v-27.6C284,212.9,278,206.8,270.6,206.8z\n' +
        '\t M270.6,123.5H244c-7.4,0-13.5,6-13.5,13.5v27.6c0,7.4,6,13.5,13.5,13.5h26.5c7.4,0,13.5-6,13.5-13.5V137\n' +
        '\tC284,129.6,278,123.5,270.6,123.5z M26.9,261.3c-9.2-9.2-24.9-2.7-24.9,10.3v213.9c0,13,15.7,19.5,24.9,10.3l136.9-106.9\n' +
        '\tc5.7-5.7,5.7-14.9,0-20.6L26.9,261.3z M486.7,263L350.1,369.4c-5.7,5.7-5.7,14.9,0,20.6l136.5,106.6c9.2,9.2,24.9,2.7,24.9-10.3\n' +
        '\tv-213C511.5,260.3,495.8,253.8,486.7,263z M435.9,16.3l4,72.8c-47-55.5-116.1-87.4-188.8-87.3C130,1.7,29.2,88.6,7.5,203.5\n' +
        '\tc-1.2,6.5,3.1,12.8,9.6,14c0.7,0.1,1.4,0.2,2.2,0.2h49c5.6,0,10.4-3.8,11.7-9.3C102.8,114,197.8,55.9,292.3,78.6\n' +
        '\tc42.8,10.3,80.2,36.3,104.8,72.9l-81.5-4.9c-6.6-0.3-12.2,4.8-12.6,11.4c0,0.2,0,0.4,0,0.6V206c0,6.6,5.4,12,12,12h180.2\n' +
        '\tc6.6,0,12-5.4,12-12V15.7c0-6.6-5.4-12-12-12h-47.4c-6.6,0-12,5.4-12,12C435.9,15.9,435.9,16.1,435.9,16.3z',
    ],
  } as IconDefinition,
  mesh: {
    prefix: 'fac' as IconPrefix,
    iconName: 'mesh' as IconName,
    icon: [
      512,
      512,
      [],
      'e009',
      'M0.9,339.7v-156c0-3.4,0-6.9,0-10.4c-0.1-1.5,0.4-2.9,1.4-4c16.1-19.2,32.1-38.4,48.2-57.6\n' +
        '\tC72.1,85.9,93.8,60,115.4,34.3c0.8-1,1.9-1.6,3.1-1.9c14.3-2.7,28.4-5.2,42.5-7.7l52-9.3l53.5-9.5c8.5-1.5,17-3.1,25.6-4.6\n' +
        '\tc1.1-0.2,2.2,0,3.1,0.4c48.8,27.4,97.6,54.9,146.3,82.4c3,1.2,5.7,2.9,8.1,4.9c1.7,2.7,2.9,5.7,3.6,8.8\n' +
        '\tc19.2,51.9,38.5,103.9,57.7,155.8c0.5,1.3,0.5,2.8,0,4.1c-19.2,51.9-38.3,103.7-57.4,155.6c-0.3,0.8-0.6,1.6-0.8,2.4\n' +
        '\tc-2.5,7.3-2.5,7.3-9,11c-17.7,10.1-35.4,20.2-53,30.3l-86.1,49.4c-3.1,1.8-6.2,3.6-9.3,5.3c-0.8,0.4-1.7,0.6-2.6,0.5\n' +
        '\tc-12.7-2.1-25.3-4.3-38-6.4l-45.1-7.7l-43-7.2c-12.7-2.1-25.3-4.4-38-6.3c-3.4-0.2-6.8-0.8-10.1-1.8c-1-0.3-2-0.8-2.8-1.5\n' +
        '\tc-4-4.5-7.8-9-11.7-13.6c-26.7-31.2-53.4-62.5-80.1-93.7c-6.2-7.2-12.4-14.5-18.7-21.6c-1.4-1.6-2.6-3.3-3.9-4.9\n' +
        '\tc-0.2-0.3-0.4-0.7-0.4-1c-0.2-2.3-0.4-4.5-0.4-6.8H0.9 M205.9,198H60.7l92.4,118.8L205.9,198 M404.5,134.9l-132.3,50.7l91.7,91.8\n' +
        '\tL404.5,134.9 M332.6,303l-90.3-90.2l-51.6,116L332.6,303 M339.4,341.6l-142,25.8l87.4,90.3L339.4,341.6 M62.6,158.6h130.9\n' +
        '\tl-66.2-77.4L62.6,158.6 M268.6,144.8L388.2,99l-86.8-48.8L268.6,144.8 M261,46.7L164.3,64l64.5,75.5L261,46.7 M113.7,330.2\n' +
        '\tl-73.5-94.7v86.6l73.5,8 M436.6,165.4L404.5,278l62.3-31.2L436.6,165.4 M234.8,462.6L167.4,393l-15.5,55.7L234.8,462.6 M406.4,402.8\n' +
        '\tl-30.3-46.9l-40.9,87.3l0.4,0.3L406.4,402.8 M118.2,423.6l14.5-51.9l-64.3-7l-0.2,0.5L118.2,423.6 M401.8,323.3l27.6,42.6l25.3-68.6\n' +
        '\tl-0.4-0.3L401.8,323.3',
    ],
  } as IconDefinition,
  opacity: {
    prefix: 'fac' as IconPrefix,
    iconName: 'opacity' as IconName,
    icon: [
      512,
      512,
      [],
      'e010',
      'M358.9,256h-51.2v-51.2h51.2V256z M358.9,102.4h51.2V51.6c-0.1-0.1-0.3-0.2-0.4-0.3h-50.8V102.4z M358.9,102.4h-51.2v51.2\n' +
        '\th51.2V102.4z M410.1,153.6h51.2V103c-0.1-0.2-0.3-0.4-0.4-0.6h-50.8V153.6z M461.3,256v51.2h45.6c3.4-16.5,5.1-33.7,5.1-51.2H461.3z\n' +
        '\t M461.3,153.6v51.2h45.6c-3.6-17.8-9.1-34.9-16.2-51.2H461.3z M358.9,307.2h-51.2v51.2h51.2V307.2z M358.9,256v51.2h51.2V256H358.9z\n' +
        '\t M358.9,204.8h51.2v-51.2h-51.2V204.8z M490.7,358.4h-29.4V409C472.9,393.4,482.8,376.5,490.7,358.4z M409.6,460.8h-50.7v29.6\n' +
        '\tC377,482.5,394,472.6,409.6,460.8z M307.7,51.2h51.2V21.5c-16.3-7.1-33.4-12.7-51.2-16.3V51.2z M358.9,358.4v51.2h51.2v-51.2H358.9z\n' +
        '\t M307.7,409.6v51.2h51.2v-51.2H307.7z M460.8,409.6h-50.7v50.8C429.3,446,446.3,428.9,460.8,409.6z M410.1,307.2v51.2h51.2v-51.2\n' +
        '\tH410.1z M259.2,0c-1.1,0-2.1,0-3.2,0C114.6,0,0,114.6,0,256s114.6,256,256,256c17.7,0,35-1.8,51.7-5.2v-45.9h-51.2v-51.2h51.2v-51.2\n' +
        '\th-51.2v-51.2h51.2V256h-51.2v-51.2h51.2v-51.2h-51.2v-51.2h51.2V51.2h-51.2V0H259.2z M410.1,256h51.2v-51.2h-51.2V256z',
    ],
  } as IconDefinition,
  axes: {
    prefix: 'fac' as IconPrefix,
    iconName: 'axes' as IconName,
    icon: [
      512,
      512,
      [],
      'e011',
      'M290.5,151.6c0,36.5,0.2,73-0.2,109.6c-0.1,7.2,2,11.8,7.7,16.4c64.2,52.8,128.2,105.9,192.2,159c13.2,11,17.8,25.7,13,40.9\n' +
        '\tc-7.7,24.6-36.5,32.4-57.4,15.5c-13.7-11.1-27.2-22.5-40.8-33.7c-48.5-40.1-97-80.1-145.4-120.3c-4.3-3.6-6.8-3.8-11.3-0.1\n' +
        '\tC187.5,390,126.6,440.8,65.7,491.7c-13.4,11.2-29.4,13.4-43,5.9c-20.1-11.2-24.4-40.4-7.3-56c17.7-16.2,36.5-31.2,54.9-46.6\n' +
        '\tc47.6-39.9,95.2-79.8,142.9-119.5c3.7-3.1,5.1-6.2,5.1-11c-0.2-74.2-0.1-148.5-0.1-222.7c0-14.2,5-26,16.8-34.3\n' +
        '\tc16.7-11.6,51.2-5.5,55,25c0.4,3.1,0.4,6.3,0.4,9.5C290.5,78.5,290.5,115,290.5,151.6z',
    ],
  } as IconDefinition,
  pen_plus: {
    prefix: 'fac' as IconPrefix,
    iconName: 'pen_plus' as IconName,
    icon: [
      512,
      512,
      [],
      'e012',
      'M368.4,18.3c21.9-21.9,57.3-21.9,79.2,0l46.1,46.1c21.8,21.9,21.8,57.3,0,79.2l-55.8,55.7L312.7,74.1L368.4,18.3z\n' +
        '\t M417.4,224l-46,153.3c-6,19.9-21.2,35.7-40.9,42.3L66.2,508.2c-11.3,3.8-23.8,1-32.4-7.3l153.6-153.6c6.3,3,13.3,4.7,19.8,4.7\n' +
        '\tc27.4,0,48-21.5,48-48s-20.6-48-48-48c-25.6,0-48,21.5-48,48c0,7.4,2.6,14.4,5.6,20.7L11.1,478.3c-8.3-8.6-11.1-21.1-7.3-32.5\n' +
        '\tl88.6-264.3c5.7-19.7,22.4-34.9,42.3-40.9l152.4-46L417.4,224z M52-56V53h104.5v47H52v110H3V100h-104.5V53H3V-56H52z',
    ],
  } as IconDefinition,
  pen_minus: {
    prefix: 'fac' as IconPrefix,
    iconName: 'pen_minus' as IconName,
    icon: [
      512,
      512,
      [],
      'e013',
      'M368.4,18.3c21.9-21.9,57.3-21.9,79.2,0l46.1,46.1c21.8,21.9,21.8,57.3,0,79.2l-55.8,55.7L312.7,74.1L368.4,18.3z\n' +
        '\t M417.4,224l-46,153.3c-6,19.9-21.2,35.7-40.9,42.3L66.2,508.2c-11.3,3.8-23.8,1-32.4-7.3l153.6-153.6c6.3,3,13.3,4.7,19.8,4.7\n' +
        '\tc27.4,0,48-21.5,48-48s-20.6-48-48-48c-25.6,0-48,21.5-48,48c0,7.4,2.6,14.4,5.6,20.7L11.1,478.3c-8.3-8.6-11.1-21.1-7.3-32.5\n' +
        '\tl88.6-264.3c5.7-19.7,22.4-34.9,42.3-40.9l152.4-46L417.4,224z M167.5,46.5v47h-220v-47H167.5z',
    ],
  } as IconDefinition,
} as IconPack;
