import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createBlock(_component_el_menu, {
    "background-color": "#{$color-sidebar}",
    "text-color": "#{$font-color.sidebar}",
    "default-active": _ctx.activeMenuItem,
    style: {"--el-menu-hover-text-color":"var(--color-sidebar)"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_sub_menu, { index: "2" }, {
        title: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('components.menu-sidebar.category.configuration')), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_el_menu_item, {
            index: "2-2",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(`/template-overview`)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('components.menu-sidebar.configuration.templates')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_sub_menu, { index: "3" }, {
        title: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('components.menu-sidebar.category.orthopedics')), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_el_menu_item, {
            index: "3-3",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push(`/work-in-progress-overview`)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('components.menu-sidebar.orthopedics.progress')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_sub_menu, { index: "4" }, {
        title: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('components.menu-sidebar.category.customize')), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_el_menu_item, {
            index: "4-1",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push(`/customize`)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('components.menu-sidebar.customize.configuration')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["default-active"]))
}