import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9a51b95c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "pdfContent",
  class: "pdf-content hidden"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.description), 1)
  ], 512))
}