import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_TransformTools = _resolveComponent("TransformTools")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.canProject)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["level-item action", {
      active: _ctx.isProjectionTypePlane,
    }]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setProjectionType(_ctx.ProjectionType.plane)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "square" })
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.canProject)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["level-item action", {
      active: _ctx.isProjectionTypeCylinder,
    }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setProjectionType(_ctx.ProjectionType.cylinder)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "database" })
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_component_TransformTools, {
      ref: "transformTools",
      modelValue: _ctx.modelValue,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelValue) = $event)),
      troisRenderer: _ctx.troisRenderer,
      editorMode: _ctx.editorMode,
      selectionList: _ctx.selectionList,
      viewCtrl: _ctx.viewCtrl,
      canTransform: _ctx.canProject,
      canManualTransform: true,
      canTogglePivotMode: false,
      selectObject: _ctx.selectObject,
      historyList: _ctx.historyList,
      ignoreCanTransformChanged: true,
      onChange: _ctx.changeTransform
    }, null, 8, ["modelValue", "troisRenderer", "editorMode", "selectionList", "viewCtrl", "canTransform", "selectObject", "historyList", "onChange"])
  ], 64))
}