<template>
    <div
      class="modal rounded-0"
      id="show-details"
      tabindex="-1"
      aria-hidden="true"
      data-backdrop="static"
    >
    <div class="modal-dialog modal-lg">
        <div class="modal-content border-0 shadow-sm rounded-0" id="info-content">
          
          <nav id="modal-nav" class="navbar navbar-expand-lg navbar-dark shadow-sm py-0">
            <div class="container-fluid">
              <a class="navbar-brand" href="#">{{ fileDetails?.fileName }}</a>
              <ul class="ml-auto d-flex mr-3">
                <li class="mx-1">
                  <button type="button" class="btn-icon-circle" @click="handleDownloadItem(fileDetails)">
                    <el-icon>
                      <font-awesome-icon icon="cloud-arrow-down"/>
                    </el-icon>
                  </button>
                </li>
                <li class="mx-1">
                  <button type="button" class="btn-icon-circle" @click="handleRemoveItem">
                    <el-icon>
                      <font-awesome-icon icon="trash"  />
                    </el-icon>
                  </button>
                </li>
              </ul>
              <div class="line"></div>
              <button type="button" class="btn-icon-circle ml-3" data-bs-dismiss="modal" aria-label="Close">
                <el-icon>
                  <font-awesome-icon icon="close"/>
                </el-icon>
              </button>
            </div>
          </nav> 
          
          <div class="modal-body p-0" v-if="fileDetails?.fileUrl.length > 0">
              <div class="video-container">
                <video 
                    controls 
                    v-if="isValidVideoFileType(fileDetails.fileName)"
                >
                    <source 
                        :src="fileDetails?.fileUrl" 
                        :type="`video/${fileDetails?.fileType}`"
                    >
                    Your browser does not support the video tag.
                </video>
                <img 
                    class="img-fluid" 
                    :src="fileDetails?.fileUrl" 
                    :alt="fileDetails?.fileName" 
                    v-if="isValidImageFileType(fileDetails.fileName)"
                >
              </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Modal } from 'bootstrap'; 
import { FileTypeImage, FileTypeVideo, isValidImageFileType, isValidVideoFileType } from '@/types/enum/upload';
import { downloadFileFromAzure } from '@/utils/file';

interface FileDetails {
  fileName: string,
  fileType: FileTypeVideo | FileTypeImage,
  fileUrl: string
}

@Options({
  emits: [
    'removeItem'
  ],
})

export default class PreviewRendererDetails extends Vue {
    @Prop({ default: 'show-details' }) id!: string;
    @Prop({ default: false }) canDownload!: boolean;
    @Prop({ default: false }) canRemove!: boolean;

    private isValidVideoFileType = isValidVideoFileType
    private isValidImageFileType = isValidImageFileType
    private showVideoModal: any = null;
    private fileDetails: FileDetails = {
        fileName: '',
        fileType: FileTypeVideo.WEBM || FileTypeImage.JPG, // Assuming MP4 as a default value, adjust as necessary
        fileUrl: ''
    }

    mounted(): void {
        const modalDialogOptions = {};
        this.showVideoModal = new Modal(
        '#show-details',
        modalDialogOptions
        );
    }

    handleRemoveItem(): void {
        this.$emit('removeItem')
    }

    async handleDownloadItem(payload: FileDetails): Promise<void> {
        console.log('handleDownloadItem', payload)
        const { fileName, fileUrl } = payload;
        await downloadFileFromAzure(fileName, fileUrl)
    }

    handleShowModal (payload: FileDetails): void {
        console.log('handleShowModal', payload)
        const { fileName, fileType, fileUrl } = payload;
        this.fileDetails = {fileName, fileType, fileUrl}
        this.showVideoModal.show();
    }
}
</script>

<style lang="scss" scoped>
.video-container {
  width: 100%; /* Membuat container mengikuti lebar div induk */
}

video {
  max-width: 100%; /* Membuat video menyesuaikan lebar div induk */
  height: auto; /* Menjaga rasio aspek video */
  display: block; /* Menghapus ruang ekstra di bawah video */
}

.modal {
  background-color: rgba(0, 0, 0, 0.795) !important; /* Mengubah warna backdrop menjadi putih */
  z-index: 6000 !important;
}

.modal-dialog {
  top: 60px;
}

#modal-nav {
  position: fixed; /* Atau gunakan fixed jika ingin selalu tetap di posisi atas layar */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1050; /* Pastikan z-index lebih tinggi dari elemen modal lainnya */
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 44%);
  background-color: rgb(25 29 33) !important
}

.btn-icon-circle:hover,
.btn-icon-circle:focus {
  background-color: rgb(3, 3, 4) !important
}
.btn-icon-circle {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: none;
  outline: none;
  font-size: 16px;
  background-color: rgb(25 29 33) !important
}

.line {
  width: 1px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.573)
}
</style>