<template>
  <el-aside
    width="3rem"
    @click="$emit('update:modelValue', !modelValue)"
    class="minimize"
  >
    <div class="expander-position">
      <div class="expander-side" v-if="useLeftSidebar">
        <font-awesome-icon icon="caret-left" v-if="modelValue" />
        <font-awesome-icon icon="caret-right" v-else />
      </div>
      <div class="expander-side expander-right" v-else>
        <font-awesome-icon icon="caret-right" v-if="modelValue" />
        <font-awesome-icon icon="caret-left" v-else />
      </div>
    </div>
  </el-aside>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  emits: ['update:modelValue'],
})
export default class ToggleSidebar extends Vue {
  @Prop({ default: true }) modelValue!: boolean;
  @Prop({ default: true }) useLeftSidebar!: boolean;
}
</script>

<style lang="scss" scoped>
.minimize {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--el-color-info-light-9);
  width: 0;
  //position: absolute;

  .expander-position {
    position: absolute;
  }

  .expander-side {
    background-color: var(--color-sidebar);
    position: relative;
    width: 3rem;
    height: 4rem;
    text-align: center;
    z-index: 10;
    border-radius: 0 0.5rem 0.5rem 0;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .expander-right {
    border-radius: 0.5rem 0 0 0.5rem;
    left: -3rem;
  }
}
</style>
