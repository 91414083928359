<template>
  <!-- Progress bars -->
  <div v-if="!readonly">
    <div class="row mb-2">
      <div class="col">
        <label for="carrying-out" class="form-label mb-2">{{ $t('components.product-parameter.fence.carrying-out') }}</label><br />
        <el-radio-group v-model="properties[0].fenceType" size="large" id="carrying-out">
          <el-radio-button
            v-for="fenceType in Object.values(FenceType)"
            :key="fenceType"
            :label="fenceType"
            @change="$emit('sizeChanged')"
            :disabled="readonly"
          >
            {{ $t(`enum.fenceType.${fenceType}`) }}
          </el-radio-button>
        </el-radio-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <label for="width-range" class="form-label mb-0">{{ $t('components.order-form.width') }}</label>
        <el-slider
          v-model="properties[0].calfCircumferenceWithoutDeduction"
          show-input
          :precision="0"
          :min="0"
          :max="20000"
          :step="1"
          :show-tooltip="false"
          id="width-range"
          @change="$emit('sizeChanged')"
          :disabled="readonly"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label for="height-range" class="form-label mb-0">{{ $t('components.order-form.height') }}</label>
        <el-slider
          v-model="properties[0].heightWithoutDeduction"
          show-input
          :precision="0"
          :min="0"
          :max="20000"
          :step="1"
          :show-tooltip="false"
          if="height-range"
          disabled
          />
      </div>
    </div>
    <div class="row" v-if="properties[0].fenceType != $t(`enum.fenceType.${FenceType.straight}`)">
      <div class="col">
        <label for="length-range" class="form-label mb-0">{{ $t('components.order-form.depth') }}</label>
        <el-slider
          v-model="properties[0].depth"
          show-input
          :precision="0"
          :min="0"
          :max="20000"
          :step="1"
          :show-tooltip="false"
          id="length-range" 
          @change="$emit('sizeChanged')"
          :disabled="readonly"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <h3 class="mt-3">{{ $t('components.order-summary.parameterTitle') }}</h3>
    <div class="row border-0">
      <div class="col mb-2">
        <div class="row">
          <label class="col form-label text-secondary mb-1">{{ $t('components.product-parameter.fence.carrying-out') }}</label>
          <label class="col form-label mb-1">{{ $t(`enum.fenceType.${properties[0].balconyType}`) }}</label>
        </div>
      </div>
      <div class="row border-0">
        <div class="col mb-2">
          <div class="row">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.width') }}</label>
            <label class="col form-label mb-1">{{ properties[0].calfCircumferenceWithoutDeduction }}</label>
          </div>
        </div>
        <div class="col mb-2">
          <div class="row">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.height') }}</label>
            <label class="col form-label mb-1">{{ properties[0].heightWithoutDeduction }}</label>
          </div>
        </div>
        <div class="col mb-2">
          <div class="row">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.depth') }}</label>
            <label class="col form-label mb-1">{{ properties[0].depth }}</label>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import { FenceType } from "@/types/enum/order";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
  emits: ['sizeChanged']
})

export default class ProductParamterFence extends Vue {
  @Prop() properties!: any[];
  @Prop({ default: false }) readonly!: boolean;

  FenceType = FenceType;

  mounted(): void {
    //this.properties[0].heightWithoutDeduction = 1100;
  }
}
</script>

<style scoped lang="scss">
.text-secondary {
  color: #0000008D !important;
}
.form-label {
  width: 20rem;
}
</style>