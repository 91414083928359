export enum Views {
  front = 'front',
  back = 'back',
  right = 'right',
  left = 'left',
  top = 'top',
  bottom = 'bottom',
  custom = 'custom',
}

export enum ModifyType {
  translate = 'translate',
  rotate = 'rotate',
  scale = 'scale',
}

export enum AxisType {
  x = 'x',
  y = 'y',
  z = 'z',
}

export enum ObjectType {
  None = 'None',
  Import = 'Import',
  Mesh = 'Mesh',
  Line = 'Line',
  Group = 'Group',
  Camera = 'Camera',
}

export enum SpecialObjectName {
  Pivot = 'Pivot',
  BoundingBox = 'BoundingBox',
  StartPoint = 'StartPoint',
  EndPoint = 'EndPoint',
  ArrowHelper = 'ArrowHelper',
  CenterPoint = 'CenterPoint',
  Bone = 'Bone',
  Skeleton = 'Skeleton',
  CurvePoint = 'CurvePoint',
  FFDPoint = 'FFDPoint',
  SoftModifier = 'SoftModifier',
  ProjectionPlane = 'ProjectionPlane',
}

export enum SpecialObjectPrefix {
  plane = 'plane:',
  curve = 'curve:',
  camera = 'camera:',
}

export enum OrientationAxis {
  none = 'none',
  x = 'x',
  y = 'y',
  z = 'z',
  all = 'all',
}

export enum ProjectionType {
  plane,
  cylinder,
}

export enum FFDAxis {
  x = 'x',
  y = 'y',
  z = 'z',
  xy = 'xy',
  xz = 'xz',
  yz = 'yz',
  all = 'all',
}

export const isSpecialObject = (
  name: string,
  includePrefix = true
): boolean => {
  return (
    !!name &&
    (Object.values(SpecialObjectName).includes(name as SpecialObjectName) ||
      (includePrefix &&
        Object.values(SpecialObjectPrefix).find((item) =>
          name.startsWith(item)
        ) !== undefined))
  );
};
